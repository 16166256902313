// privileges: List<{
//     id: string,
//     name: string,
//     description: string,
//     permission: number,
//     createdBy: string,
//     createdAt: date,
//     updatedAt: date,
// }>,
// total: number

import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef Privileges
 * @property {string} name
 * @property {string} description
 * @property {number} permission
 * @property {string} createdBy
 * @property {date} createdAt
 * @property {date} updatedAt
 * @property {string} id
 */

/**
 * @typedef GetPivilegesListRes
 * @property {Privileges[]} privileges
 * @property {number} total
 */

// /**
//  * @typedef GetRecommendationsFilters
//  * @property {string[]} accounts
//  * @property {string[]} regions
//  */

/**
 * @typedef GetPivilegesListReq
 * @property {number} page
 * @property {number} limit
 * @property {string} filterQuery
 */

export const getPrivilegesList = (token) => {
  /**
   * @param {GetPivilegesListReq} req
   * @returns {Promise<GetPivilegesListRes>}
   */
  return async (req, signal) => {
    const privilegesList = await fetch(
      `${config.apiHost}/v1/privileges/get-all`,
      {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!privilegesList.ok) {
      throw new Error((await privilegesList.json()).message);
    }
    return await privilegesList.json();
  };
};

/**
 * @param {GetPivilegesListReq} req
 * @param {any[]=} deps
 */
export const usePrivilegesList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getPrivilegesList", req, ...deps],
    queryFn: ({ signal }) => getPrivilegesList(appContext.idToken)(req, signal),
  });
  return query;
};
