import React from "react";
import TableErrorState from "@/components/States/TableErrorState";
import { Placeholder } from "react-bootstrap";
import styles from "@/components/States/states.module.css";
import { Stack } from "react-bootstrap";

export default function LoadingRbacTable() {
  return (
    <>
      {/* <Stack direction="col" gap={2}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Placeholder
            style={{ width: "20%", height: 24 }}
            className={styles.placeholder_css}
          />
          <Placeholder
            style={{ width: "10%", height: 24 }}
            className={styles.placeholder_css}
          />
        </div>

        <Placeholder
          style={{ width: "60%", height: 24 }}
          className={styles.placeholder_css}
        />

        <Placeholder
          style={{ width: "30%", height: 24 }}
          className={styles.placeholder_css}
        />
      </Stack> */}

      <TableErrorState />
    </>
  );
}
