import { AppContext } from "@/App.context";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

const setBudgetListAzure = (token) => {
  return async (Qdata) => {
    if (!Qdata?.tags[0]?.values?.length) Qdata.tags = [];
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/list?scope=${Qdata?.scope}`
    if(Qdata.type !== "card"){
      url += `&page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`
    }
    const setBudget = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          mg: Qdata.mg,
          rg: Qdata.rg,
          accounts: Qdata.accounts,
          tags: Qdata.tags,
        }),
      }
    );
    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error(errorResponse.message);
    }
    const res = await setBudget.json();
    if (res?.data?.length === 0) {
      if (Qdata?.accounts?.length || Qdata?.tags?.length || Qdata?.rg?.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

const setBudgetListAws = (token) => {
  return async (Qdata) => {
    if (!Qdata?.tags[0]?.values?.length) Qdata.tags = [];

    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/list?orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`
    if(Qdata?.type !=="card"){
      url += `&page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}`
    }
    const setBudget = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },

        body: JSON.stringify({
          accounts: Qdata?.accounts,
          rg: Qdata?.rg,
          tags: Qdata?.tags,
        }),
      }
    );

    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error(errorResponse.message);
    }
    const res = await setBudget.json();
    console.log("response", res);

    if (res?.data?.length === 0) {
      if (Qdata?.accounts?.length || Qdata?.tags?.length || Qdata?.rg?.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return res;
  };
};

export const useListApiAws = (querydata) => {
  console.log("querydata",querydata)
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setBudgetListAws", querydata],
    queryFn: () => setBudgetListAws(appContext.idToken)(querydata),
    retry:false
  });
  return query;
};

export const useListApiAzure = (payload) => {
  console.log("payload",payload)

  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setBudgetListAzure",  payload],
    queryFn: () => setBudgetListAzure(appContext.idToken)( payload),
    retry:false
  });
  return query;
};
