import React, { useContext } from "react";
import { useMemo, useState } from "react";
import { useDebouncedValue } from "rooks";
import styles from "@/page-components/admin/admin.module.css";
import Avatar from "@/assets/img/user_pic.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserTableComponent from "../tables/admin.profile.group.table.js";
import GroupTableComp from "../groups/group.table.js";
import { useGroupsList } from "@/services/admin/Groups/getGroupsList.repo.js";
import { useSearchParams } from "react-router-dom";
import Overview from "./overview.part.js";
import UserProfileTable from "../tables/admin.profile.group.table.js";
import { UsersContext } from "./admin.context.js";
import { useUserContext } from "./admin.context.js";
import { AppContext } from "@/App.context.js";
import { useUserProfileDetails } from "@/services/admin-profile/getUserProfileDetails.repo.js";

export default function UserProfile() {
  const context = useUserContext();
  const appContext = useContext(AppContext);
  const id = appContext?.user?.id || null;

  const { data } = useUserProfileDetails(id);

  return (
    <UsersContext.Provider value={context}>
      <div>
        <div className={styles.userContainer}>
          <div className={styles.userInfoContainer}>
            <img
              src={Avatar}
              alt="Avatar"
              className="rounded-circle hover"
              width="120px"
              height="120px"
            />
            <div className={styles.userDetails}>
              <div className={styles.nameAndDesignation}>
                <span className={styles.userName}>
                  {data?.user?.name || "--"}
                </span>
                {/* <div style={{ width: "10px", height: "10px" , borderRadius:"50%", backgroundColor:"gray"}}></div> */}
                <span className={styles.designation}>
                  {data?.userProfile?.jobTitle || "--"}
                </span>
              </div>

              <div className={styles.teamAndEmail}>
                <span className={styles.teamName}>
                  Team - {data?.userProfile?.department || "--"}
                </span>
                <span className={styles.emailStyling}>
                  {data?.userProfile?.mail || "--"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.userTableContainer}>
          <Tabs
            defaultActiveKey="overview"
            className={styles.tabsUserProfile}
            variant="underline"
          >
            <Tab eventKey="overview" title="Overview" id={styles.mybuttonsty}>
              <Overview data={data} />
            </Tab>
            <Tab eventKey="groups" title="Groups" id={styles.mybuttonsty}>
              <UserProfileTable />
            </Tab>
          </Tabs>
        </div>
      </div>
    </UsersContext.Provider>
  );
}
