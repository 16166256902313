import React, { useState, useContext, useMemo } from "react";
import { useAccountRegionStatistics } from "@/services/saving/getTagListSaving.repo";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { Button } from "react-bootstrap";
import styles from "../savings.module.css";
import { SavingContext } from "../saving-context-file.part";
import add from "@/assets/img/add.svg";
import TagItem from "./tag-item.part";
// import { useTagValueStatistics } from "@/services/saving/getTagListSaving.repo";
import clsx from "clsx";
import FilterState from "@/components/States/FilterState";
import { useTagsTypesStatistics } from "@/services/saving/getTagTypesSaving.repo";

const FilterSection = ({ service, name }) => {
  const [selectedTittle, setSelectedTittle] = useState(null);
  const context = useContext(SavingContext);
  const res = { service: service, selectedTittle: selectedTittle };
  const dropdown = useAccountRegionStatistics(res);

  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const { data: tagsdata, isLoading: TagsLoading } =
    useTagsTypesStatistics(query);

  if (TagsLoading) {
    return <FilterState />;
  }

  return (
    <>
      {/* {TagsLoading && (<FilterState/>)} */}
      <div className={styles.filter_main_div}>
        <Filter
          tittle={name}
          setSelectedTittle={setSelectedTittle}
          data={dropdown?.data?.data}
          filterType="account"
          selectedValues={context.selectAccounts}
          onSelect={(list) => {
            context?.setSelectAccounts(list);
          }}
          onRemove={(list) => {
            context?.setSelectAccounts(list);
          }}
        />

        {service === "azure" && (
          <Filter
            tittle={"Resource Group"}
            setSelectedTittle={setSelectedTittle}
            data={dropdown?.data?.data}
            filterType="rg"
            selectedValues={context?.selectRg}
            onSelect={(list) => {
              context?.setSelectRg(list);
            }}
            onRemove={(list) => {
              context?.setSelectRg(list);
            }}
          />
        )}

        <Filter
          tittle={"Region"}
          setSelectedTittle={setSelectedTittle}
          data={dropdown?.data?.data}
          filterType="region"
          selectedValues={context.selectRegions}
          onSelect={(list) => {
            context?.setSelectRegions(list);
          }}
          onRemove={(list) => {
            context?.setSelectRegions(list);
          }}
        />

        <hr className="w-100 mb-0" />

        <div className={styles.filter_box_div}>
          <div className={styles.filter_name_addsign}>
            <div className={styles.filter_name}>Tags</div>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                context.setSelectedTags((arr) => {
                  const newArr = [...arr];
                  newArr.push({
                    filterOn: "",
                    filterType: "include",
                    values: [],
                  });
                  return newArr;
                });
              }}
            >
              <img src={add} alt="" />
            </Button>
          </div>
          {context.selectedTags.map((tag, index) => (
            <TagItem
              service={service}
              tag={tag}
              index={index}
              tagsdata={tagsdata}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const context = useContext(SavingContext);

  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      context.setSelectAccounts(selectedList);
    }
    if (filterType === "region") {
      context.setSelectRegions(selectedList);
    }
    if (filterType === "rg") {
      context.setSelectRg(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription Name" || title === "Account Name") {
      title = "Account";
    }
    if (title === "Resource Group") {
      title = "rg";
      // setSelectedTittle(title);
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <>
      <div className={clsx(styles.filter_padding_div)}>
        <div className={clsx(styles.dimension_div)}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={clsx(styles.filter_group_name_css)}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.id,
                  name: e.name,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
