import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import modulestyle from "@/page-components/RI-Capacity/ri.module.css";
// import notification from "@/assets/img/notification-cross.svg";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import clsx from "clsx";
import { RiContext } from "@/page-components/RI-Capacity/ri.context";
import { Offcanvas, Spinner } from "react-bootstrap";
import BulkYearComponent from "./BulkYearComponent";
import { useBulkModalStatics } from "@/services/ri-recommendation/getBulkModalStatics.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const RecommendationModal = (service) => {
  const context = useContext(RiContext);
  const [hideFilterSection, setHideFilterSection] = useState(false);

  const handleClose = () => {
    context.setShowBulkModal(false);
  };
  const query = useMemo(() => {
    return {
      service: service.service,
      subscriptionId: context.subscriptionId,
      platform: context.platform,
      vmFamily: context.instanceType,
      region: context.region,
    };
  }, [
    context.instanceType,
    context.platform,
    context.region,
    context.subscriptionId,
    service,
  ]);

  const { data, isLoading, error } = useBulkModalStatics(query);
  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);

  return (
    <Offcanvas
      show={context?.showBulkModal}
      placement="end"
      style={{
        backgroundColor: hideFilterSection ? "#F8F9FA" : "",
        width: "90%",
        padding: "16px",
        overflowY: "scroll",
      }}
    >
      <div className={style.recommendationHeader}>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!hideFilterSection && (
            <>
              Bulk Recommendation
              <img
                style={{ marginLeft: "8px" }}
                src={infoIcon}
                width={16}
                height={16}
                alt="infoIcon"
              />
            </>
          )}
        </span>
        <img
          src={crossModal}
          style={{ cursor: "pointer" }}
          alt="Close Notification"
          onClick={handleClose}
        />
      </div>

      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}

      {data?.recommendations?.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "12px",
            }}
          >
            <div className={style.recommendationText}>
              <p>
                Instead of procuring capacity for each Virtual Machine, group
                all virtual machines based on attributes (Tenancy, VM Shape,
                Region, and Platform) and provide bulk purchase recommendations
                with instance flexibility enabled. This approach allows capacity
                to be shared among all family members with matching attributes.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px 15% 16px 12px",
              }}
            >
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>VM Family</span>
                <span className={modulestyle.vm_value}>
                  {context?.instanceType}
                </span>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>On-Demand Cost</span>
                <div
                  className={modulestyle.vm_other_values}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <span>{Number(context?.onDemandC).toFixed(2)}</span>
                  <span className={clsx("container", style.project_cost1)}>
                    /m
                  </span>
                </div>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Qty-To-Purchase</span>
                <span className={modulestyle.vm_other_values}>
                  {context?.quantity_to_purchase}
                </span>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>
                  Instance Flexibility
                </span>
                <span className={modulestyle.vm_other_values}>
                  {context?.instance_flexibility}
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className={modulestyle.modalHeadingText}>
              Reserved Instance models
            </div>

            <div className={modulestyle.container}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "20%" }}
              >
                <div
                  className={modulestyle.specs}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    textAlign: "left",
                    paddingTop: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <span className={modulestyle.specs_name}>SPECS</span>
                  <span className={modulestyle.on_demand_cost}>Cost:</span>
                  <span className={modulestyle.on_demand_cost}>
                    Monthly Cost:
                  </span>
                  <span className={modulestyle.on_demand_cost}>
                    Break-Even period: -
                  </span>
                  <span className={modulestyle.on_demand_cost}>
                    Savings vs. On-Demand: -
                  </span>
                </div>
              </div>
              <div style={{ width: "80%" }}>
                <BulkYearComponent service={service} data={data} />
              </div>
            </div>
            <div className={modulestyle.resourceDetails}>
              <span className={modulestyle.resource_name}>VM Series:</span>
              <span className={modulestyle.resource_value}>
                {context?.VMSeries} ,
              </span>
              <span className={modulestyle.resource_name}>Region:</span>
              <span className={modulestyle.resource_value}>
                {context?.region} ,
              </span>
              <span className={modulestyle.resource_name}>Platform:</span>
              <span className={modulestyle.resource_value}>
                {context?.platform} ,
              </span>
              <span className={modulestyle.resource_name}>Tenancy:</span>
              <span className={modulestyle.resource_value}>
                {context?.Tenancy},
              </span>
              <span className={modulestyle.resource_name}>Scope:</span>
              <span className={modulestyle.resource_value}>
                {context?.scopeName}
              </span>
            </div>
          </div>
        </>
      )}
    </Offcanvas>
  );
};

export default RecommendationModal;
