import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAWSStatistics} data
 */

/**
 * @typedef GetAWSStatistics
 * @property {string} account_name
 * @property {string} account_id
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'all-cloud' | 'aws' | 'azure' | 'gcp'} service
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatistics>}
 */

export const getBillingAccounts = (token) => {
  return async ({ service,accountType }) => {
    console.log("accountType:", accountType)
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/filter?type=billing_profile&account_type=${accountType}`,
      {
        method: "GET",
        body: JSON.stringify(),
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
   
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    // const hasNoData = Object.values(data || {}).every((serviceData) =>
    //   serviceData.every(
    //     (item) => item.resource_count === 0 && item.unblended_cost === null
    //   )
    // );

    // if (hasNoData) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR);
    // }

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useBillingAccounts = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getBillingAccounts", req, ...deps],
    queryFn: () => getBillingAccounts(appContext.idToken)(req),
  });
  return query;
};
