import React, { useState } from "react";
import notification from "../assets/img/notification-cross.svg";
import line from "../assets/img/line.svg";
import "../assets/css/NotificationModal.css";

const NotificationModal = ({ toggleModal }) => {
  const [activeTab, setActiveTab] = useState("AllNotification");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "SavingOpportunities":
        return (
          <div>
            {/* Content for Saving Opportunities tab */}
            Saving Opportunities Content
          </div>
        );
      case "AnomalyDetections":
        return (
          <div>
            {/* Content for Anomaly Detections tab */}
            {/* <div>Anomaly Detections Content</div> */}
            <div className="increase-ec2-s3">First AnomalyDetections</div>
            <div className="increase-ec2-s3">Second AnomalyDetections</div>
            <div className="increase-ec2-s3">Third AnomalyDetections</div>
          </div>
        );
      default:
        return (
          <div className={`tab-content ${activeTab === "AllNotification" ? "active-tab" : ""}`}>
            {/* Content for All Notification tab */}
            {/* All Notification Content */}
            <div className="amazone-savings-missed__Cont">
              <div className="amazone-cloud-watch">
                <div className="amazone-cloud-watch-text">AmazonCloudWatch costs are up $122.04 (132%)</div>
              </div>

              {/* <div className="line__DIV">{" "}<img src={line} /></div> */}
              <img src={line} className="line__DIV"/>

              <div className="increase-cloudeq__DIV">
                <div className="increase-value">12 Oct 2023</div>
                <div className="increase-aws-value">Increase of 12% in Compute costs in AWS in Account cloudEQ</div>
                <div className="increase-ec2-s3">Major increase in EC2, S3</div>
              </div>

              {/* <div className="line__DIV">{" "}<img src={line} /></div> */}
              <img src={line} className="line__DIV"/>


              <div className="increase-cloudeq__DIV">
                <div className="increase-value">12 Oct 2023</div>
                <div className="increase-aws-value">Storage increased by 12%</div>
                <div className="increase-ec2-s3">
                  M16 EC2 instances added this month
                </div>
              </div>

              {/* <div className="line__DIV">{" "}<img src={line} /></div> */}
              <img src={line} className="line__DIV"/>


              <div className="increase-cloudeq__DIV">
                <div className="increase-value">11 Oct 2023</div>
                <div className="increase-aws-value">
                  Missed Saving Opportunities
                </div>
                <div className="increase-ec2-s3">
                  You could have saved ~$270.54 in the last 30 days if you purchased
                  additional EC2 RIs.
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="main-notification__Container ">
      <div className="notification-cross-icon">
        <div className="notification-text">Notifications</div>
        <div className="notification-cross__Img hover" onClick={toggleModal}>
          <img src={notification} alt="Close Notification" />
        </div>
      </div>

      <div className="notification-anomaly-savings__Div">
        <div className="all-notification__Container nav nav-tabs">
          <div
            className={`all-notification-first__container ${activeTab === "AllNotification" ? "active-tab" : ""}`}
          >
            <div
              className="data-toggle "
              onClick={() => handleTabClick("AllNotification")}
            >
              <button  data-toggle="tab"  className="all-notification-first__container-text btn-color">
                All Notification
              </button>
              
            </div>
          </div>
          <div
            className={`saving-Opportunities ${activeTab === "SavingOpportunities" ? "active-tab" : ""}`}
          >
            <div
              className="data-toggle "
              onClick={() => handleTabClick("SavingOpportunities")}
            >
              <button  data-toggle="tab"  className="saving-Opportunities-text btn-color">
                Saving Opportunities
              </button>
            </div>
          </div>
          <div
            className={`anomaly-detections__Cont ${activeTab === "AnomalyDetections" ? "active-tab" : ""}`}
          >
            <div
              className="data-toggle "
              onClick={() => handleTabClick("AnomalyDetections")}
            >
              <button  data-toggle="tab"  className="anomaly-detections-text btn-color">
                Anomaly Detections
              </button>
            </div>
            <div className="anomaly-detections-value">
              <div className="anomaly-detections-value__container">3</div>
            </div>
          </div>
        </div>

        {renderTabContent()}
      </div>
    </div>
  );
};

export default NotificationModal;
