import React, { Fragment, useState } from "react";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import RemoveIcon from "@/assets/img/filter-delete.svg";
import collapseIcon from "@/assets/img/collapseIcon.svg";
import dropdown from "@/assets/img/dropDownImg.svg";
import dropup from "@/assets/img/dropUp.svg";
import clsx from "clsx";

function CollapsibleRow({
  service,
  scope,
  owner,
  vmSeries,
  vmType,
  resourceId,
  region,
  quantity,
  unit,
  unitPrice,
  zone,
  cost,
  showRemoveIcon = false,
  defaultIsOpen = false,
  children,
  level = 0,
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const determineScope = () => {
    return (
      scope.scopeName ||
      scope.subscriptionId ||
      scope.rgName ||
      scope.resourceName ||
      "N/A"
    );
  };

  const getIcon = () => {
    if (scope.scopeName) {
      return isOpen ? dropup : dropdown;
    } else if (children && children.length > 0) {
      return isOpen ? RemoveIcon : collapseIcon;
    }
    return null;
  };

  return (
    <Fragment>
      <tr className={clsx(styles.collapse_tr)}>
        <td
          onClick={() =>
            (scope.scopeName || (children && children.length > 0)) &&
            setIsOpen(!isOpen)
          }
          style={
            scope.scopeName ||
            (children && children.length > 0) ||
            showRemoveIcon
              ? {
                  cursor: "pointer",
                  paddingLeft: level > 0 ? `${level * 24}px` : "8px",
                }
              : { paddingLeft: level > 0 ? `${level * 24}px` : "8px" }
          }
        >
          {getIcon() && (
            <img
              src={getIcon()}
              alt=""
              style={{
                marginRight: "8px",
                transform: "translate(0px, -1px)",
              }}
            />
          )}
          <span>{determineScope()}</span>
        </td>
        <td>{resourceId}</td>
        <td>{vmType}</td>
        <td>{vmSeries}</td>
        <td>{region}</td>
        {service === "aws" && <td>{zone}</td>}
        <td>{owner}</td>
        <td style={{ textAlign: "end" }}>{cost > 0 ? "$" + cost : "--"}</td>
      </tr>

      {isOpen &&
        children?.map((child, index) => (
          <CollapsibleRow
            key={index}
            scope={child}
            resourceId={child.resourceId}
            vmType={child.vmType}
            vmSeries={child.vmSeries}
            region={child.region}
            owner={child.owner}
            cost={child.cost}
            showRemoveIcon={child.childrens?.length > 0}
            level={level + 1}
            children={child.childrens}
          />
        ))}
    </Fragment>
  );
}

export default function ScopeModalTable(props) {
  return (
    <div>
      <Table responsive>
        <thead className={styles.collapsibleTableHead}>
          <tr className={styles.tr_head_css}>
            <td className={styles.col_header} style={{ width: "35%" }}>
              Scope
            </td>
            <td className={styles.col_header}>Resource ID</td>
            <td className={styles.col_header}>
              {props.service === "azure"
                ? "VM Type"
                : props.service === "aws"
                ? "Instance Type"
                : "--"}
            </td>
            {props.service === "azure" && (
              <td className={styles.col_header}>VM Series</td>
            )}
            <td className={styles.col_header}>Region</td>
            {props.service === "aws" && (
              <td className={styles.col_header}>AZ - Availability Zone</td>
            )}
            <td className={styles.col_header}>Owner</td>
            <td className={clsx(styles.col_header, styles.side_align)}>
              Amount in USD
            </td>
          </tr>
        </thead>
        <tbody>
          {(props?.modaltabledata?.data || []).map((tableData, index) => (
            <Fragment key={index}>
              <CollapsibleRow
                service={props.service}
                key={index}
                scope={tableData}
                resourceId={tableData.resourceId}
                vmType={tableData.vmType}
                vmSeries={tableData.vmSeries}
                region={tableData.region}
                zone={tableData.zone || "--"}
                owner={tableData.owner}
                cost={tableData.cost}
                showRemoveIcon={tableData.childrens?.length > 0}
                level={0}
                children={tableData.childrens}
              />
            </Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export const ToolTipComponent = ({ data, childrens, prefix = "$ " }) => {
  const formattedData = prefix + (data || 0)?.toFixed(8);
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
          <strong>{formattedData}</strong>
        </Tooltip>
      }
    >
      <span style={{ cursor: "pointer" }}>{childrens}</span>
    </OverlayTrigger>
  );
};
