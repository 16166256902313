import React from 'react'
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";

export const CustomTooltip = ({ active, payload, month, service }) => {
  console.log("object")
    if (!active) {
      return null;
    }
    return (
      <div className={styless.custom_tooltip}>
        <div className="tooltip_date">
          <div className="tooltip_date_css">{payload?.[0]?.payload?.month ||payload?.[0]?.payload?.name||payload?.[0]?.payload?.idle_days||payload?.[0]?.payload?.date}</div>
        </div>
        <table>
          <thead>
            <tr>
              <th className={styless.cloud_cell}>Amount Types</th>
              <th className={styless.cost_cell}>Costs</th>
            </tr>
          </thead>
          <tbody>
            {(payload || []).map((entry, index) => (
              <tr key={`tooltip-row-${index}`}>
                <td className={styless.cloud_cell}>{entry.dataKey}</td>
                <td className={styless.cost_cell}>
                  ${Number(entry.value)?.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
