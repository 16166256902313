import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetRecommendationsListRes
 * @property {string} message
 */

/**
 * @typedef AddAcceptDismissIdleReq
 * @property {string} service
 * @property {string} resourceId
 * @property {string} action
 */


export const addAcceptDismissIdle = (token) => {
  /**
   * @param {AddAcceptDismissIdleReq} req
   * @returns {Promise<GetRecommendationsListRes>}
   */
  return async ({ resourceId, service, action }) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/saving-details`,
      {
        method: "PUT",
        body: JSON.stringify({
          resourceId,
          action,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    return await recommendationsList.json();
  };
}

/**
 * @param {any[]=} deps
 */
export const useAcceptDismissIdle = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["addAcceptDismissIdle", appContext.idToken, ...deps],
    mutationFn: addAcceptDismissIdle(appContext.idToken),
  });
  return mutation;
};
