function enabledClouds() {
  const enabledCloudsEnv = process.env.REACT_APP_ENABLED_CLOUDS

  if (!enabledCloudsEnv) {
    throw new Error("REACT_APP_ENABLED_CLOUDS environment variable is not set.");
  }
  /** @type {Set<'aws' | 'gcp' | 'azure'>} */
  const clouds = new Set();
  process.env.REACT_APP_ENABLED_CLOUDS.split(",").forEach((e) => {
    switch (e.toLowerCase()) {
      case "aws":
      case "azure":
      case "gcp":
        clouds.add(e.toLowerCase());
        break;
      default:
    }
  });
  return clouds;
}

const config = {
  apiHost: process.env.REACT_APP_API_HOST,
  authHost: process.env.REACT_APP_AUTH_HOST,
  authRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  enabledClouds: enabledClouds(),
};

export default config;
        