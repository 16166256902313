import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef ArchivedRecommendation
 * @property {string} account
 * @property {string} resourceId
 * @property {string} region
 * @property {string} currentType
 * @property {string} recommendationType
 * @property {string} recommendedType
 * @property {string} currentType
 * @property {number} savings
 * @property {number} riSavings
 * @property {number} utilization_cpu
 * @property {number} utilization_mem
 * @property {number} utilization_disk
 * @property {number} cost
 */

/**
 * @typedef GetArchivedRecommendationsListRes
 * @property {ArchivedRecommendation[]} recommendations
 */

/**
 * @typedef GetArchivedRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetArchivedRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 * @property {'downsize' | 'upsize' | 'terminate'} recommendationType
 * @property {number} page
 * @property {number} limit
 */

export const getArchivedRecommendationsList = (token) => {
  /**
   * @param {GetArchivedRecommendationsListReq} req
   * @returns {Promise<GetArchivedRecommendationsListRes>}
   */
  return async ({
    page,
    limit,
    recommendationType,
    service,
    desc,
    orderBy,
  }) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/archived`,
      {
        method: "POST",
        body: JSON.stringify({
          page,
          limit,
          desc,
          orderBy,
          recommendationType,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!recommendationsList.ok) {
      if (ERROR_STATUS_CODES.includes(recommendationsList.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (recommendationsList.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await recommendationsList.json()).message);
    }
    return await recommendationsList.json();
  };
};

/**
 * @param {GetArchivedRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useArchivedRecommendationsList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getArchivedRecommendationsList",
      appContext.idToken,
      req,
      ...deps,
    ],
    queryFn: ({ signal }) =>
      getArchivedRecommendationsList(appContext.idToken)(req),
  });
  return query;
};
