import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */


/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'account' | 'region' | 'rg' } selectedTittle
 * @property {'usage' | 'disk'} tab
 */


export const getAiFilterList = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    { service, tab, selectedTittle },
    signal
  ) => {
    
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ai-recommendations/filter?tab=${tab}&type=${selectedTittle}`,
      {
        method: "POST",
        body: JSON.stringify({}),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAiFilterList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAiFilterList", req, ...deps],
    queryFn: ({ signal }) =>
        getAiFilterList(appContext.idToken)(req, signal),
  });
  return query;
};

// ai-recommendations/filter?tab=usage&type=owner
// ai-recommendations/disk-recommendations/list