import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
const data = [
  { name: "Category 1", value1: 20 },
  { name: "Category 2", value1: 40 },
  { name: "Category 3", value1: 15 },
  { name: "Category 4", value1: 20 },
  { name: "Category 5", value1: 40 },
  { name: "Category 6", value1: 15 },
  { name: "Category 7", value1: 20 },
  { name: "Category 8", value1: 40 },
  { name: "Category 9", value1: 15 },
  { name: "Category 10", value1: 20 },
  { name: "Category 11", value1: 40 },
  { name: "Category 12", value1: 15 },
  { name: "Category 13", value1: 20 },
  { name: "Category 14", value1: 40 },
  { name: "Category 15", value1: 15 },
  { name: "Category 16", value1: 20 },
  { name: "Category 17", value1: 40 },
  { name: "Category 18", value1: 15 },
  { name: "Category 19", value1: 20 },
  { name: "Category 20", value1: 40 },
  { name: "Category 21", value1: 15 },
];

const BarGraphErrorStates = () => {
  return (
    <div>
      <Placeholder
        className={styles.placeholder_css}
        xs={2}
        style={{ height: "25px", marginBottom: "12px" }}
      />
      <Row>
        <Col xs={10}>
          <Placeholder
            className={styles.placeholder_css}
            xs={5}
            style={{ height: "24px" }}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "32px",
          }}
        >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "24px", width: "24px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "24px", color: "white" }}
          />
        </Col>
      </Row>

      {/* -----------------------------bar graph----------------------------------------------- */}

      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          barSize={36}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid
            strokeDasharray="0"
            stroke="var(--Neutral-D9, #F8F9FA) "
          />
          <XAxis dataKey="name" hide />
          <YAxis
            stroke="var(--Neutral-D9, #F8F9FA)"
            tick={<CustomYAxisTick />}
          />

          <Bar dataKey="value1" fill="#F8F9FA" animation="glow"></Bar>
        </BarChart>
      </ResponsiveContainer>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
        </div>
      </Row>
    </div>
  );
};

export default BarGraphErrorStates;
const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <rect x={-33} y={0} width={36} height={24} fill="#F8F9FA" />
  </g>
);

export const BarState = () => {
  return (
    <div>
      <Placeholder
        className={styles.placeholder_css}
        xs={2}
        style={{ height: "25px", marginBottom: "12px" }}
      />
      <Row>
        <Col xs={12}>
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "24px", color: "white" }}
          />
        </Col>
      </Row>

      {/* -----------------------------bar graph----------------------------------------------- */}

      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          barSize={36}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid
            strokeDasharray="0"
            stroke="var(--Neutral-D9, #F8F9FA) "
          />
          <XAxis dataKey="name" hide />
          <YAxis
            stroke="var(--Neutral-D9, #F8F9FA)"
            tick={<CustomYAxisTick />}
          />

          <Bar dataKey="value1" fill="#F8F9FA" animation="glow"></Bar>
        </BarChart>
      </ResponsiveContainer>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 15 }}
            />
            <Placeholder
              className={styles.placeholder_css}
              style={{ height: "15px", width: 116 }}
            />
          </Stack>
        </div>
      </Row>
    </div>
  );
};
