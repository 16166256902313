import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef RemoveRecommendationFromArchivesRes
 * @property {string} message
 */

/**
 * @typedef RemoveRecommendationFromArchivesReq
 * @property {string} service
 * @property {string} resourceId
 */

export const removeRecommendationFromArchives = (token) => {
  /**
   * @param {RemoveRecommendationFromArchivesReq} req
   * @returns {Promise<RemoveRecommendationFromArchivesRes>}
   */
  return async ({
    resourceId,
    service,
  }) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/remove-from-archive`,
      {
        method: "DELETE",
        body: JSON.stringify({
          resourceId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    return await recommendationsList.json();
  };
}

/**
 * @param {any[]=} deps
 */
export const useRemoveRecommendationFromArchives = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["removeRecommendationFromArchives", appContext.idToken, ...deps],
    mutationFn: removeRecommendationFromArchives(appContext.idToken),
  });
  return mutation;
};
