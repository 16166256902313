import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "../../cost.explorer.module.css";

const StackedBarGraph = ({ barChartData, colorMap, services }) => {
  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <div className="tooltip_date">
            <div className="tooltip_date_css">{label}</div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Cloud</th>
                <th style={{ textAlign: "right" }}>Costs</th>
              </tr>
            </thead>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`tooltip-row-${index}`}
                  className={styles.horizontal_line}
                >
                  <td className={styles.cloud_cell}>
                    {entry.dataKey === "AWS" && (
                      <img src={awsLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey === "Azure" && (
                      <img src={azureLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey === "GCP" && (
                      <img src={gcpLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey}
                  </td>
                  <td className={styles.cost_cell}>${entry.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={barChartData}
        className="legend-custom-color"
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
      >
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          fontSize={12}
          fontWeight={400}
          fontFamily="Inter"
          fontStyle="normal"
        />
        {services.map((key) => {
          if (key === "label") return null;

          return (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={colorMap[key]}
              barSize={80}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarGraph;
