import React from "react";
import styles from "@/page-components/admin/user profile/admin.profile.module.css";
import clsx from "clsx";

export default function Overview({ data }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "24px",
      }}
    >
      <div className={styles.userAccountStatusDiv}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <span className={styles.userAccountStatusText}>
            User Account Status
          </span>
          <span
            className={styles.activeDisabledText}
            style={{
              color: data?.userProfile?.accountEnabled ? "#127E24" : "#D01A1A",
            }}
          >
            {data?.userProfile?.accountEnabled ? "Active" : "Disabled"}{" "}
          </span>
        </div>
      </div>

      <div className={styles.userProfileOverviewDiv}>
        <span className={styles.userProfileOverviewText}>
          User Profile Overview
        </span>
        <div className={styles.userDetailsDiv}>
          <div className={styles.userDetailsInnerDivs}>
            <span
              className={styles.userAccountStatusText}
              style={{ color: "#797B80" }}
            >
              Country/City
            </span>
            <span className={styles.detailValues}>
              {data?.userProfile?.country || "--"}
            </span>
          </div>

          <div className={styles.userDetailsInnerDivs}>
            <span
              className={styles.userAccountStatusText}
              style={{ color: "#797B80" }}
            >
              {" "}
              Email
            </span>
            <span className={styles.detailValues}>
              {data?.user?.email || "--"}
            </span>
          </div>

          <div className={styles.userDetailsInnerDivs}>
            <span
              className={styles.userAccountStatusText}
              style={{ color: "#797B80" }}
            >
              User_preferred_language
            </span>
            <span className={styles.detailValues}>
              {data?.userProfile?.preferredLanguage || "--"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
