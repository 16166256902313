import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


// {
//     "reservation": {
//         "reservationName": "VM_RI_13-24-2024-01-10",
//         "purchasedQuantity": 1,
//         "term": "1 Year",
//         "expiresOn": "2025-02-21",
//         "instanceFlexibility": "Off",
//         "region": "centralindia",
//         "vmFamily": "Standard_DS5_v2",
//         "platform": null,
//         "unusedQuantity": null,
//         "unusedCost": null,
//         "recommendedQuantity": null
//     },
//     "recommendations": [
//         {
//             "recommendedScope": "Switch Instance Flexibility from Off to On & Use Same RG",
//             "unusedQuantity": 6,
//             "unusedCost": 0,
//             "instanceFlexibility": "On"
//         }
//     ]
// }

/**
 * @typedef AllBodyStatistics
 * @property {AllDetailsStatistics} year_model
 * @property {AllDetailsStatistics} cost
 * @property {AllDetailsStatistics} monthly_cost
 * @property {AllDetailsStatistics} quantity_to_purchase
 * @property {AllDetailsStatistics} unit_price
 */


/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } servicetype
 */

export const getModalStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service ,subscriptionId ,platform ,vmFamily,region}) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/reserved-instance/bulk-details`,
      {
        method: "post",
        body: JSON.stringify({
          subscriptionId: subscriptionId,
          platform:platform ,
          vmFamily:vmFamily ,
          region:region,
        }),
        
        headers: {
        "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners?.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res.recommendations?.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useBulkModalStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getModalStatistics", req, ...deps],
    queryFn: () => getModalStatistics(appContext.idToken)(req),
  });
  return query;
};
