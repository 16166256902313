import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

// ------------------------------forecast download-------------------------------------------------------------
export const getForecastDownload = (token) => {
  return async (
    {
      service,

      accounts,
    },
    signal
  ) => {
    const url = new URL(
      `${config.apiHost}/v1/${service}/cost/forecast-download`
    );

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify({
        accounts,
      }),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `forecast-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseForecastDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getForecastDownload", req, ...deps],
    mutationFn: ({ signal }) =>
      getForecastDownload(appContext.idToken)(req, signal),
  });
  return mutation;
};
