import React, { useMemo } from "react";
import googleIcon from "@/assets/img/gcp_logo.svg";
import azureIcon from "@/assets/img/azurelogo.svg";
import awsIcon from "@/assets/img/aws_logo2.svg";
import { useAllTablesStatistics } from "@/services/saving/getAllTableStatistics.repo";
import TableErrorState from "@/components/States/TableErrorState";
import styles from "./savings.module.css";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const SavingAllCloudTable = ({ service, toDate, fromDate }) => {


  const query = useMemo(() => {
    return {
      service,
      toDate,
      fromDate,
    };
  }, [service, toDate, fromDate]);

  const { data, isLoading, error } = useAllTablesStatistics(query);

  const Tabledata = data
    ? Object.keys(data).map((cloudService) => ({
        Service: cloudService.toUpperCase(),
        Savings: (data[cloudService]?.total_saving?.cost || 0).toFixed(2),
        "idle resources": (
          data[cloudService]?.idle_resources?.cost || 0
        ).toFixed(2),
        rightsizing: (data[cloudService]?.right_sizing?.cost || 0).toFixed(2),
        snapshot: (data[cloudService]?.snapshots?.cost || 0).toFixed(2),
        Image:
          cloudService === "aws"
            ? awsIcon
            : cloudService === "azure"
            ? azureIcon
            : googleIcon,
      }))
    : [];


  return (
    <>
    {isLoading && <TableErrorState/>}
    {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Savings Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
    {data && (
    <div
      className="container-fluid table_main_container p-0"
      style={{ minWidth: "30%", marginLeft: "12px", paddingBottom:"32px" }}
    >
      <table className="table table-bordered" id={styles.customers}>
        <thead>
          <tr>
            <th className={styles.th_data} style={{textAlign:"left"}}>Cloud</th>
            <th className={styles.th_data}>Savings</th>
            <th className={styles.th_data}>Idle Resources</th>
            <th className={styles.th_data}>Rightsizing</th>
            <th className={styles.th_data}>Snapshots</th>
            {}
          </tr>
        </thead>
        <tbody>
        {Tabledata.map((item) => (
            <tr key={item.Service}>
              <td className={styles.table_data} style={{textAlign:"left"}}>
                {item.Image && (
                  <img
                    src={item.Image}
                    alt={item.Service}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                )}
                {item.Service}
              </td>
              <td className={styles.table_data}>{Number(item?.Savings).toFixed(2) || 0}</td>
              <td className={styles.table_data}>{Number(item["idle resources"]).toFixed(2) || 0}</td>
              <td className={styles.table_data}>{Number(item?.rightsizing).toFixed(2) || 0}</td>
              <td className={styles.table_data}>{Number(item?.snapshot).toFixed(2) || 0}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-bottom">
          <tr>
            <td className={styles.total_Costs1}>
              <b>Total Savings</b>
            </td>
            <td className={styles.tfooter_class}>
              {Tabledata && Array.isArray(Tabledata)
                ? Tabledata
                    .reduce(
                      (total, item) => total + parseFloat(Number(item?.Savings).toFixed(2) || 0),
                      0
                    )
                    .toFixed(2)
                : 0}
            </td>
            <td className={styles.tfooter_class}>
              {Tabledata && Array.isArray(Tabledata)
                ? Tabledata
                    .reduce(
                      (total, item) => total + parseFloat(Number(item["idle resources"]).toFixed(2) || 0),
                      0
                    )
                    .toFixed(2)
                : 0}
            </td>
            <td className={styles.tfooter_class}>
              {Tabledata && Array.isArray(Tabledata)
                ? Tabledata
                    .reduce(
                      (total, item) => total + parseFloat(Number(item?.rightsizing).toFixed(2) || 0),
                      0
                    )
                    .toFixed(2)
                : 0}
            </td>
            <td className={styles.tfooter_class}>
              {Tabledata && Array.isArray(Tabledata)
                ? Tabledata
                    .reduce(
                      (total, item) => total + parseFloat(Number(item?.snapshot).toFixed(2) || 0),
                      0
                    )
                    .toFixed(2)
                : 0}
            </td>
         
          </tr>
          <tr>
            <td colSpan="6" className={styles.table_info_css}>
              *Figures in USD
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    )}
    </>
  );
};

export default SavingAllCloudTable;
