import * as constants from "./constant";

const initialState = {
  token: "",
  user: [],
  search: [],
  countries_markers: [],
  isLoggedIn: false,
  role: null,
  sidebarActive: false,
  selectedResource: "allClouds",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN:
      return {
        ...state,
        user: action.payload.userdata[0],
        token: action.payload.token,
        isLoggedIn: action.payload.isLogged,
        isAdmin: action.payload.isAdmin,
        isSuperAdmin: action.payload.isSuperAdmin,
        role: action.payload.role,
        tokenData: action.payload.tokenData,
      };
    case constants.ADD_VALUE_TO_SEARCH_FILTER:
      return {
        ...state,
        search: action.addvalue,
      };
    case constants.REMOVE_FILTER:
      return {
        ...state,
        search: action.updatedsearchstate,
      };
    case constants.COUNTRIES_MARKERS:
      return {
        ...state,
        countries_markers: action.addcountries_markers,
      };
    case constants.CLEAR_FILTER:
      return {
        ...state,
        search: [],
      };
    case constants.LOGOUT:
      return {
        ...state,
        user: "",
        token: "",
        isLogged: false,
        isAdmin: false,
        isSuperAdmin: false,
        tokenData: "",
      };
    case constants.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarActive: action.payload,
      };

    case constants.ON_RESOURCE_CHANGE:
      return {
        ...state,
        selectedResource: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
