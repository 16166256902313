import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";




/**
 * @typedef AllBodyStatistics
 * @property {AllDetailsStatistics} detailsStatics
 * @property {AllCardDetailsStatistics[]} CardDetailStatics 
 */
/**
 * @typedef AllDetailsStatistics
 * @property {String} resource_id
 * @property {String} instance_type
 * @property {String} region
 * @property {String} tenancy
 * @property {String} platform
 * @property {Number} ondemand_hrs
 * @property {Number} ondemand_cost
 * @property {Number} monthly_cost
 * @property {Number} yearly_ri_cost
 */
/**
 * @typedef AllCardDetailsStatistics
 * @property {String} year_model
 * @property {String} ondemand_cost
 * @property {String} monthly_cost
 * @property {String} savings_vs_ondemand_percent
 * @property {String} monthly_saving
 * @property {String} break_even
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } servicetype
 */

export const getModalStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service , servicetype,subscriptionId ,platform ,resourceId,vmFamily,region}) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/${servicetype}/details`,
      {
        method: "post",
        body: JSON.stringify({
          subscriptionId: subscriptionId,
          platform:platform ,
          resourceId:resourceId ,
          vmFamily:vmFamily ,
          region:region,
        }),
        
        headers: {
        "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners?.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res.recommendations?.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiModalStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getModalStatistics", req, ...deps],
    queryFn: () => getModalStatistics(appContext.idToken)(req),
  });
  return query;
};
