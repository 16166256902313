import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/cost-explorer/components/filter-section/filter-section.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import clsx from "clsx";
import FilterState from "@/components/States/FilterState";
import { BillingContext } from "../../billing-context.part";
import { useBillingAccounts } from "@/services/billing-credit/getBillingAccount.repo";

const CreditFilterSection = ({ service, name }) => {
  const Context = useContext(BillingContext);
  const query = useMemo(() => {
    return {
      service: service,
      accountType: Context?.accountType,
    };
  }, [Context?.accountType, service]);
  const { data, isLoading, error } = useBillingAccounts(query);
  const [selectedTittle, setSelectedTittle] = useState(null);

  useEffect(() => {
    Context?.setBillingAccount([]);
  }, [Context?.accountType]);

  return (
    <>
      {isLoading && <FilterState />}
      {(data || error) && !isLoading && (
        <div className={styles.filter_main_div}>
          <Filter
            tittle={name}
            setSelectedTittle={setSelectedTittle}
            data={data?.data}
            filterType="account"
            // selectedValues={Context?.billingAccount}
            selectedValues={
              Array.isArray(Context?.billingAccount)
                ? Context?.billingAccount
                : []
            }
            onSelect={(list) => {
              Context?.setBillingAccount(list);
            }}
            onRemove={(list) => {
              Context?.setBillingAccount(list);
            }}
          />
        </div>
      )}
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const Context = useContext(BillingContext);
  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      Context?.setBillingAccount(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription Name" || tittle === "Account Name") {
      title = "Account";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <>
      <div className={clsx(styles.filter_padding_div)}>
        <div className={clsx(styles.dimension_div)}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={clsx(styles.filter_group_name_css)}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.id,
                  name: e.name,
                };
              })}
              selectedValues={selectedValues}
              onSelect={(list) => {
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditFilterSection;
