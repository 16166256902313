import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

/**
 * @typedef DeleteGroupReq
 * @property {number} groupId
 */

/**
 * @typedef DeleteGroupRes
 * @property {string} message
 */

export const deleteGroup = (token) => {
  /**
   * @param {DeleteGroupReq} req
   * @returns {Promise<DeleteGroupRes>}
   */

  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/groups/${req.groupId}/delete-group`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    const json = await totalStats.json();
    return json;
  };
};

/**
 * @param {any[]=} deps
 */
export const useDeleteGroup = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["deleteGroup", ...deps],
    mutationFn: deleteGroup(appContext.idToken),
  });
  return mutation;
};
