import config from "@/config";
import { useMutation } from "@tanstack/react-query";

/**
 * @typedef GetAccessTokenRes
 * @property {string} email - Email address
 * @property {string} id - User id
 * @property {string} access_token - Access token for the user
 * @property {string} id_token - Id token for the user
 * @property {number} expires_in - Time in seconds after which the token gets expired
 * @property {any} user - User object
 */

/**
 * Fetches access token for the code provided
 * @param {string} code Access code provided from SSO
 * @returns {Promise<GetAccessTokenRes>}
 */
export const getAccessToken = async (code) => {
  if (!code) throw new Error("Invalid code");
  const owners = await fetch(
    `${config.authHost}/access_token/?code=${code || ""}`,
    { method: "GET" }
  );
  if (!owners.ok) {
    throw new Error((await owners.json()).message);
  }
  return await owners.json();
};

/**
 * @param {string} accessCode
 * @param {any[]=} deps
 */
export const useGetAccessToken = (accessCode, deps = []) => {
  const mutate = useMutation({
    queryKey: ["getAccessToken", accessCode, ...deps],
    queryFn: () => getAccessToken(accessCode),
  });
  return mutate;
};
