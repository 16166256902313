import React, { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import { BillingContext } from "../billing-context.part";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import ModalExpandTable from "./modal-table.part";

const CreditExpandModal = ({ data, error, isLoading, service }) => {
  const context = useContext(BillingContext);
  const handleClose = () => {
    context?.setShowCreditModal(false);
  };
  return (
    <Offcanvas
      show={context?.showCreditModal}
      placement="end"
      style={{
        width: "60%",
        padding: "16px",
        overflowY: "scroll",
        gap: "12px",
      }}
    >
      <div className={styles.modal_main_cont}>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className={styles.billing_header}>
              <div className={styles.credit_with_icon}>
                <span className={styles.credit_modal_name}>Credits</span>{" "}
                <img src={infoIcon} alt=""></img>
              </div>
              <img
                src={crossModal}
                alt="Close Notification"
                onClick={handleClose}
              />
            </div>
            <span className={styles.credit_des}>
              {" "}
              View all available {service === "aws" ? "Aws" : "Azure"} credits.
              Credits are automatically applied to eligible charges on your
              invoice.{" "}
            </span>
          </div>
          <div className={styles.azzount_box}>
            <span className={styles.current_name}>CURRENT BALANCE</span>
            <span className={styles.current_values_modal}>
              ${(data?.data?.currentBalance || data?.data?.currentCreditBalance || 0).toFixed(2)}
            </span>
          </div>
        </div>
        <div className={styles.credit_list_box}>
          <span className={styles.total_name}>Credit List</span>
          <ModalExpandTable
            data={data}
            isLoading={isLoading}
            error={error}
            service={service}
          />
        </div>
      </div>
    </Offcanvas>
  );
};

export default CreditExpandModal;
