import React, { useState } from "react";


/**
 * @typedef UsersContextTypeOf
 * @property {number} selectedItem
 * @property {React.Dispatch<number>} setSelectedItem
 * @property {number} privilegesId
 * @property {React.Dispatch<number>} setPrivilegesId
 */

/** @type {import("react").Context<UsersContextTypeOf>} */
export const UsersContext = React.createContext();

export const useUserContext = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [hideFooter, setHideFooter] = useState(false)
  const [hideGroupModal,setHideGroupModal] = useState()
  const [privilegesId, setPrivilegesId] = useState()

  return {
    hideGroupModal,
    hideFooter,
    setHideFooter,
    setHideGroupModal,
    selectedItem,
    setSelectedItem,
    privilegesId,
    setPrivilegesId,
  };
};

export const UserContextProvider = ({ children }) => {
  const context = useUserContext();
  return (
    <UsersContext.Provider value={context}>{children}</UsersContext.Provider>
  );
};
