import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} resourcegroup
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

const awsData = {
  data: {
    count: 5,
    UsagePattern: [
      {
        subscriptionId: "e4a1d1b6-3a8e-4a8f-89a5-c5e735d3d6b7",
        accountname: "AWS-Production",
        resourcename: "aws-prod-instance-01",
        resourceid:
          "arn:aws:ec2:us-west-2:123456789012:instance/aws-prod-instance-01",
        region: "us-west-2",
        cpu_percentage: 3.45,
        memory_percentage: 12.67,
        cpu_pattern_message:
          "CPU was underutilized for 12 hours continuously for 5 days in July",
        memory_pattern_message:
          "Memory was underutilized for 14 hours continuously for 3 days in July",
        owner: "John.Doe@AWS.com",
      },
      {
        subscriptionId: "c9b1f971-5b2e-42f7-b2cf-7a485fdd9d8a",
        accountname: "AWS-Development",
        resourcename: "aws-dev-instance-02",
        resourceid:
          "arn:aws:ec2:us-east-1:123456789012:instance/aws-dev-instance-02",
        region: "us-east-1",
        cpu_percentage: 7.89,
        memory_percentage: 22.45,
        cpu_pattern_message:
          "CPU was underutilized for 10 hours continuously for 7 days in August",
        memory_pattern_message:
          "Memory was underutilized for 16 hours continuously for 2 days in August",
        owner: "Jane.Smith@AWS.com",
      },
      {
        subscriptionId: "c7d5a9e4-92f3-42e6-a234-3f91e1e7d65e",
        accountname: "AWS-Testing",
        resourcename: "aws-test-instance-03",
        resourceid:
          "arn:aws:ec2:eu-central-1:123456789012:instance/aws-test-instance-03",
        region: "eu-central-1",
        cpu_percentage: 0.56,
        memory_percentage: 4.23,
        cpu_pattern_message:
          "CPU was underutilized for 8 hours continuously for 10 days in June",
        memory_pattern_message:
          "Memory was underutilized for 12 hours continuously for 6 days in June",
        owner: "Alice.Wonder@AWS.com",
      },
      {
        subscriptionId: "a5d7f22c-d6c4-4b8d-a5a2-2a5b54f7a5d7",
        accountname: "AWS-Staging",
        resourcename: "aws-staging-instance-04",
        resourceid:
          "arn:aws:ec2:ap-south-1:123456789012:instance/aws-staging-instance-04",
        region: "ap-south-1",
        cpu_percentage: 15.67,
        memory_percentage: 35.89,
        cpu_pattern_message:
          "CPU was underutilized for 6 hours continuously for 3 days in May",
        memory_pattern_message:
          "Memory was underutilized for 9 hours continuously for 4 days in May",
        owner: "Bob.Marley@AWS.com",
      },
      {
        subscriptionId: "f2a8e5d4-3d6f-4d8e-b2f3-a7d4e5f7c8a1",
        accountname: "AWS-QA",
        resourcename: "aws-qa-instance-05",
        resourceid:
          "arn:aws:ec2:us-west-1:123456789012:instance/aws-qa-instance-05",
        region: "us-west-1",
        cpu_percentage: 0,
        memory_percentage: 0,
        cpu_pattern_message: null,
        memory_pattern_message: null,
        owner: "Charlie.Brown@AWS.com",
      },
    ],
  },
};

export const getUsagePatternStats = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    { service, filters, riContext, page, recordsPerPage },
    signal
  ) => {

    if (service === "aws") {
      return awsData;
    }
    const body = {
      accounts: filters?.accounts || [],
      region: filters?.region || [],
      owner: filters?.owner || [],
    };

    if (service === "azure") {
      body.resourcegroup = filters?.resourcegroup || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ai-recommendations/usage-patterns/list?page=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data?.count === 0) {
      if (
        body.accounts?.length ||
        body.region?.length ||
        body.owner?.length ||
        body.resourcegroup?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useUsagePatternStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getUsagePatternStats", req, ...deps],
    queryFn: ({ signal }) =>
      getUsagePatternStats(appContext.idToken)(req, signal),
  });
  return query;
};

// -------------------------------------------------for downdload as csv--------------------------------------------------

export const getUsagePatternDownload = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters, tableName }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      region: filters?.region || [],
      owner: filters?.owner || [],
    };

    if (service === "azure") {
      body.resourcegroup = filters?.resourcegroup || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ai-recommendations/${tableName}/download`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${tableName}-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useUsagePatternDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getUsagePatternDownload", req, ...deps],
    mutationFn: getUsagePatternDownload(appContext.idToken),
  });
  return mutation;
};
