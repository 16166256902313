import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "@/page-components/idle-resource/idle.module.css";

const LineChartIdleResource = ({ lineChartData, colorMap }) => {
  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={styles.custom_tooltip} >
          <div className={styles.tooltip_date} style={{paddingLeft:"8px"}}>
            <div className={styles.tooltip_date_css}>{label}</div>
          </div>
          <div style={{ paddingRight: "12px", width: "100%", paddingLeft:"8px"}}>
            <table >
              <thead >
                <tr >
                  <th className={styles.cloud_cell} style={{paddingRight:"24px"}}>Cloud</th>
                  <th className={styles.cost_cell} style={{paddingRight:"24px"}}>Cost</th>
                </tr>
              </thead>
              <tbody >
                {payload.map((entry, index) => (
                  <tr
                    key={`${styles.tooltip_row} ${index}`}
                    className={styles.horizontalline_tool}
                  >
                    <td className={styles.cloud_cell} style={{paddingRight:"24px"}}>
                      {entry.dataKey === "AWS" && (
                        <img
                          src={awsLogo}
                          alt=" "
                          className={styles.cloud_logo}
                        />
                      )}
                      {entry.dataKey === "Azure" && (
                        <img
                          src={azureLogo}
                          alt=" "
                          className={styles.cloud_logo}
                        />
                      )}
                      {entry.dataKey === "GCP" && (
                        <img
                          src={gcpLogo}
                          alt=" "
                          className={styles.cloud_logo}
                        />
                      )}
                      {entry.dataKey}
                    </td>
                    <td className={styles.cost_cell} style={{paddingRight:"24px"}}>
                      ${entry.value.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
         <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        <Line type="straight" dataKey="Azure" stroke={colorMap.Azure} />
        <Line type="straight" dataKey="AWS" stroke={colorMap.AWS} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartIdleResource;
