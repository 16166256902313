import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

// const mookdata =  {
// 	serviceCategoriesCount: 12,
// 	servicesCount: 12,
// 	resourcesCount: 11,
// 	serviceCategories: [{
// 		title: "wow",
// 		id: "01",
// 	}],
// 	services: [{
// 		description: "description12",
// 		title: "title12",
// 		id: "idd"
// 	}],
// 	resources: [{
// 		description: "string",
// 		title: "title223",
// 		id: "id23",
// 	}],
// }
// Res {
// 	count: number;
// 	results: [{
// 		description: string;
// 		title: string;
// 		id: string;
// 	}],
// }

/**
 * @typedef GetAllStatistics
 * @property {Number} count
 * @property {Getresult} results
 */
/**
 * @typedef Getresult
 * @property {String} description
 * @property {String} title
 * @property {String} id
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} results
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {'serviceCategory' | 'service' | 'resource'  } type
 * * @property {boolean=} all
 * @property {string} fromDate
 * @property {string} toDate
 */

export const getAllSearchStatics = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async ({ service,type,all ,query}, signal) => {
    const url = new URL(`${config.apiHost}/v1/${service}/search`);
    url.searchParams.append("query", query);
    url.searchParams.append("type", type);
    if (all) {
      url.searchParams.append("all", all);
    }
    const statistics = await fetch(url.toString(), {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      });
    if (!statistics.ok) {
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();
    return response
  };
}

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */

export const useAllSearchStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllSearchStatics", req, ...deps],
    queryFn: ({ signal }) => getAllSearchStatics(appContext.idToken)(req, signal),
  });
  return query;
};
