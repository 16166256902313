import React from "react";
import clsx from "clsx";
import styles from "@/page-components/savings/components/savings.module.css";
import RiHeader from "./header.part";
import RiTabs from "./tabs";

const RiHeaderContainer = (props) => {
  return (
    <>
        <div className={clsx("container-fluid", styles.HeaderMainDiv)}>
          <RiHeader data={props?.data} />
          <RiTabs />
        </div>
 
    </>
  );
};
export default RiHeaderContainer;
