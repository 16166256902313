import React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
} from "recharts";

const Barchart = ({ Bardata }) => {
  const data = [
    {
      name: "7-15 Days",
      value: Bardata.days_7_to_15 ? Bardata.days_7_to_15.count || 0 : 0,
      fill: "#33A02C",
    },
    {
      name: "16-30 Days",
      value: Bardata.days_16_to_30 ? Bardata.days_16_to_30.count || 0 : 0,
      fill: "#EE9D26",
    },
    {
      name: "More than 31 Days",
      value: Bardata.days_31_or_more ? Bardata.days_31_or_more.count || 0 : 0,
      fill: "#DE3E11",
    },
  ];

 

  // const maxValue = Math.max(...data.map((entry) => entry.value));
  // const generateTicks = () => {
  //   const tickInterval = 50;
  //   const maxTick = Math.ceil(maxValue / tickInterval) * tickInterval;
  //   const ticks = [];

  //   for (let i = 0; i <= maxTick; i += tickInterval) {
  //     ticks.push(i);
  //   }

  //   return ticks;
  // };
  // const ticks = generateTicks();

  return (
    <ResponsiveContainer width="100%" height={160}>
      <BarChart
        barSize={16}
        data={data}
        layout="vertical"
        margin={{ top: 0, right: 0, bottom: 20, left: 30 }}
      >
        <XAxis
          type="number"
          // ticks={ticks}
          // ticks={["0","30","100","150"]}
          axisLine={false}
          tickLine={false}
          style={{
            color: "#A3A3A3",
            fontSize: "10px",
            fontFamily: "Helvetica",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        />

        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          width={55}
          style={{
            color: "#495057",
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Bar dataKey="value" fill="#CF9FFF" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Barchart;
