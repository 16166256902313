import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetFilter
 * @property {GetFilterAccount}
 * @property {GetFilterRegions}
 * @property {GetFilterDisk}
 * @property {GetFilterRegions}
 * @property {GetFilterTags}
 */

/**
 * @typedef GetFilterAccount
 * @property {string} accountData
 */

/**
 * @typedef GetFilterRegions
 * @property {string} regionData
 */

/**
 * @typedef GetFilterDiskId
 * @property {string} diskIdData
 */

/**
 * @typedef GetFilterSnapshotAge
 * @property {string} snapshotAgeData
 */

/**
 * @typedef GetFilterTags
 * @property {string} tags
 */

/**
 * @typedef GetFilterResourceGroupNames
 * @property {string} resourceGroupNames
 */

/**
 * @typedef GetFilterOptionsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property {GetFilterTags} tags
 * @property {GetFilterResourceGroupNames} resourceGroupNames
 * @property {GetFilterRegions} regionData
 * @property {GetFilterAccount} accountData
 * @property {GetFilterSnapshotAge} snapshotAgeData
 * @property {GetFilterDiskId} diskIdData
 */

/**
 * @typedef GetFilterOptionsRes
 * @property {GetFilter}
 */

/**
 *
 * @param {GetFilterOptionsReq} req
 * @returns {Promise<GetFilterOptionsRes>}
 */

export const getFilterOptions = (token) => {
  return async (req) => {
    const { service, ...rest } = req;
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/filter`,
      {
        method: "POST",
        body: JSON.stringify(rest),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetFilterOptionsReq} req
 * @param {any[]=} deps
 */

export const useFilterOptions = (req, deps = []) => {
  const appContext = useContext(AppContext);
  
  const query = useQuery({
    queryKey: ["getFilterOptions", req, ...deps],
    queryFn: () => getFilterOptions(appContext.idToken)(req),
  });
  return query;
};
