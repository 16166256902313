import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} data
 */
/**
 * @typedef GetTotalStatistics
 * @property {string} id
 * @property {string} name
 */




// /**
//  * @typedef GetResultStatistics
//  * @property {number} saving_available  - Email address
//  */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 */

export const getTagsStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/tag-keys`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        }
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useTagsStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTagsStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getTagsStatistics(appContext.idToken)(req, signal),
  });
  return query;
};





// const AWS_TAGS = {
//   tag1: 'Value1',
//   tag2: 'Value2',
//   tag3: 'Value3',
// };

// const AZURE_TAGS = {
//   tag4: 'Value4',
//   tag5: 'Value5',
//   tag6: 'Value6',
// };

// /**
//  * @param {string} service 
//  * @returns {GetAllStatistics} 
//  */
// export const getTagsStatisticsStatic = (service) => {
  
//   if (service === 'aws') {
//     return {
//       data: Object.keys(AWS_TAGS).map(tag => ({
//         id: tag,
//         name: AWS_TAGS[tag],
//       })),
//     };
//   } else if (service === 'azure') {
//     return {
//       data: Object.keys(AZURE_TAGS).map(tag => ({
//         id: tag,
//         name: AZURE_TAGS[tag],
//       })),
//     };
//   } else {
   
//     return { data: [] };
//   }
// };

// /**

//  * @param {string} token
//  * @returns {Function} 
//  */
// export const getTagsStatistics = (token) => {
//   /**
//    * @param {GetStatisticsReq} req 
//    * @returns {Promise<GetAllStatistics>} 
//    */
//   return async ({ service }) => {
//     return getTagsStatisticsStatic(service);
//   };
// };

// /**

//  * @param {GetStatisticsReq} req 
//  * @param {any[]=} deps 
//  * @returns {Object} 
//  */
// export const useTagsStatistics = (req, deps = []) => {
//   const appContext = useContext(AppContext);
//   const query = useQuery({
//     queryKey: ["getTagsStatistics", appContext.idToken, req, ...deps],
//     queryFn: () => getTagsStatistics(appContext.idToken)(req),
//   });
//   return query;
// };
