import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef GetAllStatistics
 */

/**
 * @typedef GetSavingFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 * @property {GetSavingFilters} filters
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {string} toDate
 * @property {string} fromDate
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatistics>}
 */

export const getDownloadSavingData = (token) => {
  return async (
    {
      service,
      page,
      recordsPerPage,
      toDate,
      fromDate,
      filters,
      desc,
      orderBy,
      downloadService,
    },
    signal
  ) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/savings/${downloadService}?from=${fromDate}&to=${toDate}&page=${page}&recordsPerPage=${recordsPerPage}&desc=${desc}&orderBy=${orderBy}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${downloadService}-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useDownloadSavingData = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getDownloadSavingData", req, ...deps],
    mutationFn: ({ signal }) =>
      getDownloadSavingData(appContext.idToken)(req, signal),
  });
  return mutation;
};
