import { AppContext } from "@/App.context";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../loginPage/login";

export default function LogoutPage() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    appContext?.logout();
    navigate('/', { replace: true });
  }, [appContext, navigate]);

  return <Login/>;
}
