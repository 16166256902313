import React from "react";
import { Spinner } from "react-bootstrap";

export default function Login() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column",

      }}
    >
      <Spinner variant="primary" animation="border"></Spinner>

      <p
        style={{
          color: " var(--Neutral-D1, #212529)",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px" /* 100% */,
          letterSpacing: "-0.2px",
          marginTop:"30px",
          marginBottom:"15px"
        }}
      >
       Loading...
      </p>
      <p style={{fontSize:"12px"}}>Please Wait</p>
    </div>
  );
}
