import React, { useContext, useEffect } from "react";
import NavbarLogin from "./navbar.part";
import styles from "./login.module.css";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "@/App.context";
import { getAccessToken, SsoUrlReq, useSsoUrl } from "../../services/sso";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Cookie from "js-cookie";
import { add } from "date-fns";
import Login from "../loginPage/login";

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const accessCode = searchParams.get("code");
  const redirectTo = searchParams.get("redirectTo");

  const {
    data: accessTokenData,
    error: accessTokenError,
    isLoading: accessTokenLoading,
  } = useQuery({
    queryKey: ["getAccessToken", accessCode],
    queryFn: () => getAccessToken(accessCode),
  });

  useEffect(() => {
    if (accessTokenData && accessCode) {
      appContext?.updateCode(
        accessTokenData.access_token,
        accessTokenData.refresh_token,
        accessTokenData.expires_in,
        accessTokenData.id_token
      );
      appContext?.updateUser(accessTokenData.user);
    }
  }, [accessCode, accessTokenData, appContext]);

  useEffect(() => {
    if (appContext?.isLoggedIn) {
      const navigateTo =
        Cookie.get("redirectTo") || redirectTo || "/all-clouds/dashboard";
      Cookie.remove("redirectTo");
      navigate(navigateTo, { replace: true });
    }
  }, [appContext?.isLoggedIn, navigate, redirectTo]);

  if (
    appContext?.isLoggedIn ||
    (searchParams.get("code") && accessTokenLoading)
  ) {
    return <Login />;
  }

  return (
    <main className={styles.main} >
      <NavbarLogin />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{backgroundImage:"url('/LoginImg.jpg')", backgroundSize:"cover", backgroundRepeat:"no-repeat",width:"50%"}} />
        <div className={styles.mainContent}>
          <span className={styles.SSO}>SSO Sign in</span>
          <span className={styles.details}>Please enter your details</span>
          <span className={styles.emailText}>Email</span>
          <SsoForm />
          {accessCode &&
            accessTokenError instanceof Error &&
            accessTokenError && (
              <p className={styles.loginError}>{accessTokenError.message}</p>
            )}
        </div>
      </div>
    </main>
  );
};

const SsoForm = () => {
  /** @type {import('react-hook-form').UseFormReturn<SsoUrlReq>} */
  const formContext = useForm({
    resolver: joiResolver(SsoUrlReq),
    defaultValues: {},
  });

  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const {
    error: loginError,
    data: loginData,
    isPending: loginLoading,
    mutate: loginReq,
  } = useSsoUrl();

  useEffect(() => {
    if (loginData) {
      window.location.href = loginData.redirect_url;
    }
  }, [loginData]);

  useEffect(() => {
    if (redirectTo) {
      Cookie.set("redirectTo", redirectTo, {
        expires: add(new Date(), { minutes: 5 }),
      });
    }
  }, [redirectTo]);

  return (
    <>
      <form
        className={styles.loginFields}
        onSubmit={formContext.handleSubmit((d) => {
          loginReq(d);
        })}
      >
        <input
          type="email"
          className={styles.inputStyle}
          placeholder="name@cloudeq.com"
          name="email"
          required
          {...formContext.register("email")}
        />
        {loginError instanceof Error && loginError && (
          <p className={styles.loginError}>{loginError.message}</p>
        )}
        <button
          disabled={loginLoading}
          type="submit"
          className={styles.buttonStyle}
        >
          {!loginLoading && "Sign in"}
          {loginLoading && "Loading..."}
        </button>
      </form>
    </>
  );
};

export default LoginPage;
