/** @type {import('@/global')} */
import React, { useMemo, useContext, useEffect, useState } from "react";
import cpulogo from "@/assets/img/cpulogo.svg";
import disklogo from "@/assets/img/Disklogo.svg";
import memorylogo from "@/assets/img/memorylogo.svg";
import "@/assets/css/right-sizing-table.css";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import clsx from "clsx";
import { RightSizingContext } from "../../right-sizing.context";
import Pagination from "@/components/pagination/pagination.component";
import { useArchivedRecommendationsList } from "@/services/right-sizing/getArchivedRecommendationList";
import downArrow from "@/assets/img/GreenGroup.svg";
import upArrow from "@/assets/img/RedGroup.svg";
import terminateArrow from "@/assets/img/TerminateGroup.svg";
import uploadicon from "@/assets/img/uploadicon.svg";
import DataAbsense from "@/components/States/DataAbsense";
import TableErrorState from "@/components/States/TableErrorState";
import DataLoading from "@/components/States/DataLoading";
import UpSizeVMRecommendations from "@/components/States/UpSizeVMRecommendations";
import ToastComponent from "@/page-components/budgets/components/toast";
import { AdminContext } from "@/common/Admin.context";
import copyicon from "@/assets/img/copyicon.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import unarchieve from "@/assets/img/unarchieve.svg";
import { useGetHistory } from "@/services/right-sizing/getVmHistory.repo";
import UnarchiveModal from "@/page-components/snapshot/components/state_change/unarchiveModal";
import { useUpdateAcceptRecommendation } from "@/services/right-sizing/addAcceptStateField.repo";
import { useRecommendationsList } from "@/services/right-sizing/getRecommendationsList.repo";
import sorting from "@/assets/img/sorting.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

const ArchivedRightSizingTable = ({ service, refreshHeaders, name }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(RightSizingContext);
  const itemsPerPage = searchParams.get("itemsPerPage");
  const page = searchParams.get("page");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [enableUnarchiveModal, setEnableUnarchiveModal] = useState(false);
  const [resourceid, setResourceId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [projectedType, setProjectedType] = useState(null);
  const [savingsId, setSavingsId] = useState(null);
  const [orderBy, setOrderBy] = useState("region");
  const desc = searchParams.get("desc");

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleCopyClick = (event, resourceId) => {
    event.stopPropagation();
    const textarea = document.createElement("textarea");
    textarea.value = resourceId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(resourceId);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const queryParam = useMemo(() => {
    return {
      service,
      id: resourceid,
      currentType: currentType,
      projectedType: projectedType,
    };
  }, [service, resourceid, currentType, projectedType]);

  const { data: historyData } = useGetHistory(queryParam);
  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    error: acceptDismissError,
  } = useUpdateAcceptRecommendation();

  const resourceId = searchParams.get("resourceId");
  const query = useMemo(() => {
    return {
      page: page || 1,
      limit: parsedItemsPerPage || 10,
      orderBy: orderBy || "region",
      desc: searchParams.get("desc") || "true",
      recommendationType: context?.recommendationType || "downsize",
      service,
    };
  }, [
    context?.recommendationType,
    orderBy,
    page,
    parsedItemsPerPage,
    searchParams,
    service,
  ]);

  const {
    data: archivedData,
    isLoading,
    error,
    refetch: refetchArchive,
  } = useArchivedRecommendationsList(query);

  const { refetch: refetchActiveTable } = useRecommendationsList(query);

  useEffect(() => {
    if (context.removeRecommendationFromArchiveData) {
      refreshHeaders();
    }
  }, [refreshHeaders, context.removeRecommendationFromArchiveData]);

  /**
   * @param {string} resourceId
   */
  const showRecommendationModal = (resourceId) => {
    searchParams.set("resourceId", resourceId);
    setSearchParams(searchParams);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <span style={{ padding: "4px 8px" }}>Unarchive</span>
    </Tooltip>
  );
  const handleUnarchiveModal = (
    resourceId,
    snapshotId,
    history,
    projected_type,
    current_type
  ) => {
    setEnableUnarchiveModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };
  const handleUnarchiveModalClose = () => {
    setEnableUnarchiveModal(false);
  };
  useEffect(() => {
    if (updateData) {
      refetchArchive();
      refreshHeaders();
      refetchActiveTable();
    }
  }, [updateData, refreshHeaders, refetchArchive, refetchActiveTable]);
  useEffect(() => {
    if (acceptDismissError) {
      setMessage({
        message: acceptDismissError?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissError, setMessage]);

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      {isLoading && (
        <div className={styles.wrapper}>
          <TableErrorState />
        </div>
      )}
      {archivedData?.recommendations.length === 0 && (
        <div className={styles.wrapper}>
          <UpSizeVMRecommendations
            name={
              query.recommendationType === "downsize" ? "Downsize" : "Upsize"
            }
          />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {archivedData?.recommendations.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.scroll}>
              <table
                id="customers"
                className={clsx(styles.scrollable, "table-hover")}
              >
                <thead>
                  <tr>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      {name}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    {service === "azure" && (
                      <>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Name
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceName")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource ID
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceId")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Group
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() =>
                              handleOrderByClick("resourceGroupName")
                            }
                          ></img>
                        </th>
                      </>
                    )}
                    {service === "aws" && (
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        Resource ID
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("account")}
                        ></img>
                      </th>
                    )}
                    <th className={styles.tableHead} style={{ width: "11%" }}>
                      Region
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "14%" }}>
                      Utilization
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "15%" }}>
                      Owner
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "7%" }}>
                      Current Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("currentType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      Recommended Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("recommendedType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      Cost
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("cost")}
                      ></img>
                    </th>
                    <th
                      className={styles.tableHead}
                      style={{ width: "10%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {(archivedData?.recommendations || []).map((item, i) => (
                    <tr
                      className={clsx(styles.accountCol, {
                        [styles.hovered]: i === hoveredRowIndex,
                      })}
                      key={item.resourceId + "-" + i}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData
                        )}
                      >
                        <span title={item.account}>{item.account}</span>
                      </td>
                      {service === "azure" && (
                        <>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resourceName}>
                              {item.resourceName || "NULL"}
                            </span>
                          </td>

                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>

                            <button
                              className={`${
                                service === "aws"
                                  ? styles.copy_icons
                                  : service === "azure"
                                  ? styles.copy_icon
                                  : ""
                              } ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={(event) =>
                                handleCopyClick(event, item?.resourceId)
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resourceId}>
                              {item?.resourceGroupName || "Null"}
                            </span>
                          </td>
                        </>
                      )}
                      {service === "aws" && (
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.accountColNowrap,
                            styles.accountTitleCol,
                            styles.accountTitleColData
                          )}
                        >
                          <span title={item.resourceId}>
                            {item.resourceId || "Null"}
                          </span>
                          <button
                            className={`${styles.copy_icons} ${
                              copiedResourceId === item?.resourceId
                                ? "visible"
                                : ""
                            }`}
                            onClick={(event) =>
                              handleCopyClick(event, item?.resourceId)
                            }
                          >
                            <img
                              src={copyicon}
                              alt=""
                              style={{ marginRight: "-16px" }}
                            />
                          </button>
                        </td>
                      )}
                      <td
                        className={styles.accountCol}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.region}
                      </td>
                      <td className={styles.accountCol}>
                        <div className={styles.percentageBadgeWrapper}>
                          <PercentBadge
                            type="cpu"
                            value={item.utilization_cpu}
                          />
                          <PercentBadge
                            type="mem"
                            value={item.utilization_mem}
                          />
                        </div>
                      </td>

                      <td className={styles.accountCol}>
                        {item.owner || "--"}
                      </td>

                      <td className={styles.accountCol}>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          {item.recommendationType === "upsize" && (
                            <img
                              src={upArrow}
                              alt=""
                              className={styles.upArrow}
                            />
                          )}
                          {item.recommendationType === "downsize" && (
                            <img
                              src={downArrow}
                              alt=""
                              className={styles.downArrow}
                            />
                          )}
                          {item.recommendationType === "terminate" && (
                            <img src={terminateArrow} alt="" />
                          )}
                          <span className={styles.accountCol1}>
                            {item.currentType}
                          </span>
                        </span>
                      </td>

                      <td className={styles.accountCol}>
                        {item.recommendedType}
                      </td>
                      <td className={styles.accountCol}>
                        ${Number(item?.cost || 0).toFixed(2)}
                      </td>
                      <td style={{ width: "100px" }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className={styles.fixed}>
                <table
                  id="customers"
                  style={{ width: "100%" }}
                  className="table-hover"
                >
                  <thead>
                    <tr>
                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                      >
                        Savings
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("savings")}
                        ></img>
                      </th>
                      {/* <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_end
                        )}
                      >
                        RI Savings
                      </th> */}
                      <th
                        className={styles.tableHead}
                        style={{ minWidth: "155px", textAlign: "right" }}
                      >
                        Status
                      </th>
                      <th className={styles.tableHead}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(archivedData?.recommendations || []).map((item, i) => (
                      <tr
                        className={clsx(styles.accountCol, {
                          [styles.hovered]: i === hoveredRowIndex,
                        })}
                        key={item.account + " - " + i}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.accountCol_weight,
                            styles.accountCol_center,
                            Number(item?.savings || 0) < 0 &&
                              styles.accountCol_red,
                            Number(item?.savings || 0) >= 0 &&
                              styles.accountCol_green
                          )}
                        >
                          ${(item.savings || 0).toFixed(2)}
                        </td>
                        {/* <td
                          className={clsx(
                            styles.accountCol,
                            styles.accountCol_weight,
                            styles.accountCol_end,
                            Number(item?.savings || 0) < 0 &&
                              styles.accountCol_red,
                            Number(item?.savings || 0) >= 0 &&
                              styles.accountCol_green
                          )}
                          onClick={() => {}}
                        >
                          ${(item.riSavings || 0).toFixed(2)}
                        </td> */}
                        <td
                          style={{ minWidth: "155px", textAlign: "right" }}
                          className={styles.accountCol}
                        >
                          <span className={styles.badgeName}> Open</span>
                        </td>
                        <td
                          className={styles.accountCol}
                          style={{ paddingBottom: "10px" }}
                        >
                          <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <button
                              className="fgvhjikj"
                              style={{
                                border: "none",
                                background: "transparent",
                              }}
                              onClick={() => {
                                handleUnarchiveModal(
                                  item?.resourceId,
                                  item?.resourceId,
                                  item?.history_count,
                                  item?.recommendedType,
                                  item?.currentType
                                );
                              }}
                              onMouseEnter={() => setHoveredIndex(i)}
                              onMouseLeave={() => setHoveredIndex(null)}
                            >
                              <img
                                src={
                                  hoveredIndex === i ? unarchieve : uploadicon
                                }
                                alt=""
                              />
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <Pagination totalItems={archivedData?.total || 0} />
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </div>
      )}
      <UnarchiveModal
        enable={enableUnarchiveModal}
        disable={handleUnarchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        isErrorArchive={isAcceptDismissError}
        refetchTable={refetchArchive}
        savingid={savingsId}
        historyData={historyData}
        checkedHistory={checkedHistory}
        name="VM"
        onSubmit={(data) => {
          acceptMutate({
            current_type: currentType,
            projected_type: projectedType,
            resourceId: resourceid,
            service,
            status: "unarchive",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

/**
 * @typedef PercentBadgeProps
 * @property {number} value
 * @property {'cpu' | 'mem' | 'disc'} type
 */

/**
 * @param {PercentBadgeProps} props
 * @returns {import("react").ReactNode}
 */
const PercentBadge = ({ value, type }) => {
  const img = useMemo(() => {
    switch (type) {
      case "cpu":
        return cpulogo;
      case "mem":
        return memorylogo;
      case "disc":
        return disklogo;
      default:
        return null;
    }
  }, [type]);

  return (
    <span className={styles.percentageBadgeCont}>
      {img && (
        <img
          src={img}
          width="16px"
          height="16px"
          style={{ marginRight: "6px" }}
          alt=""
        />
      )}

      <span
        className={clsx(styles.badge, {
          [styles.badgeOk]: value < 50 && value >= 0,
          [styles.badgeWarning]: value >= 50 && value < 80,
          [styles.badgeDanger]: value >= 80,
          [styles.badgeNA]: value === undefined || value === null,
        })}
      >
        {(value === undefined || value === null) && "N/A"}
        {value !== undefined &&
          value !== null &&
          Number(value).toFixed(1) + "%"}
      </span>
    </span>
  );
};

export default ArchivedRightSizingTable;
