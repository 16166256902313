import green from "@/assets/img/snapdetailicon.svg";
import red from "@/assets/img/Rectangle redd.svg";
import blue from "@/assets/img/Rectangle blueDetail.svg";
import grey from "@/assets/img/Rectangle greyDetail.svg";
import other from "@/assets/img/Rectangle otherDetail.svg";
import resCircle from "@/assets/img/cicleredDetail.svg";
import blueCircle from "@/assets/img/blueCircle.svg";
import greenCircle from "@/assets/img/greenCircle.svg";
import greyCircle from "@/assets/img/greyCircle.svg";
import recblue from "@/assets/img/Rec blue.svg";
import recyellow from "@/assets/img/Rec yellow.svg";
import styles from "@/page-components/budgets/components/budgets.module.css";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";
import listView from "@/assets/img/cardViewBudget.svg";
import listselected from "@/assets/img/tableSelectedBudget.svg";
import cardView from "@/assets/img/chartTab.svg";
import Cardselected from "@/assets/img/detailChartSelected.svg";
import metadata from "@/assets/img/MetadataLogo.svg";
import activeUp from "@/assets/img/down_green_icon.svg";
import down from "@/assets/img/upsizeImage.svg";
import style from "@/page-components/idle-resource/idle.module.css";
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
  ScatterChart,
  Scatter,
} from "recharts";
import { CustomTooltip } from "./customtooltip.part";
import { TableSEction } from "./tableSection.part";
import clsx from "clsx";
import { LineChartPArt } from "./lineChart.part";
// import { useBudgetContext } from "../../budget.context";
import DetaiView from "./detailView.part";
import { Budget_Provider, BudgetContext } from "../../budget.context";
import { useSearchParams } from "react-router-dom";
import {
  TopCostListValues,
  useNewlyListValues,
  useSnapshotListValues,
  useUnusedListValues,
} from "@/services/budget/getBudgetListData.repo";
import LineChartSection from "./LineChartNewly.part";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

export const CostChartDivision = ({
  service,
  params,
  scope,
  resourceGroup,
}) => {
  const {
    useNewlyChartValues,
    useSnapshotChartValues,
    useUnusedChartValues,
    useTopCostChartValues,
  } = require("@/services/budget/getChartStats.repo");
  const { useState, useMemo, useContext } = require("react");
  const { ProgressBar, Offcanvas, Spinner } = require("react-bootstrap");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const context = useContext(BudgetContext);
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const query = useMemo(() => {
    return {
      budgetName: params?.BudgetName,
      accountId: params?.Id,
      service: service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      subscriptionId: params?.Id,
      managementGroup: params?.Id,
      resourceGroup: resourceGroup,
      scope: scope,
      resourceCreation:
        context?.NewlyView === "auto" ? "AutoScaling" : "Manual",
    };
  }, [
    // context?.NewlyView,
    context?.orderBy,
    params?.BudgetName,
    params?.Id,
    parsedItemsPerPage,
    parsedPage,
    resourceGroup,
    scope,
    searchParams,
    service,
  ]);

  const queryNewly = useMemo(() => {
    return {
      subscriptionId: params?.Id,
      managementGroup: params?.Id,
      resourceGroup: resourceGroup,
      budgetName: params?.BudgetName,
      accountId: params?.Id,
      service: service,
      page: parsedPage || 1,
      scope: scope,
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      resourceCreation:
        context?.NewlyView === "auto" ? "AutoScaling" : "Manual",
    };
  }, [
    context?.NewlyView,
    context?.orderBy,
    params?.BudgetName,
    params?.Id,
    parsedItemsPerPage,
    parsedPage,
    resourceGroup,
    scope,
    searchParams,
    service,
  ]);

  const {
    data: TopCostData,
    isLoading: TopCostDataLoading,
    error: TopCostDataerror,
  } = useTopCostChartValues(query);
  const {
    data: NewlyData,
    isLoading: NewlyDataLoading,
    error: NewlyDataerror,
  } = useNewlyChartValues(query);
  const {
    data: SnapData,
    isLoading: SnapDataLoading,
    error: SnapDataError,
  } = useSnapshotChartValues(query);
  const {
    data: UsedData,
    isLoading: UsedDataLoading,
    error: UsedDataerror,
  } = useUnusedChartValues(query);

  const {
    data: TopCostTabledata,
    isLoading: TopCostTabledataLoading,
    error: TopCostTabledataerror,
  } = TopCostListValues(query, {
    enabled: context?.BudgetView === "list",
  });
  const {
    data: newlyTabledata,
    isLoading: newlyTabledataLoading,
    error: newlyTabledataerror,
  } = useNewlyListValues(queryNewly, {
    enabled: context?.BudgetViewNewly === "list",
  });
  const {
    data: snapTabledata,
    isLoading: snapTabledataLoading,
    error: snapTabledataerror,
  } = useSnapshotListValues(query, {
    enabled: context?.BudgetViewSnap === "list",
  });
  const {
    data: unuseTableddata,
    isLoading: unuseTableddataLoading,
    error: unuseTableddataerror,
  } = useUnusedListValues(query, {
    enabled: context?.BudgetViewUnused === "list",
  });

  const Linedata = {
    data: [
      ...new Set([
        ...(NewlyData?.auto_scaling_data || []).map(
          (autoScale) => autoScale.date
        ),
        ...(NewlyData?.manually_created_data || []).map(
          (manual) => manual.date
        ),
      ]),
    ]
      .sort((a, b) => new Date(a) - new Date(b))
      .map((date) => {
        const autoScale = (NewlyData?.auto_scaling_data || []).find(
          (auto) => auto.date === date
        );
        const manualData = (NewlyData?.manually_created_data || []).find(
          (manual) => manual.date === date
        );

        return {
          name: date,
          Auto_Scaling: autoScale ? autoScale.count : 0,
          Manually_Created: manualData ? manualData.count : 0,
        };
      }),
  };

  const OtherPercentage =
    100 -
    ((UsedData?.percentage || 0) +
      (SnapData?.percentage || 0) +
      (NewlyData?.percentage || 0) +
      (TopCostData?.percentage || 0));
      
  return (
    <>
      <ProgressBar style={{ borderRadius: "0px", gap: "1px", height: "12px" }}>
        <ProgressBar
          now={TopCostData?.percentage || 0}
          key={1}
          style={{ backgroundColor: "#DF5F5F" }}
        />
        <ProgressBar
          style={{ backgroundColor: "#8FBBEA" }}
          now={NewlyData?.percentage || 0}
          key={2}
        />
        <ProgressBar
          style={{ backgroundColor: "#1ABC9CCC" }}
          now={SnapData?.percentage || 0}
          key={3}
        />
        <ProgressBar
          style={{ backgroundColor: "#ADB5BD" }}
          now={UsedData?.percentage || 0}
          key={4}
        />
        <ProgressBar
          style={{ backgroundColor: "#E9ECEF" }}
          now={OtherPercentage || 0}
          key={5}
        />
      </ProgressBar>

      <div style={{ display: "flex", justifyContent: "center", gap: "12px" }}>
        <span className={styles.legendText} style={{display:"flex",alignItems:"center",gap:"5px"}}>
        <span className={styles.topCostimgcircle} style={{borderRadius:"2px"}}></span> Top Cost Generator
        </span>
        <span className={styles.legendText}>
          <img src={blue} alt="" /> New Resources
        </span>
        <span className={styles.legendText}>
          <img src={green} alt="" /> Snapshots
        </span>
        <span className={styles.legendText}>
          <img src={grey} alt="" /> Idle Resources
        </span>
        <span className={styles.legendText}>
          <img src={other} alt="" /> Others
        </span>
      </div>

      <div className={styles.chartsContainers}>
        <div className={styles.chartContainer}>
          <div className={styles.cardListView}>
            <span
              style={{ fontWeight: "600", fontSize: "15px", color: "#343A40", display:"flex",alignItems:"center",gap:"8px" }}
            >
              {/* <img style={{ marginRight: "8px" }} src={resCircle} alt="" /> */}
              <span className={styles.topCostimgcircle}></span>
              Top Cost Generator
            </span>
            <span
              style={{ fontWeight: "500", fontSize: "11px", color: "#797B80" }}
            >
              View
              <span style={{ marginLeft: "8px" }}>
                <span
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetView === "card"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetView("card")}
                >
                  <img
                    src={
                      context?.BudgetView === "card" ? Cardselected : cardView
                    }
                    alt="Card View"
                  />
                </span>
                <span
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetView === "list"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetView("list")}
                >
                  <img
                    src={
                      context?.BudgetView === "list" ? listselected : listView
                    }
                    alt="List View"
                  />
                </span>
              </span>
            </span>
          </div>

          {context?.BudgetView === "card" && (
            <>
              {TopCostDataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {TopCostDataerror && (
                <ScenariosErrorState
                  headerItem="header"
                  error={TopCostDataerror.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}

              {TopCostData && (
                <>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#495057",
                      }}
                    >
                      Total Cost
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#212224",
                      }}
                    >
                      ${Number(TopCostData?.total_cost).toFixed(2) || "NA"}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#797B80",
                      }}
                    >
                      {TopCostData?.percentage !== null
                        ? Number(TopCostData?.percentage).toFixed(2) || "NA"
                        : "NA"}%
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                    }}
                  >
                    <ResponsiveContainer height={300} width="100%">
                      <BarChart
                        data={TopCostData?.data}
                        className="legend-custom-color"
                        margin={{ top: 0, right: 3, left: -10, bottom: 0 }}
                      >
                        <XAxis
                          dataKey="name"
                          tickLine={false}
                          axisLine={false}
                          fontSize={10}
                          fontWeight={500}
                          stroke="#495057"
                        />
                        <YAxis
                          label={{ fill: "#495057" }}
                          tickLine={false}
                          axisLine={false}
                          fontSize={10}
                          fontWeight={400}
                          stroke="#495057"
                        />
                        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                        <Tooltip
                          content={CustomTooltip}
                          cursor={{ fill: "transparent" }}
                        />
                        {service === "aws" && (
                          <Bar
                            dataKey="cost"
                            stackId="a"
                            fill="#DF5F5F"
                            barSize={144}
                          />
                        )}
                        {service === "azure" && (
                          <Bar
                            dataKey="cost"
                            stackId="a"
                            fill="#DF5F5F"
                            barSize={144}
                          />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </>
          )}

          {context?.BudgetView === "list" && (
            <>
              {TopCostTabledataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {TopCostTabledataerror && (
                <ScenariosErrorState
                  headerItem="header"
                  error={TopCostTabledataerror.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {TopCostTabledata && (
                <TableSEction type="topcost" data={TopCostTabledata} />
              )}
            </>
          )}
        </div>

        <div className={styles.chartContainer}>
          <div className={styles.cardListView}>
            <span
              style={{ fontWeight: "600", fontSize: "15px", color: "#343A40" }}
            >
              <img style={{ marginRight: "8px" }} src={blueCircle} alt="" />
              New Resources
            </span>
            <span
              style={{ fontWeight: "500", fontSize: "11px", color: "#797B80" }}
            >
              View
              <span style={{ marginLeft: "8px" }}>
                <span
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewNewly === "card"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewNewly("card")}
                >
                  <img
                    src={
                      context?.BudgetViewNewly === "card"
                        ? Cardselected
                        : cardView
                    }
                    alt="Card View"
                  />
                </span>
                <span
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewNewly === "list"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewNewly("list")}
                >
                  <img
                    src={
                      context?.BudgetViewNewly === "list"
                        ? listselected
                        : listView
                    }
                    alt="List View"
                  />
                </span>
              </span>
            </span>
          </div>
          <>
            {context?.BudgetViewNewly === "card" && (
              <>
                {NewlyDataLoading && (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                )}
                {NewlyDataerror && (
                  <ScenariosErrorState
                    headerItem="header"
                    error={NewlyDataerror.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                        message: "No Data Available",
                        additionalMessage:
                          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                      },
                      [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}
                {NewlyData && (
                  <>
                    {context?.BudgetViewNewly === "card" && (
                      <>
                        <div
                          style={{
                            padding: "0px 16px 0px 16px",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#495057",
                            }}
                          >
                            Total Cost
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              color: "#212224",
                            }}
                          >
                            {NewlyData?.total_cost &&
                              "$" +
                                Number(NewlyData?.total_cost || 0).toFixed(2)}
                          </span>
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#797B80",
                            }}
                          >
                            {NewlyData?.percentage &&
                              Number(NewlyData?.percentage || 0).toFixed(2) +
                                "%"}
                          </span>
                          {/* <img src={activeUp} alt="" /> */}
                        </div>

                        <div
                          style={{
                            padding: "0px 16px 0px 16px",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#495057",
                            }}
                          >
                            Total resources created
                            <span
                              style={{
                                fontSize: "13px",
                                fontWeight: "700",
                                marginLeft: "8px",
                              }}
                            >
                              {" "}
                              {NewlyData?.total_resource_created &&
                                (NewlyData?.total_resource_created || 0)}
                            </span>
                          </span>
                          {service === "aws" && (
                            <>
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#797B80",
                                }}
                              >
                                <img
                                  style={{ marginRight: "4px" }}
                                  src={recblue}
                                  alt=""
                                />
                                Auto Scaling (
                                {NewlyData?.total_auto_scaling_count &&
                                  (NewlyData?.total_auto_scaling_count || 0)}
                                )
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#797B80",
                                }}
                              >
                                <img
                                  style={{ marginRight: "4px" }}
                                  src={recyellow}
                                  alt=""
                                />
                                Manually Created (
                                {NewlyData?.total_manually_created_count &&
                                  (NewlyData?.total_manually_created_count ||
                                    0)}
                                )
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {context?.BudgetViewNewly === "card" && (
                      <div
                        style={{
                          padding: "0px 16px 0px 16px",
                        }}
                      >
                        {service === "aws" && (
                          <LineChartSection
                            service={service}
                            Linedata={Linedata}
                          />
                        )}
                        {service === "azure" && (
                          <LineChartSection
                            service={service}
                            Linedata={NewlyData}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {context?.BudgetViewNewly === "list" && (
              <>
                {newlyTabledataLoading && (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                )}
                {newlyTabledataerror && (
                  <ScenariosErrorState
                    headerItem="header"
                    error={newlyTabledataerror.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                        message: "No Data Available",
                        additionalMessage:
                          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                      },
                      [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}

                {newlyTabledata && (
                  <>
                    {context?.BudgetViewNewly === "list" &&
                      service === "aws" && (
                        <div
                          style={{
                            padding: "0px 16px 0px 16px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginBottom: "16px" }}>
                            <span
                              style={{ borderRadius: "4px 0px 0px 4px" }}
                              className={clsx(
                                styles.newlyTabs,
                                context?.NewlyView === "auto"
                                  ? styles.newlyTabActive
                                  : ""
                              )}
                              onClick={() => {
                                context?.setNewlyView("auto");
                              }}
                            >
                              Auto Scaling (
                              {NewlyData?.total_auto_scaling_count &&
                                (NewlyData?.total_auto_scaling_count || 0)}
                              )
                            </span>
                            <span
                              style={{ borderRadius: "0px 4px 4px 0px" }}
                              className={clsx(
                                styles.newlyTabs,
                                context?.NewlyView === "manual"
                                  ? styles.newlyTabActive
                                  : ""
                              )}
                              onClick={() => {
                                context?.setNewlyView("manual");
                              }}
                            >
                              Manually Created (
                              {NewlyData?.total_manually_created_count &&
                                (NewlyData?.total_manually_created_count || 0)}
                              )
                            </span>
                          </div>
                        </div>
                      )}
                    {context?.BudgetViewNewly === "list" && (
                      <TableSEction type="Newly" data={newlyTabledata} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>

      <div className={styles.chartsContainers}>
        <div className={styles.chartContainer}>
          <div className={styles.cardListView}>
            <span
              style={{ fontWeight: "600", fontSize: "15px", color: "#343A40" }}
            >
              <img style={{ marginRight: "8px" }} src={greenCircle} alt="" />
              Snapshots
            </span>
            <span
              style={{ fontWeight: "500", fontSize: "11px", color: "#797B80" }}
            >
              View
              <span style={{ marginLeft: "8px" }}>
                <span
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewSnap === "card"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewSnap("card")}
                >
                  <img
                    src={
                      context?.BudgetViewSnap === "card"
                        ? Cardselected
                        : cardView
                    }
                    alt="Card View"
                  />
                </span>
                <span
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewSnap === "list"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewSnap("list")}
                >
                  <img
                    src={
                      context?.BudgetViewSnap === "list"
                        ? listselected
                        : listView
                    }
                    alt="List View"
                  />
                </span>
                {/* <img
                 onClick={() => {
                  context?.setDetailShow("true");
                  context?.setmodalType("snap")
                }}
                  style={{ width: "16px", height: "16px", marginLeft: "20px" }}
                  src={metadata}
                  alt=""
                /> */}
              </span>
            </span>
          </div>
          {context?.BudgetViewSnap === "card" && (
            <>
              {SnapDataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {SnapDataError && (
                <ScenariosErrorState
                  headerItem="header"
                  error={SnapDataError.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {SnapData && (
                <>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#495057",
                      }}
                    >
                      Total Snapshot Cost:
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#212224",
                      }}
                    >
                      {SnapData?.cost &&
                        "$" + Number(SnapData?.cost || 0).toFixed(2)}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#797B80",
                      }}
                    >
                      {SnapData?.percentage &&
                        Number(SnapData?.percentage || 0).toFixed(2) + "%"}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                    }}
                  >
                    <LineChartPArt data={SnapData} service={service} />
                  </div>
                </>
              )}
            </>
          )}
          {context?.BudgetViewSnap === "list" && (
            <>
              {snapTabledataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {snapTabledataerror && (
                <ScenariosErrorState
                  headerItem="header"
                  error={snapTabledataerror.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}

              {snapTabledata && (
                <TableSEction type="snap" data={snapTabledata} />
              )}
            </>
          )}
        </div>
        <div className={styles.chartContainer}>
          <div className={styles.cardListView}>
            <span
              style={{ fontWeight: "600", fontSize: "15px", color: "#343A40" }}
            >
              <img style={{ marginRight: "8px" }} src={greyCircle} alt="" />
              Idle Resources
            </span>
            <span
              style={{ fontWeight: "500", fontSize: "11px", color: "#797B80" }}
            >
              View
              <span style={{ marginLeft: "8px" }}>
                <span
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewUnused === "card"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewUnused("card")}
                >
                  <img
                    src={
                      context?.BudgetViewUnused === "card"
                        ? Cardselected
                        : cardView
                    }
                    alt="Card View"
                  />
                </span>
                <span
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      context?.BudgetViewUnused === "list"
                        ? "#EDEFF2"
                        : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => context?.setbudgetViewUnused("list")}
                >
                  <img
                    src={
                      context?.BudgetViewUnused === "list"
                        ? listselected
                        : listView
                    }
                    alt="List View"
                  />
                </span>
                {/* <img
                 onClick={() => {
                  context?.setDetailShow("true");
                  context?.setmodalType("unused")
                }}
                  style={{ width: "16px", height: "16px", marginLeft: "20px" }}
                  src={metadata}
                  alt=""
                /> */}
              </span>
            </span>
          </div>

          {context?.BudgetViewUnused === "card" && (
            <>
              {UsedDataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {UsedDataerror && (
                <ScenariosErrorState
                  headerItem="header"
                  error={UsedDataerror.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {UsedData && (
                <>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#495057",
                      }}
                    >
                      Total Cost
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#212224",
                      }}
                    >
                      {UsedData?.total_cost &&
                        "$" + Number(UsedData?.total_cost || 0).toFixed(2)}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#797B80",
                      }}
                    >
                      {UsedData?.percentage &&
                        Number(UsedData?.percentage || 0).toFixed(2) + "%"}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "0px 16px 0px 16px",
                    }}
                  >
                    <LineChartPArt data={UsedData} service={service} />
                  </div>
                </>
              )}
            </>
          )}
          {context?.BudgetViewUnused === "list" && (
            <>
              {unuseTableddataLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {unuseTableddataerror && (
                <ScenariosErrorState
                  headerItem="header"
                  error={unuseTableddataerror.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {unuseTableddata && (
                <TableSEction type="unused" data={unuseTableddata} />
              )}
            </>
          )}
        </div>
      </div>
      <Offcanvas
        placement="end"
        show={context?.DetailShow}
        onHide={() => {
          context?.setDetailShow(false);
          context?.setmodalType("");
        }}
        style={{
          width: "70%",
        }}
      >
        <Offcanvas.Body
          //   className={styles.modal_body}
          style={{
            paddingTop: 16,
          }}
        >
          <DetaiView />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
