import React from "react";
import { PieChart, Pie, Legend, ResponsiveContainer, Cell } from "recharts";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NoDataAvailable from "@/components/States/noDataAvailable";

const UsagePieChart = ({ Piedata }) => {
  const data = (Piedata || []).slice(0, 5).map((item) => ({
    name: item.category,
    value: item.cost,
  }));

  const COLORS = [
    "#B5DDAD",
    "#94C989",
    "#79B86D",
    "#3B94CF",
    "#2579AD",
    "#014587",
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    name,
    fontSize,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(RADIAN * midAngle);
    const cos = Math.cos(RADIAN * midAngle);
    const startX = cx + outerRadius * cos;
    const startY = cy + outerRadius * -sin;
    let middleY = cy + (outerRadius + 40 * Math.abs(sin)) * -sin;
    let endX = startX + (cos >= 0 ? 1 : -1) * 10;
    let textAnchor = cos >= 0 ? "start" : "end";

    // if (midAngle >= -45 && midAngle <= 45) {
    //   return null;
    // }

    if (percent <= 0.0) {
      middleY = cy + (outerRadius + 55 * Math.abs(sin)) * -sin;
    }

    const mirrorNeeded =
      midAngle > -270 && midAngle < -210 && percent < 0.04 && index % 2 === 1;
    if (mirrorNeeded) {
      endX = startX + outerRadius * -cos * 2 + 100;
      textAnchor = "start";
    }

    return (
      <g>
        <path
          d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
          fill="none"
          stroke="#000"
          strokeWidth={1}
        />
        <text
          x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 0}
          y={middleY + fontSize / 2}
          textAnchor={textAnchor}
          fontSize={fontSize}
        >{` ${(percent * 100).toFixed(2)}%`}</text>
      </g>
    );
  };

  return (
    <div className={styles.bar_main}>
      <span className={styles.cost_trend}>Top Contributors</span>
      {Piedata?.length === 0 && (
        <NoDataAvailable
          message="No Data Available for Selected Date Range or Filters"
          addOnMsg="It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options."
        />
      )}
      {Piedata?.length > 0 && (
        <>
          <div className={styles.pieContainer}>
            <ResponsiveContainer width="100%" height={260}>
              <PieChart margin={{ top: 40, bottom: 30, left: 20, right: 0 }}>
                <Pie
                  data={data}
                  dataKey="value"
                  // outerRadius="85%"
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  startAngle={90}
                  endAngle={-270}
                  paddingAngle={8}
                  fontSize={12}
                  isAnimationActive={false}
                  label={renderCustomizedLabel}
                  stroke={0.1}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                  {/* <LabelList
                dataKey="value"
                position="outside"
                fill="#000" 
                stroke="none"
                style={{ fontSize: '12px' }} 
              /> */}
                </Pie>
                {/* <Tooltip/> */}
                <Legend
                  wrapperStyle={{ paddingLeft: 12, maxWidth: "50%" }}
                  layout="vertical"
                  align="right"
                  verticalAlign="middle"
                  iconType="square"
                  iconSize={10}
                  formatter={(value, entry) => (
                    <span>
                      <span
                        style={{
                          maxWidth: "55%",
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          transform: "translateY(20%)",
                        }}
                        title={value}
                      >
                        {value}
                      </span>
                      <span>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className={styles.tooltip_css}>
                              ${Number(entry.payload.value).toFixed(8)}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              float: "right",
                              fontWeight: 600,
                              fontSize: 13,
                              transform: "translateY(30%)",
                            }}
                          >
                            ${Number(entry.payload.value).toFixed(2)}
                          </span>
                        </OverlayTrigger>
                      </span>
                    </span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default UsagePieChart;
