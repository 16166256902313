import React, { useContext, useMemo } from "react";
import { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import billingStyles from "@/page-components/billing-credit/billing-credit.module.css";
import RemoveIcon from "@/assets/img/drop-icon.svg";
import collapseIcon from "@/assets/img/collapse-icon.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import clsx from "clsx";
import DataAbsense from "@/components/States/DataAbsense";
import NoDataAvailable from "@/components/States/noDataAvailable";
import { BillingContext } from "../billing-context.part";
import { useBillingDetailsDownload } from "@/services/billing-credit/getBillingDetailsDownload.repo";
import download from "@/assets/img/downloadimage.svg";
import { useBillingDetails } from "@/services/billing-credit/getEABillingDetails.repo";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

function CollapsibleRow({
  data,
  billing_profile_name,
  billing_profile_id,
  account,
  totalCharges,
  creditsApplied,
  azureMarketplace,
  refundOverageCredits,
  billedAmount,
  billing_account_id,
  remainingCredits,
  showRemoveIcon = false,
  defaultIsOpen = false,
  children,
  level = 0,
}) {
  const context = useContext(BillingContext);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <Fragment>
      <tr className={clsx(styles.collapse_tr, billingStyles.tr_striped)}>
        <td className={billingStyles.td_padding_col}></td>
        <td className={billingStyles.td_padding_col}></td>
        <td className={billingStyles.td_padding_col}>
          <div className={billingStyles.account_id_name}>
            <span
              className={clsx(billingStyles.account_named, billingStyles.truc)}
              title={billing_profile_name}
            >
              {billing_profile_name || account || "--"}
            </span>
            <span
              className={clsx(billingStyles.account_id, billingStyles.truc)}
              title={billing_profile_id}
            >
              {billing_profile_id || billing_account_id || "--"}
            </span>
          </div>
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          {totalCharges?.toFixed(2)}
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          {creditsApplied?.toFixed(2)}
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          {azureMarketplace?.toFixed(2)}
        </td>
        {context?.accountDropdown === "Enterprise Agreement" && (
          <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
            {refundOverageCredits?.toFixed(2)}
          </td>
        )}
        <td
          className={clsx(
            styles.side_align,
            styles.side_align,
            billingStyles.td_padding_col
          )}
        >
          {billedAmount?.toFixed(2)}
        </td>
        <td
          className={clsx(
            styles.side_align,
            styles.side_align,
            billingStyles.td_padding_col
          )}
        >
          {Number(remainingCredits || 0).toFixed(2)}
        </td>
      </tr>
    </Fragment>
  );
}

export default function CollapsibleTable({ billingName, service }) {
  const context = useContext(BillingContext);

  const query = useMemo(() => {
    if (!context?.accountType) {
      return null;
    }
    const queryData = {
      service: service,
      accountType: context?.accountType,
    };

    if (service === "azure") {
      queryData.billing_profile = context?.billingAccount.map((e) => e.id);
    }

    return queryData;
  }, [context?.accountType, context?.billingAccount, service]);

  const { data: serviceData, isLoading, error } = useBillingDetails(query);

  const [openRows, setOpenRows] = useState([]);

  const toggleRow = (index) => {
    setOpenRows((prevState) => {
      const newOpenRows = [...prevState];
      newOpenRows[index] = !newOpenRows[index];
      return newOpenRows;
    });
  };

  const downloadQuery = useMemo(() => {
    const queryData = {
      service: service,
      accountType: context?.accountType,
    };

    if (service === "azure" && Array.isArray(context?.billingAccount)) {
      queryData.billing_profile = context.billingAccount.map((e) => e.id);
    }

    return queryData;
  }, [context?.accountType, context?.billingAccount, service]);

  const { mutate: downloadMutate } = useBillingDetailsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <div
      className={billingStyles.table_main_container}
      style={{ paddingBottom: "32px", paddingTop: "24px" }}
    >
      {/* {serviceData?.data?.length === 0 && (
        <NoDataAvailable
          message="No Data Available for Selected Date Range or Filters"
          addOnMsg="It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options."
        />
      )} */}
      {((!isLoading && serviceData) || error) && (
        <div className={billingStyles.billing_header}>
          <span className={billingStyles.billing_details}>Billing Details</span>
          <div
            className={billingStyles.download_icon}
            onClick={handleDownloadClick}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
            >
              <img src={download} alt="" />
            </OverlayTrigger>
          </div>
        </div>
      )}
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {serviceData && serviceData?.data.length > 0 && (
        <>
          {serviceData?.data?.length > 0 && (
            <table responsive>
              <thead className={styles.collapsibleTableHead}>
                <tr className={styles.tr_head_css}>
                <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                  >
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                  >
                    Month
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                  >
                    Billing {billingName}/ID
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Total Charges
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Credits Applied
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Azure Marketplace
                  </td>
                  {context?.accountDropdown === "Enterprise Agreement" && (
                    <td
                      className={clsx(
                        styles.col_header,
                        styles.side_align,
                        billingStyles.td_padding_col
                      )}
                    >
                      Refund Overage Credits
                    </td>
                  )}
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Billed Amount
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Remaining Credits
                  </td>
                </tr>
              </thead>
              <tbody>
                {(serviceData?.data || []).map((tableData, index) => {
                  const isOpen = openRows[index] || false;
                  return (
                    <Fragment key={index}>
                      <tr
                        className={clsx(
                          styles.collapse_tr,
                          billingStyles.collapse_table
                        )}
                      >
                        <td
                          onClick={() => toggleRow(index)}
                          style={{
                            cursor: "pointer",
                            // paddingLeft: level > 0 ? `${level * 24}px` : "8px",
                          }}
                          className={billingStyles.td_padding_col}
                        >
                          <img
                            src={isOpen ? RemoveIcon : collapseIcon}
                            alt=""
                            style={{
                              transform: "translate(0px, -1px)",
                              display: "inline-block",
                            }}
                          />
                          {/* )} */}
                          {/* <span
                            className={
                              isOpen
                                ? styles.table_head_css
                                : billingStyles.after_expand
                            }
                          >
                            {tableData.month}
                          </span> */}
                        </td>
                        <td className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            billingStyles.td_padding_col
                          )}>
                             {tableData.month}
                          </td>
                        <td className={billingStyles.td_padding_col}>-</td>

                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          {Number(tableData?.total_charges || 0).toFixed(2)}
                        </td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          {Number(tableData?.credits_applied || 0).toFixed(2)}
                        </td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          {Number(tableData?.azure_marketplace || 0).toFixed(2)}
                        </td>
                        {context?.accountDropdown ===
                          "Enterprise Agreement" && (
                          <td
                            className={clsx(
                              isOpen
                                ? styles.table_head_css
                                : billingStyles.after_expand,
                              styles.side_align,
                              billingStyles.td_padding_col
                            )}
                          >
                            {Number(
                              tableData?.refunds_overage_credits || 0
                            ).toFixed(2)}
                          </td>
                        )}
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          {Number(tableData?.billed_amount || 0).toFixed(2)}
                        </td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          {Number(tableData?.remaining_credits || 0).toFixed(2)}
                        </td>
                      </tr>
                      {isOpen &&
                        (tableData?.data || []).map((data, index) => (
                          <CollapsibleRow
                            key={index}
                            data={data.title}
                            account={data.billing_account_name}
                            totalCharges={data.total_charges}
                            creditsApplied={data.credits_applied}
                            azureMarketplace={data.azure_marketplace}
                            refundOverageCredits={data.refunds_overage_credits}
                            billedAmount={data.billed_amount}
                            remainingCredits={data.remaining_credits}
                            billing_account_id={data.billing_account_id}
                            billing_profile_id={data?.billing_profile_id}
                            billing_profile_name={data?.billing_profile_name}
                            level={0}
                            children={data.children}
                          />
                        ))}
                    </Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                <td colSpan="9" className={billingStyles.table_info_css}>
                  *Figures in USD
                </td>
              </tfoot>
            </table>
          )}
        </>
      )}
    </div>
  );
}

export const ToolTipComponent = ({ data, children, prefix = "$ " }) => {
  const formattedData = prefix + (data || 0)?.toFixed(8);
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
          <strong>{formattedData}</strong>
        </Tooltip>
      }
    >
      <span style={{ cursor: "pointer" }}>{children}</span>
    </OverlayTrigger>
  );
};
