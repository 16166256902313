import React, { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import BsPagination from "react-bootstrap/Pagination";
import leftarrow from "@/assets/img/arrow-left 1.svg";
import rightarrow from "@/assets/img/arrow-right.svg";
import { useSearchParams } from "react-router-dom";
import styles from "./pagination.module.css";
import { DropdownButton } from "react-bootstrap";

/**
 * @typedef PaginationProps
 * @property {number} totalItems
 * @property {number} itemsPerPage
 */

/**
 * @param {PaginationProps} props
 */
const Pagination = ({ totalItems }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");
  const currentPage =
    pageParam !== null && !Number.isNaN(pageParam) ? Number(pageParam) : 1;
  const itemsPerPageParam = searchParams.get("itemsPerPage");

  const itemsPerPageOptions = [10, 20, 50];

  const [pages, maxPages, itemsPerPage] = useMemo(() => {
    const itemsPerPageNumber = Number(itemsPerPageParam);
    let itemsPerPage = 10;

    switch (parseInt(itemsPerPageNumber)) {
      case 10:
      case 20:
      case 50:
        itemsPerPage = parseInt(itemsPerPageNumber);
        break;
      default:
    }

    const maxPages =
      itemsPerPage > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(maxPages, startPage + 3);
    const pages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    return [pages, maxPages, itemsPerPage];
  }, [itemsPerPageParam, totalItems, currentPage]);

  /**
   * @param {number} page
   */
  const setPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.pagination_div}>
      <div className={styles.data_selection_container}>
        <span className={styles.font_styling}>Show</span>

        <DropdownButton id={styles.dropdown_item_button} title={itemsPerPage}>
          {itemsPerPageOptions.map((e, i) => (
            <Dropdown.Item
              className={styles.dropdown_item}
              key={"itemsPerPage-" + e + "-" + i}
              onClick={() => {
                searchParams.set("itemsPerPage", e);
                searchParams.delete("page");
                setSearchParams(searchParams);
              }}
              eventKey={e}
            >
              {e}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        <span className={styles.font_styling}>entries</span>
      </div>
      <BsPagination size="sm" className={styles.pagination}>
        <BsPagination.Item
          disabled={currentPage === 1}
          onClick={() => {
            if (currentPage > 1) {
              setPage(currentPage - 1);
            }
          }}
        >
          <span style={{ display: "flex", gap: "10px" }}>
            <img src={leftarrow} alt="" />
            <span>Previous</span>
          </span>
        </BsPagination.Item>

        {currentPage > 3 && (
          <>
            <BsPagination.Item onClick={() => setPage(1)}>1</BsPagination.Item>
            <BsPagination.Ellipsis disabled />
          </>
        )}

        {pages.map((e, i) => (
          <BsPagination.Item
            key={i}
            active={currentPage === e}
            onClick={() => setPage(e)}
            className={currentPage === e ? styles.selected_page : ""}
          >
            {e}
          </BsPagination.Item>
        ))}

        {maxPages > 5 && currentPage <= maxPages - 3 && (
          <>
            <BsPagination.Ellipsis disabled />
            <BsPagination.Item onClick={() => setPage(maxPages)}>
              {maxPages}
            </BsPagination.Item>
          </>
        )}
        <BsPagination.Item
          disabled={currentPage === maxPages}
          onClick={() => setPage(currentPage + 1)}
        >
          <span style={{ display: "flex", gap: "10px", color: "#212529" }}>
            <span>Next</span>
            <img src={rightarrow} alt="" />
          </span>
        </BsPagination.Item>
      </BsPagination>
    </div>
  );
};

export default Pagination;
