import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

// savings: {
//   id: number;
//     resource_type: "recommendation" | "snapshot" | "idle-resource";
//     ticket_id: string | null;
//     ticket_url: string | null;
//     status: "open" | "unarchive" | "discarded" | "archived" | "accepted" | "inprogress" | "done";
//     created_at: Date;
//     updated_at: Date;
// },

// history: Array<{
//   id: number;
//   ticket_id: string | null;
//     ticket_url: string | null;
//     resource_saving_id: number;
//     status: ResourceSaving['status'];
//     comment: string;
//     created_at: Date;
//     updated_at: Date;
// }>

/**
 * @typedef GetSavings
 * @property {number} id
 * @property {"recommendation" | "snapshot" | "idle-resource"}  resource_type
 * @property {string} ticket_id
 * @property {string}  ticket_url
 * @property {"open" | "unarchive" | "discarded" | "archived" | "accepted" | "inprogress" | "done"} status
 * @property {date} created_at
 * @property {date} updated_at
 *
 */

/**
 *@typedef GetHistory
 * @property {number} id
 * @property {"recommendation" | "snapshot" | "idle-resource"}  resource_type
 * @property {string} ticket_id
 * @property {string}  ticket_url
 * @property {number} resource_saving_id
 * @property {string} comment
 * @property {string} status
 * @property {date} created_at
 * @property {date} updated_at
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {GetSavings} savings
 * @property {GetHistory[]} history
 */

/**
 *@typedef GetHistoryReq
 *@property {"aws" | "azure"} service
 *@property  {string} id
 *@property {string} projectedType
 *@property {string} currentType
 */

export const getHistory = (token) => {
  /**
   *@param {GetHistoryReq} req
   * @returns {Promise <AllBodyStatisticsRes>}
   */
  return async ({ service, id,projectedType,currentType }) => {
    const getHistory = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/history?resourceId=${id}&current_type=${currentType}&projected_type=${projectedType}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!getHistory.ok) {
      throw new Error((await getHistory.json()).message);
    }
    return await getHistory.json();
  };
};

/**
   *@param {GetHistoryReq} req
 * @param {any[]=} deps
 */
export const useGetHistory = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getHistory", req, ...deps],
    queryFn: () => getHistory(appContext.idToken)(req),
    enabled: Boolean(req.id)
  });
  return query;
};
