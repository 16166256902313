import React, { useContext, useState, useMemo } from "react";
import downarrow from "@/assets/img/downarrow.svg";
import vectorUP from "@/assets/img/VectoruP.svg";
import RecTabsComponent from "./TabsRightSizing";
import {
  RightSizingContext,
  RECOMMENDATION_TYPES,
  VIEW_TYPES,
} from "../right-sizing.context";
import styles from "../rightSizing.module.css";
import clsx from "clsx";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useStatistics } from "@/services/right-sizing/getStatistics.repo";
import { useNavigate } from "react-router-dom";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

/**
 * @typedef RightSizingHeaderProps
 * @property {import('@/services/right-sizing/getAllClouds.repo').GetAllStatisticsRes} data
 * @property {string} names
 */

/**
 * @param {RightSizingHeaderProps} props
 * @returns
 */
function AllRightSizingHeader({
  names,
  savingFor,
  tilldate,
  active,
  error,
  service,
  isLoading,
  data,
}) {
  const context = useContext(RightSizingContext);

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context?.selectedTags,
    service,
  ]);

  const { data: checkdata } = useStatistics(query);

  const [selectedOption, setSelectedOption] = useState(context?.viewType);
  const handleDropdownChange = (eventKey) => {
    if (eventKey === VIEW_TYPES.archived) {
      context?.setRecommendationType(RECOMMENDATION_TYPES.downsize);
    }
    context?.setViewType(eventKey);
    setSelectedOption(eventKey);
  };

  // if (error) {
  //   return <DataLoading />;
  // } else if (!data) {
  //   return <DataAbsense />;`
  // }

  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (Math.abs(count) >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (Math.abs(count) >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  // const TotalVM = Number(data?.statistics?.total || 0);
  // const Total = formatCount(TotalVM);

  // const downsize_count = Number(data?.statistics?.downsize_count || 0);
  // const downsize_countpm = formatCount(downsize_count);

  // const upsize_count = Number(data?.statistics?.upsize_count || 0);
  // const upsize_countpm = formatCount(upsize_count);

  const saving_available = Number(
    data?.statistics?.downsize_savings_pm || 0
  ).toFixed(2);
  const saving_avai = formatCount(Math.abs(saving_available));

  const upsize_saving = Number(
    data?.statistics?.upsize_savings_pm || 0
  ).toFixed(2);
  const upsize_savingpm = formatCount(Math.abs(upsize_saving));

  const current_costs = Number(data?.statistics?.current_costs_pm || 0).toFixed(
    2
  );
  const current_costs_pm = formatCount(Math.abs(current_costs));

  const projected_costs = Number(
    data?.statistics?.projected_costs_pm || 0
  ).toFixed(2);
  const projected_costs_pm = formatCount(Math.abs(projected_costs));

  const projected_savings = Number(
    data?.statistics?.projected_savings_pm || 0
  ).toFixed(2);
  const projected_savings_pm = formatCount(Math.abs(projected_savings));

  const savings_till_date = Number(
    data?.statistics?.savings_till_date_pm || 0
  ).toFixed(2);
  const savings_till_date_pm = formatCount(Math.abs(savings_till_date));

  const navigate = useNavigate();
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <div
        className="container-fluid main-container"
        style={{ padding: "16px 40px 0px 40px" }}
      >
        <div className=" Inner_main_container">
          <div className={clsx(styles.rec_inner_first_container)}>
            <div className="inner_first_container_text">{names}</div>
            <div>
              <div className={clsx(styles.custom_select)}>
                <DropdownButton
                  id={styles.right_dropdown_header}
                  title={selectedOption}
                  onSelect={handleDropdownChange}
                >
                  <Dropdown.Item
                    id={styles.dropdown_item}
                    as="button"
                    eventKey="Active"
                    onClick={handleTagSelection}
                  >
                    Active
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={styles.dropdown_item}
                    as="button"
                    eventKey="Archive"
                    onClick={handleTagSelection}
                  >
                    Archive
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
          <div className={clsx("row", styles.rec_inner_second_container)}>
            <div
              className={clsx(
                "container col-md-3 col-sm-12 rounded",
                styles.rec_details_first
              )}
            >
              <div
                className={clsx(styles.rec_second_div_first_container_firstDiv)}
              >
                <div className={clsx("container", styles.upperContent_texts)}>
                  Total VM RECOMMENDATIONS
                </div>
                <div className="container ideal_resource_count_number">
                  <div
                    className={clsx("container", styles.rec_number_container)}
                  >
                    {data?.statistics?.total || 0}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                "container col-md-5 col-sm-12",
                styles.rec_details_sec
              )}
            >
              <div
                className=" container-fluid row"
                style={{
                  margin: "0px",
                  gap: "29px",
                  padding: "0px",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <div className={clsx(" col", styles.rec_content)}>
                  <div className="container upperContent">
                    {/* <img alt="" /> */}
                    <div
                      className={clsx("container", styles.upperContent_texts)}
                    >
                      DOWNSIZE
                    </div>
                  </div>
                  <div className={clsx("container", styles.rec_lowerContent)}>
                    <div className={styles.reclowerContent_numbers}>
                      {data?.statistics?.downsize_count || 0}
                    </div>
                    <div className={styles.reclowerContent_texts}>
                      <div className={styles.lowerContent_icon}>
                        <img alt="" src={downarrow} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx("container", styles.savings_container)}
                    style={{ padding: "0px" }}
                  >
                    <div className={clsx("container ", styles.rec_saving_text)}>
                      Savings Available:
                    </div>

                    <div className={clsx("container ", styles.rec_saving_cost)}>
                      {saving_available >= 0 ? (
                        <ToolTipComponent
                          data={data?.statistics?.downsize_savings_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            ${saving_avai}
                          </span>
                        </ToolTipComponent>
                      ) : (
                        <ToolTipComponent
                          data={data?.statistics?.downsize_savings_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            -${saving_avai}
                          </span>
                        </ToolTipComponent>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={clsx(" col", styles.rec_content)}
                  style={{ border: "none" }}
                >
                  <div className="container upperContent">
                    {/* <img alt="" /> */}
                    <div
                      className={clsx("container", styles.upperContent_texts)}
                    >
                      uPSIZE
                    </div>
                  </div>
                  <div className={clsx("container", styles.rec_lowerContent)}>
                    <div className={styles.reclowerContent_numbers}>
                      {data?.statistics?.upsize_count || 0}
                    </div>
                    <div className={styles.reclowerContent_texts}>
                      <div className={styles.lowerContent_icon}>
                        <img alt="" src={vectorUP} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx("container", styles.savings_container)}
                    style={{ padding: "0px" }}
                  >
                    <div className={clsx("container ", styles.rec_saving_text)}>
                      Costing:
                    </div>
                    <div
                      className={clsx("container ", styles.rec_saving_cost)}
                      style={{ color: "#D01A1A" }}
                    >
                      {upsize_saving >= 0 ? (
                        <ToolTipComponent
                          data={data?.statistics?.upsize_savings_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            ${upsize_savingpm}
                          </span>
                        </ToolTipComponent>
                      ) : (
                        <ToolTipComponent
                          data={data?.statistics?.upsize_savings_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            -${upsize_savingpm}
                          </span>
                        </ToolTipComponent>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="LineBTWBTHDIV" style={{ width: "1px" }}></div> */}

                {/* <div className={clsx(" col", styles.rec_content)}>
                <div className="container upperContent">
                  <img alt="" />
                  <div className="container upperContent_texts">TERMINATE</div>
                </div>
                <div className={clsx("container", styles.rec_lowerContent)}>
                  <div className={styles.reclowerContent_numbers}>
                    {data?.statistics?.terminate_count || 0}
                  </div>
                  <div className={styles.reclowerContent_texts}>
                    <div className={styles.lowerContent_icon}>
                      <img alt="" src={vectorCRoss} />
                    </div>
                  </div>
                </div>
                <div
                  className={clsx("container", styles.savings_container)}
                  style={{ padding: "0px" }}
                >
                  <div className={clsx("container ", styles.rec_saving_text)}>
                    Savings Available:
                  </div>
                  <div className={clsx("container ", styles.rec_saving_cost)}>
                    {"$" +
                      Number(
                        data?.statistics?.terminate_savings_pm || 0
                      ).toFixed(2)}
                  </div>
                </div>
              </div> */}
              </div>
            </div>

            {/* <div style={{ width: "25%", padding: "0px" }}> */}
            <div style={{ width: "25%", padding: "0px" }}>
              <div className={clsx(styles.rec_details_third)}>
                <div className={clsx("container", styles.upperContent_texts)}>
                  Current Costs
                </div>
                <div
                  className={clsx(
                    "container ",
                    styles.rec_ideal_resource_count_number
                  )}
                >
                  <div
                    className="container "
                    style={{ display: "flex", padding: "0px", gap: "4px" }}
                  >
                    <div className={clsx(styles.currunt_cost)}>
                      {current_costs >= 0 ? (
                        <ToolTipComponent
                          data={data?.statistics?.current_costs_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            ${current_costs_pm}
                          </span>
                        </ToolTipComponent>
                      ) : (
                        <ToolTipComponent
                          data={data?.statistics?.current_costs_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            -${current_costs_pm}
                          </span>
                        </ToolTipComponent>
                      )}
                    </div>
                    <div className={clsx("container", styles.currunt_cost1)}>
                      /m
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.current_costsStyling}>
                <div
                  className="contanier"
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "0px",
                    gap: "4px",
                  }}
                >
                  <div className={clsx(styles.project_cost_text)}>
                    Projected Costs:
                  </div>
                  <div style={{ display: "flex", padding: "0px", gap: "2px" }}>
                    <div className={clsx("container ", styles.project_cost)}>
                      {projected_costs >= 0 ? (
                        <ToolTipComponent
                          data={data?.statistics?.projected_costs_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            ${projected_costs_pm}
                          </span>
                        </ToolTipComponent>
                      ) : (
                        <ToolTipComponent
                          data={data?.statistics?.projected_costs_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            -${projected_costs_pm}
                          </span>
                        </ToolTipComponent>
                      )}
                    </div>
                    <div className={clsx("container", styles.project_cost1)}>
                      /m
                    </div>
                  </div>
                </div>
                <span className={styles.include_down_up_text}>
                  (Includes Upsize and Downsize costs)
                </span>
              </div>
            </div>

            <div
              className={clsx(
                active === "active" && styles.greenColor,
                active === "archive" && styles.redColor,
                "container col-md-2 col-sm-12 ",
                styles.rec_second_div_third_container
              )}
            >
              <div className="container-fluid innerContainer-1">
                <div className="container innerContainer-div">
                  <div className="container innerContainer-text">
                    <div className="container innerContainer-text-1">
                      {savingFor}
                    </div>
                    <div className="container innerContainer-text-2">
                      {projected_savings >= 0 ? (
                        <ToolTipComponent
                          data={data?.statistics?.projected_savings_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            ${projected_savings_pm}
                          </span>
                        </ToolTipComponent>
                      ) : (
                        <ToolTipComponent
                          data={data?.statistics?.projected_costs_pm || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            -${projected_savings_pm}
                          </span>
                        </ToolTipComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "innerContainer-2",
                  active === "active" && styles.darkgreenColor,
                  active === "archive" && styles.darkredColor
                )}
              >
                <div className={styles.recinnerSecondContainer}>
                  <div className="innerSecondContainer-text">{tilldate}</div>
                  <div className=" innerSecondContainer-num">
                    {savings_till_date >= 0 ? (
                      <ToolTipComponent
                        data={data?.statistics?.savings_till_date_pm || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          ${savings_till_date_pm}
                        </span>
                      </ToolTipComponent>
                    ) : (
                      <ToolTipComponent
                        data={data?.statistics?.savings_till_date_pm || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          -${savings_till_date_pm}
                        </span>
                      </ToolTipComponent>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RecTabsComponent />
        </div>
      </div>
    </>
  );
}

export default AllRightSizingHeader;
