import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/budgets/components/budgets.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { Button } from "react-bootstrap";
import add from "@/assets/img/add.svg";
import {
  useAccountFilterBudgets,
  useAccountFilterManagementBudgets,
} from "@/services/budget/getBudgetFilterAccounts.repo";
import { useFilterBudgetsTag } from "@/services/budget/getBudgetTagKeys.repo";
import TagItem from "./budgetsFilterDimensionTags";
import { useRgFilterBudgets } from "@/services/budget/getBudgetFilterRg.repo";
import { BudgetContext } from "../budget.context";
import { useNavigate, useSearchParams } from "react-router-dom";

const BudgetFilterSection = ({
  dimensionKeys,
  name,
  service,
  selectedValues,
  setSelectedValues,
  setSelectedTags,
  selectedTags,
}) => {
  const [selectedTittle, setSelectedTittle] = useState(null);
  const [count, setCount] = useState(1);
  const context = useContext(BudgetContext);
  const [searchParams] = useSearchParams();
  const scope = searchParams.get("scope");
  useEffect(() => {
    if(scope){
      context?.setSelectedGroup(scope);
    }
  },[ scope]);

  let query = {
    service,
    scope: context?.selectedGroup,
  };

  let queryAzure = {
    service,
    scope: context?.selectedGroup,
  };

  const subscriptions =
    selectedValues?.account && (selectedValues?.account || []).map((e) => e.id);

  let azureQuery = useMemo(() => {
    return {
      service,
      subscriptions: { subscriptions: subscriptions },
      scope: context?.selectedGroup,
    };
  }, [context?.selectedGroup, service, subscriptions]);

  const management = useAccountFilterManagementBudgets(queryAzure);
  const dropdown = useAccountFilterBudgets(query);
  // const dimensionKeys = useFilterBudgetsTag(query);
  const azureFilterRg = useRgFilterBudgets(azureQuery);

  useEffect(() => {
    setSelectedValues({});
    setSelectedTags([
      {
        filterOn: "",
        filterType: "include",
        values: [],
      },
    ]);
  }, [context?.selectedGroup]);


  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.filterHeader}>Filters</div>

        {service === "aws" && (
          <>
            <div style={{ marginTop: "16px" }}>
              <Filter
                tittle={name}
                setSelectedTittle={setSelectedTittle}
                setSelectedValues={setSelectedValues}
                data={dropdown?.data?.data}
                filterType="account"
                selectedValues={selectedValues}
              />
            </div>
          </>
        )}
        {service === "azure" && (
          <>
            <span className="filterScopeSelection">Scope</span>
            <span
              className="radioButtonContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
              }}
            >
              <RadioButton
                name="scope"
                value="management_group"
                title="Management Group "
              />
              <RadioButton
                name="scope"
                value="subscription"
                title="Subscription"
              />
              <RadioButton
                name="scope"
                value="resource_group"
                title="Resource Group"
              />
            </span>
            {context?.selectedGroup === "management_group" && (
              <Filter
                tittle="Management Group"
                setSelectedTittle={setSelectedTittle}
                setSelectedValues={setSelectedValues}
                data={management?.data?.data}
                filterType="management"
                selectedValues={selectedValues}
              />
            )}
            {(context?.selectedGroup === "subscription" ||
              context?.selectedGroup === "resource_group") && (
              <Filter
                tittle={name}
                setSelectedTittle={setSelectedTittle}
                setSelectedValues={setSelectedValues}
                data={dropdown?.data?.data}
                filterType="account"
                selectedValues={selectedValues}
              />
            )}

            {context?.selectedGroup === "resource_group" && (
              <Filter
                tittle={"Resource Group"}
                setSelectedTittle={setSelectedTittle}
                setSelectedValues={setSelectedValues}
                data={azureFilterRg?.data?.data}
                filterType="ResourceGroup"
                selectedValues={selectedValues}
              />
            )}
          </>
        )}

        <div className={styles.filterContainerBudgets}>
          <hr className="w-100 mb-0 mt-3px" />
          <div
            className={styles.filter_name_addsign}
            style={{ marginBottom: "-8px" }}
          >
            <div className={styles.filter_name}>Tag</div>
            <div>
              <Button
                variant="link"
                className="pt-0"
                onClick={() => {
                  const lastTag = selectedTags[selectedTags.length - 1];
                  if (lastTag && lastTag.values.length > 0) {
                    setCount((prev) => prev + 1);
                    if (count < dimensionKeys?.data.length) {
                      setSelectedTags((arr) => {
                        const newArr = [...arr];
                        newArr.push({
                          filterOn: "",
                          filterType: "include",
                          values: [],
                        });
                        return newArr;
                      });
                    }
                  }
                }}
              >
                <img src={add} alt="" />
              </Button>
            </div>
          </div>
          {(selectedTags || []).map((tag, index) => (
            <TagItem
              service={service}
              tag={tag}
              index={index}
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
              tagItems={dimensionKeys?.data || []}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default BudgetFilterSection;

const Filter = ({
  tittle,
  data,
  setSelectedValues,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const [selectV, setSelectV] = useState({});
  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const handleRemove = (selectedList) => {
    setSelectedValues((prevValues) => {
      return {
        ...prevValues,
        [filterType]: selectedList,
      };
    });
  };

  const handleTittle = (title) => {
    if (title === "Subscription") {
      title = "Account";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <div className={styles.filterContainerBudgets}>
      <div className={styles.dimension_div}>
        <div className={styles.dimension_name_css}>{tittle}</div>
        <div
          style={{ position: "relative", width: "100%" }}
          onClick={() => handleTittle(tittle)}
        >
          <FilterSearch
            multiselect={true}
            options={(data || []).map((e) => {
              return {
                id: e?.id,
                name: e?.name,
              };
            })}
            selectedValues={selectedValues[filterType] || []}
            onSelect={(list) => {
              handleRemove(list);
              handleTagSelection();
            }}
            onRemove={(list) => {
              handleRemove(list);
            }}
            displayValue="name"
            placeholder={tittle}
            toggle={
              <>
                <FilterSearchToggle placeholder={tittle} />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export const RadioButton = ({ name, title, value }) => {
  const context = useContext(BudgetContext);
  const handleChange = (e) => {
    const newValue = e.target.value;
    context?.setSelectedGroup(newValue);
  };

  return (
    <div className="radioButtonSection">
      <input
        style={{ cursor: "pointer" }}
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={context?.selectedGroup === value}
        onChange={handleChange}
      />
      <label htmlFor={value} style={{ cursor: "pointer" }}>
        {title}
      </label>
    </div>
  );
};
