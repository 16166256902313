import config from "@/config";
import Joi from "joi";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

/**
 * @typedef {keyof typeof import('./addCostExplorerWidget.repo').AWS_DIMENSIONS} AWS_DIMENSIONS
 */

/**
 * @typedef {keyof typeof import('./addCostExplorerWidget.repo').AZURE_DIMENSIONS} AZURE_DIMENSIONS
 */

/**
 * @typedef UpdateCostExplorerWidgetReqFilters
 * @property {AWS_DIMENSIONS | AZURE_DIMENSIONS} dimension
 * @property {boolean} isInclude
 * @property {string[]} values
 */

/**
 * @typedef IUpdateCostExplorerWidgetReq
 * @property {string} id
 * @property {string} title
 * @property {string} cloud
 * @property {string} showPrevious
 * @property {string} interval
 * @property {string} granularity
 * @property {string} graphType
 * @property {string} costAggregation
 * @property {AWS_DIMENSIONS | AZURE_DIMENSIONS} dimension
 * @property {UpdateCostExplorerWidgetReqFilters[]} filters
 * @property {Date} fromDate
 * @property {Date} toDate
 */

/** @type {Joi<IUpdateCostExplorerWidgetReq>} */
export const UpdateCostExplorerWidgetReq = Joi.object({
  id: Joi.string().required(),
  title: Joi.string().required().messages({
    "string.empty": "Title is required",
    "any.required": "Title is required",
  }),
  showPrevious: Joi.string().required().messages({
    "string.empty": "Previous value is required",
    "any.required": "Previous value is required",
  }),
  cloud: Joi.string().valid("aws", "azure", "gcp").required().messages({
    "string.empty": "Cloud is required",
    "any.required": "Cloud is required",
  }),
  interval: Joi.string().valid("days", "weeks", "months").required().messages({
    "string.empty": "Interval is required",
    "any.required": "Interval is required",
  }),
  granularity: Joi.string()
    .valid("date", "week", "monthname")
    .required()
    .messages({
      "string.empty": "Granularity is required",
      "any.required": "Granularity is required",
    }),
  graphType: Joi.string().valid("bar", "line").required().messages({
    "string.empty": "GraphType is required",
    "any.required": "GraphType is required",
  }),
  costAggregation: Joi.string().optional(),
  dimension: Joi.string().required().messages({
    "string.empty": "Dimension is required",
    "any.required": "Dimension is required",
  }),
  filters: Joi.array().items(
    Joi.object({
      dimension: Joi.string().messages({
        "string.empty": "Dimension field is required",
        "any.required": "Dimension field is required",
      }),
      isInclude: Joi.boolean(),
      values: Joi.array().items(Joi.string()).min(1).messages({
        "array.min": "Values cannot be empty",
      }),
    })
  ),
  tags: Joi.array().items(
    Joi.object({
      filterOn: Joi.string().allow("").messages({
        "string.empty": "Filter on field is required",
        "any.required": "Filter on field is required",
      }),
      filterType: Joi.valid("include", "exclude").optional(),
      values: Joi.array().items(Joi.string()).optional(),
    })
  ),
  fromDate: Joi.date().required(),
  toDate: Joi.date().required(),
});

/**
 * @typedef SingleWidgetLocal
 * @property {string} widgetId
 */

export const updateCostExplorerWidget = (token) => {
  /**
   * @param {UpdateCostExplorerWidgetReq} req
   * @returns {Promise<boolean>}
   */
  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/${req.cloud}/cost/update-widget`,
      {
        method: "PUT",
        body: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    return true;
  };
};

/**
 * @param {any[]=} deps
 */
export const useUpdateCostExplorerWidget = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["updateCostExplorerWidget", appContext.idToken, ...deps],
    mutationFn: updateCostExplorerWidget(appContext.idToken),
  });
  return mutation;
};
