import React from "react";
import styles from "@/page-components/admin/admin.module.css";
import searchbarIcon from "@/assets/img/searchbarIcon.svg";
import clsx from "clsx";

const SearchBar = ({ setFilterQuery, className, value }) => {
  return (
    <div className={clsx(styles.searchbar_main_div, className)}>
      <img src={searchbarIcon} alt="Search"></img>
      <input
        type="search"
        placeholder="Search"
        color="#ADB5BD"
        className={styles.search_name_css}
        value={value} 
        onChange={(e) => setFilterQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
