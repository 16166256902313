import React, { useCallback, useEffect, useMemo } from "react";
import "../components/budgets.page.css";
import addWidget from "@/assets/img/addWidget.svg";
import menu_dot from "@/assets/img/menu-dots 1.svg";
import UsedChart from "@/page-components/budgets/Aws/used.part";
import ForcastBudget from "../components/forecast.part";
import ChartDetails from "../components/chartDetail.part";
import { useState, useRef } from "react";
import AddNewWidgets from "../components/addWidget.part";
import BudgetSkeleton from "../components/skeleton.part";
import nofication from "@/assets/img/notifications_active.svg";
import notificationOff from "@/assets/img/notifications_off.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import style from "@/page-components/idle-resource/idle.module.css";

import { CSVLink } from "react-csv";
import download from "@/assets/img/download.svg";
import { useListApi, useListApiAws } from "@/services/budget/setBudgetCard";
import { useCombineQuery } from "@/services/budget/setDelete";
import ToastComponent from "../components/toast";
import BudgetsErrorStates from "@/components/States/BudgetsErrorStates";
import Update from "../components/edit.part";
import styles from "../components/budgets.module.css";
import ConformationDialog from "../components/Conformation-Dialog";
import DataLoading from "@/components/States/DataLoading";
import clsx from "clsx";
import AppToast from "../components/customeToastComponent";
import BudgetFilterSection from "../components/budgetFilterSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Budget_Provider } from "../budget.context";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import sorting from "@/assets/img/sortingBudget.svg";
import menuicon from "@/assets/img/menu.svg";
import DropdowmComponent from "@/common/DropdownComponent";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import listView from "@/assets/img/listViewBudget.svg";
import listselected from "@/assets/img/tableSelectedBudget.svg";
import cardView from "@/assets/img/cardViewBudget.svg";
import Cardselected from "@/assets/img/cardSelectedBudget.svg";
import tableselected from "@/assets/img/tableSelectedBudget.svg";
import Pagination from "@/components/pagination/pagination.component";
import TableErrorState from "@/components/States/TableErrorState";
import { ProgressBar } from "react-bootstrap";
import { useListApiAwsdownload } from "@/services/budget/getBudgetDownload.repo";
import { useFilterBudgetsTag } from "@/services/budget/getBudgetTagKeys.repo";

const BudgetsPage = () => {
  const service = "aws";
  const [isLayoverOpen, setIsLayoverOpen] = useState(false);
  const [recordId, setRecordId] = useState([]);
  const [toast, setToast] = useState(false);
  const [edit, setEdit] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  const [message, setMessage] = useState();
  const [currentData, setCurrentData] = useState(null);
  const [notificationOn, setNotificationOn] = useState(true);
  const [conformation, setConformation] = useState({
    dialog: false,
    delete: false,
  });
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [BudgetView, setbudgetView] = useState(() => localStorage.getItem("budgetView") || "card");
  const [hideCreate, setHideCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [orderBy, setOrderBy] = useState("usedamount");
  const desc = searchParams.get("desc");
  const navigate = useNavigate();
  const overlayClass = isLayoverOpen ? "show" : "hide";
  //   {
  //   message: "Under Progress",
  //   type: "info",
  // }

  useEffect(() => {
    localStorage.setItem("budgetView", BudgetView);
  }, [BudgetView]);

  const payload = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;

    return {
      type: BudgetView,
      page: parsedPage || 1,
      orderBy: orderBy || "usedamount",
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      tags: selectedTags,
      service: service,
      accounts:
        selectedValues?.account &&
        (selectedValues?.account || []).map((e) => e.id),
    };
  }, [
    BudgetView,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    selectedTags,
    selectedValues?.account,
  ]);

  const {
    data: listApi,
    isLoading,
    refetch: list,
    isSuccess: listAllSuccess,
    isError: listAllIsError,
    error: listAllError,
  } = useListApiAws(payload);

  const {data:dimensionKeys,refetch:dimensionrefetch} = useFilterBudgetsTag(payload);

  useEffect(() => {
    if (
      listAllError &&
      (listAllError.message === API_NO_DATA_FOUND_ERROR ||
        listAllError.message === API_NOT_FOUND_ERROR ||
        listAllError.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [listAllError]);

  const toggleLayover = () => {
    setIsLayoverOpen(!isLayoverOpen);
  };
  useEffect(() => {
    if (toast === false) {
      setMessage("");
    }
  }, [toast]);

  const handleDownloadCsv = (index) => {
    const budgetData = listApi?.data[index];
    const csvData = [Object.keys(budgetData), Object.values(budgetData)];
    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${listApi?.data[index].BudgetName}-csv-file.csv`;
    link.click();
  };

  const refetchApi = () => {
    list();
  };

  const closeLayover = () => {
    setIsLayoverOpen(false);
  };

  const closeUpdate = () => {
    setEdit(false);
  };

  const {
    mutate: Body,
    data: Details,
    isSuccess,
    isError,
    isPending,
    error,
  } = useCombineQuery();

  const handleFunctionality = useCallback(
    (Id, name, status = null) => {
      const notificationFn = (indication) => {
        Body({
          body: { ACCOUNT_ID: Id, BudgetName: name },
          service: service,
          type: status === "Active" ? "snooze" : "unsnooze",
          selection: indication,
        });

        setNotificationOn((prevNotificationOn) => ({
          ...prevNotificationOn,
          [name]: !prevNotificationOn[name],
        }));
        setToast(!toast);
      };

      const singleRes = (indication) => {
        if (indication === "singleRes") {
          Body(
            {
              body: { accountId: Id, budgetName: name },
              service: service,
              selection: indication,
            },
            {
              // onSuccess: (response) => {
              //   if (response) {
              //     setEdit((prevEdit) => !prevEdit);
              //   } else {
              //     setToast((prevToast) => !prevToast);
              //   }
              // },
              onError: (error) => {
                setToast((prevToast) => !prevToast);
              },
            }
          );
          setEdit((prevEdit) => !prevEdit);
        }
      };
      return {
        notificationFn,
        singleRes,
      };
    },
    [Body, toast]
  );

  useEffect(() => {
    if (isSuccess === true) setTimeout(() => {list();dimensionrefetch()}, 10000);
  }, [list, isSuccess, dimensionrefetch]);

  useEffect(()=>{
    if(isSuccess === true) dimensionrefetch()
  },[dimensionrefetch, isSuccess])

  const handleDelete = useCallback(
    ({ ACCOUNT_ID, NAME, OPERATION }) => {
      Body(
        {
          body: { ACCOUNT_ID: ACCOUNT_ID, BudgetName: NAME },
          service: service,
          selection: OPERATION,
        },
        {
          onSuccess: () => {
            setToast(true);
          },
        },
        {
          onError: (error) => {
            setToast((prevToast) => !prevToast);
          },
        }
      );
      setTimeout(() => list(), [2000]);
    },
    [Body, list]
  );

  useEffect(() => {
    if (isSuccess && Details !== undefined) {
      setMessage({ message: Details?.message, type: "success" });
    }
    if (isError || error) {
      setMessage({ message: error?.message, type: "Anamaly" });
    }
  }, [isSuccess, Details, isError, error, setMessage]);
  const handleUrl = (name, id) => {
    searchParams.set("BudgetName", `${name}`);
    searchParams.set("Id", id);
    setSearchParams(searchParams);
    navigate(`/aws/budgets/${name}/${id}`);
  };

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);

    event.currentTarget.classList.add("dragging");
  };

  const handleDragOver = (event) => {
    event.preventDefault();

    event.currentTarget.classList.add("over");
  };
  const handleDragLeave = (event) => {
    event.currentTarget.classList.remove("over");
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    const draggedItem = budgetList[draggedIndex];
    const updatedList = [...budgetList];

    updatedList.splice(draggedIndex, 1);
    updatedList.splice(index, 0, draggedItem);

    setBudgetList(updatedList);

    event.currentTarget.classList.remove("dragging", "over");
  };
  const handleDragEnd = (event) => {
    event.currentTarget.classList.remove("dragging", "over");
  };
  useEffect(() => {
    if (listApi?.data) {
      setBudgetList(listApi.data);
    }
  }, [listApi?.data]);
  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const items = [
    {
      eventKey: "Download as CSV",
      icon: download,
      label: "Download as CSV",
    },
  ];
  const downloadQuery = useMemo(() => {
    return {
      service: service,
      tableName: "download",
    };
  }, []);
  const { mutate: downloadMutate } = useListApiAwsdownload(downloadQuery);
  const handleDownloadClick = (eventKey) => {
    if (eventKey === "Download as CSV") {
      downloadMutate(downloadQuery);
    }
  };
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  return (
    <Budget_Provider>
      <>
        {/* <AppToast
        data={Details}
        successMessage={isSuccess}
        isError={isError}
        errorExist={error}
      /> */}

        {toast && (
          <ToastComponent message={message} toast={toast} setToast={setToast} />
        )}

        {/* {!isLoading && ( */}
        <div className="budget_main_container" style={{ height: "100vh" }}>
          <div className="budget_container">
            <>
              <div className="budgets_header">
                <div className="budget_head">
                  <span className="head_text">Budgets</span>
                  <span className="head_paragraph">
                    This dashboard allows you to create, update or delete a
                    budget. It also enables you to snooze or un-snooze the
                    budget alerts.
                  </span>
                </div>
                <div className={styles.cardListContainer}>
                  <div className=" btn add_new_widgets" onClick={toggleLayover}>
                    <img className="add_widgte_svg" src={addWidget} alt=" " />
                    <span className="add_widget_text">Create Budgets</span>
                  </div>
                  <span className={styles.cardListView}>
                    <span
                      style={{
                        borderRadius: "8px 0px 0px 8px",
                        transition: "background 0.3s ease-in-out",
                        background:
                          BudgetView === "card" ? "#EDEFF2" : "transparent",
                      }}
                      className={styles.buttonView}
                      onClick={() => {
                        setbudgetView("card");
                      }}
                    >
                      <img
                        src={BudgetView === "card" ? Cardselected : listView}
                        alt="Card View"
                      />
                    </span>
                    <span
                      style={{
                        borderRadius: "0px 8px 8px 0px",
                        transition: "background 0.3s ease-in-out",
                        background:
                          BudgetView === "list" ? "#EDEFF2" : "transparent",
                      }}
                      className={styles.buttonView}
                      onClick={() => {
                        setbudgetView("list");
                        handleTagSelection();
                      }}
                    >
                      <img
                        src={BudgetView === "list" ? listselected : cardView}
                        alt="List View"
                      />
                    </span>
                  </span>
                </div>
              </div>
            </>
            {BudgetView === "card" && (
              <div
                className="cardView"
                style={{ overflowY: "scroll", height: "100vh" }}
              >
                {isLoading && <BudgetsErrorStates />}
                {listAllError && (
                  <ScenariosErrorState
                    error={listAllError.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                        message: "No Data Available for Selected Filters",
                        additionalMessage:
                          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                      },
                      [API_NO_DATA_FOUND_ERROR]: {
                        message: "No Data Available",
                        additionalMessage:
                          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                      },
                      [API_LOADING_ERROR]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}
                {!isLoading && !(listAllError || listAllIsError) && (
                  <div className="chart_conatiner">
                    {budgetList.length === 0 && (
                      <BudgetSkeleton functionality={toggleLayover} />
                    )}
                    {budgetList.map((data, index) => (
                      <DataItem
                        data={data}
                        notificationOn={notificationOn}
                        setConformation={setConformation}
                        setCurrentData={setCurrentData}
                        handleFunctionality={handleFunctionality}
                        handleDownloadCsv={handleDownloadCsv}
                        handleUrl={handleUrl}
                        index={index}
                        draggable
                        onDragStart={handleDragStart(index)}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop(index)}
                        onDragEnd={handleDragEnd}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {BudgetView === "list" && (
              <>
                {isLoading && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <TableErrorState />
                    </div>
                  </>
                )}
                {listAllError && (
                  <ScenariosErrorState
                    error={listAllError.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                        message: "No Data Available for Selected Filters",
                        additionalMessage:
                          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                      },
                      [API_NO_DATA_FOUND_ERROR]: {
                        message: "No Data Available",
                        additionalMessage:
                          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                      },
                      [API_LOADING_ERROR]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}
                {!isLoading && !(listAllError || listAllIsError) && (
                  <div className="container-fluid  p-0">
                    <div style={{ minWidth: "30%" }}>
                      <table
                        className="table table-hover"
                        id={styles.customers}
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ width: "4%" }}
                            ></th>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ width: "20%" }}
                            >
                              Name
                              <img
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                src={sorting}
                                alt=""
                                className={clsx(
                                  searchParams.get("desc") === "false" &&
                                    searchParams.get("orderBy") ===
                                      "budgetname" &&
                                    styles.sortingUp,
                                  searchParams.get("desc") === "true" &&
                                    searchParams.get("orderBy") ===
                                      "budgetname" &&
                                    styles.sorting_icon
                                )}
                                onClick={() => handleOrderByClick("budgetname")}
                              ></img>
                            </th>
                            <th className={clsx(styles.th_class)}>
                              Budget
                              <img
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                src={sorting}
                                alt=""
                                className={clsx(
                                  searchParams.get("desc") === "false" &&
                                    searchParams.get("orderBy") === "amount" &&
                                    styles.sortingUp,
                                  searchParams.get("desc") === "true" &&
                                    searchParams.get("orderBy") === "amount" &&
                                    styles.sorting_icon
                                )}
                                onClick={() => handleOrderByClick("amount")}
                              ></img>
                            </th>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ width: "25%" }}
                            >
                              Current Spend
                              <img
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                src={sorting}
                                alt=""
                                className={clsx(
                                  searchParams.get("desc") === "false" &&
                                    searchParams.get("orderBy") ===
                                      "usedamount" &&
                                    styles.sortingUp,
                                  searchParams.get("desc") === "true" &&
                                    searchParams.get("orderBy") ===
                                      "usedamount" &&
                                    styles.sorting_icon
                                )}
                                onClick={() => handleOrderByClick("usedamount")}
                              ></img>
                            </th>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ textAlign: "end" }}
                            >
                              Forecasted
                              <img
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                src={sorting}
                                alt=""
                                className={clsx(
                                  searchParams.get("desc") === "false" &&
                                    (searchParams.get("orderBy") ===
                                      "forecastedamount" ||
                                      "") &&
                                    styles.sortingUp,
                                  searchParams.get("desc") === "true" &&
                                    searchParams.get("orderBy") ===
                                      "forecastedamount" &&
                                    styles.sorting_icon
                                )}
                                onClick={() =>
                                  handleOrderByClick("forecastedamount")
                                }
                              ></img>
                            </th>
                            <th className={clsx(styles.th_class)}>
                              Periodicity
                            </th>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ textAlign: "start" }}
                            >
                              Start Date
                              <img
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                src={sorting}
                                alt=""
                                className={clsx(
                                  searchParams.get("desc") === "false" &&
                                    (searchParams.get("orderBy") ===
                                      "StartDate" ||
                                      "") &&
                                    styles.sortingUp,
                                  searchParams.get("desc") === "true" &&
                                    searchParams.get("orderBy") ===
                                      "StartDate" &&
                                    styles.sorting_icon
                                )}
                                onClick={() => handleOrderByClick("StartDate")}
                              ></img>
                            </th>
                            <th
                              className={clsx(styles.th_class)}
                              style={{ textAlign: "center" }}
                            >
                              Threshold
                            </th>
                            <th
                              style={{
                                width: "4%",
                                background: "#f6f6f6",
                                textAlign: "center",
                              }}
                            >
                              <DropdowmComponent
                                menuIcon={menuicon}
                                items={items}
                                onItemClick={handleDownloadClick}
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {(listApi?.data || []).map((item, index) => {
                            const barColor = clsx("forcastPercentageColor", {
                              forcastPercentageExcededColor:
                                item?.UsedAmount > item?.Amount,
                            });
                            const useageDataPercentage = Number(
                              (
                                ((item?.usedAmount || item?.UsedAmount || 0) /
                                  Number(item?.amount || item?.Amount || 0)) *
                                100
                              )?.toFixed(2)
                            );

                            return (
                              <tr
                                key={index}
                                className={clsx(style.table_row, "p-0")}
                              >
                                <td
                                  className={style.table_data__Class}
                                  title={item?.budgetName}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      // <Tooltip id={index}>
                                      //   {notificationOn[item?.NAME]
                                      //     ? "Notification On"
                                      //     : "Notification Off"}
                                      // </Tooltip>
                                      <Tooltip id={index}>
                                        {item?.status === "Snoozed"
                                          ? "Notification Off"
                                          : "Notification On"}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      className="notification_bell"
                                      id={index}
                                      src={
                                        item?.status === "Snoozed"
                                          ? notificationOff
                                          : nofication
                                      }
                                      alt=""
                                      onClick={() =>
                                        handleFunctionality(
                                          item?.AccountId,
                                          item?.BudgetName,
                                          item?.status
                                        ).notificationFn("notification")
                                      }
                                    />
                                  </OverlayTrigger>
                                </td>
                                <td
                                  style={{
                                    color: "#056EE5",
                                    cursor: "pointer",
                                  }}
                                  className={style.table_data__Class}
                                  title={item?.BudgetName}
                                  onClick={() =>
                                    handleUrl(item?.BudgetName, item?.AccountId)
                                  }
                                >
                                  {item?.BudgetName || "--"}
                                </td>
                                <td
                                  className={style.table_data__Class}
                                  title={item?.Amount}
                                >
                                  {(item?.Amount &&
                                    "$" + Number(item?.Amount).toFixed(2)) ||
                                    "--"}
                                </td>
                                <td className={style.table_data__Class}>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "#212224",
                                      }}
                                    >
                                      {useageDataPercentage}%
                                    </h1>
                                    <h1
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "#495057CC",
                                      }}
                                    >
                                      $
                                      {Number(item?.UsedAmount || 0).toFixed(2)}
                                    </h1>
                                  </span>
                                  <ProgressBar style={{ height: "8px" }}>
                                    <ProgressBar
                                      className={barColor}
                                      now={useageDataPercentage}
                                      key={1}
                                    />
                                  </ProgressBar>
                                </td>
                                <td
                                  className={style.table_data__Class}
                                  title={item?.ForecastedAmount}
                                  style={{
                                    textAlign: "end",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {item?.ForecastedAmount &&
                                    "$" + (item?.ForecastedAmount || "--")}
                                </td>
                                <td
                                  className={style.table_data__Class}
                                  title={item?.TimeUnit}
                                >
                                  {item?.TimeUnit || "--"}
                                </td>
                                <td
                                  className={style.table_data__Class}
                                  title={item?.StartDate}
                                  style={{ textAlign: "start" }}
                                >
                                  {item?.StartDate || "--"}
                                </td>
                                <td
                                  className={style.table_data__Class}
                                  title={item?.Threshold}
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.Threshold &&
                                    Number(item?.Threshold || "--").toFixed(
                                      2
                                    ) + "%"}
                                </td>
                                <td>
                                  <Dropdown id="budgetId">
                                    <Dropdown.Toggle
                                      variant="light"
                                      id={styles.custom_dropdown_toggle}
                                      className={clsx(
                                        styles.Dropdown_Toggel,
                                        styles.custom_dropdown_toggle
                                      )}
                                      style={{ border: "none" }}
                                    >
                                      <img
                                        src={menu_dot}
                                        className="pb-2"
                                        alt="menu"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      className={styles.dropdown_menu}
                                    >
                                      <Dropdown.Item
                                        className={styles.budget_dropdown_item}
                                        onClick={() => {
                                          handleFunctionality(
                                            item?.AccountId,
                                            item?.BudgetName
                                          ).singleRes("singleRes");
                                        }}
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className={styles.budget_dropdown_item}
                                        onClick={() => {
                                          setCurrentData({
                                            ACCOUNT_ID: item?.AccountId,
                                            NAME: item?.BudgetName,
                                            OPERATION: "delete",
                                          });
                                          setConformation((prev) => ({
                                            ...prev,
                                            dialog: true,
                                            delete: false,
                                          }));
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                      {/* <Dropdown.Divider />

                                      <Dropdown.Item
                                        className={styles.budget_dropdown_item}
                                        eventKey="Download as CSV"
                                        onClick={() => handleDownloadCsv(index)}
                                      >
                                        <img
                                          src={download}
                                          alt=""
                                          style={{ marginRight: "10px" }}
                                        />
                                        Download as CSV
                                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination totalItems={listApi?.total_count || 0} />
                  </div>
                )}
              </>
            )}
          </div>
          {!hideFilterSection && (
            <BudgetFilterSection
              name={"Accounts"}
              service={service}
              selectedTags={selectedTags}
              selectedValues={selectedValues}
              setSelectedTags={setSelectedTags}
              setSelectedValues={setSelectedValues}
              dimensionKeys={dimensionKeys}
            />
          )}
        </div>
        <AddNewWidgets
          refetch={refetchApi}
          description={"Select Your Account and Enhance Budget Precision"}
          range={range}
          setRange={setRange}
          service={service}
          Title={"Create Budget"}
          setRecordId={setRecordId}
          recordId={recordId}
          onClose={setIsLayoverOpen}
          onOpen={isLayoverOpen}
          name="Accounts Name"
          alert="Alert & Notifications Conditions"
        />
        {edit && (
          <div className="add_widget_overlay_screens">
            <Update
              editData={Details}
              description={
                "Select Your Account ID and Enhance Budget Precision"
              }
              edit={edit}
              setEdit={setEdit}
              onClose={closeUpdate}
              service={service}
              Title={"Edit Budget"}
              setRecordId={setRecordId}
              recordId={recordId}
              name="Account ID"
              setCurrentData={setCurrentData}
              setConformation={setConformation}
              alert="Alert & Notifications Conditions*"
              loading={isPending}
            />
          </div>
        )}
        {conformation.dialog && (
          <div className={styles.customeOverlay}>
            <div className={styles.conformationBox}>
              <ConformationDialog
                setConformation={setConformation}
                setToast={setToast}
                handleDelete={() => handleDelete(currentData)}
                refetch={refetchApi}
              />
            </div>
          </div>
        )}
      </>
    </Budget_Provider>
  );
};

const DataItem = ({
  data,
  notificationOn,
  index,
  handleFunctionality,
  setCurrentData,
  setConformation,
  handleDownloadCsv,
  handleUrl,
  onDragStart,
  onDragOver,
  onDragLeave,
  onDrop,
  onDragEnd,
}) => {
  const [randomValue] = useState(Math.floor(Math.random() * 100));

  return (
    <div
      key={index}
      className="chart_card "
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <div className="sandbox-budget">
        <span
          className="text_sandbox elipese"
          style={{ maxWidth: "195px" }}
          onClick={() => handleUrl(data.BudgetName, data?.AccountId)}
          title={data?.BudgetName}
        >
          {data.BudgetName}
        </span>
        <div style={{ display: "flex", gap: "12px" }}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              // <Tooltip id={index}>
              //   {notificationOn[data.NAME]
              //     ? "Notification On"
              //     : "Notification Off"}
              // </Tooltip>
              <Tooltip id={index}>
                {data?.status === "Snoozed"
                  ? "Notification Off"
                  : "Notification On"}
              </Tooltip>
            }
          >
            <img
              className="notification_bell"
              id={index}
              src={data.status === "Snoozed" ? notificationOff : nofication}
              alt=""
              onClick={() =>
                handleFunctionality(
                  data.AccountId,
                  data.BudgetName,
                  data.status
                ).notificationFn("notification")
              }
            />
          </OverlayTrigger>
          <Dropdown id="budgetId">
            <Dropdown.Toggle
              variant="light"
              id={styles.custom_dropdown_toggle}
              className={clsx(
                styles.Dropdown_Toggel,
                styles.custom_dropdown_toggle
              )}
              style={{ border: "none" }}
            >
              <img src={menu_dot} className="pb-2" alt="menu" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdown_menu}>
              <Dropdown.Item
                className={styles.budget_dropdown_item}
                onClick={() => {
                  handleFunctionality(
                    data.AccountId,
                    data.BudgetName
                  ).singleRes("singleRes");
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.budget_dropdown_item}
                onClick={() => {
                  setCurrentData({
                    ACCOUNT_ID: data.AccountId,
                    NAME: data.BudgetName,
                    OPERATION: "delete",
                  });
                  setConformation((prev) => ({
                    ...prev,
                    dialog: true,
                    delete: false,
                  }));
                }}
              >
                Delete
              </Dropdown.Item>
              {/* <Dropdown.Divider />

              <Dropdown.Item
                className={styles.budget_dropdown_item}
                eventKey="Download as CSV"
                onClick={() => handleDownloadCsv(index)}
              >
                <img src={download} alt="" style={{ marginRight: "10px" }} />
                Download as CSV
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <UsedChart chartdata={data} randomValue={randomValue} index={index} />
      <ForcastBudget
        forcastdata={data}
        randomValue={randomValue}
        index={index}
      />
      <ChartDetails chartDetails={data} index={index} />
    </div>
  );
};

export default BudgetsPage;
