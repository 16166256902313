import React, { useContext } from "react";
import { Offcanvas, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import { Table } from "react-bootstrap";
import clsx from "clsx";
import { usePrivilegesById } from "@/services/admin/Privileges/getPrivilegesById.repo";
import { PERMISSIONS } from "@/services/admin/Privileges/createPrivilege.repo";
import { format } from "date-fns";
import { AdminPrivilegeContext } from "./privilege.context";
import disable from "@/assets/img/disable.svg";
import { UsersContext } from "../user profile/admin.context";

/**
 * @callback HandleEditBtn
 * @param {number} id
 * @returns {void}
 */

/**
 * @callback HandleDeleteBtn
 * @param {number} id
 * @param {string} name
 * @returns {void}
 */

/**
 * @typedef PrivilegeDataProps
 * @property {boolean} show
 * @property {() => void} handleClose
 * @property {number=} selectedItem
 * @property {HandleEditBtn} handleEditBtn
 * @property {HandleDeleteBtn} handleDeleteBtn
 */

/**
 * @param {PrivilegeDataProps} props
 */
export default function PrivilegeData({
  show,
  handleClose,
  selectedItem,
  handleEditBtn,
  handleDeleteBtn,
  groupId,
}) {
  const adminContext = useContext(AdminPrivilegeContext);
  const userContext = useContext(UsersContext);

  const id = selectedItem;

  const { data, isLoading } = usePrivilegesById(selectedItem);

  const handleGoBack = () => {
    // userContext.setSelectedItem(true);
    userContext.setPrivilegesId(undefined);
  };

  return (
    <>
      <Offcanvas
        style={{ width: userContext?.hideFooter ? "800px" : "40%" }}
        placement="end"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner variant="primary" />
          </div>
        )}

        {!isLoading && (
          <>
            <Offcanvas.Header closeButton style={{ paddingRight: "24px" }}>
              <div style={{ padding: "24px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {userContext?.hideFooter && (
                    <span className={styles.goBackBtn} onClick={handleGoBack}>
                      &lt; Go Back
                    </span>
                  )}
                  <div className={styles.modalTitle}>
                    <span className={styles.mainTitle}>
                      {data?.privilege?.name || ""}
                    </span>
                    <span className={styles.subTitle}>
                      Last Modified on{" "}
                      {data?.privilege?.createdAt
                        ? format(
                            new Date(data?.privilege?.createdAt),
                            "dd/MM/yyyy"
                          )
                        : "--"}
                    </span>
                  </div>
                </div>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Header>
              <div
                className={styles.table_div}
                style={{ padding: "0px 24px 24px 24px" }}
              >
                <span className={styles.discription_name}>Description</span>
                <p className={styles.discription_details}>
                  {data?.privilege?.description || "--"}
                </p>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body
              style={{ width: "100%", backgroundColor: "#F8F9FA" }}
            >
              <Table style={{ marginTop: "12px" }}>
                <thead>
                  <tr>
                    <th className={styles.thead}>
                      Accounts/Subscriptions/Projects
                    </th>
                    <th className={clsx(styles.thead, styles.textalign_center)}>
                      Edit
                    </th>
                    <th className={clsx(styles.thead, styles.textalign_center)}>
                      Read
                    </th>
                  </tr>
                  <tr style={{ height: "10px" }}></tr>
                </thead>
                <tbody>
                  {data?.accounts.map((e) => (
                    <>
                      <tr className={styles.mytr}>
                        <td
                          style={{ display: "flex", alignItems: "flex-start" }}
                          className={clsx(
                            styles.mytd,
                            styles.gap,
                            styles.border_bottomall
                          )}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">
                                {e?.accountName || e?.accountNo}
                              </Tooltip>
                            }
                          >
                            <div className={styles.my_truncatecellss}>
                              <span>{e?.accountName || e?.accountNo}</span>
                              <img className={styles.img_width} alt="" />
                            </div>
                          </OverlayTrigger>
                        </td>
                        <td
                          className={clsx(
                            styles.mytd,
                            styles.textalign_center,
                            styles.border_bottomall
                          )}
                        >
                          <input
                            checked={e.permission === PERMISSIONS.edit}
                            type="checkbox"
                          />
                        </td>
                        <td
                          className={clsx(
                            styles.mytd,
                            styles.textalign_center,
                            styles.border_bottomall
                          )}
                        >
                          {e.permission === PERMISSIONS.edit ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={tooltip}
                            >
                              <img
                                className={styles.img_width}
                                src={disable}
                                alt=""
                              />
                            </OverlayTrigger>
                          ) : (
                            <input
                              checked={e.permission === PERMISSIONS.read}
                              type="checkbox"
                            />
                          )}
                        </td>
                      </tr>
                      {e.resourceGroups?.length > 0 && (
                        <tr>
                          <td
                            className={styles.border_bottomall}
                            colSpan={3}
                            style={{
                              backgroundColor: "#F8F9FA",
                              border: "none",
                              padding: "0px",
                            }}
                          >
                            <Table
                              borderless
                              style={{ padding: "0px", margin: "0px" }}
                            >
                              <tbody>
                                {e.resourceGroups.map((x) => (
                                  <tr
                                    style={{ backgroundColor: "#F8F9FA" }}
                                    className={styles.border_bottomall}
                                  >
                                    <td
                                      className={clsx(
                                        styles.mytd,
                                        styles.gap,
                                        styles.border_bottomall
                                      )}
                                    >
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            s{x?.resourceGroupName}
                                          </Tooltip>
                                        }
                                      >
                                        <div
                                          className={styles.my_truncatecells}
                                        >
                                          <span className="ms-4">
                                            {x.resourceGroupName}
                                          </span>
                                        </div>
                                      </OverlayTrigger>
                                    </td>
                                    <td
                                      className={clsx(
                                        styles.mytd,
                                        styles.textalign_center
                                      )}
                                    >
                                      <input
                                        checked={
                                          x.permission === PERMISSIONS.edit
                                        }
                                        type="checkbox"
                                      />
                                    </td>
                                    <td
                                      className={clsx(
                                        styles.mytd,
                                        styles.textalign_center,
                                        styles.border_bottomall
                                      )}
                                    >
                                      {e.permission === PERMISSIONS.edit  || PERMISSIONS.read? (
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={tooltip}
                                          
                                        >
                                          <img 
                                            className={styles.img_width}
                                            src={disable}
                                            alt=""
                                          />
                                        </OverlayTrigger>
                                      ) : (
                                        <input
                                          checked={
                                            e.permission === PERMISSIONS.read
                                          }
                                          type="checkbox"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </Offcanvas.Body>
            {!userContext?.hideFooter && (
              <div className={styles.modal_footer_css}>
                <div
                  className={styles.button_main_div}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className={styles.delete_button}
                    disabled={adminContext?.deletePrivilegeLoading}
                    onClick={() => handleDeleteBtn(id, data.privilege.name)}
                  >
                    Delete
                  </button>
                  <div className={styles.buttons}>
                    <button
                      onClick={handleClose}
                      disabled={adminContext?.deletePrivilegeLoading}
                      className={styles.cancel_button}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={adminContext?.deletePrivilegeLoading}
                      className={styles.edit_button}
                      onClick={handleEditBtn}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Offcanvas>
    </>
  );
}

const tooltip = (
  <Tooltip id="tooltip">
    <span style={{ padding: "4px 8px" }}>
      Read access provided to the account is inherited and can’t be changed.{" "}
    </span>
  </Tooltip>
);
