import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

/**
 * @typedef RightSizingPieChartProps
 * @property {number} downsizeCount
 * @property {number} upsizeCount
 * @property {number} terminateCount
 */

/**
 * @param {RightSizingPieChartProps} props
 * @returns {import("react").ReactNode}
 */
const RightSizingPieChart = ({
  downsizeCount,
  upsizeCount,
  terminateCount,
}) => {
  const mydata = [
    { name: "yellow", value: upsizeCount, color: "#F6D662" },
    { name: "Red", value: terminateCount, color: "#D01A1A" },
    { name: "Green", value: downsizeCount, color: "#3DA64F" },
  ];

  return (
    <div className=" container p-0">
      <ResponsiveContainer width="100%" height={123}>
        <PieChart >
          <Pie
            data={mydata}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={50}
            fill=""
            
          >
            {mydata.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RightSizingPieChart;
