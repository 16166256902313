import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {number} total
 * @property {number} current_costs_pm
 * @property {number} downsize_count
 * @property {number} downsize_savings_pm
 * @property {number} upsize_count
 * @property {number} upsize_savings_pm
 * @property {number} projected_costs_pm
 * @property {number} projected_savings_pm
 * @property {number} terminate_count
 * @property {number} terminate_savings_pm
 * @property {number} savings_percent
 * @property {number} projected_savings_ri_pm
 * @property {number} upsize_cost_pm
 * @property {number} terminate_cost_pm
 * @property {number} savings_till_date_pm
 * @property {number} downsize_cost_pm
 */

/**
 * @typedef GetAllServices
 * @property {GetAllStatistics} aws
 * @property {GetAllStatistics} azure
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} statistics
 */

/**
 * @typedef GetAllServicesRes
 * @property {GetAllServices} services
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 */

export const getAllStatistics = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/statistics`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllStatistics", req, ...deps],
    queryFn: ({ signal }) => getAllStatistics(appContext.idToken)(req, signal),
  });
  return query;
};

/**
 * @param {any[]=} deps
 */
export const useAllCloudStatistics = (deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllStatistics", ...deps],
    queryFn: ({ signal }) =>
      getAllStatistics(appContext.idToken)({ service: "all-cloud" }, signal),
  });
  return query;
};
