import React, { useMemo, useContext } from "react";
import IdleResourceHeader from "../components/idle-resource-header.part";
import AllCloudBarGraph from "../components/Cost&usage/all-cloud-bar-graph.part";
import AllCloudIndividualCloudCost from "../components/Cost&usage/all-cloud-individual-cloud-cost.part";
import IdleResourceTablePart from "../components/idle-resouce-table.part";
import { useStatistics } from "@/services/IdleResources/getAllStatics.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import { IdleContext, IdleProvider } from "../components/idle-context-file.part";
import DataLoading from "@/components/States/DataLoading";

const AllCloudIdleResource = () => {
  return (
    <IdleProvider>
      <AllCloudIdleResourceComp />
    </IdleProvider>
  );
};

const AllCloudIdleResourceComp = () => {
  const context = useContext(IdleContext);

  
const query = useMemo(() => {
  const tags = (context?.selectedTags || []).filter(
    (e) => e.filterOn.length > 0 && e.values.length > 0
  );
  return {
    service: "all-cloud",
    untagged: context.showUntaggedOnly,
    filters: {
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      rg: context?.selectedResourceGroup?.length
        ? context.selectedResourceGroup.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    },
  };
}, [context.selectAccounts, context.selectRegions, context.selectedResourceGroup, context?.selectedTags, context.showUntaggedOnly]);

  const { data, isLoading, isError } = useStatistics(query);

  return (
    <div className="container-fluid">
      {isLoading && <AllPageLoading />}
      {isError && <DataLoading />}
      {/* {!data && (<DataAbsense/>)} */}
      {data && (
        <div className="row" style={{marginLeft:"-12px"}}>
          <div className="col-lg-12 p-0">
            <IdleResourceHeader service="all-cloud" showDropdown={false} data={data} />

            <div
              className="container-fluid col-lg-12 "
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: "24px",
                paddingLeft: "40px",
                paddingRight: "20px",
              }}
            >
              <div className="row w-100">
                <div className="col-12 p-0 pe-2 pt-4 d-flex gap-4 flex-column">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      paddingRight: "10px",
                      marginLeft: "0px",
                    }}
                  >
                    <AllCloudBarGraph service="all-cloud" />
                    <AllCloudIndividualCloudCost service="all-cloud" />
                    <IdleResourceTablePart service="all-cloud" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCloudIdleResource;
