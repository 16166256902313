import React, { createContext, useState, useEffect } from "react";
import { useDeleteWidget } from "@/services/costExplorer/deleteWidget.repo";

/** @type {import('react') React} */

/** @typedef {import('@/services/costExplorer/deleteWidget.repo.js').DeleteWidgetReq} IDeleteWidgetReq */

/** @typedef {import("@/services/costExplorer/getAllWidgets.repo").Widget} Widget */

/**
 * @callback DeleteWidgetFunc
 * @param {IDeleteWidgetReq} props
 */

/**
 * @typedef CustomWidgetContextType
 * @property {Widget[]} widgets
 * @property {boolean} isLayoverOpen
 * @property {React.Dispatch<boolean>} setIsLayoverOpen
 * @property {React.Dispatch<Widget>} setWidgets
 * @property {string=} selectedWidget
 * @property {React.Dispatch<string?>} setSelectedWidget
 * @property {string=} deleteWidgetId
 * @property {React.Dispatch<string?>} setDeleteWidgetId
 * @property {string=} updateWidgetId
 * @property {React.Dispatch<string?>} setUpdateWidgetId
 * @property {boolean} selectedWidgetDimension
 * @property {React.Dispatch<boolean?>} setSelectedWidgetDimension
 * @property {DeleteWidgetFunc} deleteWidget
 * @property {import('@tanstack/react-query').UseMutationResult<string, Error, IDeleteWidgetReq>} deleteWidgetMutation
 */

/** @type {import("react").Context<CustomWidgetContextType>} */
export const CustomWidgetContext = createContext();

export const CustomWidgetProvider = ({ children }) => {
  const [isLayoverOpen, setIsLayoverOpen] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState();
  const [deleteWidgetId, setDeleteWidgetId] = useState();
  const [updateWidgetId, setUpdateWidgetId] = useState();
  const [selectedWidgetDimension, setSelectedWidgetDimension] = useState();
  const deleteWidgetMutation = useDeleteWidget();

  useEffect(() => {
    if (!selectedWidget) {
      setUpdateWidgetId(undefined);
    }
  }, [selectedWidget]);

  /** @type {DeleteWidgetFunc} */
  const deleteWidget = (req) => {
    setDeleteWidgetId(req.widgetId);
    deleteWidgetMutation.mutate(req);
  };

  return (
    <CustomWidgetContext.Provider
      value={{
        widgets,
        setWidgets,
        selectedWidget,
        setSelectedWidget,
        selectedWidgetDimension,
        setSelectedWidgetDimension,
        deleteWidgetMutation,
        deleteWidget,
        deleteWidgetId,
        setDeleteWidgetId,
        updateWidgetId,
        setUpdateWidgetId,
        isLayoverOpen,
        setIsLayoverOpen,
      }}
    >
      {children}
    </CustomWidgetContext.Provider>
  );
};
