import React from "react";
import styles from "@/components/States/states.module.css";
import clsx from "clsx";
import NoEntry from "@/assets/img/noEntry.svg";

export default function EmptyTable(props) {
  return (
    <div>
      <div className={clsx(styles.main_container)}>
        <div className={styles.img_Container}>
          <img src={NoEntry} width={"32px"} height={"32px"} alt="" />
        </div>
        <div className={styles.sec_container}>
          <div className={styles.text_css} style={{fontSize:"18px",color:"#212529"}}> {props.name} </div>
          <div className={styles.text_css} style={{fontSize:"16px", color:"#495057"}} >{props.message}</div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
