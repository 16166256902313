/** @type {import('@/global')} */
import React, { useMemo, useContext, useEffect, useState } from "react";
import cpulogo from "@/assets/img/cpulogo.svg";
import disklogo from "@/assets/img/Disklogo.svg";
import memorylogo from "@/assets/img/memorylogo.svg";
import downArrow from "@/assets/img/GreenGroup.svg";
import upArrow from "@/assets/img/RedGroup.svg";
import terminateArrow from "@/assets/img/TerminateGroup.svg";
import "@/assets/css/right-sizing-table.css";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { RightSizingContext } from "../../right-sizing.context";
import RightSizingModal from "./RightSizingModal";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@/components/pagination/pagination.component";
import copyicon from "@/assets/img/copyicon.svg";
import ArchiveModal from "@/page-components/snapshot/components/state_change/archiveModal";
import { useGetHistory } from "@/services/right-sizing/getVmHistory.repo";
import { useUpdateAcceptRecommendation } from "@/services/right-sizing/addAcceptStateField.repo";
import { useDiscardedTable } from "@/services/right-sizing/getDiscardedTable.repo";
import menuicon from "@/assets/img/menu.svg";
import DiscardedIcon from "@/assets/img/discardedIcon.svg";
import { Modal } from "react-bootstrap";
import HistoryModal from "@/page-components/snapshot/components/state_change/historyModal";
import ToastComponent from "@/page-components/budgets/components/toast";
import TableErrorState from "@/components/States/TableErrorState";
import sorting from "@/assets/img/sorting.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

const DiscardedTable = ({ service, name, refreshHeaders, resource }) => {
  const context = useContext(RightSizingContext);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const resourceId = searchParams.get("resourceId");
  const [savingsId, setSavingsId] = useState(null);
  const [enableArchiveModal, setEnableArchiveModal] = useState(false);
  const [resourceid, setResourceId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [enableHistory, setEnableHistory] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [projectedType, setProjectedType] = useState(null);
  const [orderBy, setOrderBy] = useState("region");
  const desc = searchParams.get("desc");

  const handleHistoryModal = (resourceId, projected_type, current_type) => {
    setEnableHistory(Boolean(resourceId));
    setResourceId(resourceId);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };
  const handleCloseforHistory = () => {
    setEnableHistory(undefined);
    setResourceId(undefined);
    setProjectedType(undefined);
    setCurrentType(undefined);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleCopyClick = (event, resourceId) => {
    event.stopPropagation();
    const textarea = document.createElement("textarea");
    textarea.value = resourceId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(resourceId);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const query = useMemo(() => {
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      orderBy: orderBy || "region",
      desc: searchParams.get("desc") || "true",
    };
  }, [orderBy, parsedItemsPerPage, parsedPage, searchParams, service]);

  const {
    data,
    isLoading,
    error,
    isError,
    refetch: refetchActiveTable,
  } = useDiscardedTable(query);

  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    error: acceptDismissError,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: resourceid,
      currentType: currentType,
      projectedType: projectedType,
    };
  }, [service, resourceid, currentType, projectedType]);

  const { data: historyData } = useGetHistory(queryParam);

  const handleArchiveModalClose = () => {
    setEnableArchiveModal(false);
    setResourceId(undefined);
    setCheckedHistory(undefined);
  };

  const handleArchiveModal = (
    resourceId,
    history,
    projected_type,
    current_type
  ) => {
    setEnableArchiveModal(true);
    setResourceId(resourceId);
    setCheckedHistory(history);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };

  useEffect(() => {
    if (updateData) {
      refetchActiveTable();
      refreshHeaders();
    }
  }, [updateData, refetchActiveTable, refreshHeaders]);

  useEffect(() => {
    if (acceptDismissError) {
      setMessage({
        message: acceptDismissError?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissError, setMessage]);
  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      {isLoading && (
        <div className={styles.wrapper}>
          <TableErrorState />
        </div>
      )}
       {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
      {data?.total > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.scroll}>
              <table
                id="cuprojected_typestomers"
                className={clsx(styles.scrollable, "table-hover")}
              >
                <thead>
                  <tr>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      {name}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    {service === "azure" && (
                      <>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Name
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceName")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          {resource}
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceId")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Group
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() =>
                              handleOrderByClick("resourceGroupName")
                            }
                          ></img>
                        </th>
                      </>
                    )}
                    {service === "aws" && (
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        {resource}
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("account")}
                        ></img>
                      </th>
                    )}
                    <th className={styles.tableHead} style={{ width: "11%" }}>
                      Region
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "14%" }}>
                      Utilization
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "15%" }}>
                      Owner
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "7%" }}>
                      Current Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("currentType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      Recommended Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("recommendedType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      Cost
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("cost")}
                      ></img>
                    </th>
                    <th
                      className={styles.tableHead}
                      style={{ width: "10%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={9}>
                        <div
                          style={{
                            width: "100%",
                            marginBottom: 40,
                            marginTop: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                            style={{ top: "50" }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}

                  {!isLoading &&
                    !error &&
                    (data?.recommendation || []).length === 0 && (
                      <tr>
                        <td colSpan={11}>
                          <p className="text-center my-3">
                            No records to display
                          </p>
                        </td>
                      </tr>
                    )}

                  {!isLoading && error && (
                    <tr>
                      <td colSpan={9}>
                        <p className="text-center my-3">{error.message}</p>
                      </td>
                    </tr>
                  )}

                  {(data?.recommendation || []).map((item, i) => (
                    <tr
                      id={styles.table_body_tr}
                      className={clsx(styles.accountCol, {
                        [styles.hovered]: i === hoveredRowIndex,
                      })}
                      key={item.resourceId + "-" + i}
                      onClick={() => {
                        searchParams.set("resourceId", item.resourceId);
                        setSearchParams(searchParams);
                      }}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData,
                          styles.my_white_space
                        )}
                      >
                        <span title={item.account}>{item.account}</span>
                      </td>
                      {service === "azure" && (
                        <>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resource_name}>
                              {item?.resource_name || "NULL"}
                            </span>
                          </td>

                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={(event) =>
                                handleCopyClick(event, item?.resourceId)
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resourceId}>
                              {item?.resourceGroupName || "Null"}
                            </span>
                          </td>
                        </>
                      )}
                      {service === "aws" && (
                        <>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={(event) =>
                                handleCopyClick(event, item?.resourceId)
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                        </>
                      )}
                      {/* <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData
                        )}
                      >
                        <span title={item.resourceId}>
                          {item?.resourceGroupName || "Null"}
                        </span>
                      </td> */}
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item?.region || "Null"}
                      </td>
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        <div className={styles.percentageBadgeWrapper}>
                          <PercentBadge
                            type="cpu"
                            value={item.utilization_cpu}
                          />
                          <PercentBadge
                            type="mem"
                            value={item.utilization_mem}
                          />
                        </div>
                      </td>

                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item.owner || "--"}
                      </td>

                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item.current_type || "--"}
                      </td>

                      <td className={styles.accountCol}>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          {item.recommendation === "upsize" && (
                            <img
                              src={upArrow}
                              alt=""
                              className={styles.upArrow}
                            />
                          )}
                          {item.recommendation === "downsize" && (
                            <img
                              src={downArrow}
                              alt=""
                              className={styles.downArrow}
                            />
                          )}
                          {item.recommendation === "terminate" && (
                            <img src={terminateArrow} alt="" />
                          )}
                          <span className={styles.accountCol1}>
                            {item.projected_type || "--"}
                          </span>
                        </span>
                      </td>
                      <td className={styles.accountCol}>
                        ${item.cost?.toFixed(2) || "--"}
                      </td>
                      {/* <td style={{ width: "100px" }}></td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.fixed}>
                <table
                  id="customers"
                  style={{ width: "100%" }}
                  className="table-hover"
                >
                  <thead>
                    <tr>
                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                      >
                        Savings
                      </th>
                      {/* <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_end
                        )}
                      >
                        RI Savings
                      </th> */}
                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                        style={{ minWidth: "155px", textAlign: "right" }}
                      >
                        Status
                      </th>
                      <th className={styles.tableHead}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan={3}></td>
                      </tr>
                    )}

                    {!isLoading &&
                      (data?.recommendation || []).length === 0 && (
                        <tr>
                          <td colSpan={3}> </td>
                        </tr>
                      )}

                    {!isLoading &&
                      (data?.recommendation || []).map((item, i) => (
                        <tr
                          style={{ position: "relative" }}
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={item.resourceId + "-" + i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            style={{ textAlign: "center" }}
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,
                              styles.accountCol_center,
                              Number(item?.savings || 0) < 0 &&
                                styles.accountCol_red,
                              Number(item?.savings || 0) > 0 &&
                                styles.accountCol_green
                            )}
                            // onClick={() =>
                            //   showRecommendationModal(item.resourceId)
                            // }
                          >
                            ${(item.savings || 0).toFixed(2)}
                          </td>
                          {/* <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,
                              styles.accountCol_end,
                              Number(item?.riSavings || 0) < 0 &&
                                styles.accountCol_red,
                              Number(item?.riSavings || 0) >= 0 &&
                                styles.accountCol_green
                            )}
                            onClick={() => {}}
                          >
                            ${(item.riSavings || 0).toFixed(2)}
                          </td> */}

                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight,
                              styles.snapshot_col_end
                            )}
                            style={{ minWidth: "155px", textAlign: "right" }}
                          >
                            <span className={styles.discarded_button}>
                              <img src={DiscardedIcon} alt=""></img> Discarded
                            </span>
                          </td>
                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight
                            )}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                id={styles.dropdown_basic_check}
                                className={styles.custom_dropdown_toggle}
                                style={{
                                  border: "none",
                                  textAlign: "start",
                                  paddingTop: "0px",
                                  paddingBottom: "4px",
                                }}
                              >
                                <img src={menuicon} alt=""></img>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className={styles.dropdown_menu}>
                                <Dropdown.Item
                                  className={styles.dropdown_item}
                                  onClick={() =>
                                    handleHistoryModal(
                                      item?.resourceId,
                                      item?.projected_type,
                                      item?.current_type
                                    )
                                  }
                                >
                                  <span className={styles.dropdown_names_css}>
                                    View History
                                  </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={styles.dropdown_item}
                                  onClick={() => {
                                    acceptMutate({
                                      resourceId: item?.resourceId,
                                      status: "open",
                                      service: service,
                                      projected_type: item?.projected_type,
                                      current_type: item?.current_type,
                                    });
                                  }}
                                >
                                  <span className={styles.dropdown_names_css}>
                                    Move to Open
                                  </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={styles.dropdown_item}
                                  onClick={() =>
                                    handleArchiveModal(
                                      item?.resourceId,
                                      item?.history_count,
                                      item?.projected_type,
                                      item?.current_type
                                    )
                                  }
                                >
                                  <span className={styles.dropdown_names_css}>
                                    Archive
                                  </span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <Pagination totalItems={data?.total || 0} />
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </div>
      )}

      <Offcanvas
        placement="end"
        show={resourceId}
        onHide={() => {
          searchParams.delete("resourceId");
          setSearchParams(searchParams);
        }}
        style={{
          width: 884,
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            width: 884,
          }}
        >
          <RightSizingModal resourceId={resourceId} service={service} />
        </Offcanvas.Body>
      </Offcanvas>

      {Boolean(resourceid) && (
        <Modal
          show={enableHistory}
          onHide={handleCloseforHistory}
          contentClassName={styles.historyModal}
          centered
        >
          <HistoryModal
            historyData={historyData}
            enableHistory={enableHistory}
          />
        </Modal>
      )}

      <ArchiveModal
        enable={enableArchiveModal}
        // resourceId={resourceid}
        // service={service}
        disable={handleArchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={resourceid}
        historyData={historyData}
        checkHistory={checkedHistory}
        name="VM"
        onSubmit={(data) => {
          acceptMutate({
            current_type: currentType,
            projected_type: projectedType,
            resourceId: resourceid,
            service,
            status: "accepted",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

/**
 * @typedef PercentBadgeProps
 * @property {number} value
 * @property {'cpu' | 'mem' | 'disc'} type
 */

/**
 * @param {PercentBadgeProps} props
 * @returns {import("react").ReactNode}
 */
const PercentBadge = ({ value, type }) => {
  const img = useMemo(() => {
    switch (type) {
      case "cpu":
        return cpulogo;
      case "mem":
        return memorylogo;
      case "disc":
        return disklogo;
      default:
        return null;
    }
  }, [type]);

  return (
    <span className={styles.percentageBadgeCont}>
      {img && (
        <img
          src={img}
          width="16px"
          height="16px"
          style={{ marginRight: "6px" }}
          alt=""
        />
      )}

      <span
        className={clsx(styles.badge, {
          [styles.badgeOk]: value < 50 && value >= 0,
          [styles.badgeWarning]: value >= 50 && value < 80,
          [styles.badgeDanger]: value >= 80,
          [styles.badgeNA]: value === undefined || value === null,
        })}
      >
        {(value === undefined || value === null) && "--"}
        {value !== undefined &&
          value !== null &&
          Number(value).toFixed(1) + "%"}
      </span>
    </span>
  );
};

export default DiscardedTable;
