import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

// {
//   "idle_resource_total": {
//       "idle_resource_count": 2,
//       "unblended_cost": 2.4129033416393213
//   },
//   "idle_resources": {
//       "disk": {
//           "resource_type": "ebs",
//           "count": 1,
//           "cost": 2.4129033416393213
//       },
//       "ip": {
//           "resource_type": "elastic-ip",
//           "count": 1,
//           "cost": null
//       }
//   }
// }

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} idle_resource_total
 * @property {GetIdleStatistics} idle_resources
 */
/**
 * @typedef GetTotalStatistics
 * @property {number} idle_resource_count  - Email address
 * @property {number} unblended_cost - User id
 */

/**
 * @typedef GetIdleStatistics
 * @property {GetDiskStatistics=} disk
 * @property {GetVmStatistics=} vm
 * @property {GetIpStatistics=} ip
 * @property {GetLoadStatistics=} lb
 */

/**
 * @typedef GetDiskStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetVmStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */
/**
 * @typedef GetLoadStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIpStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */



/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 * @property {GetIdleFilters} filters
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getStatsArchievedStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === 'azure') {
      body.rg = filters?.rg || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/total-stats-archived`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatsArchievedStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatsArchievedStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getStatsArchievedStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
