import React, { useContext, useMemo } from "react";
import ForecastedBarGraph from "./forecast-bar-graph";
import ForecastCostTable from "./forecast-table.part";
import ForecastFilterSection from "./forecast-filter.part";
import { FilterContext } from "../filter-section/filter-section.component";
import { useForecastedBarGraph } from "@/services/costExplorer/getForecastBarGraph.repo";


const ForecastPage = ({ service, accountName }) => {
  const filterContext = useContext(FilterContext);

  const query = useMemo(() => {
    return {
      service: service,
      accounts: filterContext.selectForecastAccount.map((e) => e.id),
    };
  }, [filterContext.selectForecastAccount, service]);

  const { data, isLoading, error } = useForecastedBarGraph(query);

  const accounts = filterContext.selectForecastAccount || [];

  return (
    <div className="d-flex flex-row">
      <div
        className="d-flex flex-column py-4 content-wrapper-left"
        style={{
          width: "82%",
          gap: "24px",
        }}
      >
        <>
          <ForecastedBarGraph
            accounts={accounts}
            data={data?.data}
            isLoading={isLoading}
            error={error}
            service={service}
          />
          <ForecastCostTable
            accounts={accounts}
            data={data?.data}
            isLoading={isLoading}
            error={error}
            accountName={accountName}
          />
        </>
      </div>
      <div style={{ width: "17%" }}>
        <ForecastFilterSection name={accountName} service={service}  />
      </div>
    </div>
  );
};

export default ForecastPage;
