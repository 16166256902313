import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "@/page-components/admin/admin.module.css";
import { FilterSearch } from "@/components/filter-search/filter-search";
import { FilterSearchToggle } from "@/components/filter-search/filter-search";
import { useForm, Controller, useWatch } from "react-hook-form";
import success from "@/assets/img/success_image.svg";
import failure from "@/assets/img/f-remove.svg";
import {
  useGetUserEmails,
  useCreateUser,
  useUserServiceSearch,
} from "@/services/admin/Roles/createUser.repo";
import { joiResolver } from "@hookform/resolvers/joi";
import { CreateUserReqValidator } from "@/services/admin/Roles/createUser.repo";
import { useDebouncedValue } from "rooks";
import { DeleteMessageModal } from "../components/delete.modal.part";
import { useEditUserInfo } from "@/services/admin/Roles/editUser.repo";
import clsx from "clsx";
import { useDeleteUser } from "@/services/admin/Roles/deleteUser.repo";
import { RolesContext } from "./roles.context";

export default function AddUserModal(props) {
  const adminContext = useContext(RolesContext);

  const [checkboxON, setcheckboxON] = useState(false);
  const [checkboxOFF, setcheckboxOFF] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [filterQueryForEmails, setFilterQueryForEmails] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGroupInputForAdmin, setShowGroupInputForAdmin] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
    props.onHide();
  };
  /** @type {import('react-hook-form').UseFormReturn<CreateUserReqValidator>} */
  const formData = useForm({
    resolver: joiResolver(CreateUserReqValidator),
    defaultValues: {
      addAsAdmin: false,
    },
  });
  const addAsAdmin = useWatch({
    control: formData.control,
    name: "addAsAdmin",
  });

  useEffect(() => {
    if (addAsAdmin) {
      formData?.setValue("enable_create_budget_privilage", true);
    }
  }, [addAsAdmin, formData]);

  const {
    data: editUser,
    mutate: modifiedUser,
    reset: resetEdit,
    error: editError,
    isPending: editLoading,
  } = useEditUserInfo();

  useEffect(() => {
    if (editUser) {
      if (props?.refetchUserTable) props?.refetchUserTable();
      if (props?.refetchAdminTable) props.refetchAdminTable();
      setShowSuccessModal(true);
      setShowSuccessMessage(
        addAsAdmin ? "Admin Edited Successfully" : "User Edited Successfully"
      );

      resetEdit();
      props.onHide();
    }
  }, [props, editUser, resetEdit, addAsAdmin]);

  const {
    mutate: deleteUser,
    isLoading: deletingUser,
    data: userDeleteData,
    reset: deleteReset,
  } = useDeleteUser();

  const DeleteTitle = props?.editData?.user?.name;

  const {
    mutate: createUser,
    data: create,
    reset,
    error: createError,
    isPending: addingUser,
  } = useCreateUser();

  useEffect(() => {
    if (createError) {
      setShowFailureModal(true);
      if (addAsAdmin) {
        setFailureMessage("Admin Creation Failed");
      } else {
        setFailureMessage("User Creation Failed");
      }

      props.onHide();
      reset();
      formData.reset();
    }
  }, [createError, formData, reset, props, addAsAdmin]);

  useEffect(() => {
    if (create) {
      setShowSuccessModal(true);
      setShowSuccessMessage(
        addAsAdmin ? "Admin Added Successfully" : "User Added Successfully"
      );
      props.onHide();

      formData.reset();
      reset();
      props.refetch();
    }
  }, [create, formData, props, reset, addAsAdmin]);

  useEffect(() => {
    if (editError) {
      setShowFailureModal(true);
      setFailureMessage(
        addAsAdmin ? "Admin update Failed" : "User update Failed"
      );

      props.onHide();
      formData.reset();
      reset();
    }
  }, [editError, reset, formData, props, addAsAdmin]);

  const [debouncedFilterQuery] = useDebouncedValue(filterQueryForEmails, 500);

  const emailList = useGetUserEmails(
    {
      filterQuery: debouncedFilterQuery,
    },
    [adminContext.createUserModal]
  );

  const groupUserList = useUserServiceSearch("groups");
  const enableCreateBudgetPrivilage =
    props?.editData?.user?.enable_create_budget_privilage;
  const valueToBool =
    enableCreateBudgetPrivilage === 1 || enableCreateBudgetPrivilage === true;


  useEffect(() => {
    if (props?.editData) {
      formData.setValue("description", props?.editData?.user?.description);
      formData.setValue("email", props?.editData?.user?.email);
      formData.setValue("addAsAdmin", props?.editData?.user?.roles === "Admin");
      formData.setValue("enable_create_budget_privilage", valueToBool);
      formData.setValue("name", props?.editData?.user?.name);
      formData.setValue(
        "groupIds",
        (props?.editData?.groups || []).map((e) => {
          return {
            name: e.name,
            id: e.id,
            description: e.description,
          };
        })
      );
    }
  }, [props?.editData, formData, valueToBool]);

  const handleFailureModalClose = () => {
    setShowFailureModal(false);
    setTimeout(() => {
      setcheckboxON(false);
      setcheckboxOFF(true);
    }, 1000);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    setTimeout(() => {
      setcheckboxON(false);
      setcheckboxOFF(true);
    }, 1000);
  };

  useEffect(() => {
    if (editUser) {
      if (props?.refetchUserTable) props?.refetchUserTable();
      if (props?.refetchAdminTable) props.refetchAdminTable();
      resetEdit();
      props.onHide();
    }
  }, [props, editUser, resetEdit]);

  const onSubmit = async (data) => {
    if (data?.addAsAdmin) {
      data.enable_create_budget_privilage = true;
      delete data?.groupIds;
    } else if (!data?.addAsAdmin && !data?.groupIds?.length) {
      formData.setError("groupIds", "This field is req");
      // setIsAdminAdded(false);
      return false;
    }
    const groupIds = (data?.groupIds || []).map((i) => i.id);

    if (props?.UserID) {
      delete data?.email;
      delete data?.name;
      modifiedUser({
        body: { ...data, groupIds },
        id: props?.editData?.user?.id,
      });
    } else {
      createUser({ body: { ...data, groupIds } });
    }
  };

  useEffect(() => {
    if (props.deleteGroupData) {
      reset();
      if (props?.refetchUserTable) props?.refetchUserTable();
      if (props?.refetchAdminTable) props.refetchAdminTable();
    }
  }, [props, props.deleteGroupData, reset]);

  return (
    <>
      <Modal
        {...props}
        centered
        onHide={() => {
          if (!props.UserID) {
            formData.reset();
          }
          props.onHide();
        }}
        size="lg"
      >
        <form onSubmit={formData.handleSubmit(onSubmit)}>
          <Modal.Header
            // closeButton
            style={{ border: "none", padding: "24px 24px 0px 24px" }}
            className={styles.modalHeader}
          >
            <Modal.Title
              id="contained-modal-title-vcenter"
              className={styles.modalTitle}
            >
              <span className={styles.mainTitle}>
                {props.UserID
                  ? `Edit ${addAsAdmin ? "Admin" : "User "}`
                  : `Add ${addAsAdmin ? "Admin" : "User "}`}
              </span>
              <span className={styles.subTitle}>
                Add details to {props.UserID ? "edit" : "add"}{" "}
                {addAsAdmin ? "admin" : "user "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "24px 24px 0px 24px" }}>
            <div className={styles.modalContainer}>
              <div className={styles.modalBody}>
                <span
                  className={styles.subTitle}
                  style={{ lineHeight: "20px" }}
                >
                  Email Address
                </span>

                {Boolean(props.UserID) && (
                  <input
                    disabled
                    className="filter_search_trigger "
                    {...formData.register("email")}
                  />
                )}

                {!props.UserID && (
                  <Controller
                    control={formData.control}
                    name="email"
                    render={({ field }) => (
                      <>
                        <FilterSearch
                          disabled={props.UserID}
                          multiselect={false}
                          loading={emailList?.isLoading}
                          options={(emailList?.data?.emails || []).map((e) => ({
                            email: e.email || "null",
                            name: e.name || "null",
                            id: e.email || "null",
                          }))}
                          itemRenderer={(item, index) => {
                            return (
                              <div className={clsx(styles.filter)}>
                                <div className={styles.myemailstyling}>
                                  <span>{item.name}</span>{" "}
                                  <span className={styles.emailOnly}>
                                    {item.id}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                          onSearchChange={(search) => {
                            setFilterQueryForEmails(search);
                          }}
                          displayValue="email"
                          selectedValues={field.value ? [field.value] : []}
                          onSelect={(v) => {
                            formData.setValue("name", v.name);
                            formData.setValue("email", v.email);
                          }}
                          toggle={
                            <FilterSearchToggle placeholder="Select Email" />
                          }
                        />
                      </>
                    )}
                  />
                )}

                {formData.formState?.errors?.email && (
                  <p className={styles.errorMessage}>
                    {formData.formState.errors.email.message}
                  </p>
                )}
              </div>

              <div className={styles.add_as_admin}>
                <Controller
                  control={formData.control}
                  name="addAsAdmin"
                  render={({ field }) => (
                    <input
                      onSelect={field.onChange}
                      type="checkbox"
                      className={styles.checkbox}
                      style={{ opacity: field.value ? 1 : 0.5 }}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      checked={field.value}
                    />
                  )}
                />
                <span className={styles.addAdminText}>Add as Admin</span>
              </div>
              <div className={styles.add_as_admin}>
                <Controller
                  control={formData.control}
                  name="enable_create_budget_privilage"
                  render={({ field }) => (
                    <input
                      onSelect={field.onChange}
                      type="checkbox"
                      className={styles.checkbox}
                      style={{ opacity: field.value ? 1 : 0.5 }}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      checked={field.value}
                    />
                  )}
                />
                <span className={styles.addAdminText}>Create Budgets</span>
              </div>
              {!addAsAdmin && (
                <div className={styles.modalBody}>
                  <span
                    className={styles.subTitle}
                    style={{ lineHeight: "20px" }}
                  >
                    Groups
                  </span>

                  <Controller
                    control={formData.control}
                    name="groupIds"
                    render={({ field }) => (
                      <FilterSearch
                        multiselect
                        loading={groupUserList?.isLoading}
                        options={(groupUserList?.data?.groups || []).map(
                          (e) => ({
                            name: e.name || "null",
                            description: e.description || "null",
                            id: e.id || "null",
                          })
                        )}
                        itemRenderer={(item, index) => {
                          return (
                            <div className={styles.filter}>
                              <span>{item.name} </span>
                              <span className={styles.infoStyle}>
                                {item.description}
                              </span>
                            </div>
                          );
                        }}
                        displayValue="name"
                        selectedValues={field.value || []}
                        onSelect={(e) => {
                          field.onChange(e);
                        }}
                        onRemove={(e) => {
                          field.onChange(e);
                        }}
                        toggle={
                          <FilterSearchToggle
                            maxItems={2}
                            placeholder="Select Group"
                            type="admin"
                          />
                        }
                      />
                    )}
                  />

                  {formData?.formState?.errors?.groupIds && (
                    <p className={styles.errorMessage}>
                      {formData.formState.errors.groupIds.message}
                    </p>
                  )}
                </div>
              )}

              {addAsAdmin && (
                <span className={styles.my_note}>
                  Note: By selecting 'Admin' role, user will have access to all
                  system functionalities
                </span>
              )}

              <div className={styles.modalBody}>
                <span
                  className={styles.subTitle}
                  style={{ lineHeight: "20px" }}
                >
                  User Description
                </span>
                <textarea
                  placeholder="Add Description"
                  className="filter_search_trigger"
                  rows={4}
                  cols={50}
                  style={{ resize: "none", height: "72px", color: "#212529" }}
                  {...formData.register("description")}
                />
                {formData.formState?.errors?.description && (
                  <p className={styles.errorMessage}>
                    {formData.formState.errors.description.message}
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <div className={styles.footer_add}>
              {props.UserID ? (
                <>
                  <div className={styles.modal_user_footar}>
                    <div className={styles.button_main_div}>
                      <button
                        type="button"
                        className={styles.delete_button}
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </button>
                    </div>
                    <div className={styles.buttons}>
                      <button
                        type="button"
                        onClick={() => {
                          props.onHide();
                        }}
                        className={styles.cancel_button}
                      >
                        Cancel{" "}
                      </button>
                      <button type="submit" className={styles.edit_button}>
                        {editLoading ? "Loading..." : "Edit"}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => {
                      formData.reset();
                      props.onHide();
                    }}
                    variant="outline-primary"
                    className={styles.cancelButton}
                    style={{ width: "20%" }}
                  >
                    Cancel
                  </Button>
                  <Button className={styles.addButton} type="submit">
                    {addingUser ? "Loading..." : "Add"}
                  </Button>
                </>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={success} alt="" />
            </span>

            {checkboxON && (
              <span className={styles.success_message}>
                Admin Added Successfully
              </span>
            )}

            {checkboxOFF && (
              <span className={styles.success_message}>
                User Added Successfully
              </span>
            )}
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_button}
              onClick={handleSuccessModalClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*Failure Modal*/}
      <Modal
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={failure} alt="" />
            </span>

            {checkboxON && (
              <span className={styles.success_message}>
                Admin Creation Failed
              </span>
            )}

            {checkboxOFF && (
              <span className={styles.success_message}>
                User Creation Failed
              </span>
            )}
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_button}
              onClick={handleFailureModalClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <DeleteMessageModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        HideParentModal={props.onHide}
        type={addAsAdmin ? "Admin" : "User"}
        title={DeleteTitle}
        deleteAction={props.deleteGroup}
        deleteData={props.deleteGroupData}
      />
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={success} alt="" />
            </span>

            <span className={styles.success_message}>{showSuccessMessage}</span>
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_buttom}
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={failure} alt="" />
            </span>
            <span className={styles.success_message}>{failureMessage}</span>
          </div>
          <div className={styles.close_button}>
            <Button
              className={styles.close_button}
              onClick={handleFailureModalClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
