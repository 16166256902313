import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import TableErrorState from "./TableErrorState";
import BarGraphErrorStates from "./BarGraphErrorStates";

const AllPageLoading = () => {
  const data = [
    { name: "Category 1", value1: 20 },
    { name: "Category 2", value1: 40 },
    { name: "Category 3", value1: 15 },
    { name: "Category 4", value1: 20 },
    { name: "Category 5", value1: 40 },
    { name: "Category 6", value1: 15 },
    { name: "Category 7", value1: 20 },
    { name: "Category 8", value1: 40 },
    { name: "Category 9", value1: 15 },
    { name: "Category 10", value1: 20 },
    { name: "Category 11", value1: 40 },
    { name: "Category 12", value1: 15 },
    { name: "Category 13", value1: 20 },
    { name: "Category 14", value1: 40 },
    { name: "Category 15", value1: 15 },
    { name: "Category 16", value1: 20 },
    { name: "Category 17", value1: 40 },
    { name: "Category 18", value1: 15 },
    { name: "Category 19", value1: 20 },
    { name: "Category 20", value1: 40 },
    { name: "Category 21", value1: 15 },
  ];

  return (
    <div className=" d-flex flex-column gap-5" style={{margin:"12px 40px"}}>
      <Placeholder animation="glow">
        <div className=" d-flex flex-column gap-2">
          <Row>
            <Col xs={11}>
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "25px", marginLeft:"-10px" }}
              />
            </Col>
            <Col xs={1}>
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "25px", width: "100%" }}
              />
            </Col>
          </Row>
          <Row className="d-flex justified-content-start gap-0 ">
            <Col xs={12} style={{padding:"0px"}}>
            <Placeholder
            className={styles.placeholder_css}
            xs={12}
            style={{ height: "175px" }}
          />
            </Col>
            {/* <Col xs={3} style={{padding:"0px"}}>
            <Placeholder
            
            xs={12}
            style={{ height: "175px", background:"#F4F6F8" }}
          />
            </Col>
            <Col xs={2} style={{padding:"0px"}}>
            <Placeholder
           
            xs={12}
            style={{ height: "175px" ,background:"#F4F6F8"}}
          />
            </Col> */}

          </Row>

         

          <Row
            style={{
              borderBottom: "1px solid var(--Neutral-D8, #E9ECEF)",
              paddingBottom: "10px",
            }}
          >
            <Col xs={11}/>
            <Col>
              <Placeholder
                style={{ width: "100%", height: 26 }}
                className={styles.placeholder_css}
              />
            </Col>
          </Row>
        </div>
        <div style={{display:"flex", justifyContent:"start",gap:"16px"}}>
        <div className=" d-flex flex-column gap-2" style={{ marginTop: 12, width:"100%", borderRight:"1px solid var(--Neutral-D8, #E9ECEF)" }}>
         <BarGraphErrorStates/>
          {/* ---------------------------------table--------------------------------------------- */}
          <div style={{marginRight:"32px", marginTop:"12px"}}>
            {/* ToDO instead  of borderbottom remove the border from the table lib */}
          
          <TableErrorState/>
          </div>
         
        </div>
        {/* -------------------------------------filter selection--------------------------------- */}
        <div style={{display:"flex",flexDirection:"column", gap:"18px", marginTop:"12px"}}>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>

        </div>
        </div>
        
      </Placeholder>
    </div>
  );
};

export default  AllPageLoading;
  


