import React, { useState } from "react";
import style from "../../rightSizing.module.css";
// import notification from "@/assets/img/notification-cross.svg";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import upSize from "@/assets/img/upSizeIcon.svg";
import downSizing from "@/assets/img/successCircle.svg";
import clsx from "clsx";
import { useRecommendationById } from "@/services/right-sizing/getRecommendationById.repo";
import { useSearchParams } from "react-router-dom";
import showless from "@/assets/img/showLess.svg";
import showmore from "@/assets/img/showMore.svg";

const RightSizingModal = ({ resourceId, service }) => {
  const sourceData = useRecommendationById({ resourceId, service });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showRiPricingTable, setShowRiPricingTable] = useState(false);

  const handleClose = () => {
    searchParams.delete("resourceId");
    setSearchParams(searchParams);
  };

  const toggleRiPricingTable = () => {
    setShowRiPricingTable(!showRiPricingTable);
  };

  return (
    <>
      {sourceData?.isError && (
        <div className={style.loading}>
          <p>{Error}</p>
        </div>
      )}
      {(sourceData?.isLoading || !resourceId) && (
        <div className={style.loading}>
          <p>Loading...</p>
        </div>
      )}
      {resourceId && !sourceData?.isLoading && !sourceData?.isError && (
        <>
          <div className={style.recommendationHeader}>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              VM Recommendation
              <img
                style={{ marginLeft: "8px" }}
                src={infoIcon}
                width={16}
                height={16}
                alt="infoIcon"
              />
            </span>
            <img
              src={crossModal}
              alt="Close Notification"
              onClick={handleClose}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div
              className={clsx("container", style.batch, {
                // [style.dangerBatch]:
                //   sourceData?.data?.recommendation?.recommendation?.toLowerCase() === "upsize",
              })}
            >
              <span
                className={clsx(style.batch1, {
                  [style.dangerBatch]:
                    sourceData?.data?.recommendation?.recommendation?.toLowerCase() ===
                    "upsize",
                })}
              >
                {sourceData?.data?.recommendation?.recommendation?.toLowerCase() ===
                  "upsize" && (
                  <img src={upSize} width={14} height={14} alt="successIcon" />
                )}
                {sourceData?.data?.recommendation?.recommendation?.toLowerCase() !==
                  "upsize" && (
                  <img
                    src={downSizing}
                    width={14}
                    height={14}
                    alt="successIcon"
                  />
                )}
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {sourceData?.data?.recommendation?.recommendation?.toLowerCase() ===
                    "upsize" && "Upsizing VM"}
                  {sourceData?.data?.recommendation?.recommendation?.toLowerCase() ===
                    "downsize" && "Downsizing VM"}
                  {sourceData?.data?.recommendation === null && "Sizing"}

                  {/* {!sourceData?.data?.recommendation?.recommendation || "sizing"} */}
                </span>
              </span>
            </div>

            <div className={style.recommendationText}>
              <p>
                Automated rightsizing recommendations optimize resource
                allocation, ensuring optimal performance and cost-efficiency in
                cloud environments.
              </p>
              {/* <span className={style.extension}>Learn more</span> */}
            </div>
          </div>

          {sourceData?.data?.recommendation && (
            <RecommendationTable data={sourceData?.data} />
          )}


          
          {/*This Know more section has to be removed */}

          {/* {Object.keys(sourceData?.data?.riPricings || {}).length > 0 && (
            <div className={clsx(style.riContainer, "mt-3")}>
              <div className={style.riDropDown}>
                <b className={style.savemore_text}>Save more</b>
                <span
                  className={style.savemore_text}
                  style={{ fontWeight: "400" }}
                >
                  with Reserved Instances.
                </span>

                <span
                  className={style.showless_more}
                  onClick={toggleRiPricingTable}
                >
                  {showRiPricingTable ? "Show less" : "Know more"}
                  {showRiPricingTable && <img src={showless} alt="" />}
                  {!showRiPricingTable && <img src={showmore} alt="" />}
                </span>
              </div>

              {showRiPricingTable && (
                <div className={style.riInstance}>
                  <p className={style.riText}>Reserved Instance Options</p>
                  <RiPricingTable
                    data={sourceData?.data?.riPricings}
                    service={service}
                  />
                  {Object.keys(sourceData?.data?.riPricings).length > 0 && (
                    <p className={style.riFooter}>
                      *Region:
                      <b>
                        {" "}
                        {
                          sourceData?.data?.riPricings[
                            Object.keys(sourceData?.data?.riPricings)[0]
                          ].region
                        }
                      </b>
                      , Operating System:
                      <b>
                        {
                          sourceData?.data?.riPricings[
                            Object.keys(sourceData?.data?.riPricings)[0]
                          ].operating_system
                        }
                      </b>
                    </p>
                  )}
                </div>
              )}
            </div>
          )} */}
        </>
      )}
    </>
  );
};

const RecommendationTable = ({ data }) => {
  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (count >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (count >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }
  const current_price = (data?.recommendation?.current_price || "--").toFixed(
    2
  );
  const currentPrice = formatCount(current_price);
  const projected_price = (
    data?.recommendation?.projected_price || "--"
  ).toFixed(2);
  const projectedPrice = formatCount(projected_price);
  const Savings =
    data?.recommendation?.Savings?.toFixed(2) ||
    data?.recommendation?.savings?.toFixed(2) ||
    "--";
  const SavingsP = formatCount(Savings);

  const dynamicBackgroundColor = clsx(style.backgroundDownsize, {
    [style.backgroundUpsize]:
      data.recommendation.recommendation?.toLowerCase() === "upsize",
  });

  const dynamicExpenditureColor = clsx({
    [style.backgroundUpsizeHighlight]:
      data?.recommendation?.recommendation?.toLowerCase() === "upsize",
    [style.backgroundDownsizeHighlight]:
      data?.recommendation?.recommendation?.toLowerCase() !== "upsize",
  });
  return (
    <table className={style.recommendationTable}>
      <thead>
        <tr>
          <th className={style.headerText}>SPECS</th>
          <th>
            <div className={style.tableHeader}>
              CURRENT RESOURCES
              <span className={style.tittle}>
                {data?.recommendation?.resource_name}
              </span>
            </div>
          </th>
          <th>
            <div className={`${style.tableHeadRec} ${dynamicBackgroundColor}`}>
              VM RECOMMENDATION
              <span className={style.tittle} style={{ fontWeight: 700 }}>
                {data?.recommendation?.resource_name}
              </span>{" "}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={style.recInfoSpec}>Type</td>
          <td className={style.recSpec}>
            {data.recommendation.current_type || "--"}
          </td>
          <td
            className={clsx(style.recProjectedValues, dynamicBackgroundColor)}
          >
            {data?.recommendation?.recommended_type || "--"}
          </td>
        </tr>
        <tr>
          <td className={style.recInfoSpec}>VCPU</td>
          <td className={style.recSpec}>
            {data?.recommendation?.current_cpu || "--"}
          </td>
          <td
            style={{ fontSize: "13px", fontWeight: "700" }}
            className={`${style.recProjectedValues} ${dynamicBackgroundColor} `}
          >
            {data?.recommendation?.rec_cpu || "--"}
          </td>
        </tr>
        <tr>
          <td className={style.recInfoSpec}>Memory</td>
          <td className={style.recSpec}>
            {data?.recommendation?.current_mem || "--"} GB
          </td>
          <td
            style={{ fontSize: "13px", fontWeight: "700" }}
            className={`${style.recProjectedValues} ${dynamicBackgroundColor} `}
          >
            {data?.recommendation?.rec_mem || "--"} GB
          </td>
        </tr>
        {data?.recommendation?.current_netw && (
          <tr>
            <td className={style.recInfoSpec}>Network</td>
            <td className={style.recSpec}>
              {data?.recommendation?.current_netw || "--"} Mbps
            </td>
            <td
              style={{ fontSize: "13px", fontWeight: "700" }}
              className={`${style.recProjectedValues} ${dynamicBackgroundColor} `}
            >
              {data?.recommendation?.rec_netw || "--"} Mbps
            </td>
          </tr>
        )}
        <tr>
          <td className={style.recInfoSpec}>Costs</td>
          <td className={style.recSpec}>
            {currentPrice >= 0 && `$${currentPrice}`}
            {currentPrice < 0 && `-$${Math.abs(currentPrice)}`}

            {/* $ {(data?.recommendation?.current_price || "--").toFixed(2) } */}
          </td>
          <td
            style={{ fontSize: "13px", fontWeight: "700" }}
            className={`${style.recProjectedValues} ${dynamicBackgroundColor} `}
          >
            {projectedPrice >= 0 && `$${projectedPrice}`}
            {projectedPrice < 0 && `-$${Math.abs(projectedPrice)}`}
            {/* $ {(data?.recommendation?.projected_price || "--").toFixed(2)} */}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <div className={`${style.saving} ${dynamicExpenditureColor}`}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "700px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <span className={clsx(style.up_down_header_text)}>
                  {data?.recommendation?.recommendation?.toLowerCase() ===
                    "upsize" && "Costing on Upsizing"}
                  {data?.recommendation?.recommendation?.toLowerCase() !==
                    "upsize" && "Savings on downsizing"}
                </span>
                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                  {SavingsP >= 0 && `$${SavingsP}`}
                  {SavingsP < 0 && `-$${Math.abs(SavingsP)}`}
                  {/* $
                  {(data?.recommendation?.Savings || data?.recommendation?.savings || "--")?.toFixed(2) || "--"} */}
                </span>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const RiPricingTable = ({ data, service }) => {
  return (
    <table>
      <thead>
        <tr style={{ backgroundColor: "#F8F9FA", width: "100%" }}>
          <th className={style.riTableHeader}>1 Year Commitment</th>
          <th className={style.riTableHeader}>Upfront </th>
          <th className={style.riTableHeader}>RI Monthly Fees</th>
          <th className={style.riTableHeader}>Savings Over On-Demand</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((e) => (
          <tr>
            <td
              className={style.riTableHeader}
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              {data[e].purchase_option === "all-upfront" && "All Upfront"}
              {data[e].purchase_option === "no-upfront" && "No Upfront"}
              {data[e].purchase_option === "partial-upfront" &&
                "Partial Upfront"}
            </td>
            <td className={style.riTd}>
              {data[e].upfront ? "$" + data[e].upfront?.toFixed(2) : "--"}
            </td>
            <td className={style.riTd}>
              {data[e].monthly_fees
                ? "$" + data[e].monthly_fees?.toFixed(2)
                : "--"}
            </td>
            <td className={style.riTd}>
              {data[e].savings_over_on_demand?.toFixed(2) || "--"} %
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RightSizingModal;
