import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";
import { API_LOADING_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";

/**
 * @typedef Widget
 * @property {string} Id
 * @property {string} Title
 * @property {string} Show_Previous
 * @property {string} Interval_
 * @property {string} Granularity
 * @property {string} Graph_Type
 * @property {string} Cost_Aggregation
 * @property {string} dimension
 */

/**
 * @typedef GetWidgetById
 * @property {'aws' | 'gcp' | 'azure' } service
 */
export const getAllWidgets = (token) => {
  /**
   * @param {GetWidgetById} req
   * @param {AbortSignal=} signal
   * @returns {Promise<Widget[]>}
   */
  return async ({ service }, signal) => {
    try {
      const tableData = await fetch(
        `${config.apiHost}/v1/${service}/cost/widget/get-all`,
        {
          method: "GET",
          signal,
          headers: {
            Authorization: token,
          },
        }
      );
      if (!tableData.ok) {
        if (ERROR_STATUS_CODES.includes(tableData.status)) {
          throw new Error(API_LOADING_ERROR);
        }
        if (tableData.status === 404) {
          throw new Error(API_NOT_FOUND_ERROR);
        }
        throw new Error((await tableData.json()).message);
      }
      
      const data = await tableData.json();
      return data?.widgets || [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

/**
 * @param {GetWidgetById} req
 * @param {any[]=} deps
 */
export const useGetAllWidgets = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllWidgets", req, req, appContext.idToken, ...deps],
    queryFn: ({ signal }) => getAllWidgets(appContext.idToken)(req, signal),
  });
  return query;
};
