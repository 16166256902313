import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "../../snapshots.module.css";
import { InputFields, PrevHistoryContainers } from "./helper";
import { useForm, Controller } from "react-hook-form";
import Joi from "joi";
import { useUpdateAcceptRecommendation } from "@/services/snapshots/addAcceptFields.repo";
import { joiResolver } from "@hookform/resolvers/joi";
import { VMChangeStatusReq } from "@/services/right-sizing/addAcceptStateField.repo";
import { useGetHistory } from "@/services/snapshots/getAcceptDismissHistory.repo";
import HistoryModal from "./historyModal";

/** @typedef {import('@/services/snapshots/addAcceptFields.repo').UpdateAcceptRecommendationsReq} UpdateAcceptRecommendationsReq */

/**
 * @typedef IAcceptStatusReq
 * @property {string} ticket_id
 * @property {string} ticket_url
 * @property {string} comment
 */

const AcceptStatusReq = Joi.object({
  comment: Joi.string(),
  ticket_id: Joi.string(),
  ticket_url: Joi.string(),
});

/**
 * @callback AcceptStatusCallback
 * @param {IAcceptStatusReq} values
 * @returns {void}
 */

/**
 * @typedef IAcceptComponentProps
 * @property {AcceptStatusCallback} onSubmit
 * @property {boolean} enable
 * @property {number} checkHistory
 * @property {VoidFunction} disable
 */

/**
 * @param {IAcceptComponentProps} props
 * @returns
 */
const AcceptModalComponent = (props) => {
  const [enableHistoryModal, setEnableHistoryModal] = useState(false);

  /** @type {import('react-hook-form').UseFormReturn<IAcceptStatusReq>} */
  const formData = useForm({
    resolver: joiResolver(AcceptStatusReq),
    defaultValues: {},
  });

  // useEffect(() => {
  //   formData?.setValue("resourceId", props?.resourceId);
  // });

  useEffect(() => {
    if (props?.data) {
      props?.refetchActiveTable();
      formData?.resetField("comment");
      formData?.resetField("ticket_id");
      formData?.resetField("ticket_url");
      // formData?.reset();
      props?.disable();
    }
    if (props?.isErrorArchive) {
      formData?.resetField("comment");
      formData?.resetField("ticket_id");
      formData?.resetField("ticket_url");
      // formData.reset();
    }
  }, [props?.data, formData.reset, props?.isErrorArchive]);

  const handleCancel = () => {
    props?.disable();
    formData?.resetField("comment");
    formData?.resetField("ticket_id");
    formData?.resetField("ticket_url");

    setEnableHistoryModal(false);
  };

  const handleCloseforModals = () => {
    props?.disable();
    setEnableHistoryModal(false);
  };

  return (
    <Modal
      show={props?.enable}
      onHide={handleCloseforModals}
      contentClassName={styles.modalContent}
      centered
      size={enableHistoryModal ? "lg" : "m"}
      backdrop="static"
    >
      <form
        onSubmit={formData.handleSubmit((data) => {
          props.onSubmit({
            comment: data.comment,
            ticket_id: data.ticket_id,
            ticket_url: data.ticket_url,
          });
        })}
        className={styles.contentContainer}
        style={{ width: enableHistoryModal ? "400px" : "100%" }}
      >
        <div className={styles.headerSection}>
          <p className={styles.tittle}>Accept {props?.name} Recommendation</p>
          <p className={styles.tagline}>
            Add details to accept the recommendation.
          </p>
        </div>
        <div className={styles.inputFields}>
          <div>
            <Controller
              control={formData.control}
              name="ticket_id"
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <InputFields
                  tittle={"Ticket ID"}
                  symbol={"*"}
                  placeholder={"Enter Ticket ID"}
                  inputValue={field.value}
                  inputChange={field.onChange}
                  {...field}
                />
              )}
            />
            {formData?.formState?.errors?.ticket_id && (
              <p className={styles.errorMessage}>
                {formData?.formState?.errors?.ticket_id?.message}
              </p>
            )}
          </div>

          <div>
            <Controller
              name="ticket_url"
              control={formData.control}
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <InputFields
                  tittle={"Ticket URL"}
                  symbol={"*"}
                  placeholder={"Enter Ticket URL"}
                  inputValue={field.value}
                  inputChange={field.onChange}
                  {...field}
                />
              )}
            />
            {formData?.formState?.errors?.ticket_url && (
              <p className={styles.errorMessage}>
                {formData?.formState?.errors?.ticket_url?.message}
              </p>
            )}
          </div>

          <div>
            <Controller
              name="comment"
              control={formData.control}
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <InputFields
                  tittle={"Comment"}
                  symbol={"*"}
                  placeholder={"Add the Comment"}
                  {...field}
                  textValue={field.value}
                  textChange={field.onChange}
                />
              )}
            />
            {formData?.formState?.errors?.comment && (
              <p className={styles.errorMessage}>
                {formData?.formState?.errors?.comment?.message}
              </p>
            )}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <button
            id={styles.AcceptId}
            className={styles.cancleButton}
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            id={styles.AcceptId}
            className={styles.submitButton}
            // onClick={handleSubmit}
            type="submit"
          >
            {/* {props?.updatePending ? "Loading..." : "Submit"} */}
            Submit
          </button>
        </div>
        {props?.checkHistory > 0 && (
          <span
            className={styles.recHistoryTittle}
            onClick={() => setEnableHistoryModal(!enableHistoryModal)}
          >
            {enableHistoryModal
              ? "Hide Recommendation History >"
              : "View Recommendation History >"}
          </span>
        )}
      </form>
      {enableHistoryModal && props?.savingid != null && (
        <HistoryModal historyData={props?.historyData} />
      )}
    </Modal>
  );
};

export default AcceptModalComponent;
