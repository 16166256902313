import React from 'react'
import { Placeholder , Row, Col,} from 'react-bootstrap'
import styles from "@/components/States/states.module.css"

const CostTaggedLoadingState = () => {
  return (
  <div className=" d-flex flex-column gap-5" style={{margin:"8px 12px"}}>
    <Placeholder animation="glow">
      <div className=" d-flex flex-column gap-2">
        <Row className="d-flex justified-content-start gap-0 ">
          <Col xs={12} style={{padding:"0px"}}>
          <Placeholder
          className={styles.placeholder_css}
          xs={12}
          style={{ height: "175px" }}
        />
          </Col>

        </Row>
      </div>
      </Placeholder>
      </div>
  )
}

export default CostTaggedLoadingState