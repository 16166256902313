import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 *
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} data
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {string} type
 * @property {string} name
 * @property {string} accounts
 * @property {string} regions
 * @property {string} spendingBy
 */

export const getTagsInfoSearch = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async (
    { service, fromDate, toDate, accounts, regions, type, name, spendingBy },
    signal
  ) => {
    const body = {
      accounts: accounts || [],
      regions: regions || [],
      fromDate: fromDate,
      toDate: toDate,
      type: type,
      name: name,
      spendingBy: spendingBy,
    };
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/search/search-result/tags-info`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!statistics.ok) {
      if (ERROR_STATUS_CODES.includes(statistics.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (statistics.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();

    if (response?.tags.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }
    return response;
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useTagsInfoSearch = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTagsInfoSearch", req, ...deps],
    queryFn: ({ signal }) => getTagsInfoSearch(appContext.idToken)(req, signal),
  });
  return query;
};
