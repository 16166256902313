import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import green from "@/assets/img/Rectangle green.svg";
import yellow from "@/assets/img/Rectangle yellow.svg";
import red from "@/assets/img/Rectangle red.svg";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";

const SnapshotAge = ({ SnapAgeData, width, azureSnapAgeData, service }) => {
  // const totalCount = (
  //   (azureSnapAgeData?.days_0_to_15?.count || 0) +
  //   (azureSnapAgeData?.days_16_to_30?.count || 0) +
  //   (azureSnapAgeData?.days_31_or_more?.count || 0)
  // );

  // const progress0to15 = totalCount > 0 ? (azureSnapAgeData?.days_0_to_15?.count || 0) / totalCount * 100 : 0;
  // const progress16to30 = totalCount > 0 ? (azureSnapAgeData?.days_16_to_30?.count || 0) / totalCount * 100 : 0;
  // const progress31ormore = totalCount > 0 ? (azureSnapAgeData?.days_31_or_more?.count || 0) / totalCount * 100 : 0;

  return (
    <div className={clsx(styles.snapshot_age_main)} style={{ width: width }}>
      <span className={clsx(styles.snapshot_text)}>snapshots Age</span>
      <div className={clsx(styles.age_container)}>
        {service === "all-cloud" && (
          <ProgressBar
            style={{ borderRadius: "0px", gap: "1px", height: "12px" }}
          >
            <ProgressBar
              variant="success"
              now={(SnapAgeData?.days_7_to_15?.count || 0) * 100}
              key={1}
            />
            <ProgressBar
              variant="warning"
              now={(SnapAgeData?.days_16_to_30?.count || 0) * 100}
              key={2}
            />
            <ProgressBar
              variant="danger"
              now={(SnapAgeData?.days_31_or_more?.count || 0) * 100}
              key={3}
            />
          </ProgressBar>
        )}
        {service !== "all-cloud" && (
          <ProgressBar
            style={{ borderRadius: "0px", gap: "1px", height: "12px" }}
          >
            <ProgressBar
              variant="success"
              now={(azureSnapAgeData?.days_7_to_15?.count || 0) * 100}
              key={1}
            />
            <ProgressBar
              variant="warning"
              now={(azureSnapAgeData?.days_16_to_30?.count || 0) * 100}
              key={2}
            />
            <ProgressBar
              variant="danger"
              now={(azureSnapAgeData?.days_31_or_more?.count || 0) * 100}
              key={3}
            />
          </ProgressBar>
        )}

        {/* {service === "aws" && (
          <ProgressBar
            style={{ borderRadius: "0px", gap: "1px", height: "12px" }}
          >
            <ProgressBar
              variant="success"
              now={(azureSnapAgeData?.days_0_to_15?.count || 0) * 100}
              key={1}
            />
            <ProgressBar
              variant="warning"
              now={(azureSnapAgeData?.days_16_to_30?.count || 0) * 100}
              key={2}
            />
            <ProgressBar
              variant="danger"
              now={(azureSnapAgeData?.days_31_or_more?.count || 0) * 100}
              key={3}
            />
          </ProgressBar>
        )}
        {service === "gcp" && (
          <ProgressBar
            style={{ borderRadius: "0px", gap: "1px", height: "12px" }}
          >
            <ProgressBar
              variant="success"
              now={(azureSnapAgeData?.days_0_to_15?.count || 0) * 100}
              key={1}
            />
            <ProgressBar
              variant="warning"
              now={(azureSnapAgeData?.days_16_to_30?.count || 0) * 100}
              key={2}
            />
            <ProgressBar
              variant="danger"
              now={(azureSnapAgeData?.days_31_or_more?.count || 0) * 100}
              key={3}
            />
          </ProgressBar>
        )} */}

        <div className={clsx(styles.age_container)}>
          <div className={clsx(styles.value_on_age)}>
            <div className={clsx(styles.gap_value_container)}>
              <img src={green} alt="" />

              <div className={clsx(styles.days_gap)}>7-15 Days</div>
            </div>
            {service === "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {SnapAgeData?.days_7_to_15?.count || 0}
              </span>
            )}
            {service !== "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {azureSnapAgeData?.days_7_to_15?.count || 0}
              </span>
            )}
            {/* {service === "azure" && (
              <span className={clsx(styles.values_cost)}>
                {azureSnapAgeData?.days_0_to_15?.count || 0}
              </span>
            )}
            {service === "gcp" && (
              <span className={clsx(styles.values_cost)}>
                {azureSnapAgeData?.days_0_to_15?.count || 0}
              </span>
            )} */}
          </div>
          <div className={clsx(styles.value_on_age)}>
            <div className={clsx(styles.gap_value_container)}>
              <img src={yellow} alt="" />
              <div className={clsx(styles.days_gap)}>16-30 Days</div>
            </div>
            {service === "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {SnapAgeData?.days_16_to_30?.count || 0}
              </span>
            )}
            {service !== "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {azureSnapAgeData?.days_16_to_30?.count || 0}
              </span>
            )}
            {/* {service==="azure"&&(
              <span className={clsx(styles.values_cost)}>
              {azureSnapAgeData?.days_16_to_30?.count || 0}
            </span>
            )} */}
          </div>
          <div className={clsx(styles.value_on_age)}>
            <div className={clsx(styles.gap_value_container)}>
              <img src={red} alt="" />
              <div className={clsx(styles.days_gap)}>More Than 31 Days</div>
            </div>
            {service === "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {SnapAgeData?.days_31_or_more?.count || 0}
              </span>
            )}
            {service !== "all-cloud" && (
              <span className={clsx(styles.values_cost)}>
                {azureSnapAgeData?.days_31_or_more?.count || 0}
              </span>
            )}
            {/* {service==="azure"&&(
              <span className={clsx(styles.values_cost)}>
              {azureSnapAgeData?.days_31_or_more?.count || 0}
            </span>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapshotAge;
