import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";
import { API_LOADING_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";

/**
 * @typedef GetTaggedUntaggedReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {string=} granularity
 * @property {string=} dimension
 * @property {import('./addCostExplorerWidget.repo').AddCostExplorerWidgetReqFilters=} filters
 * @property {import('./addCostExplorerWidget.repo').AddCostExplorerWidgetReqTags=} tags
 */

/**
 * @typedef GetTopContributors
 * @property {string} name
 * @property {number} cost
 * @property {number} coverage
 */

/**
 * @typedef GetTaggedOnly
 * @property {number} cost
 * @property {number} coverage
 *  @property {number} count
 * @property {GetTopContributors[]} topContributors
 */

/**
 * @typedef GetUntaggedOnly
 * @property {number} cost
 * @property {number} coverage
 * @property {number} count
 * @property {GetTopContributors[]} topContributors
 */

/**
 * @typedef GetUntaggableOnly
 * @property {number} cost
 * @property {number} coverage
 *  @property {number} count
 * @property {GetTopContributors[]} topContributors
 */

/**
 * @typedef GetTaggedUntaggedRes
 * @property {GetTaggedOnly} tagged
 * @property {GetUntaggedOnly} untagged
 * @property {GetUntaggableOnly} untaggable
 */

export const getTaggedUntagged = (token) => {
  /**
   * @param {GetTaggedUntaggedReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetTaggedUntaggedRes>}
   */
  return async (
    { service, fromDate, toDate, filters, tags, showUntaggedOnly,showUntaggableOnly },
    signal
  ) => {
    const taggedUntagged = await fetch(
      `${config.apiHost}/v1/${service}/cost/tags-info`,
      {
        method: "POST",
        body: JSON.stringify({
          fromDate,
          toDate,
          filters: filters || [],
          tags: tags || [],
          showUntaggedOnly,
          showUntaggableOnly
        }),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!taggedUntagged.ok) {
      if (ERROR_STATUS_CODES.includes(taggedUntagged.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (taggedUntagged.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await taggedUntagged.json()).message);
    }
    
    const data = await taggedUntagged.json();
    return data;
  };
};

/**
 * @param {GetTaggedUntaggedReq} req
 * @param {any[]=} deps
 */
export const useTaggedUntagged = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTaggedUntagged", req, ...deps],
    queryFn: ({ signal }) => getTaggedUntagged(appContext.idToken)(req, signal),
    // staleTime: 0,
  });
  return query;
};
