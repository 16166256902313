import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "@/page-components/admin/admin.module.css";
import Button from "react-bootstrap/Button";
import success from "@/assets/img/success_image.svg";

/**
 * @typedef DeleteSuccessModalProps
 * @property {boolean} show
 * @property {() => void} onHide
 * @property {'user' | 'group' | 'privilege'} type
 */

/**
 * @param {DeleteSuccessModalProps} props
 */
export const DeleteSuccessModal = (props) => {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} centered>
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={success} alt="" />
            </span>

            <span className={styles.success_message}>
              {capitalizeFirstLetter(props.type)} Deleted Successfully
            </span>
          </div>

          <div className={styles.close_button}>
            <Button className={styles.close_buttom} onClick={props.onHide}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

/**
 * @typedef DeleteMessageModalProps
 * @property {boolean} show
 * @property {() => void} onHide
 * @property {'user' | 'group' | 'privilege'} type
 * @property {string} title
 * @property {any=} deleteData
 * @property {any=} deleteAction
 * @property {boolean} deleteLoading
 */

/**
 * @param {DeleteMessageModalProps} props
 */
export const DeleteMessageModal = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (props.deleteData) {
      setShowSuccessModal(true);
      props.onHide();
    }
  }, [props.deleteData]);
  
  return (
    <>
      <Modal
        show={props.show}
        onHide={!props.deleteLoading ? props.onHide : () => {}}
        centered
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.delete_main_heading}>
              Delete "{props.title}"
            </span>
            <span className={styles.delete_message}>
              Are you sure you want to delete this {props.type}? This action
              cannot be undone.
            </span>
          </div>

          <div className={styles.close_button}>
            <button
              disabled={props.deleteLoading}
              className={styles.deleted_button}
              onClick={() => {
                props.deleteAction();
              }}
            >
              {props.deleteLoading ? "Loading..." : "Delete"}
            </button>
            <button
              disabled={props.deleteLoading}
              className={styles.closed_button}
              onClick={props.onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <DeleteSuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        type={props.type}
      />
    </>
  );
};

function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
