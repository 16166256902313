import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import styles from "@/page-components/ai-recommendations/ai-recommend.module.css";
import clsx from "clsx";
import { AiRecommendContext } from "../ai-recom-context-file";
import { useAiFilterList } from "@/services/ai-recommendation/getAiFilterList.repo";

const FilterSection = ({ service, name }) => {
  const [selectedTittle, setSelectedTittle] = useState(null);
  const context = useContext(AiRecommendContext);
  const [tab, setTab] = useState("");

  useEffect(() => {
    if (context?.eventkey === "Usage Patterns") {
      setTab("usage");
    } else {
      setTab("disk");
    }
  }, [context?.eventkey]);

  useEffect(() => {
    context?.setSelectAccounts([]);
    context?.setSelectedResourceGroup([]);
    context?.setSelectRegions([]);
    context?.setSelectOwner([]);
    // setSelectedTittle(null);
  }, [context?.eventkey]);

  const query = useMemo(() => {
    if (!selectedTittle) return null;
    return {
      service,
      selectedTittle: selectedTittle,
      tab: tab,
    };
  }, [selectedTittle, service, tab]);

  const { data } = useAiFilterList(query);

  return (
    <>
      <div className={styles.filter_main_div}>
        <Filter
          tittle={name}
          setSelectedTittle={setSelectedTittle}
          data={data?.data}
          filterType="account"
          selectedValues={context.selectAccounts}
          onSelect={(list) => {
            context?.setSelectAccounts(list);
          }}
          onRemove={(list) => {
            context?.setSelectAccounts(list);
          }}
        />
        {/* {service === "azure" && context?.selectAccounts.length > 0 && ( */}
        <Filter
          tittle={"Resource Group"}
          setSelectedTittle={setSelectedTittle}
          data={data?.data}
          filterType="resource"
          selectedValues={context.selectedResourceGroup}
          onSelect={(list) => {
            context?.setSelectedResourceGroup(list);
          }}
          onRemove={(list) => {
            context?.setSelectedResourceGroup(list);
          }}
        />
        {/* )} */}
        <Filter
          tittle={"Region"}
          setSelectedTittle={setSelectedTittle}
          data={data?.data}
          filterType="region"
          selectedValues={context.selectRegions}
          onSelect={(list) => {
            context?.setSelectRegions(list);
          }}
          onRemove={(list) => {
            context?.setSelectRegions(list);
          }}
        />
        {/* {context?.eventkey === "Usage Patterns" && (
          <Filter
            tittle={"Owner"}
            setSelectedTittle={setSelectedTittle}
            data={data?.data}
            filterType="owner"
            selectedValues={context.selectOwner}
            onSelect={(list) => {
              context?.setSelectOwner(list);
            }}
            onRemove={(list) => {
              context?.setSelectOwner(list);
            }}
          />
        )} */}
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const context = useContext(AiRecommendContext);

  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      context.setSelectAccounts(selectedList);
    }
    if (filterType === "region") {
      context.setSelectRegions(selectedList);
    }
    if (filterType === "resource") {
      context.setSelectedResourceGroup(selectedList);
    }
    if (filterType === "owner") {
      context.setSelectOwner(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription Name" || tittle === "Account Name") {
      title = "Account";
    }
    if (title === "Resource Group") {
      title = "resource";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <>
      <div className={clsx(styles.filter_padding_div)}>
        <div className={clsx(styles.dimension_div)}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={clsx(styles.filter_group_name_css)}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.id,
                  name: e.name,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
