import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllBodyStatistics
 * @property {AllDetailsStatistics} detailsStatics
 * @property {AllCardDetailsStatistics[]} CardDetailStatics 
 */
/**
 * @typedef AllDetailsStatistics
 * @property {String} reservationName
 * @property {String} purchasedQuantity
 * @property {String} term
 * @property {String} expiresOn
 * @property {String} instanceFlexibility
 * @property {Number} region
 * @property {Number} vmFamily
 * @property {Number} platform
 * @property {Number} unusedQuantity
 * @property {Number} unusedCost
 * @property {Number} recommendedQuantity
 */
/**
 * @typedef AllCardDetailsStatistics
 * @property {String} recommendedScope
 * @property {String} unusedQuantity
 * @property {String} unusedCost
 * @property {String} instanceFlexibility
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } servicetype
 */

export const getModalStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service , servicetype , reservationId }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/${servicetype}/recommendations`,
      {
        method: "post",
        body: JSON.stringify({
            reservationId:reservationId

        }),
        
        headers: {
        "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners?.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();

    if (res?.recommendations?.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useScopeStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getModalStatistics", req, ...deps],
    queryFn: () => getModalStatistics(appContext.idToken)(req),
  });
  return query;
};
