import React from 'react';
import styles from "@/page-components/cost-explorer/cost.explorer.module.css"

const Month_costing = (props) => {
  return (
    <div className={styles.period_cost}>
      <div className={styles.preceding_header}>{props.period.header}</div>
      <div className={styles.preceding_text}>{props.period.text}</div>
      <div className={styles.preceding_cost}>{props.period.cost}</div>
    </div>
  )
}

export default Month_costing