import config from "@/config";
import Joi from "joi";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

export const GRANULARITY = {
  date: "Daily",
  week: "Weekly",
  monthname: "Monthly",
};

export const SUBSCRIPTION ={
  subscription:"subscription"
}

export const INTERVAL = {
  days: "Days",
  weeks: "Weeks",
  months: "Months",
};

/**
 * DIMENSIONS
 */
export const AWS_DIMENSIONS = {
  usage_type: "Usage Type",
  account_name: "Account Name",
  region: "Region",
  service_code: "Services",
};

export const AZURE_DIMENSIONS = {
  meterName: "Meter",
  meterCategory: "Meter Category",
  resourceGroupName: "Resource Group",
  subscriptionName: "Subscription",
};

export const BUDGETKEYS = {
  '': 'All Dimensions',
  Meter:"meterName",
  MeterCategory : "meterCategory",
  ResourceGroupName :"resourceGroupName",
  Subscription: "subscriptionName",
  AccountId:"usage_account_id"
};

export const GCP_DIMENSIONS = {};

/**
 * @typedef AddCostExplorerWidgetReqFilters
 * @property {keyof typeof AWS_DIMENSIONS | keyof typeof AZURE_DIMENSIONS} dimension
 * @property {boolean} isInclude
 * @property {string[]} values
 */

/**
 * @typedef AddCostExplorerWidgetReqTags
 * @property {string} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef IAddCostExplorerWidgetReq
 * @property {string} title
 * @property {string} cloud
 * @property {string} showPrevious
 * @property {string} interval
 * @property {string} granularity
 * @property {string} graphType
 * @property {string} costAggregation
 * @property {string} userEmail
 * @property {keyof typeof AWS_DIMENSIONS | keyof typeof AZURE_DIMENSIONS} dimension
 * @property {AddCostExplorerWidgetReqFilters[]} filters
 * @property {AddCostExplorerWidgetReqTags[]} tags
 */

/** @type {Joi<IAddCostExplorerWidgetReq>} */
export const AddCostExplorerWidgetReq = Joi.object({
  title: Joi.string().required().messages({
    "string.empty": "Title is required",
    "any.required": "Title is required",
  }),
  showPrevious: Joi.string().required().messages({
    "string.empty": "Previous value is required",
    "any.required": "Previous value is required",
  }),
  cloud: Joi.string().valid("aws", "azure", "gcp").required().messages({
    "string.empty": "Cloud is required",
    "any.required": "Cloud is required",
  }),
  interval: Joi.string().valid("days", "weeks", "months").required().messages({
    "string.empty": "Interval is required",
    "any.required": "Interval is required",
  }),
  granularity: Joi.string()
    .valid("date", "week", "monthname")
    .required()
    .messages({
      "string.empty": "Granularity is required",
      "any.required": "Granularity is required",
    }),
  graphType: Joi.string().valid("bar", "line").required().messages({
    "string.empty": "GraphType is required",
    "any.required": "GraphType is required",
  }),
  costAggregation: Joi.string().optional(),
  userEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "User Email is required",
      "any.required": "User Email is required",
    }),
  dimension: Joi.string().required().messages({
    "string.empty": "Dimension is required",
    "any.required": "Dimension is required",
  }),
  filters: Joi.array().items(
    Joi.object({
      dimension: Joi.string()
        .allow("")
        .messages({
          "string.empty": "Dimension is required",
          "any.required": "Dimension is required",
        })
        .optional(),
      isInclude: Joi.boolean().optional(),
      values: Joi.array().items(Joi.string()).optional(),
    })
  ),
  tags: Joi.array().items(
    Joi.object({
      filterOn: Joi.string().allow("").messages({
        "string.empty": "Filter on field is required",
        "any.required": "Filter on field is required",
      }),
      filterType: Joi.valid("include", "exclude").optional(),
      values: Joi.array().items(Joi.string()).optional(),
    })
  ),
});

/**
 * @typedef SingleWidgetLocal
 * @property {import("./getAllWidgets.repo").Widget} widget
 */

export const addCostExplorerWidget = (token) => {
  /**
   * @param {AddCostExplorerWidgetReq} req
   * @returns {Promise<SingleWidgetLocal>}
   */
  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/${req.cloud}/cost/widget`,
      {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    const json = await totalStats.json();
    return json;
  };
};

/**
 * @param {any[]=} deps
 */
export const useAddCostExplorerWidget = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["addCostExplorerWidget", appContext.idToken, ...deps],
    mutationFn: addCostExplorerWidget(appContext.idToken),
  });
  return mutation;
};
