import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} dimension
 * @property {string} cost
 * @property {GetDataStatistics[] =} children
 * @property {CostExplorerUsageTopContributorItemDb} topContributors
 * @property {CostExplorerUsageCostTrendItem} costTrend
 *
 */

/**
 * @typedef CostExplorerUsageTopContributorItemDb
 * @property {string} category
 * @property {number} cost
 */

/**
 * @typedef CostExplorerUsageCostTrendItem
 * @property {string} category
 * @property {number} cost
 * @property {string} month
 */

/**
 * @typedef CostExplorerUsageDetailsFilters
 * @property {string} dimension
 * @property {boolean} isInclude
 * @property {string[]} values
 */

/**
 * @typedef CostExplorerUsageDetailsTags
 * @property {string} filterOn
 * @property {string} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetStatisticsReq
 * @property {string} toDate
 * @property {string} fromDate
 * @property {string[] =} region
 * @property {string[] =} accounts
 * @property {string} dimension
 * @property {CostExplorerUsageDetailsFilters[] =} filters
 * @property {CostExplorerUsageDetailsTags[] =} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatisticsRes>}
 */

export const getUsageDetails = (token) => {
  return async ({ cloud, granularity, ...rest }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${cloud}/cost/usage-details`,
      {
        method: "POST",
        body: JSON.stringify(rest),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UsageDetailsStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getUsageDetails", appContext.idToken, req, ...deps],
    queryFn: () => getUsageDetails(appContext.idToken)(req),
  });
  return query;
};

// API RESOPNSE
// {
//   "serviceTotalCost": 0.100515568,
//   "totalCost": 963.7390835903451,
//   "usageDetails": [...],
//   "topContributors": [...],
//   "costTrend": [...]
// }
