import React from 'react'
import BarGraphErrorStates from './BarGraphErrorStates'
import TableErrorState from './TableErrorState'

export default function TableAndGraph() {
  return (
    <div className=" d-flex flex-column gap-2" style={{ marginTop: 12, width:"100%", borderRight:"1px solid var(--Neutral-D8, #E9ECEF)" }}>
         <BarGraphErrorStates/>
          {/* ---------------------------------table--------------------------------------------- */}
          <div style={{marginRight:"32px", marginTop:"12px"}}>
            {/* ToDO instead  of borderbottom remove the border from the table lib */}
          
          <TableErrorState/>
          </div>
         
        </div>
  )
}
