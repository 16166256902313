import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetCountRes[]
 * @property {number} admins
 * @property {number} users
 */

export const getCount = (token) => {
    /**
     * @returns {Promise<GetCountRes>}
     */
 
  return async ( signal) => {
    const count = await fetch(`${config.apiHost}/v1/users/get-users-count`, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      signal,
    });
    if (!count.ok) {
      throw new Error((await count.json()).message);
    }
    return await count.json();
  };
};

/**
 * @param {any[]=} deps
 */
export const useCount = ( deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getCount",...deps],
    queryFn: ({ signal }) => getCount(appContext.idToken)( signal),
  });
  return query;
};
