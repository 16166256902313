import { useMemo, useState } from "react";
import AwsServiceTable from "../components/Cost&usage/AwsServiceTable";
import KubernatesHeader from "../components/KubernatesHeader";
import FilterSection from "../components/Kubernetes_Filter_Section/Kuber_Filter_Section";
import { differenceInDays, format, parse } from "date-fns";
import { useKuberneteStackedData } from "@/services/kubernetes/getCostUsages.repo";
import subDays from "date-fns/subDays";
import GraphData from "../graphData";
import clsx from "clsx";
import styles from "../kubernates.module.css";
import TableAndGraph from "@/components/States/TableAndGraph";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const AzureKubernates = () => {
  const service = "azure";
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);
  const [selectedValues, setSelectedValues] = useState({
    granularity: "date",
  });
  const [loading, setLoading] = useState(false);
  let formattedStartDate = format(range[0].startDate, "dd-MM-yyyy");
  let formattedEndDate = format(range[0].endDate, "dd-MM-yyyy");
  const date2 = parse(formattedEndDate, "dd-MM-yyyy", new Date());
  const date1 = parse(formattedStartDate, "dd-MM-yyyy", new Date());

  const daysDifference = differenceInDays(date2, date1) + 1;
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  const query = useMemo(() => {
    let enableFilter = false;
    let filters = {
      granularity: selectedValues.granularity,
      tags: selectedTags.filter(
        (e) => e.filterOn.length > 0 && e.values.length > 0
      ),
    };

    if (
      Object.keys(selectedValues).filter((e) => e !== "granularity").length > 0
    ) {
      enableFilter = true;
      const keys = Object.keys(selectedValues).filter(
        (e) => e !== "granularity"
      );
      filters = {
        granularity: selectedValues.granularity,
        tags: selectedTags.filter(
          (e) => e.filterOn.length > 0 && e.values.length > 0
        ),
      };

      keys.forEach((e) => {
        if (selectedValues[e]?.length > 0) {
          filters[e] = selectedValues[e].map((x) => x.id);
        }
      });
    }

    if (
      filters.granularity === "date" &&
      selectedTags[0].values.length === 0 &&
      Object.keys(selectedValues).filter((e) => e !== "granularity").length ===
        0
    ) {
      enableFilter = false;
    } else {
      enableFilter = true;
    }

    return {
      filters,
      service,
      enableFilter,
      fromDate: formattedStartDate,
      toDate: formattedEndDate,
    };
  }, [
    selectedValues,
    formattedStartDate,
    formattedEndDate,
    service,
    selectedTags,
  ]);

  const costAndUseage = useKuberneteStackedData(query);

  const keys = Object.keys(costAndUseage?.data || {});

  const data = Object.values(costAndUseage?.data || {});

  let graphData = (data || [])?.map((e) => e.graphData);
  const fromDateIns = parse(formattedStartDate, "dd-MM-yyyy", new Date());

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-0">
          <KubernatesHeader
            costAndUseageLoading={costAndUseage?.isLoading}
            service={service}
            range={range}
            setRange={setRange}
            setLoading={setLoading}
            formattedEndDate={formattedEndDate}
            formattedStartDate={formattedStartDate}
            daysDifference={daysDifference}
          />
          {/* {!loading&&costAndUseage?.isLoading && <BarGraphErrorStates />} */}

          <div
            className="container-fluid col-lg-12 "
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              gap: "24px",
              paddingLeft: "57px",
            }}
          >
            <div className="row w-100">
              <div
                id={styles.combinedCol}
                className={clsx(
                  "col-10 p-0 pe-4 pt-4 d-flex gap-4 flex-column ",
                  styles.container
                )}
                style={{ minHeight: "500px" }}
              >
                {costAndUseage?.isLoading && <TableAndGraph />}
                {costAndUseage?.error && (
                  <ScenariosErrorState
                    error={costAndUseage?.error.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                        message: "No Data Available for Selected Filters",
                        additionalMessage:
                          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                      },
                      [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                        message:
                          "No Data for the Selected Date Range",
                        additionalMessage:
                          "The selected date range doesn’t contain any data. Try choosing a different range to view available savings.",
                      },
                      [API_LOADING_ERROR]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}

                {!loading && costAndUseage?.data && (
                  <>
                    <GraphData
                      graphData={graphData}
                      keys={keys}
                      daysDifference={daysDifference}
                      selectedValues={selectedValues}
                      fromDateIns={fromDateIns}
                      graphLoading={costAndUseage?.isLoading}
                    />
                    <AwsServiceTable
                      data={data}
                      dataLoading={costAndUseage?.isLoading}
                    />
                  </>
                )}
              </div>
              <div className="col-2 p-0 sidebar_filter">
                <FilterSection
                  service={service}
                  setSelectedValues={setSelectedValues}
                  selectedValues={selectedValues}
                  name="Subscription"
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AzureKubernates;
