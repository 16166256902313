import React, { useState } from "react";
import styles from "../../snapshots.module.css";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import clsx from "clsx";
import showless from "@/assets/img/showLess.svg";
import showmore from "@/assets/img/showMore.svg";

export default function HistoryModal({ historyData, enableHistory }) {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  const displayedHistory = showAll
    ? historyData?.history || []
    : (historyData?.history || []).slice(0, 3);

  return (
    <div
      className={clsx({
        [styles.recHistoryContainer]: !enableHistory,
        [styles.historyModal]: enableHistory,
      })}
      style={{ overflowY: "auto", overflowX: "hidden" }}
    >
      <span className={styles.historyTittle}>Recommendation History</span>
      <Button className={styles.hideContainer} onClick={toggleShowAll}>
        {showAll ? "Hide Previous" : "Show Previous"}
        {showAll ? (
          <img src={showless} alt="" />
        ) : (
          <img src={showmore} alt="" />
        )}
      </Button>

      {displayedHistory.map((history, index) => (
        <div key={index} style={{ width: "100%" }}>
          <div className={styles.headingContainer}>
            <div>
              <span style={{ fontSize: "12px", fontWeight: 500 }}>Status:</span>{" "}
              {history.status === "open" && (
                <span style={{ color: "rgb(153 163 173)" }}>Open</span>
              )}
              {history.status === "accepted" && (
                <span style={{ color: "#22BC22" }}>Accepted</span>
              )}
              {history.status === "discarded" && (
                <span style={{ color: "#D01A1A" }}>Discarded</span>
              )}
              {history.status === "archived" && (
                <span style={{ color: "#E08267" }}>Archived</span>
              )}
              {history.status === "inprogress" && (
                <span style={{ color: "#4A7B9A" }}>Approval Pending</span>
              )}
              {history.status === "unarchive" && (
                <span style={{ color: "#056EE5" }}>Unarchived</span>
              )}
            </div>
            <>Date : {format(new Date(history.created_at), "dd-MM-yy")}</>
          </div>
          <div className={styles.detailContainers}>
            {history.status === "accepted" && (
              <div className={styles.acceptCreteriaDetails}>
                <>
                  <div>
                    TicketID{" "}
                    <p
                      className={styles.detailsComments}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "130px",
                      }}
                      title={history.ticket_id || "NA"}
                    >
                      {history.ticket_id || "NA"}
                    </p>
                  </div>
                  <div>
                    Ticket URL
                    <p
                      className={styles.detailsComments}
                      title={history.ticket_url}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "150px",
                      }}
                    >
                      {history.ticket_url || "NA"}
                    </p>
                  </div>
                </>
              </div>
            )}
            <div>
              <p>Comment</p>
              <p className={styles.detailsComments}>
                {history?.comment || "NA"}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
