import React from "react";
import styles from "@/page-components/ai-recommendations/ai-recommend.module.css";
import AIRecommendTabs from "./ai-recommend-tabs.part";
import clsx from "clsx";

const AiRecommendHeader = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.inner_first_container_text}>VM & Disk Usage</div>
      <AIRecommendTabs />
    </div>
  );
};

export default AiRecommendHeader;
