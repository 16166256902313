import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dropdown from "../assets/img/dropdown.svg";
import { Outlet } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import clsx from "clsx";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "./NotificationModal";
import "../assets/css/NotificationModal.css";
import Avatar from "@/assets/img/user_pic.svg";
import * as constants from "@/store/constant";
import awsLogo from "@/assets/img/AWS_mainLogo21.svg";
import azureLogo from "@/assets/img/AZURE_mainLogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import { AppContext, ROLES } from "@/App.context";
import config from "@/config";
import search from "@/assets/img/searchIcon.svg";
import cross from "@/assets/img/crossIconSearch.svg";
import SideSearchSection from "@/page-components/search-module/sideSearchSection";
import { useDebounce } from "rooks";
import SidebarWithAccordian from "./SidebarWithAccordian";

const Navbar = ({ children }) => {
  const appContext = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sidebarActive, selectedResource } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  const toggle_sidebar = (e) => {
    dispatch({ type: constants.TOGGLE_SIDEBAR, payload: false });
  };

  const current = currentPath;

  function getPlatform(path) {
    const segments = path.split("/");
    return segments[1];
  }

  const platform = getPlatform(current);

  const ToggleDropdown = () => {
    appContext.setEnableSearchDropdown(false);
  };

  let curUrl = window.location.pathname.split("/");
  let lastString = curUrl[2];

  const handleChange = (cloud) => {
    dispatch({ type: constants.ON_RESOURCE_CHANGE, payload: cloud });
    if (cloud === "all-clouds" && lastString === "budgets") {
      navigate(`/aws/${lastString}`);
    } else {
      navigate(`/${cloud}/${lastString}`);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length > 2) {
      appContext.setEnableSearchDropdown(true);
    } else {
      appContext.setEnableSearchDropdown(false);
    }
  };

  const debouncedSetSearchText = useDebounce((value) => {
    setDebouncedSearchText(value);
  }, 700);

  useEffect(() => {
    debouncedSetSearchText(inputValue);
  }, [inputValue, debouncedSetSearchText]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setCurrentPath(location.pathname);

    const changedUrl = location.pathname.split("/");
    let lastStringModule = changedUrl[changedUrl.length - 2];
    let cloud = changedUrl[1];

    // if (location.pathname === "/all-clouds/dashboard") {
    //   appContext.setEnableSearchDropdown(false);
    // }
    if (
      window.location.pathname.includes("/all-clouds") ||
      window.location.pathname.includes("/gcp")
    ) {
      appContext.setEnableSearchDropdown(false);
    }

    if (
      lastStringModule !== "aws" ||
      lastStringModule !== "azure" ||
      lastStringModule !== "gcp" ||
      lastStringModule !== "all-clouds"
    ) {
      dispatch({ type: constants.ON_RESOURCE_CHANGE, payload: changedUrl[2] });
    }
    dispatch({ type: constants.ON_RESOURCE_CHANGE, payload: cloud });
  }, [location, dispatch]);

  const getDisabledItems = () => {
    if (currentPath.includes("/azure/details")) {
      return ["aws", "gcp", "all-clouds"];
    } else if (currentPath.includes("/aws/details")) {
      return ["azure", "gcp", "all-clouds"];
    } else if (currentPath.includes("/gcp/details")) {
      return ["aws", "azure", "all-clouds"];
    }

    if (currentPath.includes("/azure/search")) {
      return ["aws", "azure", "gcp", "all-clouds"];
    } else if (currentPath.includes("/aws/search")) {
      return ["aws", "azure", "gcp", "all-clouds"];
    } else if (currentPath.includes("/gcp/search")) {
      return ["aws", "azure", "gcp", "all-clouds"];
    }
    return [];
  };

  const disabledItems = getDisabledItems();

  const cloudNamesMap = {
    aws: "AWS",
    azure: "Azure",
    gcp: "GCP",
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="row header shadow-sm"
          style={{ position: "fixed", width: "100%", zIndex: "1001" }}
        >
          <div
            className={clsx("col-sm-2 col-md-2 my-auto px-3 py-2", {
              "shrink-sidebar": !sidebarActive,
            })}
          >
            <div className="d-flex gap-3">
              <div className="finops_name my-auto">FinOps</div>
              <div className="vr" style={{ backgroundColor: "white" }}></div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  gap: config.enabledClouds.size === 1 ? "4px" : "",
                }}
              >
                <div className="anaylyzing_name">Analyzing</div>
                {config.enabledClouds.size === 1 && (
                  <>
                    <div className="enabled_cloud">
                      {Array.from(config.enabledClouds).map((cloud) => (
                        <span key={cloud}>{cloudNamesMap[cloud] || ""}</span>
                      ))}
                    </div>
                  </>
                )}
                {config.enabledClouds.size > 1 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      style={{ padding: "0px" }}
                    >
                      <span>
                        <span
                          style={{
                            color: "white",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          {selectedResource === "all-clouds" && " All Clouds"}
                          {selectedResource === "aws" && " AWS"}
                          {selectedResource === "azure" && " Azure"}
                          {selectedResource === "gcp" && " GCP"}
                        </span>
                        <img src={dropdown} alt="person" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mainDropdownMenu">
                      {lastString !== "budgets" && (
                        <Dropdown.Item
                          id="dropdownItem"
                          onClick={() => handleChange("all-clouds")}
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                          }}
                          disabled={disabledItems.includes("all-clouds")}
                        >
                          <span style={{ fontSize: "13px", fontWeight: "600" }}>
                            All Clouds
                          </span>
                        </Dropdown.Item>
                      )}

                      {config.enabledClouds.has("aws") && (
                        <Dropdown.Item
                          id="dropdownItem"
                          onClick={() => handleChange("aws")}
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                          }}
                          disabled={disabledItems.includes("aws")}
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ width: "22px", height: "16px" }}
                              src={awsLogo}
                              alt=""
                            />
                            <span
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              AWS
                            </span>
                          </span>
                        </Dropdown.Item>
                      )}

                      {config.enabledClouds.has("azure") && (
                        <Dropdown.Item
                          id="dropdownItem"
                          onClick={() => handleChange("azure")}
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                          }}
                          disabled={disabledItems.includes("azure")}
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ width: "22px", height: "16px" }}
                              src={azureLogo}
                              alt=""
                            />{" "}
                            <span
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              Azure
                            </span>
                          </span>
                        </Dropdown.Item>
                      )}
                      {config.enabledClouds.has("gcp") && (
                        <Dropdown.Item
                          id="dropdownItem"
                          onClick={() => handleChange("gcp")}
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                          }}
                          disabled={disabledItems.includes("gcp")}
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ width: "22px", height: "16px" }}
                              src={gcpLogo}
                              alt=""
                            />
                            <span
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              GCP
                            </span>
                          </span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>

          <div
            className={clsx(
              "col-sm-10 col-md-8 col-lg-10 col-10 header-menu pb-0 d-flex flex-column justify-content-center",
              { "expand-content": !sidebarActive }
            )}
          >
            <div className="row">
              <div className="col-sm-1 col-md-1 col-1 pl-0 m-auto"></div>

              <div className="col-sm-11 col-md-11 col-11 justify-content-end">
                <div className="row justify-content-end mr-6">
                  <div className="col-sm-10 col-md-10 col-10 text-right flex-header-menu justify-content-end">
                    <div className=" d-flex justify-content-between align-items-center gap-3 ">
                      {/* {(window.location.pathname.includes("aws/dashboard") ||
                        window.location.pathname.includes(
                          "azure/dashboard"
                        )) && ( */}
                      {!(
                        window.location.pathname.includes("/all-clouds") ||
                        window.location.pathname.includes("/gcp")
                      ) &&
                        (window.location.pathname.includes("/dashboard") ||
                          window.location.pathname.includes("/search")) && (
                          <>
                            <div className="searchBar">
                              <span style={{ width: "100%" }}>
                                <img src={search} alt="search" />
                                <input
                                  id="searchBarInput"
                                  type="text"
                                  className="searchedItem"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  placeholder="Search"
                                  style={{ width: "93%" }}
                                />
                              </span>
                              <img
                                src={cross}
                                alt="crossIcon"
                                onClick={() => {
                                  setInputValue("");
                                  appContext.setEnableSearchDropdown(false);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            {/* <img
                            className={style.searchBarButton}
                            src={searchButton}
                            alt=""
                          /> */}
                          </>
                        )}

                      <Dropdown>
                        <Dropdown.Toggle as={UserDropdown}>
                          <div>
                            <img
                              src={Avatar}
                              alt="Adam"
                              className="rounded-circle hover"
                              style={{ maxHeight: "36px", maxWidth: "36px" }}
                              width="36px"
                              height="36px"
                            />
                            <img
                              src={dropdown}
                              alt="Adam"
                              className="rounded-circle "
                              style={{ maxHeight: "36px", maxWidth: "36px" }}
                              width="36px"
                              height="36px"
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {appContext?.user?.roles === ROLES.admin && (
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/admin/privileges");
                              }}
                            >
                              Admin Console
                            </Dropdown.Item>
                          )}

                          {appContext?.user?.roles === ROLES.user && (
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/profile");
                              }}
                            >
                              Profile
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/all-clouds/dashboard");
                            }}
                          >
                            {" "}
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/logout");
                            }}
                          >
                            Sign Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="add_widget_overlay_screen">
          <NotificationModal
            toggleModal={toggleModal}
            style={{
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "red",
              top: "0",
            }}
          />
        </div>
      )}
      <div className="container-fluid">
        <div className="row content-section" style={{ padding: "0px" }}>
          {/* <Sidebar /> */}
          <SidebarWithAccordian />
          <div className="main_container">
            {children ? children : <Outlet />}
          </div>
        </div>
      </div>
      {sidebarActive && (
        <div
          style={{
            position: "fixed",
            background: "rgba(0, 0, 0, 0.5)",
            width: "100%",
            height: "100%",
          }}
          className="black-overlay"
          onClick={toggle_sidebar}
        />
      )}
      {appContext.enableSearchDropdown &&
        !(
          window.location.pathname.includes("/all-clouds") ||
          window.location.pathname.includes("/gcp")
        ) &&
        (window.location.pathname.includes("/dashboard") ||
          window.location.pathname.includes("/search")) && (
          <>
            <SideSearchSection
              service={platform}
              searchText={debouncedSearchText}
              settingtext={() => {
                setInputValue("");
              }}
              setModalFalse={() => {
                appContext.setEnableSearchDropdown(false);
              }}
            />
          </>
        )}
    </>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const UserDropdown = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

export default Navbar;
