import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef Users
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {number} groups
 * @property {string} creatorName
 * @property {date} createdAt
 * @property {string} id
 * @property {string} roles
 */

/**
 * @typedef GetUsersListRes
 * @property {Users[]} users
 * @property {number} total
 */

// /**
//  * @typedef GetRecommendationsFilters
//  * @property {string[]} accounts
//  * @property {string[]} regions
//  */

/**
 * @typedef GetUsersListReq
 * @property {'Admin' | 'User'} userType
 * @property {number} page
 * @property {number} limit
 * @property {string} filterQuery
 */

export const getUsersList = (token) => {
  /**
   * @param {GetUsersListReq} req
   * @returns {Promise<GetUsersListRes>}
   */
  return async ({
    page,
    limit,
    userType,
    filterQuery
  }, signal) => {
    const usersList = await fetch(
      `${config.apiHost}/v1/users/get-all`,
      {
        method: "POST",
        body: JSON.stringify({
          page,
          limit,
          userType,
          filterQuery
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        signal,
      }
    );
    if (!usersList.ok) {
      throw new Error((await usersList.json()).message);
    }
    var result = await usersList.json()
    return  result;
  };
}

/**
 * @param {GetUsersListReq} req
 * @param {any[]=} deps
 */
export const useUsersList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getUsersList", req, ...deps],
    queryFn: ({ signal }) => getUsersList(appContext.idToken)(req, signal),
  });
  return query;
};
