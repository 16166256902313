import { AppContext } from "@/App.context";
import config from "@/config";
import { DownloadFile } from "@/lib/download-file";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllBodyStatistics
 * @property {String} accountname
 * @property {String} instance_type
 * @property {String} region
 * @property {String} tenancy
 * @property {String} platform
 * @property {Number} ondemand_hrs
 * @property {Number} ondemand_cost
 * @property {Number} yearly_ri_cost
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } type
 */

export const getStatisticsDownload = (token) => {
  // /**
  //  * @param {GetStatisticsReq} req
  //  * @returns {Promise<AllBodyStatisticsRes>}
  //  */
  return async ({
    service,
    type,
    page,
    recordsPerPage,
    filters,
    tableName,
  }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/${type}/${tableName}?page=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...filters,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${type}-${tableName}-data.csv`;
    DownloadFile(blob, fileName);
  };
};
/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiStatisticsDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["getStatisticsDownload", req, ...deps],
    mutationFn: ({signal}) => getStatisticsDownload(appContext.idToken)(req,signal),
  });
  return query;
};
