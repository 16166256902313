import React from "react";
import "@/assets/css/Right-sizing-chart.css";
import awslogo from "@/assets/img/aws_logo2.svg";
import azurelogo from "@/assets/img/azurelogo.svg";
import RightSizingPieChart from "./RightSizingPieChart.js";
import { useAllCloudStatistics } from "@/services/right-sizing/getAllClouds.repo";
import BudgetsErrorStates from "@/components/States/BudgetsErrorStates.js";
import DataLoading from "@/components/States/DataLoading.js";

export const RightSizingChart = () => {
  const { data, isError, isLoading } = useAllCloudStatistics();

  if (isLoading) {
    return (
      <div>
        <BudgetsErrorStates />
      </div>
    );
  } else {
    return (
      <>
        <div
          className="sec_cont container-fluid"
          style={{ display: "contents" }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="container-fluid text_Cont">
              <span className="text-css">
                Individual Cloud VM Recommendations
              </span>
            </div>
            <div className="container-fluid long-content-css">
              Explore In-Depth Recommendations for VM Right Sizing and Insights
              into Resource Utilization. Unlock FinOps Potential by Aligning
              Infrastructure with Actual Workload Demands. Delve into Actionable
              Data for Informed Decision-Making and Efficient Resource
              Allocation.
            </div>
          </div>
        </div>
        <div className="row">
          <CardData statistics={data?.services.aws} logo={awslogo} />
          <CardData statistics={data?.services.azure} logo={azurelogo} />
        </div>
      </>
    );
  }
};

export default RightSizingChart;
export const CardData = ({ statistics, logo }) => {
  const allData = statistics;

  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (Math.abs(count) >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (Math.abs(count) >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  const TotalSavingPercentage = Number(allData?.savings_percent || 0).toFixed(
    2
  );
  const TotalSaving = formatCount(TotalSavingPercentage);

  const projectedSavings = Number(allData?.projected_savings_pm || 0).toFixed(
    2
  );
  const projectedSav = formatCount(Math.abs(projectedSavings));

  const savingAvailable = Number(allData?.downsize_savings_pm || 0).toFixed(2);
  const savingAvai = formatCount(Math.abs(savingAvailable));

  const down_cost = Number(allData?.downsize_cost_pm || 0).toFixed(2);
  const down_costpm = formatCount(Math.abs(down_cost));

  const upsizeCount = Number(allData?.upsize_count || 0);
  const upsizeCountpm = formatCount(upsizeCount);

  const downsiszeCount = Number(allData?.downsize_count || 0);
  const downsiszeCountpm = formatCount(downsiszeCount);

  const upsizeSaving = Number(allData?.upsize_savings_pm || 0).toFixed(2);
  const upsizeSavingpm = formatCount(Math.abs(upsizeSaving));

  const upsizeCost = Number(allData?.upsize_cost_pm || 0).toFixed(2);
  const upsizeCostpm = formatCount(Math.abs(upsizeCost));

  const projectedSavingri = (allData?.projected_savings_ri_pm || 0).toFixed(2);
  const projectedSavingripm = formatCount(Math.abs(projectedSavingri));

  return (
    <div className="chart-main-Cont-sec col mb-4">
      <div
        className="h-100"
        style={{ border: "1px solid #DEE2E6", borderRadius: "8px" }}
      >
        <div className="card-body d-flex flex-column p-4">
          <div
            className="d-flex justify-content-between  mb-3"
            style={{ height: "138px" }}
          >
            <div
              className=" container d-flex flex-column align-items-start aws-logo"
              style={{ padding: "0px" }}
            >
              <img src={logo} className="logo-css" alt="logo" />
              <div className="mt-3">
                <div className="sav-text">Savings Available</div>
                <div className="savings-first-value">{TotalSaving}%</div>
                <div className="aws-savings">
                  <div className="saving-text">
                    {projectedSavings >= 0
                      ? `$${projectedSav}`
                      : `-$${projectedSav}`}

                    {/* {projectedSav >= 0 && `$${projectedSav}`}
                    {projectedSav < 0 && `-$${Math.abs(projectedSav)}`} */}

                    {/* ${(allData?.projected_savings_pm || 0).toFixed(2)} */}
                  </div>
                  {/* <div className="savings-first-value">
                    {allData?.totalSavinginPerc || 0}
                  </div>
                  <div className="savings-second-value">
                    {allData?.totalSavingDollar || 0}
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 p-0">
                <RightSizingPieChart mydata={allData} />
              </div>
            </div>

            <RightSizingPieChart
              downsizeCount={allData?.downsize_count || 0}
              upsizeCount={allData?.upsize_count || 0}
              // terminateCount={allData?.terminate_count || 0}
            />
          </div>
          <div className="line-vertical"></div>
          <div className="d-flex justify-content-center mt-3 gap-3">
            <div className="aws-sec-cont">
              <div className="range-line"></div>
              <div className="downsize-value-cont">
                <div className="Downsize-cont"> Downsize</div>
                <div className="doensize-value">{downsiszeCountpm}</div>
              </div>
              <div className="saving-avail-cont">
                <div className="sav-text">Savings Available</div>
                <div className="sav-val-cont">
                  {savingAvailable >= 0 ? `$${savingAvai}` : `-$${savingAvai}`}
                </div>
              </div>
              <div className="current-cost-div">
                <div className="cur-cost-text">Current Costs</div>
                <div className="cur-cost-val">
                  {down_cost >= 0 ? `$${down_costpm}` : `-$${down_costpm}`}
                </div>
              </div>
            </div>

            {/* <div className="aws-sec-cont">
              <div
                className="range-line"
                style={{ backgroundColor: "#D01A1A" }}
              ></div>
              <div className="downsize-value-cont">
                <div className="Downsize-cont">Terminate</div>
                <div className="doensize-value">
                  {allData?.terminate_count || 0}
                </div>
              </div>
              <div className="saving-avail-cont">
                <div className="sav-text">Savings Available</div>
                <div className="sav-val-cont">
                  ${((allData?.terminate_savings_pm || 0)).toFixed(2)}
                </div>
              </div>
              <div className="current-cost-div">
                <div className="cur-cost-text">Current Costs</div>
                <div className="cur-cost-val">
                  ${((allData?.terminate_cost_pm || 0)).toFixed(2)}
                </div>
              </div>
            </div> */}
            <div className="aws-sec-cont">
              <div
                className="range-line"
                style={{ backgroundColor: "#F6D662" }}
              ></div>
              <div className="downsize-value-cont">
                <div className="Downsize-cont">Upsize</div>
                <div className="doensize-value">{upsizeCountpm}</div>
              </div>
              <div className="saving-avail-cont">
                <div className="sav-text">Additional Cost</div>
                <div className="sav-val-cont" style={{ color: "#D01A1A" }}>
                  {upsizeSaving >= 0
                    ? `$${upsizeSavingpm}`
                    : `-$${upsizeSavingpm}`}
                </div>
              </div>
              <div className="current-cost-div">
                <div className="cur-cost-text">Current Costs</div>
                <div className="cur-cost-val">
                  {upsizeCost >= 0 ? `$${upsizeCostpm}` : `-$${upsizeCostpm}`}
                </div>
              </div>
            </div>
          </div>

          {/*RI FEATURE HAS TO BE REMOVED */}

          {/* <div className="d-flex justify-content-center mt-3 gap-3">
            <div className="aws-sec-cont">
              <div className="ri-saving-cont">RI Savings</div>
              <div className="saving-avail-cont">
                <div className="sav-text">Savings Available</div>
                <div className="sav-val-cont1">
                {projectedSavingri >= 0? `$${projectedSavingripm}`: `-$${projectedSavingripm}`}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
