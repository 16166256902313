import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

// export interface TotalStatsDbResponse {
//     rootAccountNumber: string;
//     totalCharges: string;
//     creditApplied: string;
//     currentBalance: string;
// }

/**
 * @typedef GetAllStatistics
 * @property {string} rootAccountNumber
 * @property {string} totalCharges
 * @property {string} creditApplied
 * @property {string} currentBalance
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-clouds' } service
 */

export const getStatsHeaderStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, accountType, billing_profile }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/${accountType}/total-stats`,
      {
        method: "POST",
        body: JSON.stringify({
          billing_profile,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (
      service === "aws" &&
      (!data?.data?.rootAccountNumber ||
        data?.data?.rootAccountNumber === "") &&
      (data?.data?.creditApplied === undefined ||
        data?.data?.creditApplied === 0) &&
      (data?.data?.currentBalance === undefined ||
        data?.data?.currentBalance === 0) &&
      (data?.data?.totalCharges === undefined || data?.data?.totalCharges === 0)
    ) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }

    if (
      service === "azure" &&
      (data?.data?.creditApplied === undefined ||
        data?.data?.creditApplied === null ||
        data?.data?.creditApplied === 0) &&
      (data?.data?.totalCharges === undefined ||
        data?.data?.totalCharges === null ||
        data?.data?.totalCharges === 0) &&
      (data?.data?.billedAmount === undefined ||
        data?.data?.billedAmount === null ||
        data?.data?.billedAmount === 0) &&
      (data?.data?.currentCreditBalance === undefined ||
        data?.data?.currentCreditBalance === null ||
        data?.data?.currentCreditBalance === 0)
    )
      if (billing_profile.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
      }

    return data;
  };
};
// {
//   "success": true,
//   "message": "",
//   "data": {
//       "rootAccountNumber": "3bfc6ea5-5bed-5df3-6c6e-d98ad71d3743:d5931ae1-fa13-4603-97f9-bfde25fc8ff5_2019-05-30",
//       "creditApplied": 300.00000000000006,
//       "currentBalance": 29548,
//       "totalCharges": 3000
//   }
// }

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatsHeaderStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatsHeaderStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getStatsHeaderStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
