import React from "react";
import TabsContainer from "./tabs-container.component";
import CostExplorerHeader from "./cost-explorer-header.component";

const CostExplorerContainer = ({
  selectedCustomTabs,
  setSelectedCustomTabs,
  selectedForecastTab,
  setSelectedForecastTab,
  service,
  range,
  setRange,
  toDate,
  fromDate,
  daysDifference,
  data,
}) => {
  return (
    <div className="HeaderMainDiv">
      <CostExplorerHeader
        service={service}
        range={range}
        setRange={setRange}
        toDate={toDate}
        fromDate={fromDate}
        daysDifference={daysDifference}
        data={data}
      />

      <TabsContainer
        service={service}
        selectedCustomTabs={selectedCustomTabs}
        setSelectedCustomTabs={setSelectedCustomTabs}
        selectedForecastTab={selectedForecastTab}
        setSelectedForecastTab={setSelectedForecastTab}
      />
    </div>
  );
};

export default CostExplorerContainer;
