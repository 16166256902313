import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAzureStatistics} azure
 * @property {GetAWSStatistics} aws
 */

/**
 * @typedef GetAzureStatistics
 * @property {string} resource_count
 * @property {string} month
 * @property {string} total_savings
 */

/**
 * @typedef GetAWSStatistics
 * @property {string} resource_count
 * @property {string} month
 * @property {string} total_savings
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'all-cloud' | 'aws' | 'gcp' | 'azure'} service
 * @property {string} fromDate
 * @property {string} toDate
 */

/**
 * Fetches access token for the code provided
 * @param {GetStatisticsReq} req Access code provided from SSO
 * @returns {Promise<GetAllStatistics>}
 */
export const getAllGraphStatistics = (token) => {
  return async ({
    service,
    toDate,
    fromDate,
    page,
    recordsPerPage,
  }) => {
    const response = await fetch(
      `${config.apiHost}/v1/${service}/savings/six-month-count?from=${fromDate}&to=${toDate}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      if (ERROR_STATUS_CODES.includes(response.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (response.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await response.json()).message);
    }

    const data = await response.json();

    if (data?.azure.length === 0 && data?.aws.length === 0) {
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      }
      throw new Error("No data available.");
    }

    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllGraphStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllGraphStatistics", req, ...deps],
    queryFn: () => getAllGraphStatistics(appContext.idToken)(req),
  });
  return query;
};
