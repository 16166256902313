import { AppContext } from "@/App.context";
import config from "@/config";
import { DownloadFile } from "@/lib/download-file";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef ArchivedRecommendation
 * @property {string} account
 * @property {string} resourceId
 * @property {string} region
 * @property {string} currentType
 * @property {string} recommendationType
 * @property {string} recommendedType
 * @property {string} currentType
 * @property {number} savings
 * @property {number} riSavings
 * @property {number} utilization_cpu
 * @property {number} utilization_mem
 * @property {number} utilization_disk
 * @property {number} cost
 */

/**
 * @typedef GetArchivedRecommendationsListRes
 * @property {ArchivedRecommendation[]} recommendations
 */

/**
 * @typedef GetArchivedRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetArchivedRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 * @property {'downsize' | 'upsize' | 'terminate'} recommendationType
 */

export const getArchivedRecommendationsList = (token) => {
  /**
   * @param {GetArchivedRecommendationsListReq} req
   * @returns {Promise<GetArchivedRecommendationsListRes>}
   */
  return async ({
    recommendationType,
    service,
  }) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/archived-download`,
      {
        method: "POST",
        body: JSON.stringify({
          recommendationType,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    const reader = recommendationsList.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `Archive-${recommendationType}-data.csv`;
    DownloadFile(blob, fileName);
    return await recommendationsList.json();
  };
};

/**
 * @param {GetArchivedRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useArchivedRecommendationsListDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: [
      "getArchivedRecommendationsList",
      appContext.idToken,
      req,
      ...deps,
    ],
    mutationFn: ({ signal }) =>
      getArchivedRecommendationsList(appContext.idToken)(req,signal),
  });
  return query;
};
