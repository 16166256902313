import React, { useContext, useEffect, useMemo } from "react";
import {RiContext, RiProvider } from "../ri.context";
import { useRiRecommendationStatistics } from "@/services/ri-recommendation/getTotalRiStatics.repo";
import { useNavigate } from "react-router-dom";
import RiRecommendation from "../components/Tab-Components/ri_recommendations.part";
import RiHeaderContainer from "../components/header.container";
import BulkRecommendation from "../components/Tab-Components/bulk_recommendations.part";
import ScopeInstance from "../components/Tab-Components/scope_instance_recommendations.part";

export default function AzureRiScreenComponent() {
  return (
    <RiProvider>
      <AzureRiScreens />
    </RiProvider>
  );
}

const AzureRiScreens = ({ service = "azure" }) => {
  const context = useContext(RiContext);

  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const {
    data: ridata
  } = useRiRecommendationStatistics(query);

  useEffect(() => {
    if (context?.eventkey === "RI Recommendations") {
      context?.setTab("ri");
    }
    if (context?.eventkey === "Bulk Recommendations") {
      context?.setTab("bulk");
    }
    if (context?.eventkey === "Scope & Instance Flexibility Recommendations") {
      context?.setTab("scope");
    }
  }, [context]);

  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    handleTagSelection();
  }, [context.tab]);

  const accountName = "Subscription";
  const instType = "Instance Type";

  return (
    <div>
      <RiHeaderContainer data={ridata} />
      {context?.eventkey === "RI Recommendations" && (
        <RiRecommendation
          service={service}
          accountName={accountName}
          instType={instType}
        />
      )}
      {context?.eventkey === "Bulk Recommendations" && (
        <BulkRecommendation
          service={service}
          accountName={accountName}
          instType={instType}
        />
      )}
      {context?.eventkey === "Scope & Instance Flexibility Recommendations" && (
        <ScopeInstance service={service} />
      )}
    </div>
  );
};
