import React from "react";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import { compareAsc, format, parse } from "date-fns";
import clsx from "clsx";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableErrorState from "@/components/States/TableErrorState";

const ForecastCostTable = ({
  data,
  accounts,
  isLoading,
  error,
  accountName,
}) => {
  const sortedData = (data || []).sort((a, b) => {
    const aDate = parse(a.month || a.formatted_month, "MMM''yy", new Date());
    const bDate = parse(a.month || b.formatted_month, "MMM''yy", new Date());
    return compareAsc(aDate, bDate);
  });
  const monthKeys =
    data && data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== "account")
      : [];

  return (
    <div className="table_main_container">
      {!isLoading && (data?.length > 0 || error) && (
        <div className="all_cloud_text mb-3">Costs and usage breakdown</div>
      )}
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {data?.length > 0 && (
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table className="table-hover" style={{ width: "100%" }}>
            <thead style={{ backgroundColor: "#f6f6f6" }}>
              <tr>
                <th className={clsx(styles.table_class, styles.align__left)}>
                  {accounts.length === 0
                    ? "Month"
                    : `${accountName}${accounts.length > 1 ? "s" : ""}`}
                </th>
                {accounts.length === 0 ? (
                  <>
                    {(data || []).map((item) => (
                      <th
                        className={styles.table_class}
                        key={item.formatted_month}
                      >
                        {item.formatted_month}
                      </th>
                    ))}
                  </>
                ) : (
                  <>
                    {(monthKeys || []).map((month) => (
                      <th className={styles.table_class} key={month}>
                        {month}
                      </th>
                    ))}
                  </>
                )}
              </tr>
            </thead>
            {accounts.length === 0 ? (
              <tbody>
                <tr>
                  <td className={clsx(styles.table_body, styles.align__left)}>
                    Forecasted Cost
                  </td>

                  {(data || []).map((item) => (
                    <td
                      key={item.formatted_month}
                      className={clsx(styles.table_body, styles.align__right)}
                    >
                      ${item.total_amount.toFixed(2) || "--"}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className={clsx(styles.table_body, styles.align__left)}>
                    Current Cost
                  </td>
                  {(data || []).map((item) => (
                    <td
                      key={item.formatted_month}
                      className={clsx(styles.table_body, styles.align__right)}
                    >
                      ${item.current_cost.toFixed(2) || "--"}
                    </td>
                  ))}
                </tr>
              </tbody>
            ) : (
              <tbody>
                {(data || []).map((item, index) => (
                  <tr key={index}>
                    <td
                      className={clsx(
                        styles.table_body_account,
                        styles.align__left
                      )}
                    >
                      {item.account}
                    </td>
                    {(monthKeys || []).map((month) => (
                      <td
                        key={month}
                        className={clsx(styles.table_body, styles.align__right)}
                      >
                        {item[month].toFixed(2) || 0}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
            <tfoot>
              <tr className={styles.tFoot}>
                <td colSpan={1000} style={{ border: "none", padding: "8px" }}>
                  *Figures in USD
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default ForecastCostTable;
