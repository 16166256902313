import React from 'react'
import styles from "@/components/States/states.module.css"
import clsx from 'clsx';
import NoEntry from "@/assets/img/noEntry.svg"

const UpSizeVMRecommendations = ({name}) => {
  return (
    <div >
         <div className={clsx(styles.main_container)}>
      <div className={styles.img_Container}>
        <img src={NoEntry} width={"32px"} height={"32px"} alt="" />
      </div>
      <div className={styles.sec_container}>
        <div className={styles.text_css}>No {name} Recommendations</div>
        
        {/* </div> */}
      </div>
    </div>
    </div>
  )
}

export default UpSizeVMRecommendations