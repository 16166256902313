import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetTotalCountStatistics[]} data
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {String} id
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

export const getAccountBudgets = (token) => {
  return async ({ scope ,management_group, subscriptionid, service }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/budget/get-scope?scope=${scope}`,
      {
        method: "POST",
        body:JSON.stringify({
          scope: scope,
          management_group:management_group || "null" ,
          subscriptionId: subscriptionid || "null"

        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await owners.json();
  }
}
export const getAWSAccountBudgets = (token) => {
  return async ({service,scope }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/budget/accounts?scope=${scope}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await owners.json();
  }
}

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseAllAccountStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAccountBudgets", req, ...deps],
    queryFn: () => getAccountBudgets(appContext.idToken)(req),
  });
  return query;
};
export const UseAllAWSAccountStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAWSAccountBudgets", req, ...deps],
    queryFn: () => getAWSAccountBudgets(appContext.idToken)(req),
  });
  return query;
};
