import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef GetRecommendationsByIdReq
 * @property {'aws' | 'gcp' | 'azure'} service
 * @property {string} resourceId
 */

/**
 * @typedef GetRecommendationsByIdRes
 * @property {*} recommendation
 */

export const getRecommendationById = (token) => {
  /**
   * @param {GetRecommendationsByIdReq} req
   * @returns {GetRecommendationsByIdRes}
   */
  return async (req) => {
    if (!req.resourceId) return null;
    const body = { recommendation_id: req.resourceId ,actionType:req.action_type};
    const recommendationId = await fetch(
      `${config.apiHost}/v1/${req.service}/cost-recommendations/listing-detail`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(body),
      }
    );

    if (!recommendationId.ok) {
      if (ERROR_STATUS_CODES.includes(recommendationId.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (recommendationId.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await recommendationId.json()).message);
    }
    const res = await recommendationId.json();
    if (Object.keys(res || {}).length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetRecommendationsByIdReq} req
 */
export const useCostRecommendationById = (req) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getRecommendationById",
      appContext.idToken,
      req.service,
      req.resourceId,
    ],
    queryFn: () => getRecommendationById(appContext.idToken)(req),
  });
  return query;
};
