import React, { useContext, useState } from "react";
import styles from "@/page-components/search-module/search.module.css";
import SearchHeader from "./search-components/search-header.part";
import BarGraphSearch from "./search-components/search-bar-graph.part";
import MainFilterComponent from "./search-components/filter-search-components.part";
import SidebarSummery from "./search-components/sidebar-summery.part";
import CostByServices from "./search-components/cost-services.part";
import { SearchContext, SearchProvider } from "./search-context";
import TagsResourceTable from "./search-components/tags-resource-table.part";
import ResourceCollapseTable from "./search-components/resource-collapse-table.part";

const SearchComponents = ({ service }) => {
  return (
    <SearchProvider>
      <SearchPart service={service} />
    </SearchProvider>
  );
};

const SearchPart = ({ service }) => {
  const context = useContext(SearchContext);

  return (
    <div className={styles.search_component}>
      <MainFilterComponent service={service} />
      <SearchHeader service={service} />
      <div className={styles.body_section}>
        <div className={styles.costContainer}>
          <BarGraphSearch service={service} />
          {context?.type !== "resource" && (
            <div className={styles.costSpendSection}>
              <CostByServices service={service} />
            </div>
          )}

          {context?.type === "resource" && (
            <div className={styles.resource_collapse}>
              <ResourceCollapseTable service={service} />
              <TagsResourceTable service={service} />
            </div>
          )}
          <div></div>
        </div>
        <div className={styles.bar_table_sidebar_summery}>
          <SidebarSummery service={service} />
        </div>
      </div>
    </div>
  );
};

export default SearchComponents;
