import React from "react";
import styles from "./states.module.css";
import loadingImg from "@/assets/img/loading-img.svg";
import reloadImg from "@/assets/img/reload-img.svg";
import { Button } from "react-bootstrap";
 
const DataLoading = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.img_container}>
        <img src={loadingImg} alt="" />
      </div>
      <div className={styles.sec_container}>
        {/* <div className="sec_container-first"> */}
        <div className={styles.text_css}>Data Loading Issue</div>
        <div className={styles.sec_text_css}>
          Oops! We encountered a problem loading your data. Please ensure a
          stable internet connection and try again. If the issue persists,
          contact support.
        </div>
        {/* </div> */}
 
        {/* here provide gap 10px need to change in css */}
        <div className={styles.sec_container}>
          <Button style={{width:"100px", height:"40px", backgroundColor:"transparent" , outline:"none", border:"none"}} onClick={()=>window.location.reload()}>
            <img src={reloadImg} alt="" />
 
          {/* this div using same css as sec-text-css */}
 
          <span className={styles.reload_text_css}>Reload</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
 
export default DataLoading;