import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {Number} totalCost
 * @property {Getservicefamily[]} serviceFamilies
 */

/**
 * @typedef Getservicefamily
 * @property {string} serviceFamily
 * @property {String} totalCost
 * @property {Getservices[]} services
 */

/**
 * @typedef Getservices
 * @property {string} service
 * @property {string} cloud
 * @property {string} cost
 */

/**
 * @typedef GetAllStatisticsRes
 * @property { GetAllStatistics[] } result
 */
/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 */

export const getallcostUsageStats = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async ({ service, ...rest }, signal) => {
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/cost/service-cost-breakdown`,
      {
        method: "POST",
        body: JSON.stringify(rest),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!statistics.ok) {
      if (ERROR_STATUS_CODES.includes(statistics.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (statistics.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await statistics.json()).message);
    }

    const response = await statistics.json();
   
    if ( response?.totalCost === 0 &&
      response?.serviceFamilies?.length === 0) {
       
        throw new Error(API_NO_DATA_FOUND_ERROR);
      
    }
    
      return response;
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useCostUsageStatics = ({ showData, ...req }, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    enabled: Boolean(showData),
    queryKey: ["getallcostUsageStats", req, ...deps],
    queryFn: ({ signal }) =>
      getallcostUsageStats(appContext.idToken)(req, signal),
  });
  return query;
};
