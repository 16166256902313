import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import info from "@/assets/img/info.svg";
import { useState } from "react";
import { useCreateBudget } from "@/services/budget/setBudgets.repo";
import { UseAllAWSAccountStatics } from "@/services/budget/getBugetsAccount.repo";
import { useStatusBudget } from "@/services/budget/setStatus.repo";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CreateAwsBudget, SelectField } from "./helper";
import { joiResolver } from "@hookform/resolvers/joi";
import BudgetFilterComponent from "./FilterComponent";
import ToastComponent from "./toast";
import { BudgetContext } from "../budget.context";
import forecastImage from "@/assets/img/forecastImage.svg";
import reload from "@/assets/img/reloadInBudget.svg";
import greenBarGraph from "@/assets/img/greenBarGraph.svg";
import { UseForecastValue } from "@/services/budget/getForecastValue.repo";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { Spinner } from "react-bootstrap";

const AddNewWidgets = ({
  onClose,
  service,
  name,
  alert,
  Title,
  setRecordId,
  recordId,
  refetch,
  onOpen,
  description,
}) => {
  const context = useContext(BudgetContext);

  const query = useMemo(() => {
    return {
      scope: context?.selectedGroupAws,
      service,
    };
  }, [context?.selectedGroupAws, service]);

  const [recId, setRecId] = useState(" ");

  const handleError = (errors) => {};
  const handleAccountChange = (selected) => {
    context?.setSelectedAccounts(selected);
  };

  const { data: totaldata, isLoading } = UseAllAWSAccountStatics(query);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
    watch,
    resetField,
  } = useForm({
    resolver: joiResolver(CreateAwsBudget(context)),
    defaultValues: {
      CHARGE_TYPE: "COST",
      NOTIFICATION_TYPE: "ACTUAL",
      ...(context?.selectedGroupAws === "organization" &&
      totaldata?.data?.length === 1
        ? { ACCOUNT_ID: totaldata?.data[0]?.id }
        : {}),
    },
  });
  let {
    mutate: Id,
    data,
    reset: createReset,
    isError,
    error: createError,
    isSuccess: successMessage,
    isPending,
  } = useCreateBudget();
  
  let { mutate: stausAPI, data: status } = useStatusBudget();

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedTagValues, setSelectedTagValues] = useState([]);
  const [extractData, setExtractData] = useState([]);
  const [extractTagsData, setExtractTagsData] = useState([]);
  const [message, setMessage] = useState();
  const [toast, setToast] = useState(false);
  const [isForecastClicked, setIsForecastClicked] = useState(false);
  const [EndDatedisable, setEndDatedisable] = useState(true);


  const filters = useMemo(() => {
    const modifiedFormat = (selected, type, data) => {
      return Object.keys(selected)
        .filter((e) => e !== "Dimension Values" && e !== "Tag Values")
        .map((key) => ({
          name:
            data === extractData
              ? data.filter((names) => names.id === key).map((e) => e.name)[0]
              : key,
          values: selected[key].map(item => item.id),
        }));
    };

    return {
      dimentions: modifiedFormat(selectedValues, "dimension", extractData),
      tags: modifiedFormat(selectedTagValues, "tags", extractTagsData),
    };
  }, [selectedValues, selectedTagValues, extractData, extractTagsData]);

  const clearFilterData = () => {
    setSelectedTagValues([]);
    setSelectedValues([]);
  };

  useEffect(() => {
    if (data?.data?.RECORD_ID) {
      setRecordId([data?.data?.RECORD_ID]);
    }
  }, [setRecordId, data?.data?.RECORD_ID]);

  useEffect(() => {
    if (createError && createError.message) {
      const regex = /"([^"]+)".+$/;
      const matches = createError.message.match(regex);
      if (matches && matches.length >= 2) {
        const fieldName = matches[1];
        const errorMessage = matches[0];
        setError(fieldName, { type: "server", message: errorMessage });
      } else {
        let START_DATE;
        setError(START_DATE, { type: "server", message: createError.message });
      }
    }
  }, [createError, setError]);

  useEffect(() => {
    let intervalId;
    let timeoutId;
    createReset();
    reset();
    clearFilterData();
    context?.setSelectedAccounts("");
    context?.setSelectedEmail("");
    setIsForecastClicked(false)
    if (recordId && recordId.length > 0) {
      intervalId = setInterval(() => {
        stausAPI({ body: { budgets: recordId }, service: service });
      }, 10000);
      createReset();
      reset();
      clearFilterData();
      context?.setSelectedAccounts("");
      context?.setSelectedEmail("");
      setEndDatedisable(true)
      setIsForecastClicked(false)
      timeoutId = setTimeout(() => refetch(), 10000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [service, recordId, reset, createReset]);

  useEffect(() => {
    let timeoutId;
    if (status && status?.status && status?.status.length > 0) {
      const statusData = status?.status[0].status?.budget_status?.toLowerCase();

      if (statusData === "completed" || statusData === "failed") {
        setRecordId([]);
        setRecId(" ");
        onClose(false);
        createReset();
        reset();
        clearFilterData();
      }
    }
  }, [setRecordId, status, createReset, reset, onClose]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;

      if (value) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

        if (!isValidEmail) {
          setError("ALERTS_RECEIPIENTS", {
            type: "manual",
            message: "Please enter a valid email address",
          });
        } else {
          setError("ALERTS_RECEIPIENTS", null);
          context?.setSelectedEmail((prevRecipients) => {
            const updatedRecipients = [...prevRecipients, value];
            setValue("ALERTS_RECEIPIENTS", updatedRecipients, {
              shouldValidate: true,
            });
            return updatedRecipients;
          });
          event.target.value = "";
        }
      }
    }
  };
  const handleRemoveEmail = (index) => {
    context?.setSelectedEmail((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== index)
    );
  };
  const onSubmit = async (data2) => {
    data2.START_DATE = format(data2.START_DATE, "yyyy-MM-dd");
    data2.EXPIRATION_DATE = format(data2?.EXPIRATION_DATE, "yyyy-MM-dd");
    delete data2.Dimension;
    delete data2.Tags;

    Id({
      body: {
        ...data2,
        CHARGE_TYPE: "COST",
        NOTIFICATION_TYPE: "ACTUAL",
        filters,
        ...(context?.selectedGroupAws === "organization" &&
        totaldata?.data?.length === 1
          ? { ACCOUNT_ID: totaldata?.data[0]?.id }
          : {}),
      },
      service: service,
    });

    // if (!isError || !createError) {
    //   onClose(false);
    // }

    // setTimeout(() => {
    //   refetch();
    // }, 1000);
    // onClose(false);
  };

  const handleCloseOverlay = () => {
    onClose(false);
    createReset();
    reset();
    clearFilterData();
    context?.setSelectedAccounts("");
    setEndDatedisable(true)
    context?.setSelectedGroupAws("organization");
    context?.setSelectedEmail("");
    setIsForecastClicked(false)
  };

  useEffect(() => {
    reset({
      scope: context?.selectedGroupAws,
      CHARGE_TYPE: "COST",
      THRESHOLD_TYPE: "",
      NAME: "",
      AMOUNT: "",
      THRESHOLD: "",
      ALERTS_RECEIPIENTS: "",
    });
    context?.setSelectedAccounts("");
    reset();
    clearFilterData();
    setEndDatedisable(true)
  }, [context?.selectedGroupAws]);

  useEffect(() => {
    if (totaldata?.data?.length === 1) {
      context?.setSelectedAccounts(totaldata?.data[0]?.id);
    }
  }, [context, context.selectedGroupAws, totaldata?.data]);

  const selectedPeriod = watch("PERIOD");

  const getMinExpirationDate = (startDate) => {
    if (!startDate) return null;

    const start = new Date(startDate);
    if (selectedPeriod === "MONTHLY") {
      return new Date(start.getFullYear(), start.getMonth() + 1, 0);
    } else if (selectedPeriod === "QUARTERLY") {
      return new Date(start.getFullYear(), start.getMonth() + 3, 0);
    } else if (selectedPeriod === "ANNUALLY") {
      return new Date(start.getFullYear() + 1, start.getMonth(), 0);
    }
    return null;
  };
  useEffect(() => {
    setValue("EXPIRATION_DATE", "");
  }, [selectedPeriod, setValue]);
  // ----------------------------------------------------------------------------------

  const startDate = watch("START_DATE");
  const expirationDate = watch("EXPIRATION_DATE");
  // const account_id = watch("ACCOUNT_ID");

  useEffect(()=>{
    if(startDate && selectedPeriod ){
      setEndDatedisable(false)
    }
  },[selectedPeriod, startDate])



  const isFormCompleted =
    selectedPeriod && startDate && expirationDate && context?.SelectedAccounts;

    useEffect(()=>{
      setIsForecastClicked(false)
    },[selectedPeriod,startDate,expirationDate,context?.SelectedAccounts])


  const periodDisplayMapping = {
    MONTHLY: "Month",
    QUARTERLY: "Quarter",
    ANNUALLY: "Annual",
  };

  const forecastQuery = useMemo(() => {
    return {
      service,
      period: selectedPeriod,
      start_date: startDate,
      expiration_date: expirationDate,
      account_id: context?.SelectedAccounts,
      filters,
    };
  }, [context?.SelectedAccounts, expirationDate, filters, selectedPeriod, service, startDate]);

  const {
    data: forecastData,
    mutate: refetchForecastData,
    
    // refetch: refetchForecastData,
    isSuccess: forecastSuccess,
    isPending:forecastLoading,
    error: forecastError,
  } = UseForecastValue(isForecastClicked ? forecastQuery : null);

  useEffect(() => {
    if (isForecastClicked) {
      refetchForecastData();
    }
  }, [isForecastClicked, refetchForecastData]);
  
  const handleOpenForecast = () => {
    setIsForecastClicked(true);
    refetchForecastData();
  };

  const handleReload = () => {
    refetchForecastData();
  };

  const formattedForecastCost = formatCount(
    Number(forecastData?.data?.total_forecast?.Amount || 0)
  );


  useEffect(() => {
    if (successMessage && data !== undefined) {
      setEndDatedisable(true)
      setToast(true);
      setMessage({ message: data?.message, type: "success" });
      setTimeout(() => onClose(false), 3000);
    } else if (isError) {
      setToast(true);
      setMessage({ message: createError?.message, type: "Anamaly" });
    }
  }, [successMessage, data, isError, createError, onClose, setMessage]);
  
  useEffect(() => {
    if (isForecastClicked && forecastError && !forecastSuccess) {
      setToast(true);
      setMessage({ message: "Unable to get forecast info.", type: "Anamaly" });
      setTimeout(() => setToast(false), 3000);
    }
    if (isForecastClicked && forecastSuccess) {
      setToast(false);
    }
  }, [isForecastClicked, forecastError, forecastSuccess, setMessage]);
   
  return (
    <>
      {toast && (
        <ToastComponent message={message} toast={toast} setToast={setToast} />
      )}
      <Offcanvas
        style={{ width: "53%" }}
        show={onOpen}
        placement="end"
        onHide={handleCloseOverlay}
        backdrop="static"
      >
        <form
          className="mybudgetform"
          onSubmit={handleSubmit(onSubmit, handleError)}
        >
          <div className="budget_main_div">
            {/* -----------------create budget name------------------------------------------------------------ */}
            <div className="budget_first_box">
              <Offcanvas.Header id="createBudgetHeader" closeButton>
                <div className="create_budget">
                  <Offcanvas.Title>
                    <div className="create_budget_name">{Title}</div>
                  </Offcanvas.Title>
                </div>
              </Offcanvas.Header>
              <p className="create_budget_disc">
                Establish a financial plan and configure notifications to assist
                you in tracking your expenditures.
              </p>
            </div>
            <Offcanvas.Body id="createBudgetBody">
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Account</div>
                  <div className="budget_discription">{description}</div>
                </div>
                <span className="radioButtonContainer">
                  <RadioButton
                    name="scope"
                    value="organization"
                    title="Organization"
                    control={control}
                  />
                  <RadioButton
                    name="scope"
                    value="account"
                    title="Account"
                    control={control}
                  />
                </span>
                <SelectField
                  control={control}
                  title={name}
                  placeholder={name}
                  rules={errors.ACCOUNT_ID}
                  fieldName={"ACCOUNT_ID"}
                  service={service}
                  commonFieldData={(totaldata?.data || []).map((item) => item)}
                  isLoading={isLoading}
                  onSelect={handleAccountChange}
                  disableOptionAWs={true}
                />
              </div>
              {context?.SelectedAccounts !== "" && (
                <BudgetFilterComponent
                  service={service}
                  rules={errors}
                  control={control}
                  selectedTagValues={selectedTagValues}
                  selectedValues={selectedValues}
                  setSelectedTagValues={setSelectedTagValues}
                  setSelectedValues={setSelectedValues}
                  extractTagsData={extractTagsData}
                  setExtractTagsData={setSelectedTagValues}
                  setExtractData={setExtractData}
                  resetField={resetField}
                  clearFilterData={clearFilterData}
                />
              )}
              {/*- ------------------------------------------Name, period,start date and end date---------------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Details</div>
                  <div className="budget_discription">
                    Give your budget a unique name. Select the time window it
                    analyses during each evaluation period, its expiration date
                    and the amount.
                  </div>
                </div>
                <div className="charge_select">
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Name
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>

                    <div>
                      <input
                        className="input_box_css"
                        name="NAME"
                        placeholder="Enter A Unique Name"
                        {...register("NAME")}
                      />
                    </div>
                    {errors.NAME && (
                      <span className="budgetsError">
                        {errors.NAME.message}
                      </span>
                    )}
                  </div>
                  <span className="semicolon_css"></span>

                  <SelectField
                    control={control}
                    rules={errors.PERIOD}
                    title={"Period"}
                    placeholder={"Select"}
                    fieldName="PERIOD"
                    service={service}
                    commonFieldData={[
                      { id: "MONTHLY", name: "Monthly" },
                      { id: "QUARTERLY", name: "Quarterly" },
                      { id: "ANNUALLY", name: "Annually" },
                    ]}
                  />
                </div>
                <div className="charge_select">
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Start Date
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="START_DATE"
                        rules={errors.START_DATE}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              selected={field.value}
                              // onChange={(date) => field.onChange(date)}
                              onChange={(date) => {
                                const firstDayOfMonth = new Date(
                                  date.getFullYear(),
                                  date.getMonth(),
                                  1
                                );
                                field.onChange(firstDayOfMonth);
                              }}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="Enter Start Date"
                              className="input_box_css"
                            />
                            {errors.START_DATE && (
                              <p className="budgetsError">
                                {errors.START_DATE.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <span className="semicolon_css"></span>
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Expiration Date{" "}
                      <span className="astriek_css">
                        <b>*</b>
                      </span>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="EXPIRATION_DATE"
                        rules={errors.EXPIRATION_DATE}
                        render={({ field }) => (
                          <>
                            <DatePicker
                            disabled = {EndDatedisable}
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="Enter End Date"
                              className="input_box_css"
                              minDate={getMinExpirationDate(
                                watch("START_DATE")
                              )}
                            />
                            {errors.EXPIRATION_DATE && (
                              <p className="budgetsError">
                                {errors.EXPIRATION_DATE.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------amount section------------------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Amount</div>
                  <div className="budget_discription">
                    Give your budget amount threshold
                  </div>
                </div>
                <div className="charge_select" style={{ width: "100%", alignItems:"flex-end" }}>
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Amount ($){" "}
                      <span className="astriek_css">
                        <b>*</b>
                      </span>{" "}
                    </div>
                    <div>
                      <input
                        className="input_box_css"
                        name="AMOUNT"
                        placeholder="Enter amount"
                        {...register("AMOUNT")}
                      />
                      {errors.AMOUNT && (
                        <p className="budgetsError">{errors.AMOUNT.message}</p>
                      )}
                    </div>
                  </div>
                  <span className="semicolon_css"></span>
                  {isFormCompleted && (
                    <>
                      {!isForecastClicked ? (
                        <div
                          className="fore_casted"
                          style={{
                            marginBottom: errors.AMOUNT ? "19px" : "0px",
                            cursor: "pointer"
                          }}
                          onClick={handleOpenForecast}
                        >
                          <img src={forecastImage} alt=""></img>
                          <span
                            className="forecast_name"
                            style={{ color: "#fff" }}
                          >
                            Check Forecasted Cost
                          </span>
                        </div>
                      ) : (
                        <div
                          className="forecast_data"
                          style={{ width: "100%",
                            marginBottom: errors.AMOUNT ? "19px" : "0px", }}
                        >
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div className="for_cast">
                              <img src={greenBarGraph} alt=""></img>
                              <span className="forcasted_cost">
                                Forecasted Cost
                              </span>
                            </div>

                            {forecastLoading && (
                              <Spinner style={{width:"17px",height:"17px",color:"grey"}}/>
                            )}
                            {!forecastLoading && (
                            <img
                            style={{cursor:"pointer"}}
                              src={reload}
                              alt=""
                              onClick={handleReload}
                            ></img>
                            )}

                          </div>
                          <div>
                            <span className="forecast_value">
                              {" "}
                              {formattedForecastCost}
                            </span>
                            <span className="period">
                              /{periodDisplayMapping[selectedPeriod] || ""}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* -------------------------------------threshold section-------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">
                    {alert}
                    <span className="astriek_css">
                      <b>*</b>
                    </span>
                  </div>
                  <div className="budget_discription">
                    Configure alert conditions and send email notifications
                    based on your spend.
                  </div>
                </div>
                <div className="charge_select">
                  <SelectField
                    control={control}
                    title={"Notification Type"}
                    rules={errors.NOTIFICATION_TYPE}
                    service={service}
                    placeholder={"Select type"}
                    fieldName="NOTIFICATION_TYPE"
                    commonFieldData={[{ id: "ACTUAL", name: "Actual" }]}
                    disableOptionAWs={true}
                  />
                  <span className="semicolon_css"></span>
                  <SelectField
                    control={control}
                    title={"Comparison Operator"}
                    rules={errors.COMPARISON_OPERATOR}
                    placeholder={"Select"}
                    fieldName="COMPARISON_OPERATOR"
                    service={service}
                    commonFieldData={[
                      { id: "GREATER_THAN", name: "Greater Than" },
                      { id: "LESS_THAN", name: "Less Than" },
                      { id: "EQUAL_TO", name: "Equal To" },
                    ]}
                  />
                </div>

                <div className="charge_select">
                  <SelectField
                    control={control}
                    title={"Threshold Type"}
                    placeholder={"Select type"}
                    fieldName="THRESHOLD_TYPE"
                    rules={errors.THRESHOLD_TYPE}
                    service={service}
                    commonFieldData={[
                      { id: "PERCENTAGE", name: "Percentage" },
                      { id: "ABSOLUTE_VALUE", name: "Absolute Value" },
                    ]}
                  />

                  <span className="semicolon_css"> </span>
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Threshold
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>
                    <div>
                      <input
                        type="number"
                        className="input_box_css"
                        name="THRESHOLD"
                        placeholder="Select type"
                        {...register("THRESHOLD")}
                      />
                      {errors.THRESHOLD && (
                        <p className="budgetsError">
                          {errors.THRESHOLD.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">
                    Alert recipients (email)
                    <span className="astriek_css">
                      <b> *</b>
                    </span>
                  </div>
                </div>
                <div className="accountid_box">
                  <div className="accountid_name"></div>
                  <div>
                    <input
                      className="input_box_css"
                      name="ALERTS_RECEIPIENTS"
                      placeholder="example@email.com"
                      {...register("ALERTS_RECEIPIENTS")}
                    />
                    {errors.ALERTS_RECEIPIENTS && (
                      <p className="budgetsError">
                        {errors.ALERTS_RECEIPIENTS.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* ----------------------------------learn more section------------------------------------------ */}
              <div className="learn_more_box">
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  <span className="learn_more_css">
                    Your budget evaluation will begin in a few hours.
                  </span>
                  <span className="highlighted_name">Learn More</span>
                </div>
              </div>

              <div className="button_box">
                <div className="cancel_create_button">
                  <button
                    type="button"
                    style={{ cursor: "pointer" }}
                    className="cancel_button"
                    onClick={() => {
                      handleCloseOverlay();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="create_button">
                    {isPending ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </div>
        </form>
      </Offcanvas>
    </>
  );
};

export const RadioButton = ({ control, name, title, value }) => {
  const context = useContext(BudgetContext);

  return (
    <>
      <div className="radioButtonSection">
        <Controller
          name={name}
          control={control}
          defaultValue={context?.selectedGroupAws}
          render={({ field }) => (
            <>
              <input
                type="radio"
                name={name}
                id={value}
                value={value}
                checked={field?.value === value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  context?.setSelectedGroupAws(e.target.value);
                }}
              />
              <label>{title}</label>
            </>
          )}
        />
      </div>
    </>
  );
};

export default AddNewWidgets;
