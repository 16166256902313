import React, { useMemo, useContext } from "react";
import BodySection from "../components/bodysection.part";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "../snapshots.module.css";
import { useState } from "react";
import AWSHeaderSection from "../components/awsHeader.part";
import { SnapshotProvider } from "../snap.contex";
import AchieveBodySection from "../components/acchieveBodysection.part";
import styles from "../snapshots.module.css";
import clsx from "clsx";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import DataLoading from "@/components/States/DataLoading";
import { SnapshotContext } from "../snap.contex";
import { useNavigate } from "react-router-dom";
import TabsComponent from "../components/state_change/tabs";
import HeaderState from "@/components/States/HeaderState";
import { UseArchiveheaderStatics } from "@/services/snapshots/getarchiveHeaderStatics.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const AzurePage = ({ service = "azure" }) => {
  return (
    <SnapshotProvider>
      <AzureSnapshotPage service={service} />
    </SnapshotProvider>
  );
};

const AzureSnapshotPage = ({ service = "azure" }) => {
  const context = useContext(SnapshotContext);
  // const query = useMemo(() => {
  //   return {
  //     service,
  //   };
  // }, [service]);
  const query = useMemo(() => {
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }
    return {
      service,

      filters,
    };
  }, [
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
  ]);

  const navigate = useNavigate();

  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchStatistics,
  } = useHeaderStatistics(query);

  const {
    data: myarchiveData,
    isLoading: archiveLoading,
    isError: archiveError,
    error: ErrorArchive,
    refetch: refetchStatisticsArchive,
  } = UseArchiveheaderStatics(query);

  const [selectedOption, setSelectedOption] = useState("Active");
  const handleDropdownChange = (eventKey) => {
    setSelectedOption(eventKey);
  };
  return (
    <>
    {isLoading && (
      <AllPageLoading/>
    )}

    {!isLoading && (

      <>
      
      
      <div>
        <div
          className="container-fluid main-container"
          style={{ paddingBottom: "0px" }}
        >
          <div className=" Inner_main_container">
            <div
              className={styles.inner_first_container}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={styles.inner_first_container_text}>Snapshots</div>
              <DropdownButton
                id={styles.snap_dropdown_header}
                title={selectedOption}
                onSelect={handleDropdownChange}
              >
                <Dropdown.Item
                  id={styles.dropdown_item}
                  as="button"
                  eventKey="Active"
                  onClick={handleTagSelection}
                >
                  Active
                </Dropdown.Item>
                <Dropdown.Item
                  id={styles.dropdown_item}
                  as="button"
                  eventKey="Archive"
                  onClick={handleTagSelection}
                >
                  Archive
                </Dropdown.Item>
              </DropdownButton>
            </div>

            {selectedOption === "Active" && (
              <AWSHeaderSection
                service="azure"
                data={data}
                selectedOption={selectedOption}
                error={error}
                isLoading={isLoading}
              />
            )}

            {selectedOption === "Archive" && (
              <AWSHeaderSection
                service="azure"
                data={myarchiveData}
                error={ErrorArchive}
                isLoading={archiveLoading}
              />
            )}

            <div
              className={clsx(
                "contnainer-fluid ",
                styles.inner_third_container
              )}
            >
              {selectedOption == "Active" && <TabsComponent />}
              <div
                className={clsx("container-fluid", styles.inner_contaier_text)}
              >
                Updated Today at 7:05AM
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedOption === "Active" && (
        <BodySection
          showFilterSection={true}
          service="azure"
          name="Subscription Name"
          refreshHeaders={() => {
            refetchStatistics();
            refetchStatisticsArchive();
          }}
        />
      )}
      {selectedOption === "Archive" && (
        <AchieveBodySection
          width="100%"
          service="azure"
          name="Subscription Name"
          refreshHeaders={() => {
            refetchStatisticsArchive();
            refetchStatistics();
          }}
        />
      )}
      </>
    )}


     
    </>
    // </SnapshotProvider>
  );
};

export default AzurePage;
