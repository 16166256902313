import React, { useContext, useMemo } from "react";
import { Tabs, Tab } from "react-bootstrap";
import styles from "@/page-components/RightSizing/rightSizing.module.css"
import {
  RECOMMENDATION_TYPES,
  RightSizingContext,
} from "../right-sizing.context";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";

const RecTabsComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(RightSizingContext);

  const defaultTab = useMemo(() => {
    const type = context?.recommendationType;
    return type ? type : RECOMMENDATION_TYPES.downsize;
  }, [context?.recommendationType]);

  const handleChangeTab = (e) => {
    searchParams.delete('page');
    searchParams.delete('itemsPerPage');
    setSearchParams(searchParams);
    context?.setRecommendationType(e);
  };

  return (
    <div className={styles.btn__Container}>
      <div className="btn-combo__Container">
        <Tabs
          defaultActiveKey={defaultTab}
          className={styles.mytabs_button}
          fill
          onSelect={(e) => handleChangeTab(e)}
          style={{ gap: "4px" }}
        >
          <Tab eventKey={RECOMMENDATION_TYPES.downsize} title="Downsize" />
          <Tab eventKey={RECOMMENDATION_TYPES.upsize} title="Upsize" />
          {context.viewType === "Active" && (
          <Tab eventKey={RECOMMENDATION_TYPES.discarded} title="Discarded" />
          )}
          {/* <Tab eventKey={RECOMMENDATION_TYPES.terminate} title="Terminate" /> */}
        </Tabs>
      </div>
      <div  className={clsx(
                  "container-fluid",
                  styles.Rec_inner_contaier_text
                )}>Updated Today at 7:05AM</div>
    </div>
  );
};

export default RecTabsComponent;
