import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/ai-recommendations/ai-recommend.module.css";
import clsx from "clsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import menuicon from "@/assets/img/menu.svg";
import downArrow from "@/assets/img/rightSizingSavingArrow.svg";
import upArrow from "@/assets/img/rightSizingCostArrow.svg";
import { AiRecommendContext } from "./ai-recom-context-file";
import { useDiskRecommendStats } from "@/services/ai-recommendation/getDiskRecommendation.repo";
import Pagination from "@/components/pagination/pagination.component";
import { useSearchParams } from "react-router-dom";
import download from "@/assets/img/download.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import DropdownComponent from "@/common/DropdownComponent";
import { useUsagePatternDownload } from "@/services/ai-recommendation/getUsagePatterns.repo";
import TableErrorState from "@/components/States/TableErrorState";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { useSafeSetState } from "rooks";

const DiskRecommendationsTable = ({ service, accountName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(AiRecommendContext);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [hideIcon, setHideIcon] = useState("");

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        resourcegroup: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        region: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    itemsPerPage,
    page,
    service,
  ]);

  const { data, isLoading, error } = useDiskRecommendStats(query);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      context?.setHideFilter(true);
    } else {
      context?.setHideFilter(false);
    }
  }, [context, error]);

  useEffect(() => {
    if (error && error.message === API_NO_DATA_FOUND_ERROR) {
      setHideIcon(true);
    } else {
      setHideIcon(false);
    }
  }, [error]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", "1");
      return updatedParams;
    });
  }, [
    context.selectAccounts,
    context.selectedResourceGroup,
    context.selectRegions,
  ]);

  const downloadQuery = useMemo(() => {
    return {
      service,
      tableName: "disk-recommendations",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        resourcegroup: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        region: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        owner: context?.selectOwner?.length
          ? context.selectOwner.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectOwner,
    context.selectRegions,
    context.selectedResourceGroup,
    service,
  ]);

  const { mutate: downloadMutate } = useUsagePatternDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      <div
        className={styles.table_stuc}
        style={{
          width: context?.hideFilter ? "100%" : "82%",
          paddingRight: context?.hideFilter ? "0px" : "24px",
          marginTop: context.hideFilter ? "0px" : "24px",
          background: context?.hideFilter
            ? "var(--Neutral-D9, #F8F9FA)"
            : "transparent",
          paddingBottom: context.hideFilter ? "0px" : "32px",
        }}
      >
        <div className={styles.usage_pattern_box}>
          {!context.hideFilter && (
            <span className={styles.usage_pattern_name}>
              Disk Recommendations
            </span>
          )}
          {!(context.hideFilter || hideIcon) && (
            <div className={styles.menu_icon_css1}>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
              >
                <span
                  className={styles.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <img src={downlaodVM} alt="" />
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>

        {service === "azure" && <>{isLoading && <TableErrorState />}</>}
        {error && (
          <div>
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
              }}
            />
          </div>
        )}
        {data?.data?.count > 0 && (
          <>
            <div style={{ overflowX: "auto", minWidth: "30%" }}>
              <table style={{ width: "100%" }} className="table-hover">
                <thead>
                  <tr>
                    <th className={styles.table_head_css}>{accountName}</th>
                    <th className={styles.table_head_css}>Resource Name/ID</th>
                    {service === "azure" && (
                      <th className={styles.table_head_css}>Resource Group</th>
                    )}

                    <th className={styles.table_head_css}>Region</th>
                    <th className={styles.table_head_css}>Disk Name</th>
                    <th className={styles.table_head_css}>Disk bursting</th>
                    <th className={styles.table_head_css}>Disk Size GB</th>
                    <th className={styles.table_head_css}>Max IOPS</th>
                    <th className={styles.table_head_css}>Max TP</th>
                    <th className={styles.table_head_css}>Max Used IOPS</th>
                    <th className={styles.table_head_css}>Max Used TP</th>
                    <th className={styles.table_head_css}>Recommendation</th>
                    <th className={styles.table_head_css}>
                      Recommended Disk Type
                    </th>
                    <th
                      className={styles.table_head_css}
                      style={{ textAlign: "right" }}
                    >
                      Savings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(data?.data?.DiskRecommendationList || []).map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item?.accountname}
                        >
                          {item.accountname || "--"}
                        </td>
                        {service === "azure" && (
                          <td
                            className={clsx(
                              styles.table_body_css,
                              styles.trunc_ai_css
                            )}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id={`tooltip-${index}`}
                                  className={styles.tooltip_css}
                                >
                                  {item.resourceid || "--"}
                                </Tooltip>
                              }
                            >
                              <div className={styles.describe_main}>
                                <span
                                  className={clsx(
                                    // styles.table_desc,
                                    styles.trunc_ai_css
                                  )}
                                >
                                  {item?.resourceid || "--"}
                                </span>
                              </div>
                            </OverlayTrigger>
                          </td>
                        )}
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item.resourcegroup}
                        >
                          {item.resourcegroup || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item.disk_region}
                        >
                          {item.disk_region || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item?.datadisk_name}
                        >
                          {item?.datadisk_name || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {item?.disk_bursting || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {(item?.disk_size_gb).toFixed(2) || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {(item?.disk_max_iops).toFixed(2) || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {(item?.disk_max_tp).toFixed(2) || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {(item?.disk_max_used_iops).toFixed(2) || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                        >
                          {(item?.disk_max_used_tp).toFixed(2) || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item?.recommendation}
                        >
                          {item?.recommendation && (
                            <img
                              src={
                                item?.recommendation_type?.toLowerCase() ===
                                "downsize"
                                  ? downArrow
                                  : upArrow
                              }
                              alt=""
                              style={{ marginRight: "6px" }}
                            ></img>
                          )}
                          {item?.recommendation || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item?.recommendation_disk_type}
                        >
                          {/* {item?.RecommendedDiskType && (
                            <img
                              src={
                                item.RecommendedDiskType ===
                                "increase_disk_iops"
                                  ? upArrow
                                  : downArrow
                              }
                              alt=""
                              style={{ marginRight: "6px" }}
                            />
                          )} */}
                          {item?.recommendation_disk_type || "--"}
                        </td>
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css,
                            styles.saving_css
                          )}
                        >
                          ${Number(item?.savings).toFixed(2) || 0}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <Pagination totalItems={data?.data?.count || 0} />
          </>
        )}
      </div>
    </>
  );
};

export default DiskRecommendationsTable;
