/** @type {import('./global')} */
import { useRemoveRecommendationFromArchives } from "@/services/right-sizing/removeRecommendationFromArchives";
import React, { createContext, useState, useEffect } from "react";

export const RECOMMENDATION_TYPES = {
  upsize: "upsize",
  downsize: "downsize",
  discarded: "discarded",
};

export const VIEW_TYPES = {
  recommendations: "Active",
  archived: "Archive",
};

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesRes} RemoveRecommendationFromArchivesRes
 */

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesReq} RemoveRecommendationFromArchivesReq
 */

/**
 * @typedef RightSizingContextType
 * @property {keyof typeof VIEW_TYPES} viewType
 * @property {React.Dispatch<React.SetStateAction<keyof typeof VIEW_TYPES>>} setViewType
 * @property {string} recommendationType
 * @property {React.Dispatch<React.SetStateAction<string>>} setRecommendationType
 * @property {string[]} selectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedAccounts
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedRegions
 * @property {RemoveRecommendationFromArchivesRes} removeRecommendationFromArchiveData
 * @property {UseMutateFunction<RemoveRecommendationFromArchivesRes, Error, RemoveRecommendationFromArchivesReq>} removeRecommendationFromArchives
 * @property {string[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedTags
 */

/**
 * @type {React.Context<RightSizingContextType>}
 */
export const RightSizingContext = createContext();

/**
 * @returns {RightSizingContextType}
 */
export const useRightSizingContext = () => {
  const [viewType, setViewType] = useState(VIEW_TYPES.recommendations);

  const [recommendationType, setRecommendationType] = useState(
    RECOMMENDATION_TYPES.downsize
  );

  /** @type {UseState<string[]>} */
  const [selectedAccounts, setSelectedAccounts] = useState([]);

    /** @type {UseState<string[]>} */
    const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  const {
    data: removeRecommendationFromArchiveData,
    mutate: removeRecommendationFromArchives,
    error: rightsizeErrorRemoveFromSaving
  } = useRemoveRecommendationFromArchives();

  /** @type {UseState<string[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: 'include',
      values: [],
    }
  ]);

  useEffect(() => {
    setSelectedResourceGroup([]);
}, [selectedAccounts]);

  return {
    viewType,
    setViewType,
    recommendationType,
    setRecommendationType,
    selectedAccounts,
    setSelectedAccounts,
    selectedRegions,
    setSelectedRegions,
    removeRecommendationFromArchiveData,
    removeRecommendationFromArchives,
    rightsizeErrorRemoveFromSaving,
    selectedTags,
    setSelectedTags,
    selectedResourceGroup,
    setSelectedResourceGroup,
  };
};

export const RightSizingProvider = ({ children }) => {
  const context = useRightSizingContext();
  return (
    <>
      <RightSizingContext.Provider value={context}>
        {children}
      </RightSizingContext.Provider>
    </>
  );
};
