import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetTotalCountStatistics[]} data
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {String} forecast
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

export const getForecastValue = (token) => {
  return async ({
    account_id,
    expiration_date,
    start_date,
    period,
    service,
    filters,
    scope,
    management_group,
    resource_group
  }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/budget/get-forecast`,
      {
        method: "POST",
        body: JSON.stringify({
          account_id,
          resource_group,
          management_group,
          scope,
          expiration_date,
          start_date,
          period,
          filters,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      const errorResponse = await owners.json();
      throw new Error(errorResponse.message);
    }
    const data = await owners.json();

    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseForecastValue = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["getForecastValue", req, ...deps],
    mutationFn: () => getForecastValue(appContext.idToken)(req),
    // retry:false
  });
  return query;
};
