/** @type {import('./global')} */
import { useAcceptDismissIdle } from "@/services/IdleResources/addAcceptDismissIdle.repo";
import React, { createContext, useState, useEffect } from "react";

/**
 * @typedef {import('@/services/IdleResources/getAllStatics.repo').GetAllStatisticsFilters} TagItem
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').GetRecommendationsListRes} GetRecommendationsListRes
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').AddAcceptDismissIdleReq} AddAcceptDismissIdleReq
 */

/**
 * @typedef IdleContextType
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {TagItem[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedTags
 * @property {GetRecommendationsListRes} addAcceptDismissIdleData
 * @property {UseMutateFunction<GetRecommendationsListRes, Error, AddAcceptDismissIdleReq>} addAcceptDismissIdle
 * @property {Error=} acceptDismissIdleError
 * @property {() => void} idleReset
 */

/**
 * @type {React.Context<IdleContextType>}
 */
export const IdleContext = createContext();

/**
 * @returns {IdleContextType}
 */
export const useIdleContext = () => {
  /** @type {UseState<string[]>} */
  const [selectAccounts, setSelectAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [showUntaggedOnly, setShowUntaggedOnly] = useState(false);

  /** @type {UseState<TagItem[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  const [value, setValue] = useState([undefined, undefined]);

  const [eventkey, setEventkey] = useState("Open");

  useEffect(() => {
    setSelectedResourceGroup([]);
  }, [selectAccounts]);

  const {
    data: addAcceptDismissIdleData,
    mutate: addAcceptDismissIdle,
    error: acceptDismissIdleError,
    isError: checking,
    reset: idleReset,
  } = useAcceptDismissIdle();

  return {
    value,
    setValue,
    eventkey,
    setEventkey,
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    selectedTags,
    setSelectedTags,
    addAcceptDismissIdleData,
    addAcceptDismissIdle,
    acceptDismissIdleError,
    selectedResourceGroup,
    setSelectedResourceGroup,
    checking,
    idleReset,
    showUntaggedOnly,
    setShowUntaggedOnly,
  };
};

export const IdleProvider = ({ children }) => {
  const context = useIdleContext();
  return (
    <>
      <IdleContext.Provider value={context}>{children}</IdleContext.Provider>
    </>
  );
};
