import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";



/**
 * @typedef GetAllStatistics
 * @property {GetDimensionValues} dimensions
 */

/**
 * @typedef GetAllLeftBarValues
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} result
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 */

export const getAllDimensions = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async ({ service }, signal) => {
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/cost/get-all-dimensions`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token
        }
      }
    );
    if (!statistics.ok) {
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();
    return response
  };
}

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllDimensions = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllDimensions", req, ...deps],
    queryFn: ({ signal }) => getAllDimensions(appContext.idToken)(req, signal),
  });
  return query;
};


// GET /azure/cost/get-all-dimensions
// {
// 	"dimensions": {
// 		"id": "",
// 		"name": "",
// 	}[]
// }