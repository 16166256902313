import { AppContext } from "@/App.context";
import config from "@/config";
import { DownloadFile } from "@/lib/download-file";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  endOfWeek,
  format,
  isBefore,
  parse,
  setWeek,
  startOfWeek,
} from "date-fns";
import { useContext } from "react";

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {string=} granularity
 * @property {string=} dimension
 * @property {boolean} enableFilter
 * @property {boolean=} showUntaggedOnly
 * @property {import('./addCostExplorerWidget.repo').AddCostExplorerWidgetReqFilters=} filters
 * @property {import('./addCostExplorerWidget.repo').AddCostExplorerWidgetReqTags=} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {Record<'type' | string, string>[]} data
 */
export const getAllGraphStatistics = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */

  return async (
    {
      service,
      fromDate,
      toDate,
      filters,
      tags,
      dimension,
      tagname,
      granularity,
      showUntaggedOnly,
      showUntaggableOnly,
    },
    signal
  ) => {
    const fromDateIns = parse(fromDate, "dd-MM-yyyy", new Date());
    const toDateIns = parse(toDate, "dd-MM-yyyy", new Date());
    const values = await fetch(
      `${config.apiHost}/v1/${service}/cost/filter-contributors`,
      {
        method: "POST",
        body: JSON.stringify({
          fromDate: format(fromDateIns, "yyyy-MM-dd"),
          toDate: format(toDateIns, "yyyy-MM-dd"),
          dimension,
          tagname,
          granularity,
          filters: filters || [],
          showUntaggedOnly,
          showUntaggableOnly,
          tags: tags || [],
        }),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (!values.ok) {
      if (ERROR_STATUS_CODES.includes(values.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (values.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await values.json()).message);
    }

    dimension = dimension || "Service";

    const data = await values.json();

    if (data?.data.length === 0) {
      if (showUntaggedOnly || showUntaggableOnly) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (
        dimension ||
        granularity ||
        filters.length ||
        tagname ||
        tags.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    const serviceMap = data.data.reduce((map, cur) => {
      let obj = {};
      if (map.has(cur[dimension])) {
        obj = map.get(cur[dimension]);
      }
      let key = cur.usage_date || cur.date || cur.granularity;
      if (granularity === "date") {
        key = format(new Date(key), "dd-MM-yyyy");
      } else if (granularity === "week") {
        const week = setWeek(fromDateIns, cur.granularity + 1);
        key =
          format(startOfWeek(week), "dd LLL") +
          " - " +
          format(endOfWeek(week), "dd LLL");
      } else {
        key = cur.granularity;
      }
      obj[key] = cur.total_cost || cur.costInBillingCurrency;
      map.set(cur[dimension], obj);
      return map;
    }, new Map());
    const graph = Array.from(serviceMap.keys()).map((e) => ({
      Service: e,
      ...(serviceMap?.get(e) || {}),
    }));

    return {
      data: graph,
      dates: Array.from(
        new Set(
          [
            ...graph.map((x) =>
              Object.keys(x).filter((e) => e !== "Service" && e !== "ranking")
            ),
          ].flat()
        )
      ).sort((a, b) => {
        if (granularity === "date") {
          a = parse(a, "dd-MM-yyyy", new Date());
          b = parse(b, "dd-MM-yyyy", new Date());
          if (isBefore(a, b)) {
            return -1;
          } else if (isBefore(b, a)) {
            return 1;
          }
          return 0;
        } else if (granularity === "week") {
          return 0;
        } else {
          return 0;
        }
      }),
    };
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllGraphDataStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllGraphStatistics", req, ...deps],
    queryFn: ({ signal }) =>
      getAllGraphStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
