import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RolesContext } from "@/page-components/admin/roles/roles.context";
import style from "@/page-components/admin/admin.module.css";
import Group from "@/assets/img/user_Group.svg";
import line from "@/assets/img/Y-axis grid.svg";
import userProfile_img from "@/assets/img/userProfile_img.svg";
import { useUsersById } from "@/services/admin/Roles/getUsersById.repo";
import SpinnerComponent from "../components/spinner.component";
import clsx from "clsx";
import green_exclamation from "@/assets/img/green_exclamation.svg";
import format from "date-fns/format";
import vector from "@/assets/img/right_Vector_userAdmin.svg";
import AddUserModal from "./create.user.modal.part";
import { useDeleteUser } from "@/services/admin/Roles/deleteUser.repo";
import { DeleteMessageModal } from "../components/delete.modal.part";

const UserInfoModal = ({
  selectedItem,
  handleClose,
  refetch,
}) => {
  const adminContext = useContext(RolesContext);
  const {data,isLoading,refetch: refetchUserIds} = useUsersById(selectedItem, [adminContext.showGroupModal]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (id) => {
    setShowDeleteModal(true);
    // deleteGroup(id);
    // setTimeout(() => refetch(), 2000);
  };


  const {
    mutate: deleteUser,
    isLoading: deletingUser,
    data: userDeleteData,
    reset: deleteReset,
  } = useDeleteUser();

  const handleModalShow = () => {
    adminContext.setCreateUserModal(true);
    adminContext.handleGroupClose();
  };

  const handleModalClose = () => {
    adminContext.setCreateUserModal(false);
  };
  const UserID = data?.user?.id || 0;
  const user = data?.user?.name || "--";
  useEffect(() => {
    if (userDeleteData) {
      deleteReset();
      refetch();
    }
  }, [userDeleteData, deleteReset, refetch]);

  return (
    <>
      <Offcanvas
        className={style.sideComponent}
        placement="end"
        show={adminContext.showGroupModal}
        onHide={adminContext.handleGroupClose}
      >
        {isLoading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "50%" }}
          >
            <SpinnerComponent />
          </div>
        )}
        {data && (
          <>
            <div className={style.mainheader}>
              <Offcanvas.Header closeButton className={style.modal_header_css}>
                <div className={style.groupmainModal}>
                  <img src={userProfile_img} alt="" />
                  <div className={style.group_modal}>
                    <span>
                      <span className={style.groupUSer_name}>
                        {data?.user?.name || ""}
                      </span>
                      <span
                        className={clsx({
                          [style.AdminBadge]: adminContext?.isAdminInfoModal,
                          [style.UserBadge]: !adminContext?.isAdminInfoModal,
                        })}
                      >
                        {adminContext?.isAdminInfoModal ? "Admin" : "User"}
                      </span>
                    </span>
                    <span className={style.last_modified_name}>
                      {data?.user?.email || ""}
                    </span>
                  </div>
                </div>
              </Offcanvas.Header>
              <div
                className={style.table_div}
                style={{ width: "100%", padding: "8px 24px 12px 24px" }}
              >
                <span className={style.discription_name}>Description</span>
                <p className={style.discription_details}>
                  {data?.user?.description || "NA"}
                </p>
              </div>
              {/* ------------------------------------------ */}
              <div className={style.creater_div}>
                <div className={style.creater_box}>
                  <span className={style.creater_name}>Creator:</span>
                  <span className={style.creater_name_value}>
                    <span
                      className={clsx(
                        style.myselected_user_data,
                        style.padding_left
                      )}
                    >
                      {data?.user?.creatorName || "--"}
                      {/* {data?.user?.creatorName && (
                        <img
                          src={vector}
                          alt=""
                          style={{ marginLeft: "8px" }}
                        />
                      )} */}
                    </span>
                    {/* <img src={vector} alt="" /> */}
                  </span>
                </div>
                <div className={style.creater_box}>
                  <span className={style.creater_name}>Creation Date: </span>
                  <span
                    className={clsx(
                      style.creater_name_value,
                      style.createdOn_styling
                    )}
                  >
                    {data?.user?.createdAt
                      ? format(new Date(data?.user?.createdAt), "dd/MM/yyyy")
                      : "--"}
                  </span>
                </div>
              </div>
            </div>

            {/* ---------------------------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {adminContext?.isAdminInfoModal && (
                <div
                  style={{
                    display: "inline-block",
                    justifyContent: "center",
                    marginTop: "32px",
                    paddingTop: "36px",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    backgroundColor: "#F8F9FA",
                    borderTop: "1px solid #E9ECEF",
                  }}
                >
                  <img
                    style={{ marginRight: "8px" }}
                    width={16}
                    height={16}
                    src={green_exclamation}
                    alt=""
                  />
                  <span className={style.adminContent}>
                    Admin has access to all system functionalities.
                  </span>
                </div>
              )}

              {!adminContext?.isAdminInfoModal && (
                <Offcanvas.Body
                  id="admin"
                  
                  // className="offcanva_body"
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderTop: "1px solid #E9ECEF",
                    marginTop: "36px",
                    msOverflowY:"scroll",
                    height:"190px"
                    
                  }}
                >
                  <div className={style.gap} style={{ padding: "0px" }}>
                    <img className={style.myimgGroup} src={Group} alt="" />
                    Groups ({data?.groups?.length || 0})
                  </div>
                  {(data?.groups || []).map((item) => (
                    <div className={style.smallGap}>
                      <div className={style.body_gap}>
                        <span className={style.group_Heading}>{item.name}</span>
                        <span className={style.group_statement}>
                          {item.description}
                        </span>
                      </div>
                      <img src={line} alt="" />
                    </div>
                  ))}
                </Offcanvas.Body>
              )}

              <div className={style.modal_footer_css}>
                <div className={style.button_main_div}>
                  <button
                    className={style.delete_button}
                    disabled={deletingUser}
                    // onClick={()=>handleDeleteClick(data?.user?.id)}

                    onClick={() => {
                      setShowDeleteModal(true);

                      // refetchUserIds();
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className={style.buttons}>
                  <button onClick={handleClose} className={style.cancel_button}>
                    Cancel{" "}
                  </button>
                  <button
                    className={style.edit_button}
                    onClick={handleModalShow}
                  >
                    Edit{" "}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Offcanvas>

      <AddUserModal
        show={adminContext.createUserModal}
        onHide={()=>{
          handleModalClose();
        }}
        editData={data}
        deleteUser={deleteUser}
        // refetch={refetch}
        refetchUserTable={refetch}
        
        UserID={UserID}
        deleteGroup={() => {
          deleteUser(data?.user?.id);
          handleClose();
          refetchUserIds();
          refetch();
        }}
        refetchUserIds={refetchUserIds}
        user={user}
        userDeleteData={userDeleteData}
      />

      <DeleteMessageModal
        deleteAction={() => {
          deleteUser(data?.user?.id);
          handleClose();
          setTimeout(() => refetch(), 2000);
        }}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        type={adminContext?.isAdminInfoModal ? "Admin" : "User"}
        title={user}
        deleteData={userDeleteData}
      />
    </>
  );
};

export default UserInfoModal;
