import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

// export interface BillingDetailsDbResponse {
//     month: string;
//     chargesAgainstCredit: number;
//     creditsApplied: number;
//     refundOverageCredits: number;
//     totalCharges: number;
//     remainingCreditBalance: number;
// }

/**
 * @typedef GetAllStatistics
 * @property {string} month
 * @property {number} chargesAgainstCredit
 * @property {number} creditsApplied
 * @property {number} refundOverageCredits
 * @property {number} totalCharges
 * @property {number} remainingCreditBalance
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getBillingDetails = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, accountType, billing_profile }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/${accountType}/billing-details`,
      {
        method: "POST",
        body: JSON.stringify({
          billing_profile,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data.length === 0) {
      if (billing_profile.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
     else {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
  }
  
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useBillingDetails = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getBillingDetails", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getBillingDetails(appContext.idToken)(req, signal),
  });
  return query;
};
