import React, { useContext, useMemo } from "react";
import AllRightSizingHeader from "../components/AllPagesHeaders";
import AllPagesArchivedHeaders from "../components/AllPagesArchivedHeaders";
import RightSizingTable from "../components/rightChart/RightSizingTable";
import {
  RightSizingProvider,
  RightSizingContext,
} from "../right-sizing.context";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import FilterSection from "@/page-components/RightSizing/components/filter_section/Filter";
import ArchivedRightSizingTable from "../components/rightChart/ArchivedRightSizingTable";
import { useStatistics } from "@/services/right-sizing/getStatistics.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import DataLoading from "@/components/States/DataLoading";
import { useArchivedHeaderStatistics } from "@/services/right-sizing/getArchivedHeader.repo";
import clsx from "clsx";
import DiscardedTable from "../components/rightChart/discardedTable.part";
import { useRecommendationsListDownload } from "@/services/right-sizing/getRecommendationListDownload.repo";
import { useDiscardedTableDownload } from "@/services/right-sizing/getDiscardTable.repo";
import { useArchivedRecommendationsListDownload } from "@/services/right-sizing/getArchivedRecommendationListDownload.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AzureRightSizing = ({ service = "azure" }) => {
  return (
    <RightSizingProvider>
      <AzureRightSizingComp service={service} />
    </RightSizingProvider>
  );
};

const AzureRightSizingComp = ({ service = "azure" }) => {
  const context = useContext(RightSizingContext);
  // const query = useMemo(() => {
  //   return {
  //     service,
  //   };
  // }, [service]);
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        resourceGroupName: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context?.selectedTags,
    context?.selectedResourceGroup,
    service,
  ]);

  const {
    data,
    isLoading,
    isError,
    refetch: refetchStatistics,
  } = useStatistics(query, [context.removeRecommendationFromArchiveData]);

  const { data: data2, refetch: refetchArchivedStatistics } =
    useArchivedHeaderStatistics(query, [
      context.removeRecommendationFromArchiveData,
    ]);

  // ------------for download------------------//

  const downloadQuery = useMemo(() => {
    return {
      service,
      recommendationType: context?.recommendationType || "downsize",
    };
  }, [context?.recommendationType, service]);

  const downloadMutatediscard = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const downloadQueryArchive = useMemo(() => {
    return {
      service,
      recommendationType: context?.recommendationType || "downsize",
    };
  }, [context?.recommendationType, service]);

  const { mutate: downloadMutate } =
    useRecommendationsListDownload(downloadQuery);
  const { mutate: downloadMutateDiscard } = useDiscardedTableDownload(
    downloadMutatediscard
  );
  const { mutate: downloadMutateArchive } =
    useArchivedRecommendationsListDownload(downloadQueryArchive);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  const handleDownloadClickdiscard = () => {
    downloadMutateDiscard(downloadMutatediscard);
  };
  const handleDownloadClickarchive = () => {
    downloadMutateArchive(downloadQueryArchive);
  };

  return (
    <>
      {isError && <DataLoading />}
      {isLoading && <AllPageLoading />}
      {data && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              {context.viewType === "Active" && (
                <AllRightSizingHeader
                  data={data}
                  names="VM Recommendations"
                  savingFor="PROJECTED SAVINGS"
                  tilldate="Saved till date"
                  active="active"
                  error={isError}
                  service="azure"
                />
              )}

              {context.viewType === "Archive" && (
                <AllPagesArchivedHeaders
                  data={data2}
                  names="Archived VM Recommendations"
                  savingFor="OPPORTUNITY MISSED"
                  tilldate="Recommendations Active"
                  active="archive"
                  error={isError}
                />
              )}

              <div className={styles.tablesFilterDiv}>
                <div
                  className={clsx(
                    context.viewType === "Active" && styles.tableDiv1,
                    context.viewType === "Archive" && styles.tableDiv
                  )}
                >
                  <span
                    className={styles.recommendationsLabel}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {context.viewType === "Active" && "VM Recommendations"}
                    {context.viewType === "Archive" &&
                      "Dismissed VM Recommendations"}
                    {context.viewType === "Active" &&
                      (context?.recommendationType === "downsize" ||
                        context?.recommendationType === "upsize") && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Download as CSV</Tooltip>
                          }
                        >
                          <span
                            className={styles.downloadButton}
                            onClick={handleDownloadClick}
                          >
                            <img src={downlaodVM} alt="" />
                          </span>
                        </OverlayTrigger>
                      )}
                    {context.viewType === "Archive" &&
                      (context?.recommendationType === "downsize" ||
                        context?.recommendationType === "upsize") && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Download as CSV</Tooltip>
                          }
                        >
                          <span
                            className={styles.downloadButton}
                            onClick={handleDownloadClickarchive}
                          >
                            <img src={downlaodVM} alt="" />
                          </span>
                        </OverlayTrigger>
                      )}
                    {context.viewType === "Active" &&
                      context?.recommendationType === "discarded" && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">Download as CSV</Tooltip>
                          }
                        >
                          <span
                            className={styles.downloadButton}
                            onClick={handleDownloadClickdiscard}
                          >
                            <img src={downlaodVM} alt="" />
                          </span>
                        </OverlayTrigger>
                      )}
                  </span>
                  <p className={styles.recommendationsText}>
                    Explore In-Depth Recommendations for VM Right Sizing and
                    Insights into Resource Utilization. Unlock FinOps Potential
                    by Aligning Infrastructure with Actual Workload Demands.
                    Delve into Actionable Data for Informed Decision-Making and
                    Efficient Resource Allocation.
                  </p>
                  <div style={{ display: "flex" }}>
                    {context.viewType === "Active" &&
                      context.recommendationType !== "discarded" && (
                        <RightSizingTable
                          service="azure"
                          name="Subscription Name"
                          resource="ResourceId"
                          refreshHeaders={() => {
                            refetchStatistics();
                            refetchArchivedStatistics();
                          }}
                        />
                      )}
                    {context.recommendationType === "discarded" && (
                      <div className="col-lg-12 p-0">
                        <DiscardedTable
                          service="azure"
                          name="Subscription Name"
                          resource="ResourceId"
                          refreshHeaders={() => {
                            refetchStatistics();
                            refetchArchivedStatistics();
                          }}
                        />
                      </div>
                    )}
                    {context.viewType === "Archive" &&
                      context.recommendationType !== "discarded" && (
                        <ArchivedRightSizingTable
                          service="azure"
                          name="Subscription Name"
                          refreshHeaders={() => {
                            refetchStatistics();
                            refetchArchivedStatistics();
                          }}
                        />
                      )}
                  </div>
                </div>

                {context.viewType === "Active" &&
                  context.recommendationType !== "discarded" && (
                    <FilterSection service="azure" name="Subscription Name" />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AzureRightSizing;
