import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetTotalStatistics
 * @property {string} Resource_Type
 * @property {number} saving
 * @property {number} vm
 * @property {number} ip
 * @property {number} disk
 * @property {number} lb
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' | 'all'} resourceType
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 */


export const getSavingStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetTotalStatistics>}
   */
  return async ({
    service,
    page,
    resourceType,
    recordsPerPage,
    idleDays,
  }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/saving-details?page=${page}&recordsPerPage=${recordsPerPage}&resourceType=${resourceType}&idleDays=${idleDays}`,
      {
        method: "GET",

        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    
    const hasNoSavings = data.every((item) =>
      Object.values(item).every(
        (value) => value === 0 || value === null || typeof value === "string"
      )
    );

    if (hasNoSavings) {
      throw new Error(API_NO_DATA_FOUND_ERROR); 
    }

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useSavingStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getArchieveStatistics", req, ...deps],
    queryFn: () => getSavingStatistics(appContext.idToken)(req),
  });
  return query;
};
