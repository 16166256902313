import React, { useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import styles from "@/page-components/savings/components/savings.module.css";
import { RiContext } from "../ri.context";

const RiTabs = () => {
  const context = useContext(RiContext);

  const tableChange = (e) => {
    context.setEventkey(e);
  };

  return (
    <div className="btn__Container">
      <div className="btn-combo__Container">
        <Tabs
          defaultActiveKey={context.eventkey}
          className={styles.mytabs_button}
          fill
          onSelect={(e) => {
            tableChange(e);
          }}
          style={{ gap: "4px" }}
        >
          <Tab eventKey="RI Recommendations" title="RI Recommendations"></Tab>
          <Tab
            eventKey="Bulk Recommendations"
            title="Bulk Recommendations"
          ></Tab>
          <Tab
            eventKey="Scope & Instance Flexibility Recommendations"
            title="Scope & Instance Flexibility Recommendations"
          ></Tab>
        </Tabs>
      </div>
      <div className="text-btn__Container">Updated Today at 7:05AM</div>
    </div>
  );
};

export default RiTabs;
