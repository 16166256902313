import React from "react";
import styles from "./states.module.css";
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";

const VerticalBarGraph = ({ onClick }) => {
  return (
    <div id="my_graph" className=" d-flex flex-column gap-5" style={{ margin: "12px 40px", width:"50%" }}>
      <Placeholder animation="glow">
        <div className=" d-flex flex-column gap-2" style={{height:"auto"}}>
          <Row style={{display:"flex",justifyContent:"space-between"}}>
            <Col xs={5}>
              <Placeholder
                className={styles.placeholder_css}
                xs={4}
                style={{ height: "20px", width: "50%" }}
              />
            </Col>
            {/* <Col xs={1}>
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "20px", width: "100%" }}
              />
            </Col> */}
          </Row>
          <Placeholder
            className={styles.placeholder_css}
            xs={1}
            style={{ height: "27px", width: "15%" }}
          />

          <div
            className=""
            style={{ flex: "1 1 0", position: "relative", marginTop: "16px" }}
          >
            <div className={`${styles.horizontal_line} ${styles.one}`}></div>
            <div className={`${styles.horizontal_line} ${styles.two}`}></div>
            <div className={`${styles.horizontal_line} ${styles.three}`}></div>
            <div className={`${styles.horizontal_line} ${styles.four}`}></div>
            

            <div className={`${styles.vertical_line} ${styles.one}`}></div>
            <div className={`${styles.vertical_line} ${styles.two}`}></div>
            <div className={`${styles.vertical_line} ${styles.three}`}></div>
            <div className={`${styles.vertical_line} ${styles.four}`}></div>
            <div className={`${styles.vertical_line} ${styles.five}`}></div>
            <div className={`${styles.vertical_line} ${styles.six}`}></div>
            <div className={`${styles.vertical_line} ${styles.seven}`}></div>
            <div className={`${styles.vertical_line} ${styles.eight}`}></div>
            <div className={`${styles.vertical_line} ${styles.nine}`}></div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px",
                width: "100%",
                top: "0px",
              }}
            >
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "20px", width: "5%" }}
              />
              <Placeholder
                className={styles.placeholder_css}
                xs={5}
                style={{ height: "20px", width: "30%" }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px",
                width: "100%",
                top: "36px",
              }}
            >
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "20px", width: "5%" }}
              />
              <Placeholder
                className={styles.placeholder_css}
                xs={5}
                style={{ height: "20px", width: "70%" }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px",
                width: "100%",
                top: "72px",
              }}
            >
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "20px", width: "5%" }}
              />
              <Placeholder
                className={styles.placeholder_css}
                xs={5}
                style={{ height: "20px", width: "65%" }}
              />
            </div>
          
          </div>
        </div>
      </Placeholder>
    </div>
  );
};

export default VerticalBarGraph;
