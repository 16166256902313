import React from 'react';
import menubutton from '@/assets/img/skeleton-menu-dot.svg';
import circle from '@/assets/img/Group 2310.svg';
import addWidget from "@/assets/img/addWidget.svg";

const BudgetSkeleton = ({functionality}) => {
  return (
    <>
      <div className='skeleton_container '>
        <div className='skeleton_header'>
          <div className='first_div'></div>
          <img style={{ height: '16px',marginTop:'5px' }} src={menubutton} alt='menue'/>
        </div>
        <div className='sec_container'>
          <div className='three-contaiers'>
            <div className='first-one'></div>
            <div className='sec-one'></div>
            <div className='third-one'></div>
          </div>
          <img src={circle} alt='' />
        </div>
        <div className='third_container'>
          <div className='third-first'>
            <div className='third-first-1'></div>
            <div className='third-first-2'></div>
            <div className='third-first-3'></div>
          </div>
          <div className='third-sec'>
            <div className='third-sec-1'></div>
            <div className='third-sec-1'></div>
          </div>
        </div>
        <div className='forth-container'>
          <div className='forth-first'>
            <span className='forth-first-div'></span>
            <span className='forth-first-div' style={{ width: '42px' }}></span>
          </div>
          {/* <div className='forth-first'>
            <span className='forth-first-div' style={{ width: '70px' }}></span>
            <span className='forth-first-div' style={{ width: '42px' }}></span>
          </div> */}
          <div className='forth-first'>
            <span className='forth-first-div' style={{ width: '86px' }}></span>
            <span className='forth-first-div' style={{ width: '50px' }}></span>
          </div>
          <div className='forth-first'>
            <span className='forth-first-div' style={{ width: '71px' }}></span>
            <span className='forth-first-div' style={{ width: '80px' }}></span>
          </div>
          <div className='forth-first'>
            <span className='forth-first-div' style={{ width: '86px' }}></span>
            <span className='forth-first-div' style={{ width: '80px' }}></span>
          </div>
        </div>
        <div className='add_new_widgets_container'onClick={functionality}>
          <div className='btn add_new_widgets'>
            <img className='add_widgte_svg' src={addWidget} alt=' ' />
            <span className='add_widget_text'>Create Budgets</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetSkeleton;
