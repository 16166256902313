import React, { useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import styles from "@/page-components/savings/components/savings.module.css";
import { AiRecommendContext } from "./ai-recom-context-file";

const AIRecommendTabs = () => {
  const context = useContext(AiRecommendContext);

  const tableChange = (e) => {
    context?.setEventkey(e);
  };

  return (
    <div className="btn__Container">
      <div className="btn-combo__Container">
        <Tabs
          defaultActiveKey={context?.eventkey}
          className={styles.mytabs_button}
          fill
          onSelect={(e) => {
            tableChange(e);
          }}
          style={{ gap: "4px" }}
        >
          <Tab eventKey="Usage Patterns" title="Usage Patterns"></Tab>
          <Tab
            eventKey="Disk Recommendations"
            title="Disk Recommendations"
          ></Tab>
        </Tabs>
      </div>
      <div className="text-btn__Container">Updated Today at 7:05AM</div>
    </div>
  );
};

export default AIRecommendTabs;
