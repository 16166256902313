import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "@/page-components/admin/admin.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import success from "@/assets/img/success_image.svg";
import { useForm, Controller } from "react-hook-form";
import { useCreateGroup } from "@/services/admin/Groups/createGroup.repo";
import { useUserServiceSearch } from "@/services/admin/Roles/createUser.repo";
import failure from "@/assets/img/f-remove.svg";
import { joiResolver } from "@hookform/resolvers/joi";
import { CreateGroupValidation } from "@/services/admin/Groups/createGroup.repo";
import { Spinner } from "react-bootstrap";
import { useGroupsById } from "@/services/admin/Groups/getGroupsById.repo";
import { useEditGroupInfo } from "@/services/admin/Groups/editGroup.repo";
import { GroupsContext } from "./group.context";
import { useDebouncedValue } from "rooks";
import { useGetUserEmails } from "@/services/admin/createUser.repo";

/**
 * @typedef CreateGroupProps
 * @property {string=} groupID
 * @property {() => void} onHide
 */

/**
 * @param {CreateGroupProps} props
 */
export default function CreateGroup(props) {
  const adminContext = useContext(GroupsContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [filterQueryForEmails, setFilterQueryForEmails] = useState("");

  /** @type {import('react-hook-form').UseFormReturn<CreateGroupValidation>} */
  const formData = useForm({
    resolver: joiResolver(CreateGroupValidation),
  });

  const { data: groupByIdData, isLoading: groupLoading } = useGroupsById(
    props.groupID
  );

  const groupPrivilegesList = useUserServiceSearch("privileges");
  const usersList = useUserServiceSearch("users");

  const [debouncedFilterQuery] = useDebouncedValue(filterQueryForEmails, 500);

  const emailList = useGetUserEmails({
    filterQuery: debouncedFilterQuery,
  });

  useEffect(() => {
    if (groupByIdData) {
      formData.setValue("name", groupByIdData?.group?.name);
      formData.setValue("description", groupByIdData?.group?.description);
      formData.setValue(
        "privilegeIds",
        groupByIdData?.privileges?.map((e) => ({
          name: e.name,
          description: e.description,
          id: e.id,
        }))
      );
      formData.setValue(
        "userIds",
        groupByIdData?.users?.map((e) => ({
          name: e.name,
          description: e.description,
          id: e.id,
        }))
      );
    }
  }, [formData, groupByIdData]);

  useEffect(() => {
    if (!props.groupID) {
      
    }
  }, [formData, props.groupID]);

  const {
    mutate: addGroup,
    data: createGroup,
    error: createError,
    reset: resetAddGroup,
    isPending: addGroupLoading,
  } = useCreateGroup();

  const {
    mutate: modifiedGroup,
    error: editError,
    data: editGroupData,
    reset,
    isPending: editLoading,
  } = useEditGroupInfo();

  useEffect(() => {
    if (createGroup) {
      setShowSuccessModal(true);
      adminContext?.refetchGroups();
      props.onHide();
      formData.reset();
      resetAddGroup();
      setShowSuccessMessage("Group Created Successfully");
    }
  }, [adminContext, createGroup, formData, props, resetAddGroup]);
  useEffect(() => {
    if (editGroupData) {
      setShowSuccessModal(true);
      adminContext?.refetchGroups();
      props.onHide();
      formData.reset();
      resetAddGroup();
      setShowSuccessMessage("Group Edited Successfully");
    }
  }, [
    adminContext,
    createGroup,
    editGroupData,
    formData,
    props,
    resetAddGroup,
  ]);

  useEffect(() => {
    if (createError) {
      setShowFailureModal(true);
      adminContext?.setFailureMessage("Group Creation Failed");
      props.onHide();
      formData.reset();
    }
  }, [createError, formData]);

  useEffect(() => {
    if (editError) {
      setShowFailureModal(true);
      adminContext?.setFailureMessage("User Editing Failed");
      props.onHide();
      formData.reset();
    }
  }, [editError, formData]);

  const handleFailureModalClose = () => {
    setShowFailureModal(false);
  };

  useEffect(() => {
    if (editGroupData) {
      props.onHide();
      formData.reset();
      resetAddGroup();
      adminContext?.refetchGroups();
      reset();
    }
  }, [editGroupData, formData, props, resetAddGroup, reset, adminContext]);

  const onSubmit = (data) => {
    const userIds = (data?.userIds || []).map((i) => i.id);
    data.privilegeIds = (data?.privilegeIds || []).map((ids) => ids.id);
    if (props.groupID) {
      modifiedGroup({
        body: { ...data, userIds },
        id: props?.groupID,
      });
    } else {
      addGroup({ body: { ...data } });
    }
  };

  const onHide = () => {
    formData.reset();
    props.onHide();
  };

  useEffect(() => {
    const values = formData.getValues();
  });

  return (
    <>
      <Modal {...props} onHide={onHide} centered size="lg">
        {props.groupID && groupLoading && (
          <div
            className="d-flex justify-content-center align-items-center h-100 "
            style={{ minHeight: "500px" }}
          >
            <Spinner />
          </div>
        )}
        {((props.groupID && groupByIdData) || !props.groupID) && (
          <form onSubmit={formData.handleSubmit(onSubmit)}>
            <Modal.Header
              style={{ border: "none" }}
              className={styles.modalHeader}
            >
              <Modal.Title
                id="contained-modal-title-vcenter"
                className={styles.modalTitle}
              >
                <span className={styles.mainTitle}>
                  {props.groupID ? "Edit" : "Create"} Group
                </span>
                <span className={styles.subTitle}>
                  Add details to {props.groupID ? "Edit" : "Create"} group
                </span>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ padding: "24px 24px 0px 24px" }}>
              <div className={styles.modalContainer}>
                <div className={styles.modalBody}>
                  <span
                    className={styles.subTitle}
                    style={{ lineHeight: "20px" }}
                  >
                    Group Name
                  </span>
                  <input
                    className="filter_search_trigger "
                    placeholder="Enter Group Name"
                    {...formData.register("name")}
                  />

                  {formData?.formState?.errors?.name && (
                    <p className={styles.errorMessage}>
                      {formData.formState.errors.name.message}
                    </p>
                  )}
                </div>

                <div className={styles.modalBody}>
                  <span
                    className={styles.subTitle}
                    style={{ lineHeight: "20px" }}
                  >
                    Privileges
                  </span>

                  <Controller
                    control={formData.control}
                    name="privilegeIds"
                    render={({ field }) => (
                      <FilterSearch
                        type="admin"
                        multiselect
                        options={(
                          groupPrivilegesList?.data?.privileges || []
                        ).map((e) => {
                          return {
                            name: e.name || "null",
                            description: e.description || "null",
                            id: e.id || "null",
                          };
                        })}
                        itemRenderer={(item, index) => {
                          return (
                            <div className={styles.filter}>
                              <span>{item.name} </span>
                              <span className={styles.infoStyle}>
                                {item.description || null}
                              </span>
                            </div>
                          );
                        }}
                        displayValue="name"
                        selectedValues={field.value || []}
                        onSelect={(e) => {
                          field.onChange(e);
                        }}
                        onRemove={(e) => {
                          field.onChange(e);
                        }}
                        toggle={
                          <FilterSearchToggle
                            maxItems={2}
                            placeholder="Select Group"
                            type="admin"
                          />
                        }
                      />
                    )}
                  />
                  {/* --------------------------------------------user block---------------------------------------- */}
                  {formData?.formState?.errors?.privilegeIds && (
                    <p className={styles.errorMessage}>
                      {" "}
                      {formData.formState.errors.privilegeIds.message}
                    </p>
                  )}
                </div>

                {Boolean(props?.groupID) && (
                  <div className={styles.modalBody}>
                    <span
                      className={styles.subTitle}
                      style={{ lineHeight: "20px" }}
                    >
                      Users
                    </span>

                    <Controller
                      control={formData.control}
                      name="userIds"
                      render={({ field }) => (
                        <FilterSearch
                          type="admin"
                          multiselect
                          options={(usersList?.data?.users || []).map((e) => {
                            return {
                              name: e.name || "null",
                              description: e.description || "null",
                              id: e.id || "null",
                              email: e.email || "null",
                            };
                          })}
                          itemRenderer={(item, index) => {
                            return (
                              <div className={styles.filter}>
                                <div className={styles.myemailstyling}>
                                  <span>{item.name} </span>
                                  <span className={styles.infoStyle}>
                                    {item.email || null}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                          displayValue="name"
                          onSearchChange={(search) => {
                            setFilterQueryForEmails(search);
                          }}
                          selectedValues={field.value || []}
                          onSelect={(e) => {
                            field.onChange(e);
                          }}
                          onRemove={(e) => {
                            field.onChange(e);
                          }}
                          toggle={
                            <FilterSearchToggle
                              maxItems={2}
                              placeholder="Select User"
                              type="admin"
                            />
                          }
                        />
                      )}
                    />
                    {/* --------------------------------------------user block---------------------------------------- */}
                    {formData?.formState?.errors?.userIds && (
                      <p className={styles.errorMessage}>
                        {" "}
                        {formData.formState.errors.userIds.message}
                      </p>
                    )}
                  </div>
                )}
                {/* ----------------------------------------------------------------------------- */}

                <div className={styles.modalBody}>
                  <span
                    className={styles.subTitle}
                    style={{ lineHeight: "20px" }}
                  >
                    Group Description
                  </span>
                  <textarea
                    placeholder="Add Description"
                    {...formData.register("description")}
                    className="filter_search_trigger"
                    rows={4}
                    cols={50}
                    style={{ resize: "none", height: "72px" }}
                  />
                  {formData?.formState?.errors?.description && (
                    <p className={styles.errorMessage}>
                      {formData.formState.errors.description.message}
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "none", paddingTop: "0px" }}>
              {props.groupID ? (
                <>
                  <div className={styles.modal_user_footar}>
                    <div className={styles.button_main_div}>
                      <button
                        type="button"
                        disabled={editLoading}
                        className={styles.delete_button}
                        onClick={() => {
                          adminContext.setGroupDeleteItemName(
                            groupByIdData?.group?.name || ""
                          );
                          adminContext.setGroupDeleteItem(props.groupID);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                    <div className={styles.buttons}>
                      <button
                        type="button"
                        disabled={editLoading}
                        className={styles.cancel_button}
                        onClick={onHide}
                      >
                        Cancel{" "}
                      </button>
                      <button className={styles.edit_button} type="submit">
                        {editLoading ? "Loading..." : "Edit"}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.footer}>
                  <Button
                    type="button"
                    disabled={addGroupLoading}
                    onClick={onHide}
                    variant="outline-primary"
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={addGroupLoading}
                    className={styles.addButton}
                    type="submit"
                  >
                    {addGroupLoading ? "Loading..." : "Add"}
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </form>
        )}
      </Modal>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={success} alt="" />
            </span>

            <span className={styles.success_message}>{showSuccessMessage}</span>
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_buttom}
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*Failure Modal*/}
      <Modal
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={failure} alt="" />
            </span>

            <span className={styles.success_message}>
              {adminContext?.failureMessage}
            </span>
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_button}
              onClick={handleFailureModalClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
