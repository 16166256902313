import React from "react";
import HeaderPart_Snapshots from "./snapshots.header.part";
import vertical_line from "@/assets/img/Vector 5.svg";
import SnapshotAge from "./snapshotAge.part";
import UntaggedSnapshots from "./snapshotType.part";
import OrphanedSnapshot from "./snapshotTypeOrphaned";
import SnapshotsByCloud from "./snapBy_cloud.part";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import TabsComponent from "./state_change/tabs";
import {
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import HeaderState from "@/components/States/HeaderState";

const SnapShotHeaderSection = ({ data, error }) => {
  return (
    <>
      <div className="container-fluid main-container">
        <div className=" Inner_main_container">
          <div className={styles.inner_first_container}>
            <div className={styles.inner_first_container_text}>Snapshots</div>
          </div>
          {error && (
          <ScenariosErrorState
            headerItem="header"
            error={error.message}
            messageConfig={{
              [API_LOADING_ERROR_SNAPSHOT]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
          {data && (
            <div className={clsx("row", styles.snap_sec_div)}>
              <div className={clsx(styles.snapshots_by_types)}>
                <HeaderPart_Snapshots data1={data?.stats || 0} width={"11%"} />
                <img src={vertical_line} alt="" style={{ height: "100%" }} />
                <SnapshotAge
                  service={"all-cloud"}
                  SnapAgeData={data?.stats || 0}
                  width="25"
                />
                <img src={vertical_line} alt="" style={{ height: "100%" }} />
                <UntaggedSnapshots
                  service={"all-cloud"}
                  mydata={data?.stats || 0}
                />
                <img src={vertical_line} alt="" style={{ height: "100%" }} />
                <OrphanedSnapshot
                  service={"all-cloud"}
                  mydata2={data?.stats || 0}
                />
              </div>

              <div className={clsx(styles.snapshots_by_cloud)}>
                <div className={clsx(styles.cloud_inner_container)}>
                  <span className={clsx(styles.snapshot_text)}>
                    SNAPSHOTS BY CLOUDS
                  </span>
                  <div className={clsx(styles.By_cloud_mainContianer)}>
                    <SnapshotsByCloud
                      data={data?.snapshotByClouds || 0}
                      name="AWS"
                    />
                    <SnapshotsByCloud
                      data={data?.snapshotByClouds || 0}
                      name="Azure"
                    />
                    {/* <SnapshotsByCloud
                  data={data?.snapshotByClouds || 0}
                  name="Gcp"
                /> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={clsx("contnainer-fluid ", styles.inner_third_container)}
          >
            {/* {service != "aws" && <TabsComponent/>} */}
            <div
              className={clsx("container-fluid", styles.inner_contaier_text)}
            >
              Updated Today at 7:05AM
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SnapShotHeaderSection;
