import { useContext } from "react";
import config from "../../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

/**
 * @typedef EditUserReq
 * @property {boolean} addAsAdmin
 * @property {number[]} groupIds
 * @property {string} description
 *
 */

/**
 * @typedef EditUserRes
 * @property {string} message
 */

// /**
//  * @typedef CreateGroupValidation
//  * @property {string} name
//  * @property {string} description
//  * @property {CreatePrivilegeIdsGroups[]} privilegeIds
//  */

// export const CreateGroupValidation = Joi.object({
//   name: Joi.string().required().messages({
//     "string.empty": "*Group Name cannot be empty",
//     "any.required": "*Group Name cannot be empty",
//   }),
//   description: Joi.string().allow('').optional(),
//   privilegeIds: Joi.array().required().items(
//     Joi.object({
//       id: Joi.number(),
//       name: Joi.string().required(),
//       description: Joi.string(),
//     })
//   ).messages({
//     "string.empty": "*Privileges cannot be empty",
//     "any.required": "*Privileges cannot be empty",
//   }),
// });

const editUserDetails = (token) => {
  /**
   * @param {EditUserReq} req
   * @returns {Promise<EditUserRes>}
   */
  return async (data, id) => {
    const editUser = await fetch(
      `${config.apiHost}/v1/users/${data?.id}/update-user`,
      {
        method: "PUT",
        body: JSON.stringify(data?.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!editUser.ok) {
      throw new Error((await editUser.json()).message);
    }
    const groupStatus = await editUser.json();
    return groupStatus;
  };
};

export const useEditUserInfo = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["editUserDetails", ...deps],
    mutationFn: (body) => editUserDetails(appContext.idToken)(body),
  });
  return mutate;
};
