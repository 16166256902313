import { useUsersList } from "@/services/admin/Roles/getRolesList.repo";
import React, { createContext, useContext, useState } from "react";

export const AdminContext = React.createContext();

export const useAdminContext = () => {
  const [showRoles, setShowRoles] = useState(true);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [createPrivilegeModal, setCreatePrivilegeModal] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [showProvisions, setShowProvisions] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [usershowGroupModal, setUserShowGroupModal] = useState(false);
  const [usedDemoModal, setusedDemoModal] = useState(false);



  const [privDEleteModal, setprivDEleteModal] = useState(false);

  // const apiData = useApiFetching()

  // const [usershowGroupModal, setuserShowGroupModal] = useState(false);

  const usergroupModalShow = () => setUserShowGroupModal(true);
  const usergroupModalClose = () => setUserShowGroupModal(false);

  const userDemoModalShow = () => setusedDemoModal(true);
  const userDemoModalClose = () => setusedDemoModal(false);

  const handleGroupClose = () => setShowGroupModal(false);
  const handleGroupShow = () => setShowGroupModal(true);

  const handleTableModalShow = () => setShowTableModal(true);
  const handleTableModalClose = () => setShowTableModal(false);

  return {
    // ...apiData,
    privDEleteModal,
    setprivDEleteModal,
    usershowGroupModal,
    setUserShowGroupModal,
    createGroupModal,
    setCreateGroupModal,
    usergroupModalShow,
    createPrivilegeModal,
    setCreatePrivilegeModal,
    usergroupModalClose,
    usedDemoModal,
    createUserModal,
    setCreateUserModal,
    setusedDemoModal,
    userDemoModalShow,
    userDemoModalClose,
    showRoles,
    setShowRoles,
    showGroups,
    setShowGroups,
    setShowProvisions,
    showProvisions,
    showGroupModal,
    setShowGroupModal,
    handleGroupClose,
    handleGroupShow,
    showTableModal,
    handleTableModalShow,
    handleTableModalClose,
  };
};

const useApiFetching = () =>{

  const [query,setQuery]= useState('')
  const {
    data: rolesData,
    isLoading: loadingRoles,
    refetch,
  } = useUsersList(query);

  return{
    query,
    setQuery,
    refetch,
    rolesData,
    loadingRoles
  }
}

export const AdminContextProvider = ({ children }) => {
  const context = useAdminContext();
  return (
    <AdminContext.Provider value={context}>{children}</AdminContext.Provider>
  );
};
