/** @type {import('./global')} */
import React, { createContext, useState } from "react";

/**
 * @type {React.Context<RiContextType>}
 */
export const RiContext = createContext();

/**
 * @typedef RiContextType
 * @property {string[]} eventKey
 * @property {React.Dispatch<React.SetStateAction<string>>} setEventkey
 * @property {boolean} showBulkModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowBulkModal
 * @property {boolean} showScopeModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowScopeModal
 * @property {boolean} showRiRecommendation
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowRiRecommendation
 * @property {boolean} showBulkModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowBulkModal
 * @property {boolean} showScopeModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowScopeModal
 * @property {string[]} SelectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedAccounts
 * @property {string[]} selectedReservation
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedReservation
 * @property {string[]} selectedVMFamily
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedVMFamily
 * @property {string} selectedTitle
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedTitle
 * @property {string[]} SelecInstanceTypents
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelecInstanceTypents
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedRegions
 */


/**
 * @returns {RiContextType}
 */
export const useRIContext = () => {
  const [eventkey, setEventkey] = useState("RI Recommendations");

  /** @type {UseState<string[]>} */
  const [SelectedAccounts, setSelectedAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedReservation, setSelectedReservation] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedVMFamily, setSelectedVMFamily] = useState([]);

  /** @type {UseState<string>} */
  const [selectedTitle, setSelectedTitle] = useState("account");

  /** @type {UseState<string[]>} */
  const [SelecInstanceTypents, setSelecInstanceTypents] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [SelectedplateformType, setSelectedplateformType] = useState([]);

  /** @type {UseState<boolean[]>} */
  const [showRiRecommendation, setShowRiRecommendation] = useState(false);

  /** @type {UseState<boolean[]>} */
  const [showBulkModal, setShowBulkModal] = useState(false);

  /** @type {UseState<boolean[]>} */
  const [showScopeModal, setShowScopeModal] = useState(false);

  /** @type {UseState<string[]>} */
  const [totalCount, setTotalCount] = useState([0]);

  /** @type {UseState<string[]>} */
  const [accountID, setAccountID] = useState(0);
  
  /** @type {UseState<string[]>} */
  const [tableType, setTableType] = useState("scope");

  /** @type {UseState<string[]>} */
  const [scopeName, setScopeName] = useState("");

  /** @type {UseState<string[]>} */
  const [subscriptionId, setSubscriptionId] = useState("");

  /** @type {UseState<string[]>} */
  const [rgName, setRgName] = useState("");

  /** @type {UseState<string[]>} */
  const [instanceType, setInstanceType] = useState("");

  /** @type {UseState<string[]>} */
  const [onDemandH, setOnDemandH] = useState("");

  /** @type {UseState<string[]>} */
  const [onDemandC, setOnDemandC] = useState("");

  /** @type {UseState<string[]>} */
  const [platform, setPlatform] = useState("");

  /** @type {UseState<string[]>} */
  const [region, setRegion] = useState("");

  /** @type {UseState<string[]>} */
  const [resourceId, setResourceId] = useState("");

  /** @type {UseState<string[]>} */
  const [quantity_to_purchase, setQuantity_to_purchase] = useState("");

  /** @type {UseState<string[]>} */
  const [instance_flexibility, setInstance_flexibility] = useState("");
  /** @type {UseState<string[]>} */
  const [VMSeries, setVMSeries] = useState("");
  /** @type {UseState<string[]>} */
  const [Tenancy, setTenancy] = useState("");

  /** @type {UseState<string[]>} */
  const [tab, setTab] = useState("ri");


  return {
    Tenancy,
    setTenancy,
    VMSeries,
    setVMSeries,
    tab,
    setTab,
    quantity_to_purchase,
    setQuantity_to_purchase,
    instance_flexibility,
    setInstance_flexibility,
    resourceId,
    setResourceId,
    region,
    setRegion,
    platform,
    setPlatform,
    instanceType,
    setInstanceType,
    onDemandH,
    setOnDemandH,
    onDemandC,
    setOnDemandC,
    scopeName,
    setScopeName,
    subscriptionId,
    setSubscriptionId,
    rgName,
    setRgName,
    eventkey,
    setEventkey,
    SelectedAccounts,
    setSelectedAccounts,
    SelecInstanceTypents,
    setSelecInstanceTypents,
    selectedRegions,
    setSelectedRegions,
    SelectedplateformType,
    setSelectedplateformType,
    showRiRecommendation,
    setShowRiRecommendation,
    showBulkModal,
    setShowBulkModal,
    showScopeModal,
    setShowScopeModal,
    totalCount,
    setTotalCount,
    accountID,
    setAccountID,
    tableType,
    setTableType,
    selectedVMFamily,
    setSelectedVMFamily,
    selectedReservation,
    setSelectedReservation,
    selectedTitle,
    setSelectedTitle,
  };
};

export const RiProvider = ({ children }) => {
  const context = useRIContext();

  return (
    <>
      <RiContext.Provider value={context}>{children}</RiContext.Provider>
    </>
  );
};
