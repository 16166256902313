import Joi from "joi";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";

/**
 * A number, or a string containing a number.
 * @typedef SsoUrlReq
 * @type {object} SsoUrlReq
 * @property {string} email - Email address
 */

/** @type {Joi<SsoUrlReq>} */
export const SsoUrlReq = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }),
});

/**
 * A number, or a string containing a number.
 * @typedef SsoRes
 * @type {object}
 * @property {string} redirect_url - SSO redirect url
 */

/**
 * Sends login request and fetches the sso url to redirect to.
 * @param {SsoUrlReq} body Body of login form
 * @returns {Promise<SsoRes>} Returns redirect url
 */
export const getSsoUrl = async (body) => {
    const login = await fetch(`${config.authHost}/login/`, {
        method: 'POST',
        body: JSON.stringify(body)
    });
    if (!login.ok) {
        throw new Error((await login.json()).message);
    }
    return await login.json();
};

/**
 * @param {any[]=} deps 
 * @returns 
 */
export const useSsoUrl = (deps = []) => {
    const mutate = useMutation({
      mutationKey: ["refreshToken", ...deps],
      mutationFn: (body) => getSsoUrl(body),
    });
    return mutate;
  };
  