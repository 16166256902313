import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import privilagesIcon from "@/assets/img/privilages_icon.svg";
import { useUsersById } from "@/services/admin/Roles/getUsersById.repo";
import { useAppContext, AppContext } from "@/App.context";
import AdminGroupModal from "../user profile/admin.group.modal";
import { UsersContext } from "../user profile/admin.context";


const UserProfileTable= () => {
  const userContext = useContext(UsersContext);
  const appContext = useContext(AppContext);


  const handleClick = (item) => {
    // groupContext.setGroupSidebarItem(item);
    userContext.setSelectedItem(item);
  };


  const selectedUserID = appContext?.user?.id || null;

  const {
    data: UserData,
    isLoading,
    refetch: refetchUserIds,
  } = useUsersById(selectedUserID);

  return (
    <>
      <Table responsive className={styles.table} style={{ marginTop: "24px" }}>
        <thead className={styles.tableHeadStyling}>
          <tr>
            <th>Name</th>
            <th style={{ width: "70%" }}>Group Description</th>
            <th>Privileges</th>
          </tr>
        </thead>
        <tbody>
          {(UserData?.groups || []).map((item) => (
            <tr className={styles.tableRowStyling}>
              <td className="elipese" onClick={() => handleClick(item.id)}>
                <u
                  style={{
                    color: "#056EE5",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  {item.name}
                </u>
              </td>
              <td className="elipese" style={{ maxWidth: "700px" }}>
                {" "}
                {item.user_description}
              </td>
              <td className="elipese">
                <span className={styles.privilages_css}>
                  <img src={privilagesIcon} alt="Privilages Icon"></img>
                  <span style={{ marginLeft: "8px" }}>
                    {item?.privileges || "--"}
                  </span>
                </span>
              </td>
            </tr>
          ))}

          <tr></tr>
        </tbody>
      </Table>

      <AdminGroupModal
        handleClose={() => {
          userContext.setSelectedItem(undefined);
        }}
        selectedId={userContext.selectedItem}
        // hideGroup={userContext.setSelectedItem(undefined)}
      />
    </>
  );
};

export default UserProfileTable;
