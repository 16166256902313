import React from "react";
import FilterState from "./FilterState";
import TableErrorState from "./TableErrorState";
import HeaderState from "./HeaderState";
import Placeholder from "react-bootstrap/Placeholder";

import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import BarGraphErrorStates from "./BarGraphErrorStates";

function UsageDetailState() {
  const data = [
    { name: "Category 1", value1: 20 },
    { name: "Category 2", value1: 40 },
    { name: "Category 3", value1: 15 },
    { name: "Category 4", value1: 20 },
    { name: "Category 5", value1: 40 },
    { name: "Category 6", value1: 15 },
    { name: "Category 7", value1: 20 },
    { name: "Category 8", value1: 40 },
    { name: "Category 9", value1: 15 },
    { name: "Category 10", value1: 20 },
    { name: "Category 11", value1: 40 },
    { name: "Category 12", value1: 15 },
    { name: "Category 13", value1: 20 },
    { name: "Category 14", value1: 40 },
    { name: "Category 15", value1: 15 },
    { name: "Category 16", value1: 20 },
    { name: "Category 17", value1: 40 },
    { name: "Category 18", value1: 15 },
    { name: "Category 19", value1: 20 },
    { name: "Category 20", value1: 40 },
    { name: "Category 21", value1: 15 },
  ];
  return (
    <div style={{ display: "flex", marginBottom: "30px" }}>
      <div style={{ width: "100%", borderRight: "2px solid #DEE2E6" }}>
        <HeaderState />

        <div style={{ paddingLeft: "40px" }}>
          <div>
            <Placeholder
              className={styles.placeholder_css}
              xs={2}
              style={{ height: "25px", marginBottom: "12px" }}
            />
            <Row>
              <Col xs={10}>
                <Placeholder
                  className={styles.placeholder_css}
                  xs={8}
                  style={{ height: "24px" }}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "32px",
                }}
              >
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "24px", width: "24px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "24px", color: "white" }}
                />
              </Col>
            </Row>

            {/* -----------------------------bar graph----------------------------------------------- */}

            <div style={{width:"100%",display:"flex"}}>


            <ResponsiveContainer width={"50%"} height={300}>
              <BarChart
                barSize={36}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  stroke="var(--Neutral-D9, #F8F9FA) "
                />
                <XAxis dataKey="name" hide />
                <YAxis
                  stroke="var(--Neutral-D9, #F8F9FA)"
                  tick={<CustomYAxisTick />}
                />

                <Bar dataKey="value1" fill="#F8F9FA" animation="glow"></Bar>
              </BarChart>
            </ResponsiveContainer>

            {/* ----------------------------------------------------------------------------------- */}


            <ResponsiveContainer width={"50%"} height={255} style={{display:"flex"}}>

            <Placeholder
              className={styles.placeholder_css}
              xs={2}
              style={{ height: "244px", width: "228px", borderRadius: "50%", marginBottom: "12px" }}
            />


            <div style={{display:"flex", alignItems:"center" , paddingLeft:"30px"}}>
        
            <Row style={{display:"flex", gap:"16px"}}>
              <Col xs={5}
                style={{
                  display: "flex",
                  marginRight: "132px",
                  gap:"16px"
                }}
              >
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "40px", borderRadius:"2px" }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "324px", borderRadius:"2px"  }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "100px", borderRadius:"2px"  }}
                />
              </Col>
              <Col xs={5}
                style={{
                  display: "flex",
                  marginRight: "132px",
                  gap:"16px"
                }}
              >
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "40px", borderRadius:"2px" }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "324px", borderRadius:"2px"  }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "100px", borderRadius:"2px"  }}
                />
              </Col>
              <Col xs={5}
                style={{
                  display: "flex",
                  marginRight: "132px",
                  gap:"16px"
                }}
              >
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "40px", borderRadius:"2px" }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "324px", borderRadius:"2px"  }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "100px", borderRadius:"2px"  }}
                />
              </Col>
              <Col xs={5}
                style={{
                  display: "flex",
                  marginRight: "132px",
                  gap:"16px"
                }}
              >
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "40px", borderRadius:"2px" }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "324px", borderRadius:"2px"  }}
                />
                <Placeholder
                  className={styles.placeholder_css}
                  style={{ height: "15px", width: "100px", borderRadius:"2px"  }}
                />
              </Col>
          
            </Row>


            </div>

           
              
            </ResponsiveContainer>
            </div>

            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "16px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack direction="horizontal" gap={2}>
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 15 }}
                  />
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 116 }}
                  />
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 15 }}
                  />
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 116 }}
                  />
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 15 }}
                  />
                  <Placeholder
                    className={styles.placeholder_css}
                    style={{ height: "15px", width: 116 }}
                  />
                </Stack>
              </div>
            </Row>
          </div>
        </div>

        <div style={{ paddingLeft: "40px", paddingRight: "30px" }}>
          <TableErrorState />
        </div>
      </div>

      <FilterState />
    </div>
  );
}

export default UsageDetailState;
const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <rect x={-33} y={0} width={36} height={24} fill="#F8F9FA" />
  </g>
);
