import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const getStackedData = (token) => {
  return async (req) => {
    let service = req.service;
    var costUsage;
    let body = {};
    if (
      req?.filters &&
      req?.enableFilter &&
      Object.keys(req?.filters).length > 0
    ) {
      const body = {
        fromDate: req.fromDate,
        toDate: req.toDate,
        granularity: req.granularity,
        ...req.filters,
        ...req.tags,
      };
      costUsage = await fetch(
        `${config.apiHost}/v1/${req.service}/kubernetes/filter`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } else {
      costUsage = await fetch(
        `${config.apiHost}/v1/${req.service}/kubernetes/dimensions?fromDate=${req.fromDate}&toDate=${req.toDate}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
          },
        }
      );
    }
    if (!costUsage.ok) {
      if (ERROR_STATUS_CODES.includes(costUsage.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (costUsage.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await costUsage.json()).message);
    }

    function getEffectiveLength(array) {
      if (array.length === 1 && Object.keys(array[0]).length === 0) {
        return 0;
      }
      return array.length;
    }
    const data = await costUsage.json();

    const hasGraphData = Object.values(data).some(
      (item) => item.graphData && item.graphData.length > 0
    );
    const AllcloudHasData = getEffectiveLength(
      Array.isArray(data?.dimensionValuesAws)
        ? data?.dimensionValuesAws
        : Array.isArray(data?.dimensionValuesAzure)
        ? data?.dimensionValuesAzure
        : []
    );

    if (service === "aws" || service === "azure") {
      if (!hasGraphData) {
        if (req.fromDate && req.toDate) {
          throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
        } else if (
          req.granularity ||
          Object.keys(req.filters).length > 0 ||
          Object.keys(req.tags).length > 0
        ) {
          throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
        }

        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    } else if (service === "all-cloud") {
      if (AllcloudHasData === 0) {
        if (
          req.fromDate &&
          req.toDate &&
          req.fromDate !== "" &&
          req.toDate !== ""
        ) {
          throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
        } else {
          throw new Error(API_NO_DATA_FOUND_ERROR);
        }
      }
    }

    return data;
  };
};

export const useKuberneteStackedData = (req, selectedValues, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStackedStatistics", req, ...deps],
    queryFn: () => getStackedData(appContext.idToken)(req),
  });
  return query;
};
