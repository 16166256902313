import React, { useState, useRef, useMemo, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import BarGraphComponent from "./bar-graph-component";
import LineChartComponent from "./line-chart-component";
import { FilterContext } from "../filter-section/filter-section.component";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import { UseForecastDownload } from "@/services/costExplorer/getForecastDownload.repo";

const ForecastedBarGraph = ({ data, accounts, isLoading, error, service }) => {
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);
  const filterContext = useContext(FilterContext);

  const mappedData = (data || []).map((item) => ({
    formatted_month: item.formatted_month,
    "Forecasted Cost": item.total_amount,
    "Current Cost": item.current_cost,
  }));

  const prepareStackedBarData = (data) => {
    if (!data || data.length === 0) return [];
  
    const months = Object.keys(data[0]).filter((key) => key !== "account");
  
    const accountTotals = data.map((item) => ({
      account: item.account,
      total: months.reduce((sum, month) => sum + item[month], 0),
    }));
    const maxEntriesToShow = 3;
  
    const sortedAccounts = accountTotals
      .sort((a, b) => b.total - a.total)
      .slice(0, maxEntriesToShow)
      .map((entry) => entry.account);
  
    const stackedData = months.map((month) => {
      const monthData = { month };
      let othersTotal = 0;
  
      data.forEach((item) => {
        if (sortedAccounts.includes(item.account)) {
          monthData[item.account] = item[month];
        } else {
          othersTotal += item[month];
        }
      });
      if (accountTotals.length > maxEntriesToShow) {
        monthData["Others"] = othersTotal;
      }
  
      return monthData;
    });
  
    return stackedData;
  };
  
  const stackedBarData = prepareStackedBarData(data);
  
  

  // ----------------------------------------------------------------------------------------------
  

  const predefinedColors = [
    "#E8BF67",
    "#6EA57C",
    "#6D5DED",
    "#a5d6a7",
  ];

  const predefinedColorMap = {
    "Forecasted Cost": "#82B3AF",
    "Current Cost": "#D9E8E6",
    Others: "#CED4DA",
  };

  const colorMap = useMemo(() => {
    const dynamicColorMap = { ...predefinedColorMap };
    let colorIndex = 0;

    const keys = Object.keys(mappedData[0] || {}).concat(
      Object.keys(stackedBarData[0] || {})
    );

    keys.forEach((key) => {
      if (
        key !== "formatted_month" &&
        key !== "month" &&
        !dynamicColorMap[key]
      ) {
        dynamicColorMap[key] =
          predefinedColors[colorIndex] ||
          predefinedColors[colorIndex % predefinedColors.length];
        colorIndex++;
      }
    });

    return dynamicColorMap;
  }, [mappedData, stackedBarData]);

  // ----------------------------------------------------------------------------------------------
  const forecastData = data?.length || 0;

  let uniqueMonthCount;

  if (data && Array.isArray(data) && data.length > 0) {
    const monthKeys = Object.keys(data[0]).filter((key) => key !== "account");

    uniqueMonthCount = monthKeys.length;
  }

  // -----------------------download api-----------------------------------------------
  const tableQuery = useMemo(() => {
    return {
      service: service,
      accounts: filterContext.selectForecastAccount.map((e) => e.id),
    };
  }, [service, filterContext.selectForecastAccount]);

  const { mutate: downloadJson } = UseForecastDownload(tableQuery);

  const handleDownloadClick = () => {
    downloadJson(tableQuery);
  };

  return (
    <>
      <div
        className=" d-flex align-items-start flex-column"
        style={{
          gap: "20px",
        }}
      >
        <div className="d-flex flex-row justify-content-between w-100">
          {!isLoading && (data?.length > 0 || error) && (
            <div className="d-flex gap-2">
              <span className="all_cloud_text">
                {accounts.length === 0 ? forecastData : uniqueMonthCount} Months
                Forecasted Cost
              </span>
              <span className="last_days_name"></span>
            </div>
          )}
          {data?.length > 0 && (
            <span>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-disable-caret"
                  style={{
                    background: "#F0F6FF",
                    border: "none",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={menuicon} alt="Menu" width="3px" height="13px" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: "8px 12px" }}>
                  <Dropdown.Item
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                    className="toggle_drop_down"
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="line"
                    onClick={() => setChartType("line")}
                    className="toggle_drop_down"
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    eventKey="Download as CSV"
                    // onClick={handleDownloadCsv}
                    onClick={handleDownloadClick}
                    className="toggle_drop_down"
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={data || []}
                    filename={"GraphData.csv"}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )}
        </div>

        {isLoading && (
          <div style={{ width: "100%" }}>
            <BarGraphErrorStates />
          </div>
        )}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
        {data?.length > 0 && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 p-0">
                {accounts.length === 0 && (
                  <>
                    {chartType === "bar" ? (
                      <BarGraphComponent
                        barChartData={mappedData}
                        colorMap={colorMap}
                      />
                    ) : (
                      <LineChartComponent
                        lineChartData={mappedData}
                        colorMap={colorMap}
                      />
                    )}
                  </>
                )}
                {accounts.length > 0 && (
                  <>
                    {chartType === "bar" ? (
                      <BarGraphComponent
                        barChartData={stackedBarData}
                        colorMap={colorMap}
                      />
                    ) : (
                      <LineChartComponent
                        lineChartData={stackedBarData}
                        colorMap={colorMap}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ForecastedBarGraph;
