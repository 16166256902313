import React from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
} from "recharts";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";
import { CustomTooltip } from "./customtooltip.part";

export const LineChartPArt = ({ service, data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data?.data}
        margin={{ top: 0, right: 0, left: -25, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" vertical={false} />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <XAxis
          dataKey="idle_days"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
          minTickGap={5}
          fontFamily="Roboto"
        />
        <YAxis
          label={{ fill: "#495057" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
        />
        {service === "aws" && (
          <>
            <Line
              type="monotone"
              dataKey="cost"
              stroke="#056EE5"
              stackId="a"
            />
          </>
        )}
        {service === "azure" && (
          <>
            <Line
              type="monotone"
              dataKey="cost"
              stroke="#056EE5"
              stackId="a"
            />
          </>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
