import React, { useEffect, useMemo } from "react";
import "@/assets/css/customwidget.css";
import Button from "react-bootstrap/Button";
import { useDimensionValues } from "@/services/costExplorer/getDimensionValues.repo";
import styles from "./edit-widget.module.css";
import { FilterSearch } from "@/components/filter-search/filter-search";
import clsx from "clsx";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import { ReactComponent as AddIcon } from "@/assets/img/add.svg";
import { ReactComponent as RemoveIcon } from "@/assets/img/filter-delete.svg";
import Tooltip from "@/components/tooltip/tooltip";
import { endOfDay, startOfDay, sub, format } from "date-fns";

const FilterItem = ({
  field,
  watch,
  setValue,
  remove,
  watchCloud,
  errors,
  index,
  dimensionData,
  append,
}) => {
  const watchInterval = watch(`interval`);
  const watchShowPrevious = watch(`showPrevious`);
  const watchIsInclude = watch(`filters.${index}.isInclude`);
  const watchDimension = watch(`filters.${index}.dimension`);
  const watchValues = watch(`filters.${index}.values`);

  const watchFilters = watch("filters");

  const { fromDate, toDate } = useMemo(() => {
    return {
      toDate: endOfDay(new Date()).toISOString(),
      fromDate: startOfDay(
        sub(new Date(), {
          [watchInterval]: watchShowPrevious,
        })
      ).toISOString(),
    };
  }, [watchInterval, watchShowPrevious]);

  const { data: dimensionValues, isLoading } = useServiceDimensionValues(
    watchCloud,
    watchDimension,
    fromDate,
    toDate
  );

  const filteredValues =
    (watchValues || []).map((e) => ({
      id: e,
      name: e,
    })) || [];

  // const dimensionLabels = useMemo(() => {
  //   switch (watchCloud) {
  //     case "aws":
  //       return AWS_DIMENSIONS;
  //     case "azure":
  //       return AZURE_DIMENSIONS;
  //     case "gcp":
  //       return GCP_DIMENSIONS;
  //     default:
  //       return {};
  //   }
  // }, [watchCloud]);
  const dimensionLabels = useMemo(() => {
    return (dimensionData?.dimensions || [])
      .filter(({ id }) => id !== "tags")
      .map(({ id, name }) => ({
        id,
        name,
      }));
  }, [dimensionData]);

  const selectedFilterDimensions = useMemo(() => {
    return watchFilters.map((e) => e.dimension);
  }, [watchFilters]);

  const filterOptions = useMemo(() => {
    return dimensionLabels.filter(
      ({ id }) => !selectedFilterDimensions.includes(id)
    );
  }, [dimensionLabels, selectedFilterDimensions]);

  return (
    <>
      <div className={styles.filter_select_div}>
        <div className={styles.filter_select_css}>
          <div className="w-100">
            <FilterSearch
              options={filterOptions}
              displayValue="name"
              selectedValues={[watchDimension]}
              searchFieldPlaceholder={"Filter Grouping"}
              onSelect={(selectedItem) => {
                setValue(`filters.${index}.dimension`, selectedItem.id);
                setValue(`filters.${index}.values`, []);
              }}
              toggle={
                <div
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]:
                      !!dimensionLabels.find((d) => d.id === watchDimension),
                  })}
                >
                  {!dimensionLabels.find((d) => d.id === watchDimension) && (
                    <AddIcon className={styles.filter_dimension_toggle_icon} />
                  )}
                  {dimensionLabels.find((d) => d.id === watchDimension)?.name ||
                    "Select Filter"}
                </div>
              }
              // toggle={
              //   <div
              //     className={clsx(styles.filter_dimension_toggle, {
              //       [styles.filter_dimension_toggle_selected]:
              //         dimensionLabels[watchDimension]?.length > 0,
              //     })}
              //   >
              //     {Boolean(dimensionLabels[watchDimension]) === false && (
              //       <AddIcon className={styles.filter_dimension_toggle_icon} />
              //     )}
              //     {dimensionLabels[watchDimension]
              //       ? dimensionLabels[watchDimension]
              //       : "Select Filter"}
              //   </div>
              // }
            />
          </div>
          {dimensionLabels.find((d) => d.id === watchDimension) && (
            <Tooltip
              tooltipContent="Remove"
              overlayProps={{ placement: "bottom-end" }}
            >
              {(_show, setShow) => (
                <Button
                  variant="link"
                  className={clsx("p-0", styles.remove_icon)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  onClick={() => {
                    remove(index);
                    if (watchFilters.length === 1) {
                      append({
                        dimension: "",
                        isInclude: true,
                        values: [],
                      });
                    }
                  }}
                >
                  <RemoveIcon />
                </Button>
              )}
            </Tooltip>
          )}
        </div>

        <div className="d-block w-100">
          <FilterSearch
            multiselect
            searchFieldPlaceholder="Filter Service"
            inclusion={watchIsInclude}
            onInclusionChange={(inclusion) => {
              setValue(`filters.${index}.isInclude`, inclusion === "include");
            }}
            options={(dimensionValues || []).map((e) => ({
              id: e?.columnName || "null",
              name: e.columnName || "null",
            }))}
            displayValue="name"
            loading={isLoading}
            selectedValues={filteredValues}
            onSelect={(list) => {
              setValue(
                `filters.${index}.values`,
                list.map((e) => e.id)
              );
            }}
            onRemove={(list) => {
              setValue(
                `filters.${index}.values`,
                list.map((e) => e.id)
              );
            }}
            disabled={!watchDimension.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {filteredValues.length === 0 && (
                  <span
                    className={clsx(styles.placeholder_text_filter, "px-3")}
                  >
                    Choose Values
                  </span>
                )}
                {filteredValues.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <img
                      src={watchIsInclude ? includeIcon : excludeIcon}
                      alt=""
                    />
                    <span
                      style={{
                        overflow: "hidden",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={filteredValues.map((e) => e.name)}
                    >
                      {filteredValues
                        .slice(0, 1)
                        .map((e) => e.name || null)
                        .join(", ")}
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
        {errors.filters && errors.filters[index].values && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.filters[index].values.message}
          </small>
        )}
        {errors.filters && errors.filters[index].dimension && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.filters[index].dimension.message}
          </small>
        )}
      </div>
    </>
  );
};

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AWS_DIMENSIONS} AWS_DIMENSIONS
 */

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AZURE_DIMENSIONS} AZURE_DIMENSIONS
 */

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {AWS_DIMENSIONS | AZURE_DIMENSIONS} columnName
 * @param {string} fromDate
 * @param {string} toDate
 * @returns
 */
export const useServiceDimensionValues = (
  service,
  columnName,
  fromDate,
  toDate
) => {
  const { data: values, isLoading } = useDimensionValues({
    columnName,
    service,
    fromDate: format(new Date(fromDate), "dd-MM-yyyy"),
    toDate: format(new Date(toDate), "dd-MM-yyyy"),
  });
  // useEffect(() => {
  //   if (columnName?.length > 0) {
  //     fetchValues();
  //   }
  // }, [columnName?.length, fetchValues, service]);
  return { data: values || [], isLoading };
};

export default FilterItem;
