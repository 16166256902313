import React from "react";
import { useContext, useMemo } from "react";
import {
  Offcanvas,
  Accordion,
  Card,
  Table,
  Spinner,
  Button,
} from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import dropdown from "@/assets/img/Dropdown_arrow.svg";
import clsx from "clsx";
import { useCostUsageStatics } from "@/services/costExplorer/getCost_Usage.repo";
import { endOfDay, startOfDay } from "date-fns";
import parse from "date-fns/parse";
import Tooltip from "@/components/tooltip/tooltip";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

function ContextAwareToggle({ children, eventKey, callback, costdata }) {
  const { activeEventKey } = useContext(AccordionContext);

  const colors = useMemo(() => {
    switch (eventKey) {
      case "0":
        return ["rgba(231, 192, 103,0.1)", "rgba(231, 192, 103)"];
      case "1":
        return ["rgba(223, 131, 103,0.1)", "rgba(223, 131, 103)"];
      case "2":
        return ["rgba(127, 186, 255,0.1)", "rgba(127, 186, 255)"];
      case "3":
        return ["rgba(157, 98, 110,0.1)", "rgba(157, 98, 110)"];
      case "4":
        return ["rgba(110, 165, 124,0.1)", "rgba(110, 165, 124)"];
      case "5":
        return ["rgba(206, 212, 218,0.1)", "rgba(206, 212, 218)"];
      case "6":
        return ["rgba(255, 99, 132,0.1)", "rgba(255, 99, 132)"];
      case "7":
        return ["rgba(54, 162, 235,0.1)", "rgba(54, 162, 235)"];
      case "8":
        return ["rgba(75, 192, 192,0.1)", "rgba(75, 192, 192)"];
      default:
        return ["rgba(255, 99, 132,0.1)", "rgba(255, 99, 132)"];
    }
  }, [eventKey]);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className={styles.card_styling}
      style={{ backgroundColor: colors[0], cursor: "pointer" }}
      onClick={decoratedOnClick}
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <span
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "2px",
            backgroundColor: colors[1],
          }}
        ></span>
        {children}
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <span>${Number(costdata?.totalCost).toFixed(2) || 0}</span>
        <img src={dropdown} alt="" />
      </div>
    </div>
  );
}

export default function SideModal(props) {
  const fromDate = startOfDay(
    parse(props.fromDate, "dd-MM-yyyy", new Date())
  ).toISOString();

  const toDate = endOfDay(
    parse(props.toDate, "dd-MM-yyyy", new Date())
  ).toISOString();

  const query = useMemo(() => {
    return {
      showData: props.showModal,
      service: props.service,
      fromDate: fromDate,
      toDate: toDate,
    };
  }, [fromDate, props.service, props.showModal, toDate]);

  const { data: eventKeys, isLoading, error } = useCostUsageStatics(query);

  let serviceName;

  if (props?.service === "allclouds") {
    serviceName = "All Clouds";
  } else if (props?.service === "aws") {
    serviceName = "AWS";
  } else if (props?.service === "azure") {
    serviceName = "Azure";
  }

  return (
    <>
      <Offcanvas
        show={props.showModal}
        onHide={props.handleClose}
        {...props}
        placement="end"
        style={{ width: "34%", backgroundColor: error ? "#F8F9FA" : "white" }}
      >
        <Offcanvas.Header
          className={styles.modal_title1}
          style={{ padding: "36px 36px 0px 36px" }}
          closeButton
        >
          {serviceName} Costs & Usage
        </Offcanvas.Header>

        {isLoading ? (
          <div className={styles.errohandling}>
            <Spinner />
          </div>
        ) : (eventKeys?.serviceFamilies || []).length > 0 ? (
          <>
            <span
              style={{ padding: "0px 36px 16px 36px", marginBottom: "0px" }}
              className={styles.ComboSECDivFirstDiv}
            >
              ${Number(eventKeys?.totalCost).toFixed(2) || 0}
            </span>

            <Offcanvas.Body style={{ padding: "0px 36px 36px 36px" }}>
              <Accordion style={{ width: "100%" }} defaultActiveKey="0" flush>
                <CardHolder eventKeys={eventKeys} service={props.service} />
              </Accordion>
            </Offcanvas.Body>
          </>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            {error && (
              <div style={{ textAlign: "center", marginTop: "30%" }}>
                <ScenariosErrorState
                  error={error.message}
                  messageConfig={{
                    [API_LOADING_ERROR]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                    [API_NO_DATA_FOUND_ERROR]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Offcanvas>
    </>
  );
}

const CardHolder = ({ eventKeys, service }) => {
  return (
    <>
      {(eventKeys?.serviceFamilies || []).map((e, index) => (
        <Card
          key={index}
          style={{
            borderRadius: "0px",
            border: "none",
            marginBottom: "24px",
          }}
        >
          <Card.Header
            style={{
              border: "none",
              padding: "0px",
              backgroundColor: "white",
              height: "25px",
            }}
          >
            <ContextAwareToggle eventKey={String(index)} costdata={e}>
              {e?.serviceFamily || "Null"}
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={String(index)}>
            <Card.Body style={{ padding: "0px" }}>
              <Table responsive borderless>
                <thead>
                  <tr
                    className={styles.modal_title1}
                    style={{ fontWeight: "600", color: "#343A40" }}
                  >
                    <th style={{ width: "30%", padding: "4px 0px 4px 28px" }}>
                      Services
                    </th>

                    {service === "allclouds" && (
                      <th style={{ textAlign: "center", width: "30%" }}>
                        Cloud
                      </th>
                    )}

                    <th style={{ textAlign: "right", width: "30%" }}>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {e?.services
                    .filter((i) => i.cloud === "AWS")
                    .reduce((acc, item) => acc + Number(item?.cost || 0), 0) >
                    0 &&
                    e?.services
                      .filter((i) => i.cloud === "AWS")
                      .map((item, idx) => (
                        <tr key={idx}>
                          <td
                            className={clsx(styles.tableBodyStyling)}
                            style={{ padding: "4px 0px 4px 28px" }}
                          >
                            {item?.service || "Null"}
                          </td>
                          {service === "allclouds" && (
                            <td
                              className={styles.tableBodyStyling}
                              style={{
                                textAlign: "center",
                                padding: "4px 0px",
                              }}
                            >
                              {item?.cloud || "Null"}
                            </td>
                          )}
                          <td
                            className={styles.tableBodyStyling}
                            style={{
                              textAlign: "right",
                              padding: "4px 8px 4px 0px",
                            }}
                          >
                            <Tooltip
                              tooltipContent={
                                item?.cost != null
                                  ? "$" + (item?.cost || 0).toFixed(8)
                                  : "$0"
                              }
                              overlayProps={{ placement: "top" }}
                            >
                              {(show, setShow) => (
                                <Button
                                  onMouseLeave={() => setShow(false)}
                                  onMouseEnter={() => setShow(true)}
                                  variant="link"
                                  className={styles.tableBodyStyling}
                                  style={{
                                    textAlign: "right",
                                    padding: "4px 8px 4px 0px",
                                  }}
                                >
                                  {/* {item?.cost === null && "$0"} */}
                                  {item?.cost < 0.1 && "< $0.1"}
                                  {item?.cost > 0.1 &&
                                    "$" + (item?.cost || 0).toFixed(2)}
                                </Button>
                              )}
                            </Tooltip>
                          </td>
                        </tr>
                      ))}

                  {e?.services
                    .filter((i) => i.cloud === "AWS")
                    .reduce((acc, item) => acc + Number(item?.cost || 0), 0) >
                    0 &&
                    service === "allclouds" && (
                      <tr>
                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 0px",
                          }}
                        ></td>

                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 0px",
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          AWS
                        </td>

                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 8px 0px 4px ",
                            textAlign: "right",
                            fontWeight: 600,
                          }}
                        >
                          $
                          {Number(
                            e?.services
                              .filter((i) => i.cloud === "AWS")
                              .reduce(
                                (acc, item) => acc + Number(item?.cost || 0),
                                0
                              )
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}

                  {e?.services
                    .filter((i) => i.cloud === "Azure")
                    .reduce((acc, item) => acc + Number(item?.cost || 0), 0) >
                    0 &&
                    e?.services
                      .filter((i) => i.cloud === "Azure")
                      .map((item, idx) => (
                        <tr key={idx}>
                          <td
                            className={clsx(styles.tableBodyStyling)}
                            style={{ padding: "4px 0px 4px 28px" }}
                          >
                            {item?.service || "Null"}
                          </td>

                          {service === "allclouds" && (
                            <td
                              className={styles.tableBodyStyling}
                              style={{
                                textAlign: "center",
                                padding: "4px 0px",
                              }}
                            >
                              {item?.cloud || "Null"}
                            </td>
                          )}
                          <td
                            className={styles.tableBodyStyling}
                            style={{
                              textAlign: "right",
                              padding: "4px 8px 4px 0px",
                            }}
                          >
                            <Tooltip
                              tooltipContent={
                                item?.cost != null
                                  ? "$" + (item?.cost || 0).toFixed(8)
                                  : "$0"
                              }
                              overlayProps={{ placement: "top" }}
                            >
                              {(show, setShow) => (
                                <Button
                                  onMouseLeave={() => setShow(false)}
                                  onMouseEnter={() => setShow(true)}
                                  variant="link"
                                  className={styles.tableBodyStyling}
                                  style={{
                                    textAlign: "right",
                                    padding: "4px 8px 4px 0px",
                                  }}
                                >
                                  {/* {item?.cost === null && "$0"} */}
                                  {item?.cost < 0.1 && "< $0.1"}
                                  {item?.cost > 0.1 &&
                                    "$" + (item?.cost || 0).toFixed(2)}
                                </Button>
                              )}
                            </Tooltip>
                          </td>
                        </tr>
                      ))}

                  {e?.services
                    .filter((i) => i.cloud === "Azure")
                    .reduce((acc, item) => acc + Number(item?.cost || 0), 0) >
                    0 &&
                    service === "allclouds" && (
                      <tr>
                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 0px",
                          }}
                        ></td>

                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 0px",
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          Azure
                        </td>

                        <td
                          className={styles.tableBodyStyling}
                          style={{
                            backgroundColor: "#F8F9FA",
                            padding: "4px 8px 0px 4px ",
                            textAlign: "right",
                            fontWeight: 600,
                          }}
                        >
                          $
                          {Number(
                            e?.services
                              .filter((i) => i.cloud === "Azure")
                              .reduce(
                                (acc, item) => acc + Number(item?.cost || 0),
                                0
                              )
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </>
  );
};
