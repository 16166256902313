import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/ai-recommendations/ai-recommend.module.css";
import clsx from "clsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import cpulogo from "@/assets/img/cpulogo.svg";
import memorylogo from "@/assets/img/memorylogo.svg";
import Tooltip from "react-bootstrap/Tooltip";
import menuicon from "@/assets/img/menu.svg";
import { AiRecommendContext } from "./ai-recom-context-file";
import {
  useUsagePatternDownload,
  useUsagePatternStats,
} from "@/services/ai-recommendation/getUsagePatterns.repo";
import { useSearchParams } from "react-router-dom";
import Pagination from "@/components/pagination/pagination.component";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import TableErrorState from "@/components/States/TableErrorState";
import downlaodVM from "@/assets/img/downloadVM.svg";

const UsagePatternsTable = ({ service, accountName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(AiRecommendContext);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [hideIcon, setHideIcon] = useState("")

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      tableName: "usage-patterns",
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        resourcegroup: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        region: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        owner: context?.selectOwner?.length
          ? context.selectOwner.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectOwner,
    context.selectRegions,
    context.selectedResourceGroup,
    itemsPerPage,
    page,
    service,
  ]);
  const { data, isLoading, error } = useUsagePatternStats(query);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      context?.setHideFilter(true);
    } else {
      context?.setHideFilter(false);
    }
  }, [context, error]);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR)
    ) {
      setHideIcon(true);
    } else {
      setHideIcon(false);
    }
  }, [ error]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", "1");
      return updatedParams;
    });
  }, [
    context.selectAccounts,
    context.selectedResourceGroup,
    context.selectRegions,
    context?.selectOwner,
  ]);

  const downloadQuery = useMemo(() => {
    return {
      service,
      tableName: "usage-patterns",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        resourcegroup: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        region: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        owner: context?.selectOwner?.length
          ? context.selectOwner.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectOwner,
    context.selectRegions,
    context.selectedResourceGroup,
    service,
  ]);

  const { mutate: downloadMutate } = useUsagePatternDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      <div
        className={styles.table_stuc}
        style={{
          width: context?.hideFilter ? "100%" : "82%",
          paddingRight: context?.hideFilter ? "0px" : "24px",
          marginTop: context.hideFilter ? "0px" : "24px",
          background: context?.hideFilter
            ? "var(--Neutral-D9, #F8F9FA)"
            : "transparent",
          paddingBottom: context.hideFilter ? "0px" : "32px",
        }}
      >
          <div className={styles.usage_pattern_box}>
        {!context?.hideFilter && (
            <span className={styles.usage_pattern_name}>
              Usage Patterns ({data?.data?.count || 0})
            </span>
            )}
                    {!(context.hideFilter || hideIcon) && (

            <div className={styles.menu_icon_css1}>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
              >
                <span
                  className={styles.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <img src={downlaodVM} alt="" />
                </span>
              </OverlayTrigger>
            </div>
                    )}
          </div>
        {service === "azure" && <>{isLoading && <TableErrorState />}</>}
        {error && (
          <div style={{ width: "100%", height: "100%" }}>
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
              }}
            />
          </div>
        )}
        {data?.data?.count > 0 && (
          <>
            <div style={{ overflowX: "auto", minWidth: "30%" }}>
              <table style={{ width: "100%" }} className="table-hover">
                <thead>
                  <tr>
                    <th className={styles.table_head_css}>{accountName}</th>
                    <th className={styles.table_head_css}>Resource Name/ID</th>
                    {service === "azure" && (
                      <th className={styles.table_head_css}>Resource Group</th>
                    )}
                    <th className={styles.table_head_css}>Region</th>
                    {service === "aws" && (
                      <th className={styles.table_head_css}>Owner</th>
                    )}
                    <th className={styles.table_head_css}>CPU Utilization</th>
                    <th className={styles.table_head_css}>
                      Memory Utilization
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(data?.data?.UsagePattern || []).map((item, index) => (
                    <tr key={index}>
                      <td
                        className={clsx(
                          styles.table_body_css,
                          styles.trunc_ai_css
                        )}
                        title={item?.accountname}
                      >
                        {item?.accountname || "--"}
                      </td>
                      <td className={styles.table_body_css}>
                        <div className={styles.describe_main}>
                          <span
                            className={styles.trunc_ai_css}
                            title={item?.resourcename}
                          >
                            {item?.resourcename || "--"}
                          </span>
                          <span
                            className={clsx(
                              styles.table_desc,
                              styles.trunc_ai_css
                            )}
                            title={item.resourceid}
                          >
                            {item?.resourceid || "--"}
                          </span>
                        </div>
                      </td>
                      {service === "azure" && (
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item.resourcegroupname}
                        >
                          {item?.resourcegroupname || "--"}
                        </td>
                      )}
                      <td
                        className={clsx(
                          styles.table_body_css,
                          styles.trunc_ai_css
                        )}
                        title={item.region}
                      >
                        {item?.region || "--"}
                      </td>
                      {service === "aws" && (
                        <td
                          className={clsx(
                            styles.table_body_css,
                            styles.trunc_ai_css
                          )}
                          title={item.owner}
                        >
                          {item?.owner || 0}
                        </td>
                      )}
                      <td
                        className={clsx(
                          styles.table_body_css,
                          styles.trunc_ai_css
                        )}
                      >
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id={`tooltip-${index}`}
                              className={styles.tooltip_css}
                            >
                              {item?.cpu_pattern_message || "--"}
                            </Tooltip>
                          }
                        >
                          <div className={styles.describe_main}>
                            <PercentBadge
                              type="cpu"
                              value={item?.cpu_percentage || 0}
                            />

                            <span
                              className={clsx(
                                styles.table_desc,
                                styles.trunc_ai_css
                              )}
                            >
                              {item?.cpu_pattern_message || "--"}
                            </span>
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td
                        className={clsx(
                          styles.table_body_css,
                          styles.trunc_ai_css
                        )}
                      >
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id={`tooltip-${index}`}
                              className={styles.tooltip_css}
                            >
                              {item?.memory_pattern_message || "--"}
                            </Tooltip>
                          }
                        >
                          <div className={styles.describe_main}>
                            <PercentBadge
                              type="mem"
                              value={item?.memory_percentage}
                            />

                            <span
                              className={clsx(
                                styles.table_desc,
                                styles.trunc_ai_css
                              )}
                            >
                              {item?.memory_pattern_message || "--"}
                            </span>
                          </div>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination totalItems={data?.data?.count || 0} />
          </>
        )}
      </div>
    </>
  );
};

const PercentBadge = ({ value, type }) => {
  const img = useMemo(() => {
    switch (type) {
      case "cpu":
        return cpulogo;
      case "mem":
        return memorylogo;
      default:
        return null;
    }
  }, [type]);

  return (
    <span className={styles.percentageBadgeCont}>
      {img && (
        <img
          src={img}
          width="16px"
          height="16px"
          style={{ marginRight: "6px" }}
          alt=""
        />
      )}

      <span
        className={clsx(styles.badge, {
          [styles.badgeOk]: value < 50 && value >= 0,
          [styles.badgeWarning]: value >= 50 && value < 80,
          [styles.badgeDanger]: value >= 80,
          [styles.badgeNA]: value === undefined || value === null,
        })}
      >
        {(value === undefined || value === null) && "--"}
        {value !== undefined &&
          value !== null &&
          Number(value).toFixed(1) + "%"}
      </span>
    </span>
  );
};

export default UsagePatternsTable;
