import React from "react";
import { useContext, useState } from "react";
import { Table } from "react-bootstrap";
import styles from "../admin.module.css";
import editImg from "@/assets/img/edit.svg";
import groupImg from "@/assets/img/groups.svg";
import SideModalGroup from "@/page-components/admin/roles/user.info.modal.part";
import format from "date-fns/format";
import { Spinner } from "react-bootstrap";
import AddUserModal from "./create.user.modal.part";
import { useUsersById } from "@/services/admin/Roles/getUsersById.repo";
import {RolesContext, RolesContextProvider} from "@/page-components/admin/roles/roles.context"
import { useDeleteUser } from "@/services/admin/Roles/deleteUser.repo";
import LoadingRbacTable from "../states/loading.state.part";

const UserTableComp = ({ data, loading, refetch }) => {
  return (
    <RolesContextProvider>
      {loading && <LoadingRbacTable/>}
      {data && <UserTable data={data} loading={loading} refetch={refetch} />}
    </RolesContextProvider>
  );
};

const UserTable = ({ data, loading, refetch }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const adminContext = useContext(RolesContext);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);

  const {
    data: UserData,
    isLoading,
    refetch: refetchUserIds,
  } = useUsersById(selectedUserID);

  const {
    data: deleteUserGroup,
    mutate: deleteUser,
    isLoading: deletingUser,
    reset: deleteReset,
  } = useDeleteUser();

  const handleTableRowClick = (item) => {
    setSelectedItem(item);
    adminContext.handleGroupShow();
    adminContext.setIsAdminInfoModal(false);
  };

  const handleModalClose = () => {
    setSelectedItem(null);
  };
  const handleOpenShow = (item) => {
    setSelectedUserID(item.id);
    setAddModalOpen(true);
  };
  const handleOpenClose = () => {
    setAddModalOpen(false);
  };

  return (
    <>
      <Table responsive className={styles.table}>
        <thead className={styles.tableHeadStyling}>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Groups</th>
            <th>User Description</th>
            <th>Creator</th>
            <th>Created on</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data && data.total === 0 && (
            <tr>
              <td colSpan={6}>
                <div className="py-5 d-flex align-items-center justify-content-center">
                  No Roles to display
                </div>
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan={6}>
                <div className="py-5 d-flex align-items-center justify-content-center">
                  <Spinner variant="primary" />
                </div>
              </td>
            </tr>
          )}
          {(data || []).map((item) => (
            <tr className={styles.tableRowStyling}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => handleTableRowClick(item.id)}
              >
                <u
                  style={{
                    color: "#056EE5",
                    fontWeight: "600",
                    pointer: "cursor",
                  }}
                >
                  {item.name}
                </u>{" "}
              </td>
              <td> {item.email}</td>
              <td className={styles.onclick_name}>
                <span className={styles.badgeHolder}>
                  <span
                    className={styles.readAccess}
                    style={{ backgroundColor: "#EBFAED" }}
                  >
                    <img src={groupImg} alt="" />
                    <span>{item.groups}</span>
                  </span>
                </span>
              </td>
              <td>{item.description || "-"} </td>
              <td>{item.creatorName || "-"}</td>
              <td>{format(new Date(item.createdAt), "dd/MM/yyyy")}</td>
              <td
                style={{ textAlign: "center" }}
                onClick={() => handleOpenShow(item)}
              >
                <img src={editImg} width="12px" height="12px" alt="" />
              </td>
            </tr>
          ))}

          <tr></tr>
        </tbody>
      </Table>
      {selectedItem && (
        <SideModalGroup
          selectedItem={selectedItem}
          handleClose={adminContext.handleGroupClose}
          refetch={refetch}
          usermodalHide={handleModalClose}
        />
      )}
      <AddUserModal
        show={addModalOpen}
        onHide={()=>{
          handleOpenClose()
        }
        }
        editData={UserData}
        deleteUser={deleteUser}
        refetchUserTable={refetch}
        UserID={selectedUserID}
        deleteGroup={() => {
          deleteUser(UserData?.user?.id);
          refetch();
        }}
        deleteGroupData={deleteUserGroup}
        reset={deleteReset}
      
      />
    </>
  );
};
export default UserTableComp;
