import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "@/page-components/admin/admin.module.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchBar from "../components/searchbar.modal";
import { OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import clsx from "clsx";
import { ReactComponent as CancelIcon } from "@/assets/img/cancel_button.svg";
import disable from "@/assets/img/disable.svg";
import { joiResolver } from "@hookform/resolvers/joi";
import { useFieldArray, useForm } from "react-hook-form";
import {
  AddPrivilegeReq,
  useAddPrivilege,
  PERMISSIONS,
} from "@/services/admin/Privileges/createPrivilege.repo";
import { useGetAccounts } from "@/services/admin/Roles/getAccountsForService.repo";
import { Controller } from "react-hook-form";
import { useUpdatePrivilege } from "@/services/admin/Privileges/updatePrivilege.repo";
import { usePrivilegesById } from "@/services/admin/Privileges/getPrivilegesById.repo";
import { AdminPrivilegeContext } from "./privilege.context";
import success from "@/assets/img/success_image.svg";
import failure from "@/assets/img/f-remove.svg";
import config from "@/config";

const tooltip = (
  <Tooltip id="tooltip">
    <span style={{ padding: "4px 8px" }}>
      Read access provided to the account is inherited and can’t be changed.{" "}
    </span>
  </Tooltip>
);

/**
 * @typedef CreatePrivilegeProps
 * @property {number=} privilegeID
 */

/**
 * @param {CreatePrivilegeProps} props
 */
export default function CreatePrivilege(props) {
  const [selectedTab, setSelectedTab] = useState(
    config.enabledClouds.size > 0 ? Array.from(config.enabledClouds)[0] : ""
  );
  const [filterQuery, setFilterQuery] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const adminContext = useContext(AdminPrivilegeContext);

  const {
    data: privilegeData,
    isLoading: privilegeDataLoading,
    refetch: refetchprivilege,
  } = usePrivilegesById(props?.privilegeID, [props.show]);

  const handleDeleteClick = () => {
    adminContext.setPrivilegeDeleteName(privilegeData?.privilege.name);
    adminContext.setPrivilegeDeleteModal(props?.privilegeID);
  };

  const handleTabChange = (selectedTab) => {
    setSelectedTab(selectedTab);
  };
  const handleFailureModalClose = () => {
    setShowFailureModal(false);
  };

  /** @type {import('react-hook-form').UseFormReturn<AddPrivilegeReq>} */
  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(AddPrivilegeReq),
    defaultValues: {
      accounts: [],
      name: "",
      description: "",
    },
  });

  const { append, fields, remove, replace } = useFieldArray({
    control,
    name: "accounts",
  });

  useEffect(() => {
    if (props.privilegeID) {
      // refetchprivilege();
      replace([]);
      reset();
    }
  }, [props.privilegeID, refetchprivilege, replace, reset]);

  useEffect(() => {
    if (privilegeData?.accounts) {
      replace(privilegeData?.accounts);
      setValue("name", privilegeData?.privilege.name);
      setValue("description", privilegeData?.privilege.description);
    }
  }, [privilegeData, props.show, replace, setValue]);

  const { data: accountsData, isLoading: acccountsLoading } = useGetAccounts({
    type: selectedTab,
  });

  const {
    data: privilegeAdded,
    mutate: addPrivilege,
    reset: resetPrivilege,
    isPending: privilegeAdding,
    error: privilegeAddError,
  } = useAddPrivilege();

  const {
    data: privilegeUpdated,
    mutate: updatePrivilege,
    reset: resetUpdatePrivilege,
    isPending: privilegeUpdating,
    error: privilegeUpdateError,
  } = useUpdatePrivilege();

  useEffect(() => {
    if (privilegeAdded) {
      setShowSuccessModal(true);
      props.onHide();
      setShowSuccessMessage("Privileges Created Successfully");
    }
  }, [privilegeAdded, props, refetchprivilege]);

  useEffect(() => {
    if (privilegeUpdated) {
      setShowSuccessModal(true);
      props.onHide();
      refetchprivilege();
      setShowSuccessMessage("Privileges Edited Successfully");
    }
  }, [privilegeUpdated, props, refetchprivilege]);

  useEffect(() => {
    if (privilegeAddError) {
      setShowFailureModal(true);
      adminContext?.setFailureMessage("Privileges Creation Failed");
      // handleCancel();
    }
  }, [privilegeAddError]);

  useEffect(() => {
    if (privilegeUpdateError) {
      setShowFailureModal(true);
      adminContext?.setFailureMessage("Privileges Editing Failed");
      // handleCancel();
    }
  }, [privilegeUpdateError]);

  const handleCancel = useCallback(() => {
    // reset();
    // replace();
    // resetPrivilege();
    props.onHide();
  }, [props]);

  useEffect(() => {
    if (adminContext.deletePrivilegeData) {
      reset();
    }
  }, [adminContext.deletePrivilegeData, reset]);
  


  const handleSetModal = () =>{
    setShowSuccessModal(false)
    refetchprivilege();
    resetPrivilege();
    // reset();
  }

  useEffect(() => {
    if (privilegeAdded) {
      adminContext.refetchPrivileges();
      handleCancel();
    }
  }, [adminContext, handleCancel, privilegeAdded]);



  useEffect(() => {
    if (privilegeUpdated) {
      adminContext.refetchPrivileges();
      resetUpdatePrivilege();
      handleCancel();
    }
  }, [adminContext, handleCancel, privilegeUpdated, resetUpdatePrivilege]);

  const onHide = () => {
    reset();
    props.onHide();
  };


  return (
    <>
      <Modal {...props} onHide={onHide} centered id={styles.mymainmodal}>
        {props.privilegeID && privilegeDataLoading && (
          <div
            className="d-flex justify-content-center align-items-center h-100 "
            style={{ minHeight: "700px" }}
          >
            <Spinner />
          </div>
        )}
        {((props.privilegeID && privilegeData) || !props.privilegeID) && (
          <form
            onSubmit={handleSubmit((req) => {
              const body = {
                name: req.name,
                description: req.description,
                accounts: [],
              };

              req.accounts.forEach((e) => {
                if (!e.permission) {
                  return;
                }

                body.accounts.push({
                  accountId: e.accountId,
                  accountName: e.accountName,
                  permission: e.permission,
                  type: e.type,
                  resourceGroupId: null,
                });

                (e.resourceGroups || []).forEach((r) => {
                  if (!r.permission) {
                    return;
                  }
                  body.accounts.push({
                    accountId: e.accountId,
                    accountName: e.accountName,
                    resourceGroupId: r.resourceGroupId,
                    permission: r.permission,
                    type: e.type,
                  });
                });
              });

              if (!body.accounts.length) {
                setError("accounts", {
                  type: "any.min",
                  message: "Please provide read or edit access",
                });
                return;
              }

              if (props.privilegeID) {
                updatePrivilege({
                  id: props.privilegeID,
                  ...body,
                });
              } else {
                addPrivilege(body);
              }
            })}
          >
            <div className={styles.preMainmodal}>
              <div className={styles.firstmodal}>
                <Modal.Header
                  style={{ border: "none", padding: "0px" }}
                  className={styles.modalHeader}
                >
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className={styles.modalTitle}
                  >
                    <span className={styles.mainTitle}>
                      {props.privilegeID ? "Edit" : "Create"} Privilege
                    </span>
                    <span className={styles.subTitle}>
                      Add details to {props.privilegeID ? "Edit" : "Create"}{" "}
                      privilege
                    </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                  <div className={styles.modalContainer}>
                    <div className={styles.modalBody}>
                      <span
                        className={styles.subTitle}
                        style={{ lineHeight: "20px" }}
                      >
                        Privilege Name<span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        type="text"
                        placeholder="Add Privilege Name"
                        className="form-control"
                        style={{ resize: "none" }}
                        {...register("name")}
                      />
                      {errors?.name && (
                        <p className={styles.errorMessage}>
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div className={styles.modalBody}>
                      <span
                        className={styles.subTitle}
                        style={{ lineHeight: "20px" }}
                      >
                        Privilege Description
                      </span>
                      <textarea
                        placeholder="Add Description"
                        className="form-control"
                        rows={4}
                        cols={50}
                        style={{ resize: "none", height: "72px" }}
                        {...register("description")}
                      />

                      {errors?.description && (
                        <p className={styles.errorMessage}>
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                    <span
                      className={styles.sunmaintitle}
                      style={{ lineHeight: "20px" }}
                    >
                      Accounts/Subscriptions/Projects{" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>

                    <div className={styles.cloudMain}>
                      <div className={styles.cloudsdiv}>
                        <span className={styles.selectCloud}>Select Cloud</span>
                        <div className={styles.mytabsdiv}>
                          <Tabs
                            activeKey={selectedTab}
                            // id="uncontrolled-tab-example"
                            className={styles.mb}
                            onSelect={handleTabChange}
                          >
                            {config.enabledClouds.has("aws") && (
                              <Tab eventKey="aws" title="AWS"></Tab>
                            )}
                            {config.enabledClouds.has("azure") && (
                              <Tab eventKey="azure" title="Azure"></Tab>
                            )}
                            {config.enabledClouds.has("gcp") && (
                              <Tab eventKey="gcp" title="GCP"></Tab>
                            )}
                          </Tabs>
                        </div>
                      </div>

                      <SearchBar
                        setFilterQuery={setFilterQuery}
                        className="w-100"
                      />

                      <div className={styles.mainDAta_contianer}>
                        <div className={styles.Datacontainer}>
                          {acccountsLoading === true && (
                            <div className={styles.mySpinner}>
                              <Spinner />
                            </div>
                          )}
                          {accountsData?.accounts
                            .filter((e) => {
                              if (!filterQuery.length) return true;
                              return e.name
                                ?.toLowerCase()
                                .includes(filterQuery.toLowerCase());
                            })
                            .map((account, index) => (
                              <label key={index} className={styles.mainRow}>
                                <input
                                  type="checkbox"
                                  checked={(fields || []).some(
                                    (e) => e.accountId === account.id
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      append({
                                        accountId: account.id,
                                        permission: undefined,
                                        type: selectedTab,
                                        accountName: account.name,
                                        resourceGroups: (
                                          account?.resourceGroups || []
                                        ).map((e) => {
                                          return {
                                            permission: undefined,
                                            resourceGroupId: e.id,
                                            resourceGroupName: e.name,
                                          };
                                        }),
                                      });
                                    } else {
                                      const index = fields.findIndex(
                                        (x) => account.id === x.accountId
                                      );
                                      remove(index);
                                    }
                                  }}
                                />
                                <span className={styles.row}>
                                  {account.name}
                                </span>
                              </label>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </div>
              <div className={styles.secondmodalpart}>
                <div style={{ overflowY: "auto", maxHeight: "550px" }}>
                  <Table>
                    <thead>
                      <tr>
                        <th className={styles.thead}>
                          Accounts/Subscriptions/Projects
                        </th>
                        <th
                          className={clsx(
                            styles.thead,
                            styles.textalign_center
                          )}
                        >
                          Edit
                        </th>
                        <th
                          className={clsx(
                            styles.thead,
                            styles.textalign_center
                          )}
                        >
                          Read
                        </th>
                      </tr>
                    </thead>
                    {fields.length > 0 ? (
                      <tbody>
                        {fields.map((item, index) => (
                          <>
                            <tr
                              style={{ borderBottom: "1px solid #F8F9FA" }}
                              key={index}
                              className={styles.mytr}
                            >
                              <td
                                colSpan={3}
                                className={clsx(styles.mytd, styles.gap)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <Button
                                    variant="link"
                                    onClick={() => remove(index)}
                                  >
                                    <CancelIcon />
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <span>
                                    {item.accountName || item.accountNo}
                                  </span>
                                </div>
                              </td>
                              <td
                                className={clsx(
                                  styles.mytd,
                                  styles.textalign_center
                                )}
                              >
                                <Controller
                                  control={control}
                                  name={`accounts.${index}.permission`}
                                  render={({ field: { value, onChange } }) => {
                                    return (
                                      <>
                                        <input
                                          checked={value === PERMISSIONS.edit}
                                          type="checkbox"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              onChange(PERMISSIONS.edit);
                                              (
                                                fields.at(index)
                                                  ?.resourceGroups || []
                                              ).forEach((e, rgIndex) => {
                                                setValue(
                                                  `accounts.${index}.resourceGroups.${rgIndex}.permission`,
                                                  PERMISSIONS.edit
                                                );
                                              });
                                            } else {
                                              onChange(PERMISSIONS.read);
                                              (
                                                fields.at(index)
                                                  ?.resourceGroups || []
                                              ).forEach((e, rgIndex) => {
                                                setValue(
                                                  `accounts.${index}.resourceGroups.${rgIndex}.permission`,
                                                  PERMISSIONS.read
                                                );
                                              });
                                            }
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                />
                              </td>
                              <td
                                id="mytd"
                                className={clsx(
                                  styles.mytd,
                                  styles.textalign_center
                                )}
                              >
                                <Controller
                                  control={control}
                                  name={`accounts.${index}.permission`}
                                  render={({ field: { value, onChange } }) => {
                                    return (
                                      <>
                                        {value === PERMISSIONS.edit ? (
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={tooltip}
                                          >
                                            <img
                                              className={styles.img_width}
                                              src={disable}
                                              alt=""
                                            />
                                          </OverlayTrigger>
                                        ) : (
                                          <>
                                            <input
                                              checked={
                                                value === PERMISSIONS.read
                                              }
                                              type="checkbox"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  onChange(PERMISSIONS.read);
                                                  (
                                                    fields.at(index)
                                                      ?.resourceGroups || []
                                                  ).forEach((e, rgIndex) => {
                                                    setValue(
                                                      `accounts.${index}.resourceGroups.${rgIndex}.permission`,
                                                      PERMISSIONS.read
                                                    );
                                                  });
                                                } else {
                                                  onChange(null);
                                                  (
                                                    fields.at(index)
                                                      ?.resourceGroups || []
                                                  ).forEach((e, rgIndex) => {
                                                    setValue(
                                                      `accounts.${index}.resourceGroups.${rgIndex}.permission`,
                                                      null
                                                    );
                                                  });
                                                }
                                              }}
                                            />
                                          </>
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </td>
                            </tr>

                            {item.resourceGroups && (
                              <tr style={{ borderBottom: "1px solid #F8F9FA" }}>
                                <td
                                  colSpan={3}
                                  style={{
                                    padding: "0px",
                                    background: "#f8f9fa",
                                  }}
                                >
                                  <Table>
                                    <tbody>
                                      {item.resourceGroups?.map(
                                        (rg, rgIndex) => (
                                          <tr
                                            style={{
                                              borderBottom: "1px solid #F8F9FA",
                                            }}
                                          >
                                            <td
                                              className={styles.my_truncatecell}
                                              style={{ background: "#f8f9fa" }}
                                            >
                                              {rg.resourceGroupName}
                                            </td>
                                            <td
                                              className={clsx(
                                                styles.mytd,
                                                styles.textalign_center
                                              )}
                                            >
                                              <Controller
                                                control={control}
                                                name={`accounts.${index}.resourceGroups.${rgIndex}.permission`}
                                                render={({
                                                  field: { value, onChange },
                                                }) => (
                                                  <input
                                                    checked={
                                                      value === PERMISSIONS.edit
                                                    }
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        onChange(
                                                          PERMISSIONS.edit
                                                        );
                                                      } else {
                                                        onChange(
                                                          PERMISSIONS.read
                                                        );
                                                        setValue(
                                                          `accounts.${index}.permission`,
                                                          PERMISSIONS.read
                                                        );
                                                      }
                                                    }}
                                                  />
                                                )}
                                              />
                                            </td>
                                            <td
                                              id="sectd"
                                              className={clsx(
                                                styles.mytd,
                                                styles.textalign_center
                                              )}
                                            >
                                              <Controller
                                                control={control}
                                                name={`accounts.${index}.resourceGroups.${rgIndex}.permission`}
                                                render={({
                                                  field: { value, onChange },
                                                }) => (
                                                  <>
                                                    {value ===
                                                    PERMISSIONS.edit ? (
                                                      <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={tooltip}
                                                      >
                                                        <img
                                                          className={
                                                            styles.img_width
                                                          }
                                                          src={disable}
                                                          alt=""
                                                        />
                                                      </OverlayTrigger>
                                                    ) : (
                                                      <input
                                                        checked={
                                                          value ===
                                                            PERMISSIONS.read ||
                                                          value ===
                                                            PERMISSIONS.edit
                                                        }
                                                        disabled={true}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            onChange(
                                                              PERMISSIONS.read
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    ) : (
                      <div className={styles.myselection}>No Selection</div>
                    )}
                  </Table>
                </div>
              </div>
            </div>
            <Modal.Footer style={{ border: "none" }}>
              {props.privilegeID ? (
                <>
                  <div className={styles.modal_user_footar}>
                    <div className={styles.button_main_div}>
                      <Button
                        variant="danger"
                        className={styles.delete_button}
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </Button>

                      {errors.accounts && (
                        <span className="d-block text-danger">
                          *
                          {errors.accounts?.message ||
                            errors.accounts?.root?.message}
                        </span>
                      )}

                      {privilegeAddError && (
                        <p className="text-danger">
                          *{privilegeAddError.message}
                        </p>
                      )}

                      {privilegeUpdateError && (
                        <p className="text-danger">
                          *{privilegeUpdateError.message}
                        </p>
                      )}
                    </div>
                    <div className={styles.buttons}>
                      <Button
                        type="button"
                        variant="outline-primary"
                        disabled={privilegeUpdating}
                        className={styles.cancelButtonp}
                        onClick={handleCancel}
                        style={{ width: "120px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={privilegeUpdating}
                        className={styles.edit_button}
                        style={{ width: "120px" }}
                      >
                        {privilegeUpdating ? "Loading..." : "Save"}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className={clsx(styles.footer, "align-items-center")}>
                  {(privilegeAddError ||
                    privilegeUpdateError ||
                    errors.accounts) && (
                    <div className="d-flex w-100">
                      {errors.accounts && (
                        <span className="d-block text-danger">
                          *
                          {errors.accounts?.message ||
                            errors.accounts?.root?.message}
                        </span>
                      )}
                      {privilegeAddError && (
                        <p className="text-danger">
                          *{privilegeAddError.message}
                        </p>
                      )}

                      {privilegeUpdateError && (
                        <p className="text-danger">
                          *{privilegeUpdateError.message}
                        </p>
                      )}
                    </div>
                  )}
                  <Button
                    disabled={privilegeAdding}
                    onClick={handleCancel}
                    variant="outline-primary"
                    className={styles.cancelButton}
                    type="button"
                    style={{ width: "15%" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={privilegeAdding}
                    className={styles.addButton}
                    type="submit"
                    style={{ width: "15%" }}
                  >
                    {privilegeAdding ? "Loading..." : "Add"}
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </form>
        )}
      </Modal>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={success} alt="" />
            </span>

            <span className={styles.success_message}>{showSuccessMessage}</span>
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_buttom}
              onClick={() => handleSetModal()}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*Failure Modal*/}
      <Modal
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body className={styles.success_body}>
          <div className={styles.success_image}>
            <span className={styles.flex_direction}>
              <img src={failure} alt="" />
            </span>

            <span className={styles.success_message}>
              {adminContext?.failureMessage}
            </span>
          </div>

          <div className={styles.close_button}>
            <Button
              className={styles.close_button}
              onClick={handleFailureModalClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
