import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import dropdown from "../assets/img/dropdown.svg";
import { Outlet } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import clsx from "clsx";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import NotificationModal from "./NotificationModal";
import "../assets/css/NotificationModal.css";
import Avatar from "@/assets/img/user_pic.svg";
import * as constants from "@/store/constant";
import { ReactComponent as RolesImg } from "@/assets/img/rbac-role.svg";
import { ReactComponent as Group } from "@/assets/img/rback_group.svg";
import { ReactComponent as PrivilegesImg } from "@/assets/img/rback-provisions.svg";
import { useContext } from "react";
import { AdminContext } from "./Admin.context";
import { group } from "@/assets/img/Group.svg";

const AdminLayout = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("privileges");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const context = useContext(AdminContext);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const changedUrl = location.pathname.split("/");
    let lastString = changedUrl[changedUrl.length - 1];
    setActiveButton(lastString);
  }, [activeButton, location.pathname]);

  const handleprivilegesClick = () => {
    navigate("/admin/privileges");
    setActiveButton("privileges"); // Update active button state
  };

  const handleRoleClick = () => {
    navigate("/admin/users");
    setActiveButton("users"); // Update active button state
  };

  const handleGroupsClick = () => {
    navigate("/admin/groups");
    setActiveButton("groups"); // Update active button state
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="row header shadow-sm"
          style={{ position: "fixed", width: "100%", zIndex: "2", top: "0" }}
        >
          <div className={clsx("col-sm-2 col-md-2 my-auto px-3 py-2")}>
            <div className="d-flex gap-3" style={{ width: "800px" }}>
              <div className="finops_name my-auto">FinOps</div>
              <div className="vr" style={{ backgroundColor: "white" }}></div>

              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: "#ffffff",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                Admin Account
              </span>
            </div>
          </div>

          <div
            className={clsx(
              "col-sm-10 col-md-8 col-lg-10 col-10 header-menu pb-0 d-flex flex-column justify-content-center"
            )}
          >
            <div className="row">
              <div className="col-sm-1 col-md-1 col-1 pl-0 m-auto"></div>

              <div className="col-sm-11 col-md-11 col-11 justify-content-end">
                <div className="row justify-content-end mr-6">
                  <div className="col-sm-10 col-md-10 col-10 text-right flex-header-menu justify-content-end">
                    <div className=" d-flex justify-content-between align-items-center gap-3 ">
                      <Dropdown>
                        <Dropdown.Toggle as={UserDropdown}>
                          <div>
                            <img
                              src={Avatar}
                              alt="Adam"
                              className="rounded-circle hover"
                              style={{ maxHeight: "36px", maxWidth: "36px" }}
                              width="36px"
                              height="36px"
                            />
                            <img
                              src={dropdown}
                              alt="Adam"
                              className="rounded-circle "
                              style={{ maxHeight: "36px", maxWidth: "36px" }}
                              width="36px"
                              height="36px"
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/all-clouds/dashboard");
                            }}
                          >
                            {" "}
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/logout");
                            }}
                          >
                            Sign Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="add_widget_overlay_screen">
          <NotificationModal
            toggleModal={toggleModal}
            style={{
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "red",
              top: "0",
            }}
          />
        </div>
      )}

      <div className="adminContainer">
        <div className="sideBar">
          {/* Privileges button */}
          <span
            className={clsx("sideBarButtons", {
              active: activeButton === "privileges",
            })}
            onClick={handleprivilegesClick}
          >
            <PrivilegesImg className="roles" />
            Privileges
          </span>

          {/* Groups button */}
          <span
            className={clsx("sideBarButtons", {
              active: activeButton === "groups",
            })}
            onClick={handleGroupsClick}
          >
            {/* <img className="groups" src={group} width="20px" height="20px" /> */}
            <Group className="roles" />
            Groups
          </span>

          {/* Role button */}
          <span
            className={clsx("sideBarButtons", {
              active: activeButton === "users",
            })}
            onClick={handleRoleClick}
          >
            {/* <img className="roles" src={rolesImg} /> */}
            <RolesImg className="roles" />
            Users
          </span>
        </div>
        <div className="mainContainer">{children ? children : <Outlet />}</div>
      </div>
    </>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const UserDropdown = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

export default AdminLayout;
