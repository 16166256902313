import React from "react";
import downarrow from "@/assets/img/downarrow.svg";
import vectorUP from "@/assets/img/VectoruP.svg";
import styles from "../rightSizing.module.css";
import clsx from "clsx";
import DataLoading from "@/components/States/DataLoading";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

/**
 * @typedef RightSizingHeaderProps
 * @property {import('@/services/right-sizing/getAllClouds.repo').GetAllStatisticsRes} data
 */

/**
 * @param {RightSizingHeaderProps} props
 * @returns
 */
const RightSizingHeader = ({ data, error }) => {
  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (Math.abs(count) >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (Math.abs(count) >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  // const TotalVmRecommendation = Number(data?.statistics?.total || 0);
  // const formattedCount = formatCount(TotalVmRecommendation);

  // const Downsize = Number(data?.statistics?.downsize_count || 0);
  // const formattedCount2 = formatCount(Downsize);

  // const Upsize = Number(data?.statistics?.upsize_count || 0);
  // const formattedCount3 = formatCount(Upsize);

  const savingssAvailable = Number(data?.statistics?.downsize_savings_pm || 0).toFixed(2);
  const Downsize_savingsAvailable = formatCount(Math.abs(savingssAvailable));

  const Upsize_Costings = Number(
    data?.statistics.upsize_savings_pm || 0
  ).toFixed(2);
  const Upsize_Costing = formatCount(Math.abs(Upsize_Costings));

  // const Terminate = data?.statistics.terminate_count || 0;
  // const formattedCount4 = formatCount(Terminate);

  // const ter_saving_available = Number(
  //   data?.statistics.terminate_savings_pm || 0
  // ).toFixed(2);
  // const Terminate_savings_available = formatCount(ter_saving_available);

  const Current_Costing = Number(data?.statistics?.current_costs_pm || 0).toFixed(2);
  const formattedCount5 = formatCount(Math.abs(Current_Costing));

  const Projected_costs = Number(data?.statistics?.projected_costs_pm || 0).toFixed(2);
  const Projected_cost = formatCount(Math.abs(Projected_costs));

  const Projected_savings = Number(data?.statistics?.projected_savings_pm || 0).toFixed(2);
  const ProjectedSavings = formatCount(Math.abs(Projected_savings));

  const Saving_till_date = Number(data?.statistics?.savings_till_date_pm || 0).toFixed(2);
  const SavingTillDate = formatCount(Math.abs(Saving_till_date));

  return (
    <>
      {error && <DataLoading />}
      {data && (
        <div className="container-fluid main-container">
          <div className=" Inner_main_container">
            <div className={styles.inner_first_container}>
              <div className={styles.inner_first_container_text}>
                VM Recommendations
              </div>
            </div>
            <div className={clsx("row", styles.rec_inner_second_container)}>
              <div className={clsx(styles.rec_details_first)}>
                <div
                  className={clsx(
                    styles.rec_second_div_first_container_firstDiv
                  )}
                >
                  <div className="container ideal_resource_count">
                    Total VM Recommendations
                  </div>
                  <div className="container ideal_resource_count_number">
                    <div
                      className={clsx("container", styles.rec_number_container)}
                    >
                      {data?.statistics?.total || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className={clsx(styles.rec_details_sec)}>
                <div
                  className=" container-fluid row"
                  style={{ margin: "0px", gap: "29px", padding: "0px" }}
                >
                  <div className={clsx(" col", styles.rec_content)}>
                    <div className="container upperContent">
                      {/* <img alt="" /> */}
                      <div
                        className={clsx("container", styles.upperContent_texts)}
                      >
                        DOWNSIZE
                      </div>
                    </div>
                    <div className={clsx("container", styles.rec_lowerContent)}>
                      <div className={styles.reclowerContent_numbers}>
                        {data?.statistics?.downsize_count || 0}
                      </div>
                      <div className={styles.reclowerContent_texts}>
                        <div className={styles.lowerContent_icon}>
                          <img alt="" src={downarrow} />
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx("container", styles.savings_container)}
                      style={{ padding: "0px" }}
                    >
                      <div
                        className={clsx("container ", styles.rec_saving_text)}
                      >
                        Savings Available:
                      </div>
                      <div
                        className={clsx("container ", styles.rec_saving_cost)}
                      >
                        {savingssAvailable >= 0 ? (
                          <ToolTipComponent
                            data={data?.statistics?.downsize_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${Downsize_savingsAvailable}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent
                            data={data?.statistics?.downsize_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              -${Downsize_savingsAvailable}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx(" col", styles.rec_content)}
                    style={{ border: "none" }}
                  >
                    <div className="container upperContent">
                      {/* <img alt="" /> */}
                      <div
                        className={clsx("container", styles.upperContent_texts)}
                      >
                        UPSIZE
                      </div>
                    </div>
                    <div className={clsx("container", styles.rec_lowerContent)}>
                      <div className={styles.reclowerContent_numbers}>
                        {data?.statistics?.upsize_count || 0}
                      </div>
                      <div className={styles.reclowerContent_texts}>
                        <div className={styles.lowerContent_icon}>
                          <img alt="" src={vectorUP} />
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx("container", styles.savings_container)}
                      style={{ padding: "0px" }}
                    >
                      <div
                        className={clsx("container ", styles.rec_saving_text)}
                      >
                        Costing:
                      </div>
                      <div
                        className={clsx("container ", styles.rec_saving_cost)}
                        style={{ color: "#D01A1A" }}
                      >
                        {Upsize_Costings >= 0 ? (
                          <ToolTipComponent
                            data={data?.statistics.upsize_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${Upsize_Costing}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent
                            data={data?.statistics.upsize_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              -${Upsize_Costing}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className={clsx(" col",styles.rec_content)}>
                <div className="container upperContent">
                  <img alt="" />
                  <div className="container upperCon <ToolTipComponent
                            data={data?.statistics?.downsize_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${Downsize_savingsAvailable}
                            </span>
                          </ToolTipComponent>tent_texts">TERMINATE</div>
                </div>
                <div className={clsx("container",styles.rec_lowerContent)}>
                  <div className={styles.reclowerContent_numbers}>
                    {formattedCount4}
                  </div>
                  <div className={styles.reclowerContent_texts}>
                    <div className={styles.lowerContent_icon}>
                      <img alt="" src={vectorCRoss} />
                    </div>
                  </div>
                </div>
                <div
                  className={clsx("container", styles.savings_container)}
                  style={{ padding: "0px" }}
                >
                  <div className={clsx("container ", styles.rec_saving_text)}>
                    Savings Available:
                  </div>
                  <div className={clsx("container ", styles.rec_saving_cost)}>
                    ${Terminate_savings_available}
                  </div>
                </div>
              </div> */}
                </div>
              </div>
              <div style={{ width: "25%", padding: "0px" }}>
                <div className={clsx(styles.rec_details_third)}>
                  <div className="container ideal_resource_count">
                    Current Costs
                  </div>
                  <div
                    className={clsx(
                      "container ",
                      styles.rec_ideal_resource_count_number
                    )}
                  >
                    <div
                      className="container "
                      style={{ display: "flex", padding: "0px", gap: "4px" }}
                    >
                      <div className={clsx("container", styles.currunt_cost)}>
                        {Current_Costing >= 0 ? (
                          <ToolTipComponent
                            data={data?.statistics?.current_costs_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${formattedCount5}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent
                            data={data?.statistics?.current_costs_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              -${formattedCount5}{" "}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                      <div className={clsx("container", styles.currunt_cost1)}>
                        /m
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.current_costsStyling}>
                  <div
                    className="contanier "
                    style={{
                      display: "flex",
                      width: "100%",
                      margin: "0px",
                      gap: "4px",
                    }}
                  >
                    <div className={clsx(styles.project_cost_text)}>
                      Projected Costs:
                    </div>
                    <div
                      style={{ display: "flex", padding: "0px", gap: "2px" }}
                    >
                      <div className={clsx("container ", styles.project_cost)}>
                        {Projected_costs >= 0 ? (
                          <ToolTipComponent
                            data={data?.statistics?.projected_costs_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${Projected_cost}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent
                            data={data?.statistics?.projected_costs_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              -${Projected_cost}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                      <div className={clsx("container", styles.project_cost1)}>
                        /m
                      </div>
                    </div>
                  </div>
                  <span className={styles.include_down_up_text}>
                    (Includes Upsize and Downsize costs)
                  </span>
                </div>
              </div>

              <div className={clsx(styles.rec_second_div_third_container)}>
                <div className="container-fluid innerContainer-1">
                  <div className="container innerContainer-div">
                    <div className="container innerContainer-text">
                      <div className="container innerContainer-text-1">
                        PROJECTED SAVINGS
                      </div>
                      <div className="container innerContainer-text-2">
                        {Projected_savings >= 0 ? (
                          <ToolTipComponent
                            data={data?.statistics?.projected_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              ${ProjectedSavings}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent
                            data={data?.statistics?.projected_savings_pm || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              -${ProjectedSavings}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid innerContainer-2">
                  <div className="container-fluid innerSecondContainer">
                    <div className="container innerSecondContainer-text">
                      Saved till date
                    </div>
                    <div className="container innerSecondContainer-num">
                      {Saving_till_date >= 0
                        ?  <ToolTipComponent
                        data={ data?.statistics?.savings_till_date_pm || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          ${SavingTillDate}
                        </span>
                      </ToolTipComponent>
                        :  <ToolTipComponent
                        data={ data?.statistics?.savings_till_date_pm || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          -${SavingTillDate}
                        </span>
                      </ToolTipComponent>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contnainer-fluid inner_third_container">
              <div
                className={clsx(
                  "container-fluid",
                  styles.Rec_inner_contaier_text
                )}
              >
                Updated Today at 7:05AM
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightSizingHeader;
