import { AppContext } from "@/App.context";
import config from "@/config";
import { DownloadFile } from "@/lib/download-file";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef Recommendation
 * @property {string} account
 * @property {string} resource_name
 * @property {string} resourceGroupName
 * @property {string} resourceId
 * @property {string} region
 * @property {string} current_type
 * @property {string} owner
 * @property {string} projected_type
 * @property {number} savings
 * @property {number} riSavings
 * @property {number} utilization_cpu
 * @property {number} utilization_mem
 * @property {number} utilization_disk
 * @property {number} cost
 * @property {string} status
 * @property {number} history
 */

/**
 * @typedef GetRecommendationsListRes
 * @property {Recommendation[]} recommendation
 * @property {number} total
 */

/**
 * @typedef GetRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 */

export const getRecommendationsList = (token) => {
  /**
   * @param {GetRecommendationsListReq} req
   * @returns {Promise<GetRecommendationsListRes>}
   */
  return async ({service }, signal) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/download-all-discarded`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    const reader = recommendationsList.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `Vm-Discarded-data.csv`;
    DownloadFile(blob, fileName);

  };
};

/**
 * @param {GetRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useDiscardedTableDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["getRecommendationsList", req, ...deps],
    mutationFn: ({ signal }) =>
      getRecommendationsList(appContext.idToken)(req, signal),
  });
  return query;
};
