import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetRecommendationsByIdReq
 * @property {'aws' | 'gcp' | 'azure'} service
 * @property {string} resourceId
 */

/**
 * @typedef GetRecommendationsByIdRes
 * @property {*} recommendation
 * @property {Record<string, *>} riPricings
 */

export const getRecommendationById = (token) => {
  /**
   * @param {GetRecommendationsByIdReq} req
   * @returns {GetRecommendationsByIdRes}
   */
  return async (req) => {
    if(!req.resourceId) return null;
    const body = { resourceId: req.resourceId };
    const recommendationId = await fetch(
      `${config.apiHost}/v1/${req.service}/recommendations/get-recommendations`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(body),
      }
    );

    if (!recommendationId.ok) {
      throw new Error((await recommendationId.json()).message);
    }
    if (recommendationId.ok) {
      const data = await recommendationId.json();
      return data;
    }
  };
};

/**
 * @param {GetRecommendationsByIdReq} req
 */
export const useRecommendationById = (req) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getRecommendationById",
      appContext.idToken,
      req.service,
      req.resourceId,
    ],
    queryFn: () => getRecommendationById(appContext.idToken)(req),
  });
  return query;
};
