import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

/**
 * @typedef DeleteUserReq
 * @property {number} groupId
 */

/**
 * @typedef DeleteUserRes
 * @property {string} message
 */

export const DeleteUser = (token) => {
  /**
   * @param {DeleteUserReq} req
   * @returns {Promise<DeleteUserRes>}
   */
  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/users/${req}/delete-user`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    const json = await totalStats.json();
    return json;
  };
};

/**
 * @param {any[]=} deps
 */
export const useDeleteUser = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["DeleteUser", ...deps],
    mutationFn: DeleteUser(appContext.idToken),
  });
  return mutation;
};
