import React from "react";
import HeaderComponent from "../components/header-component.part";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import BillingDetails from "../components/billing-details-table.part";
import BillingBarGraph from "../components/billing-bar-graph.part";
import { BillingProvider } from "../billing-context.part";

const AwsBillingPage = () => {
  return (
    <BillingProvider>
      <AwsBillingPart />
    </BillingProvider>
  );
};

const AwsBillingPart = () => {
  const service = "aws";

  return (
    <div className={styles.billing_comp}>
      <HeaderComponent service={service} />
      <>
        <div
          className={styles.bar_card}
          style={{ marginTop: "24px", paddingRight: "40px" }}
        >
          <BillingBarGraph service={service} />
        </div>

        <BillingDetails service={service} />
      </>
    </div>
  );
};

export default AwsBillingPage;
