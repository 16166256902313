import React, { useMemo, useContext, useState, useEffect } from "react";
import { useFilterOptions } from "@/services/right-sizing/filter.repo";
import { RightSizingContext } from "../../right-sizing.context";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { Button } from "react-bootstrap";
import TagItem from "./tag-item.part";
import add from "@/assets/img/add.svg";
import { useNavigate } from "react-router-dom";

const FilterSection = ({ service, name }) => {
  const context = useContext(RightSizingContext);
  const [isAddButtonDisabled,setIsAddButtonDisabled] = useState(true)
  const selectedAccountIds = (context.selectedAccounts || []).map((e) => e.id);
  const res = useMemo(() => {
    return {
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
    };
  }, [service, context?.selectedAccounts]);
  const dropdown = useFilterOptions(res);

  useEffect(() => {
    const disableAddButton = context.selectedTags.some(
      (tag) => tag.filterOn === "" || tag.values.length === 0
    );
    setIsAddButtonDisabled(disableAddButton);
  }, [context.selectedTags]);

 

  return (
    <>
      <div className={styles.filter_main_div} style={{ minWidth: 0 }}>
        <Filter
          tittle={name}
          data={dropdown?.data?.accounts}
          filterType="account"
          selectedValues={context.selectedAccounts}
          onSelect={(list) => {
            context?.setSelectedAccounts(list);
          }}
          onRemove={(list) => {
            context?.setSelectedAccounts(list);
          }}
        />

        {service === "azure" && context?.selectedAccounts?.length > 0 && (
          <Filter
            tittle={"Resource Group"}
            data={dropdown?.data?.resourceGroupName}
            filterType="resourcegroupname"
            selectedValues={context.selectedResourceGroup}
            onSelect={(list) => {
              context?.setSelectedResourceGroup(list);
            }}
            onRemove={(list) => {
              context?.setSelectedResourceGroup(list);
            }}
          />
        )}

        <Filter
          tittle={"Region"}
          data={dropdown?.data?.regions}
          filterType="region"
          selectedValues={context.selectedRegions}
          onSelect={(list) => {
            context?.setSelectedRegions(list);
          }}
          onRemove={(list) => {
            context?.setSelectedRegions(list);
          }}
        />

        <hr className="w-100 mb-0" />

        <div className={styles.filter_box_div}>
          <div className={styles.filter_name_addsign}>
            <div className={styles.filter_name}>Tags</div>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                context.setSelectedTags((arr) => {
                  const newArr = [...arr];
                  newArr.push({
                    filterOn: "",
                    filterType: "include",
                    values: [],
                  });
                  return newArr;
                });
              }}
              disabled={isAddButtonDisabled}
              style={{
                opacity: isAddButtonDisabled  ? 0.4 : "",
                fill: isAddButtonDisabled ? "#CED4DA" : "",
                pointerEvents: isAddButtonDisabled  ? "none" : "auto",
              }}
            >
              <img src={add} alt="" />
            </Button>
          </div>
          {context.selectedTags.map((tag, index) => (
            <TagItem service={service} tag={tag} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedValues,

  filterType,
  selectedValues,
  onSelect,
  onRemove,
}) => {
  const context = useContext(RightSizingContext);

  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      context.setSelectedAccounts(selectedList);
    }
    if (filterType === "resourcegroupname") {
      context.setSelectedResourceGroup(selectedList);
    }
    if (filterType === "region") {
      context.setSelectedRegions(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription") {
      title = "Account";
    }
    // setSelectedTittle(title?.toLowerCase());
  };

  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <div className={styles.filter_padding_div}>
        <div className={styles.dimension_div}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={styles.filter_group_name_css}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.accountId || e.regionId || e.resourceGroup,
                  name: e.accountName || e.regionName || e.resourceGroupName,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleTagSelection();
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
