import React from 'react'
import { Placeholder , Row, Col,} from 'react-bootstrap'
import styles from "@/components/States/states.module.css"

const HeaderState = () => {
  return (
    <div className=" d-flex flex-column gap-5" style={{margin:"12px 40px"}}>
    <Placeholder animation="glow">
      <div className=" d-flex flex-column gap-2">
        <Row>
          <Col xs={11}>
            <Placeholder
              className={styles.placeholder_css}
              xs={1}
              style={{ height: "25px", marginLeft:"-10px" }}
            />
          </Col>
          <Col xs={1}>
            <Placeholder
              className={styles.placeholder_css}
              xs={1}
              style={{ height: "25px", width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="d-flex justified-content-start gap-0 ">
          <Col xs={12} style={{padding:"0px"}}>
          <Placeholder
          className={styles.placeholder_css}
          xs={12}
          style={{ height: "175px" }}
        />
          </Col>
          {/* <Col xs={3} style={{padding:"0px"}}>
          <Placeholder
          
          xs={12}
          style={{ height: "175px", background:"#F4F6F8" }}
        />
          </Col>
          <Col xs={2} style={{padding:"0px"}}>
          <Placeholder
         
          xs={12}
          style={{ height: "175px" ,background:"#F4F6F8"}}
        />
          </Col> */}

        </Row>

       

        <Row
          style={{
            borderBottom: "1px solid var(--Neutral-D8, #E9ECEF)",
            paddingBottom: "10px",
          }}
        >
          <Col xs={11}/>
          <Col>
            <Placeholder
              style={{ width: "100%", height: 26 }}
              className={styles.placeholder_css}
            />
          </Col>
        </Row>
      </div>
      </Placeholder>
      </div>
  )
}

export default HeaderState;