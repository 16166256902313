import React, { useState } from "react";
import TabsSaving from "./tabs-saving.part";
import SavingHeader from "./saving-header.part";
import styles from "./savings.module.css";
import clsx from "clsx";
import HeaderState from "@/components/States/HeaderState";
import DataLoading from "@/components/States/DataLoading";

const SavingHeaderContainer = ({
  ShowTabs,
  selectedTab,
  onTabChange,
  service,
  toDate,
  fromDate,
  range,
  setRange,
  data,
  isError,
  isLoading,
}) => {
  return (
    <>
     {isLoading && <HeaderState/>}
    {isError && <DataLoading/>}
    {data && (
    <div className={clsx("container-fluid", styles.HeaderMainDiv)}>
      <SavingHeader
        service={service}
        toDate={toDate}
        fromDate={fromDate}
        range={range}
        setRange={setRange}
        data={data}
        isLoading={isLoading}
        isError={isError}
      />

      <TabsSaving
        ShowTabs={ShowTabs}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
      />
    </div>
    )}
    </>
  );
};

export default SavingHeaderContainer;
