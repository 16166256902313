import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef Recommendation
 * @property {string} account
 * @property {string} resourceName
 * @property {string} resourceGroupName
 * @property {string} resourceId
 * @property {string} region
 * @property {string} currentType
 * @property {string} owner
 * @property {'upsize' | 'downsize' | 'terminate'} recommendationType
 * @property {string} recommendedType
 * @property {string} currentType
 * @property {number} savings
 * @property {number} riSavings
 * @property {number} utilization_cpu
 * @property {number} utilization_mem
 * @property {number} utilization_disk
 * @property {number} cost
 */

/**
 * @typedef GetRecommendationsListRes
 * @property {Recommendation[]} recommendations
 * @property {number} total
 */

/**
 * @typedef GetRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 * @property {'downsize' | 'upsize' | 'terminate'} recommendationType
 * @property {GetRecommendationsFilters} filters
 * @property {string} orderBy
 * @property {boolean} desc
 * @property {number} page
 * @property {number} limit
 */

export const getRecommendationsList = (token) => {
  /**
   * @param {GetRecommendationsListReq} req
   * @returns {Promise<GetRecommendationsListRes>}
   */
  return async (
    { page, limit, orderBy, desc, recommendationType, service, filters },
    signal
  ) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/get-all`,
      {
        method: "POST",
        body: JSON.stringify({
          page,
          limit,
          recommendationType,
          filters,
          desc,
          orderBy,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!recommendationsList.ok) {
      if (ERROR_STATUS_CODES.includes(recommendationsList.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (recommendationsList.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await recommendationsList.json()).message);
    }
    const data = await recommendationsList.json();

    if (data?.recommendations.length === 0) {
      if (
        filters?.accounts?.length ||
        filters?.regions?.length ||
        filters?.resourceGroupName?.length ||
        filters?.tags?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
    }
    return data;
  };
};

/**
 * @param {GetRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useRecommendationsList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRecommendationsList", req, ...deps],
    queryFn: ({ signal }) =>
      getRecommendationsList(appContext.idToken)(req, signal),
  });
  return query;
};
