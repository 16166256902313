import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

/**
 * @typedef GetFilterAccountOptions
 * @property {string} accountId
 * @property {string} accountName
 */

/**
 * @typedef GetFilterRegionsOptions
 * @property {string} regionId
 * @property {string} regionName
 */

/**
 * @typedef GetResourceGroupNameOptions
 * @property {string} resourceGroup
 * @property {string} resourceGroupName
 */

/**
 * @typedef GetFilterOptionsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 */

/**
 * @typedef GetFilterOptionsRes
 * @property {GetFilterAccountOptions[]} accounts
 * @property {GetFilterRegionsOptions[]} regions
 * @property {GetResourceGroupNameOptions[]} resourceGroupName
 */

export const getFilterOptions = (token) => {
  /**
   * @param {GetFilterOptionsReq} req
   * @param {AbortSignal} signal
   * @returns {Promise<GetFilterOptionsRes>}
   */
  return async ({ service, accounts }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/filters`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accounts }),
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetFilterOptionsReq} req
 * @param {any[]=} deps
 */
export const useFilterOptions = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getFilterOptions", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getFilterOptions(appContext.idToken)(req, signal),
  });
  return query;
};
