import React from "react";
import { useState, useMemo, useEffect } from "react";
import styles from "./../admin.module.css";
import { Button } from "react-bootstrap";
import addUser from "@/assets/img/c-remove 2.svg";
import Pagination from "@/components/pagination/pagination.component";
import SearchBar from "../components/searchbar.modal";
import PrivilegesTableComp from "./privileges.table";
import CreatePrivilege from "./create.privilege.modal.part";
import { usePrivilegesList } from "@/services/admin/Privileges/getPrivilegesList.repo";
import { useSearchParams } from "react-router-dom";
import { useDebouncedValue } from "rooks";
import {
  AdminPrivilegeContext,
  useAdminPrivilegeContext,
} from "./privilege.context";
import { DeleteMessageModal } from "../components/delete.modal.part";
import { useDeletePrivilege } from "@/services/admin/Privileges/deletePrivilege.repo";
import PrivilegeData from "./privilege.info.modal";
import LoadingRbacTable from "../states/loading.state.part";
import EmptyTable from "../states/empty.state.part";

const PrivilegeComp = () => {
  const adminContext = useAdminPrivilegeContext();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [filterQuery, setFilterQuery] = useState("");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [debouncedFilter] = useDebouncedValue(filterQuery, 500);

  var query = useMemo(() => {
    return {
      filterQuery: debouncedFilter?.length ? debouncedFilter : undefined,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
    };
  }, [debouncedFilter, parsedItemsPerPage, parsedPage]);

  const {
    data: privilegesData,
    refetch: refetchPrivileges,
    isLoading: privilegeLoading,
  } = usePrivilegesList(query);

  const {
    data: deletePrivilegeData,
    mutate: deletePrivilege,
    reset: deleteReset,
    isPending: deletePrivilegeLoading,
  } = useDeletePrivilege();

  useEffect(() => {
    if (deletePrivilegeData) {
      deleteReset();
      refetchPrivileges();
      adminContext.setCreatePrivilegeModal(false);
      adminContext.setPrivilegeEditSelectedItem(undefined);
      adminContext.setPrivilegeSidebarSelectedItem(undefined);
    }
  }, [adminContext, deletePrivilegeData, deleteReset, refetchPrivileges]);

  return (
    <AdminPrivilegeContext.Provider
      value={{
        ...adminContext,
        refetchPrivileges,
        deletePrivilegeLoading,
      }}
    >
      <div className={styles.mainRolesContainer}>
        <div className={styles.textAndButtonContainer}>
          <div className={styles.labelHolder}>
            <span className={styles.manageRoles}>Privileges</span>
            <span className={styles.text}>
              Policy or Privilege is a rule which defines what permissions users
              have.
            </span>
          </div>

          <Button
            className={styles.addUserButton}
            onClick={() => {
              adminContext.setCreatePrivilegeModal(true);
            }}
          >
            <img src={addUser} className={styles.AddButtonImg} alt="" />
            Create Privilege
          </Button>
        </div>

        <SearchBar setFilterQuery={setFilterQuery} />

        {!privilegesData?.privileges?.length && !debouncedFilter?.length && !privilegeLoading && (
          <EmptyTable
            name="No privileges created yet"
            message="Start creating privileges."
          />
        )}

        {privilegesData?.privileges?.length == 0 && Boolean(filterQuery?.length) &&  (
          <EmptyTable
            name="Result not found"
            message="Try searching for more."
          />
        )}

        {privilegeLoading && <LoadingRbacTable />}

        {Boolean(privilegesData?.privileges?.length) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {privilegesData && (
              <PrivilegesTableComp
                // loading={privilegeLoading}
                data={privilegesData?.privileges || []}
              />
            )}
            <Pagination totalItems={privilegesData?.total || 0} />
          </div>
        )}

        <CreatePrivilege
          privilegeID={adminContext.privilegeEditSelectedItem}
          show={adminContext.createPrivilegeModal}
          onHide={() => {
            adminContext.setPrivilegeEditSelectedItem(undefined);
            adminContext.setCreatePrivilegeModal(false);
          }}
        />

        <DeleteMessageModal
          show={adminContext.privilegeDeleteModal}
          onHide={() => adminContext.setPrivilegeDeleteModal(undefined)}
          title={adminContext?.privilegeDeleteName}
          type="privilege"
          deleteData={deletePrivilegeData}
          deleteAction={() => {
            deletePrivilege({
              privilegeId: adminContext.privilegeDeleteModal,
            });
          }}
          deleteLoading={deletePrivilegeLoading}
        />

        <PrivilegeData
          show={adminContext.privilegeSidebarSelectedItem}
          handleClose={() => {
            adminContext.setPrivilegeSidebarSelectedItem(undefined);
          }}
          selectedItem={adminContext?.privilegeSidebarSelectedItem}
          handleEditBtn={() => {
            adminContext.setCreatePrivilegeModal(true);
            adminContext.setPrivilegeSidebarSelectedItem(undefined);
            adminContext.setPrivilegeEditSelectedItem(
              adminContext.privilegeSidebarSelectedItem
            );
          }}
          handleDeleteBtn={(id, name) => {
            adminContext?.setPrivilegeDeleteName(name);
            adminContext?.setPrivilegeDeleteModal(id);
          }}
        />
      </div>
    </AdminPrivilegeContext.Provider>
  );
};

export default PrivilegeComp;
