import React, { useState } from "react";


/**
 * @typedef GroupsContextTypeOf
 * @property {string=} createGroupModal
 * @property {React.Dispatch<string?>} setCreateGroupModal
 * @property {string=} showTableModal
 * @property {React.Dispatch<string?>} setShowTableModal
 * @property {string=} usershowGroupModal
 * @property {React.Dispatch<string?>} setUserShowGroupModal
 * @property {number=} groupDeleteItem
 * @property {React.Dispatch<number?>} setGroupDeleteItem
 * @property {string=} groupDeleteItemName
 * @property {React.Dispatch<string?>} setGroupDeleteItemName
 * @property {number=} groupEditItem
 * @property {React.Dispatch<number?>} setGroupEditItem
 * @property {number=} groupSidebarItem
 * @property {React.Dispatch<number?>} setGroupSidebarItem
 * @property {() => void} refetchGroups
 * @property {boolean=} deleteLoading
 * @property {() => void} refetchGroups
 */

/** @type {import("react").Context<GroupsContextTypeOf>} */
export const GroupsContext = React.createContext();

export const useGroupsContext = () => {
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [usershowGroupModal, setUserShowGroupModal] = useState(false);
  const [groupDeleteItem, setGroupDeleteItem] = useState();
  const [groupDeleteItemName, setGroupDeleteItemName] = useState();
  const [groupEditItem, setGroupEditItem] = useState();
  const [groupSidebarItem, setGroupSidebarItem] = useState();
  const [failureMessage, setFailureMessage] = useState("");

  const handleTableModalShow = () => setShowTableModal(true);
  const handleTableModalClose = () => setShowTableModal(false);

  return {
    createGroupModal,
    setCreateGroupModal,
    handleTableModalShow,
    setUserShowGroupModal,
    handleTableModalClose,
    showTableModal,
    usershowGroupModal,
    groupDeleteItem,
    setGroupDeleteItem,
    groupDeleteItemName,
    setGroupDeleteItemName,
    groupEditItem,
    setGroupEditItem,
    groupSidebarItem,
    setGroupSidebarItem,
    failureMessage,
    setFailureMessage
  };
};

export const GroupsContextProvider = ({ children }) => {
  const context = useGroupsContext();
  return (
    <GroupsContext.Provider value={context}>{children}</GroupsContext.Provider>
  );
};
