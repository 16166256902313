import React from "react";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import editImg from "@/assets/img/edit.svg";
import { GroupsContext } from "@/page-components/admin/groups/group.context";
import format from "date-fns/format";
import { Spinner } from "react-bootstrap";
import UserIcon from "@/assets/img/user_icon_blue.svg";
import privilagesIcon from "@/assets/img/privilages_icon.svg";


const GroupTableComp = ({ data, loading }) => {
  const adminContext = useContext(GroupsContext);

  const handleToggleSidebar = (item) => {
    adminContext.setGroupSidebarItem(item);
  };

  const handleEditGroup = (item) => {
    adminContext.setGroupEditItem(item);
    adminContext.setCreateGroupModal(true);
  
  };

  return (
    <>
      <Table responsive className={styles.table}>
        <thead className={styles.tableHeadStyling}>
          <tr>
            <th>Name</th>
            <th>Group Description</th>
            <th>Users</th>
            <th>Privileges</th>
            <th>Creator</th>
            <th>Created on</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data && data.length === 0 && (
            <tr>
              <td colSpan={6} className="elipese">
                <div className="py-5 d-flex align-items-center justify-content-center">
                  No groups to display
                </div>
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan={6} className="elipese">
                <div className="py-5 d-flex align-items-center justify-content-center">
                  <Spinner variant="primary" />
                </div>
              </td>
            </tr>
          )}
          {data.map((item) => (
            <tr className={styles.tableRowStyling}>
              <td
                onClick={() => handleToggleSidebar(item.id)}
                className={styles.onclick_name}
              >
                <u
                  style={{
                    color: "#056EE5",
                    fontWeight: "600",
                    pointer: "cursor",
                  }}
                >
                  {item.name || ""}
                </u>
              </td>
              <td className="elipese">{item.description || "--"} </td>
              <td className="elipese">
                <span className={styles.user_css}>
                  <img src={UserIcon} alt="User Icon"></img>
                  <span style={{ marginLeft: "8px" }}>
                    {item?.users || "--"}
                  </span>
                </span>
              </td>
              <td className="elipese">
                <span className={styles.privilages_css}>
                  <img src={privilagesIcon} alt="Privilages Icon"></img>
                  <span style={{ marginLeft: "8px" }}>
                    {item?.privileges || "--"}
                  </span>
                </span>
              </td>
              <td className="elipese">{item.createdBy || ""}</td>
              <td className="elipese">
                {item.createdAt
                  ? format(new Date(item.createdAt), "dd/MM/yyyy")
                  : "--"}
              </td>
              <td
                className="elipese"
                style={{ textAlign: "center" }}
                onClick={() => handleEditGroup(item.id)}
              >
                <img src={editImg} width="12px" height="12px" alt="" />
              </td>
            </tr>
          ))}
          <tr></tr>
        </tbody>
      </Table>
    </>
  );
};
export default GroupTableComp;
