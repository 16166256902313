import React from "react";
import styles from "./states.module.css";
import clsx from "clsx";
import NoEntry from "@/assets/img/noEntry.svg";
import NoDataImage from "@/assets/img/noDataImg.svg";

/**
 * @typedef DataAbsenseProps
 * @property {string} containerClasses
 */

/**
 * @param {DataAbsenseProps} props
 */
const NoDataAvailable = ({ message, addOnMsg }) => {
  return (
    <div className={clsx(styles.main_container)}>
      <img src={NoEntry} width={"32px"} height={"32px"} alt="" />

      <div className={styles.sec_container}>
        <div className={styles.text_css}>{message}</div>
        <div className={styles.sec_text_css}>{addOnMsg}</div>
      </div>
    </div>
  );
};

export default NoDataAvailable;
