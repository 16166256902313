import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetPrivileges
 * @property {string} name
 * @property {string} description
 * @property {number} id
 * @property {string} permission
 * @property {string} createdby
 * @property {date} createdAt
 * @property {date} updatedAt
 */

/**
 * @typedef ResourceGroupItem
 * @property {string} resourceGroup
 * @property {number} permission
 */

/**
 * @typedef Account
 * @property {string} accountNo
 * @property {string} accountName
 * @property {number} permission
 * @property {ResourceGroupItem[]} resourceGroups
 */

/**
 * @typedef GetPrivilegesByIdRes
 * @property {GetPrivileges} privilege
 * @property {Account[]} accounts
 */

export const getPrivilegesById = (token) => {
  /**
   * @param {number} id
   * @param {AbortSignal} signal
   * @returns {Promise<GetPrivilegesByIdRes>}
   */
  return async (id, signal) => {
    const privileges = await fetch(
      `${config.apiHost}/v1/privileges/${id}/get-privilege`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
        cache: 'no-store'
      }
    );
    if (!privileges.ok) {
      throw new Error((await privileges.json()).message);
    }
    return await privileges.json();
  };
};

export const usePrivilegesById = (id, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    refetchOnMount: true,
    enabled: Boolean(id),
    queryKey: ["getPrivilegesById", id, appContext.idToken, ...deps],
    queryFn: ({ signal }) => getPrivilegesById(appContext.idToken)(id, signal),
    staleTime: 0,
  });
  return query;
};
