import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} idle_resource_total
 * @property {GetIdleStatistics} idle_resources
 * @property {number} savings_till_date
 * @property {number} savings_available
 */
/**
 * @typedef GetTotalStatistics
 * @property {number} idle_resource_count
 * @property {number} unblended_cost
 */

/**
 * @typedef GetIdleStatistics
 * @property {GetDiskStatistics=} disk
 * @property {GetVmStatistics=} vm
 * @property {GetIpStatistics=} ip
 * @property {GetLoadStatistics=} lb
 */

/**
 * @typedef GetDiskStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetVmStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */
/**
 * @typedef GetLoadStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIpStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

// /**
//  * @typedef GetResultStatistics
//  * @property {number} saving_available  - Email address
//  */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 *  @property {GetIdleFilters} filters
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 * @property { 'false' | 'true' } untagged
 */

export const getStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters, untagged }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === 'azure') {
      body.rg = filters?.rg || [];
    }
    const url = new URL(`${config.apiHost}/v1/${service}/idle-resources/total-stats`);

    if (untagged) {
      url.searchParams.append('untagged', 'true');
    }
    const owners = await fetch(url.toString(), {
        method: service === "all-cloud" ? "GET" : "POST",
        body:
          service !== "all-cloud"
            ? JSON.stringify(body)
            : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
