import React from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const SnapshotTypeOrphaned = ({ mydata2 }) => {
  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (count >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (count >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  // const totalCount2 = mydata2?.orphanedCount || 0;
  // const formattedCount2 = formatCount(totalCount2);

  return (
    <div className={clsx(styles.untagged_snapshot_main)}>
      <span className={clsx(styles.snapshot_text)}>ORPHANED SNAPSHOTS</span>
      <span className={clsx(styles.snapshot_value)}>{mydata2?.orphanedCount || 0}</span>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={clsx(styles.snap_cost_text)}>Savings:</span>
        <span
          className={clsx(
            styles.snap_cost_value,
            styles.snapshot_orphaned_color
          )}
        >
          <ToolTipComponent data={mydata2?.orphanedSavings || 0}>
            <span style={{ cursor: "context-menu" }}>
              ${Number((mydata2?.orphanedSavings).toFixed(2)) || 0}
            </span>
          </ToolTipComponent>
        </span>
      </div>
    </div>
  );
};

export default SnapshotTypeOrphaned;
