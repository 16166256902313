import React, { useContext, useState, useEffect } from "react";
import styles from "@/page-components/admin/admin.module.css";
import { Button, Dropdown, DropdownItem } from "react-bootstrap";
import { ReactComponent as AddUser } from "@/assets/img/c-remove 2.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminTable from "./admin.table.part";
import UserTable from "./user.roles.table.part";
import Pagination from "@/components/pagination/pagination.component";
import AddUserModal from "./create.user.modal.part";
import { useSearchParams } from "react-router-dom";
import dropdown from "@/assets/img/Dropdown_arrow.svg";
import SearchBar from "../components/searchbar.modal";
import { useUsersList } from "@/services/admin/Roles/getRolesList.repo";
import { useDebouncedValue } from "rooks";
import BudgetTable from "../tables/budget.table";
import {
  RolesContext,
  RolesContextProvider,
} from "@/page-components/admin/roles/roles.context";
import AddBudgetModal from "./create.budget.modal.part";
import { useCount } from "@/services/admin/Roles/getCount";
import LoadingRbacTable from "../states/loading.state.part";
import EmptyTable from "../states/empty.state.part";

const RolesComp = () => {
  return (
    <RolesContextProvider>
      <Roles />
    </RolesContextProvider>
  );
};

const Roles = () => {
  const adminContext = useContext(RolesContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userType, setUserType] = useState("Admin");
  const [filterQuery, setFilterQuery] = useState("");
  const [debouncedFilterQuery] = useDebouncedValue(filterQuery, 500);

  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  useEffect(() => {
    setFilterQuery("");
  }, [userType]);

  const query = {
    filterQuery: debouncedFilterQuery?.length
      ? debouncedFilterQuery
      : undefined,
    userType,
    page: parsedPage || 1,
    limit: parsedItemsPerPage || 10,
  };

  const setTab = (tabKey) => {
    setUserType(tabKey);
    setSearchParams(new URLSearchParams({ page: 1 }));
  };

  const {
    data: rolesData,
    isLoading: loadingRoles,
    refetch,
  } = useUsersList(query);

  const { data: getCountData, refetch: refetchCount } = useCount();

  const refetchCountAndTable = () => {
    refetch();
    refetchCount();
  };

  const handleModalShow = () => {
    adminContext.setCreateUserModal(true);
  };

  const handleModalClose = () => {
    adminContext.setCreateUserModal(false);
  };

  const handleBudgetCreation = () => {
    adminContext.setCreateBudgetModal(true);
  };

  const handleBudgetModalClose = () => {
    adminContext.setCreateBudgetModal(false);
  };

  return (
    <div className={styles.mainRolesContainer}>
      <div className={styles.textAndButtonContainer}>
        {/* <div className={styles.labelHolder}> */}
        <span className={styles.manageRoles}>User Management </span>
        {/* </div> */}

        <Button className={styles.addUserButton} onClick={handleModalShow}>
          <AddUser className={styles.addButtonImg} />
          Add User
        </Button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "24px",
            width: "80%",
          }}
        >
          <SearchBar setFilterQuery={setFilterQuery} value={filterQuery} />

          <Dropdown className={styles.dropDown} onSelect={(e) => setTab(e)}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  backgroundColor: "#F8F9FA",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "4px",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#797B80" }}>Role Type:</span>
                <span style={{ fontWeight: "600" }}>{userType} </span>
                <img
                  src={dropdown}
                  alt="Adam"
                  className="rounded-circle "
                  style={{ marginLeft: "6px" }}
                  width="10px"
                  height="10px"
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ fontSize: "13px" }}>
              <Dropdown.Item eventKey="Admin">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Admin</span>
                  <span style={{ color: "#797B80" }}>
                    {getCountData?.admins || 0}
                  </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="User">
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Users</span>
                  <span style={{ color: "#797B80" }}>
                    {getCountData?.users || 0}
                  </span>
                </div>
              </Dropdown.Item>
              {/* <Dropdown.Item eventKey="Budget">
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Budget</span>
                      <span style={{ color: "#797B80" }}>
                        {getCountData?.budgets || 0}
                      </span>
                    </div>
                  </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* <div>
              {userType == "Budget" && (
                <Button
                  variant="link"
                  className={styles.budgetButton}
                  onClick={handleBudgetCreation}
                >
                  + Add Budget Creator
                </Button>
              )}
            </div> */}
      </div>

      {!rolesData?.users?.length && !debouncedFilterQuery?.length && !loadingRoles && (
        <EmptyTable
          name="No Users added yet"
          message="Create a group to add users."
        />
      )}

      {rolesData?.users?.length == 0 && Boolean(filterQuery?.length) && (
        <EmptyTable name="Result not found" message="Try searching for more." />
      )}

      {loadingRoles && <LoadingRbacTable />}

      {Boolean(rolesData?.users?.length) && (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {" "}
          <Tabs
            activeKey={userType}
            className={styles.tabs}
            variant="underline"
            onSelect={(e) => setTab(e)}
          >
            <Tab
              eventKey="Admin"
              title={`Admin (${rolesData?.total || 0})`}
              id={styles.mybuttonsty}
            >
              <div className={styles.adding_searchbar}>
                <AdminTable
                  data={rolesData?.users || []}
                  loading={loadingRoles}
                  refetch={refetchCountAndTable}
                />
              </div>
            </Tab>
            <Tab eventKey="User" title={`Users (${rolesData?.total || 0})`}>
              <div className={styles.adding_searchbar}>
                <UserTable
                  data={rolesData?.users || []}
                  loading={loadingRoles}
                  refetch={refetchCountAndTable}
                />
              </div>
            </Tab>
            <Tab eventKey="Budget" title={`Budget`}>
              <div className={styles.adding_searchbar}>
                {/* <BudgetTable /> */}
              </div>
            </Tab>
          </Tabs>
          <Pagination totalItems={rolesData?.total} />{" "}
        </div>
      )}
      <AddUserModal
        show={adminContext.createUserModal}
        onHide={handleModalClose}
        refetch={refetch}
      />

      {/* <AddBudgetModal
            show={adminContext.createBudgetModal}
            onHide={handleBudgetModalClose}
            refetch={refetch}
          /> */}
    </div>
  );
};
export default RolesComp;
