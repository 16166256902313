import React from "react";
import closeCross from "@/assets/img/closecross.svg";
import styles from "./budgets.module.css";

const ConformationDialog = ({ setConformation, setToast,handleDelete,refetch }) => {
    
  const Conformation = () => {
   
    setConformation((prev) => {
      return {
        ...prev,
        dialog: false,
        delete: true,
      };
    });
    handleDelete()
    setToast(true);
   setTimeout(()=>refetch(),3000) 
  };

  const handleCloseOverlay = () => {
    setConformation((prev) => {
      return {
        ...prev,
        dialog: false,
        delete: false,
      };
    });
  };
  return (
    <>
      <div className={styles.dialogContainer}>
        <div>
          <div className={styles.dialogTitle}>Delete Budget</div>
          <span className={styles.dialogBody}>
            Are you sure you want to delete this budget?
            <div>This action cannot be undone.</div>
          </span>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.deleteButton} onClick={()=>Conformation()}>
            Delete
          </button>
          <button className={styles.cancleButton} onClick={()=>handleCloseOverlay()}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ConformationDialog;
