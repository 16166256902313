import config from "@/config";
import { useMutation } from "@tanstack/react-query";

/**
 * A number, or a string containing a number.
 * @typedef RefreshTokenReq
 * @property {string} refreshToken - Refresh token
 */

/**
 * @typedef RefreshTokenRes
 * @property {string} email - Email address
 * @property {string} id - User id
 * @property {string} access_token - Access token for the user
 * @property {string} id_token - Id token for the user
 * @property {number} expires_in - Time in seconds after which the token gets expired
 * @property {any} user - User object
 */

/**
 * Sends login request and fetches the sso url to redirect to.
 * @param {RefreshTokenReq} body Body of login form
 * @returns {Promise<RefreshTokenRes>} Returns redirect url
 */
export const refreshToken = async (body) => {
  if (!body.refreshToken) throw new Error("Invalid access or refresh token");
  const owners = await fetch(`${config.authHost}/refresh_token/`, {
    method: "POST",
    body: JSON.stringify({
      refresh_token: body.refreshToken,
    }),
  });
  if (!owners.ok) {
    throw new Error((await owners.json()).message);
  }
  return await owners.json();
};

/**
 * @param {any[]=} deps 
 * @returns 
 */
export const useRefreshToken = (deps = []) => {
  const mutate = useMutation({
    mutationKey: ["refreshToken", ...deps],
    mutationFn: refreshToken,
  });
  return mutate;
};
