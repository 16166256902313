import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const getPopulateFilterDropdown = (token) => {
  return async ({ service, selectedTittle }) => {
    const filterDropDown = await fetch(
      `${config.apiHost}/v1/${service}/kubernetes/filter-tags?fieldName=${selectedTittle}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!filterDropDown.ok) {
      throw new Error((await filterDropDown.json()).message);
    }
    const data = await filterDropDown.json();
     const updatedRes = (data||[]).filter((updatedRes)=>(updatedRes.fieldName!==null))
    return updatedRes;
  };
};

export const usePopulateFilter = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getKuberneteStatsData", req, ...deps],
    queryFn: () => getPopulateFilterDropdown(appContext.idToken)(req),
  });
  return query;
};
