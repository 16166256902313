import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import Joi from "joi";

// {
//     resourceId: string;
//     resource_saving_id: number | null;
//     status: "unarchive" | "discarded" | "archived" | "accepted" | "inprogress" | "done";
//     comment?: string,
//     acceptData: {
//       ticket_id: string,
//       ticket_url: string,
//     }
//   }

/** @type {Joi<UpdateAcceptRecommendationsReq>} */
export const VMChangeStatusReq = Joi.object({
  resourceId: Joi.string().required(),
  service: Joi.string(),
  projected_type: Joi.string(),
  current_type: Joi.string(),
  resource_saving_id: Joi.string().allow(null),
  status: Joi.string()
    .valid(
      "open",
      "unarchive",
      "discarded",
      "archived",
      "accepted",
      "inprogress",
      "done"
    )
    .required(),
  comment: Joi.string().optional().min(3).messages({
    "any.required": "*Comment cannot be empty",
    'string.empty' : "*Comment cannot be empty",
    "string.min": "*Comment cannot be empty"
  }),
  acceptData: Joi.object({
    ticket_id: Joi.string().required().min(3).messages({
      "any.required": "*Ticket ID cannot be empty",
      'string.empty' : "*Ticket ID cannot be empty",
      "string.min": "*Ticket ID cannot be empty"
    }),
    ticket_url: Joi.string().uri().required().min(3).messages({
      "any.required": "*Ticket URL cannot be empty",
      'string.empty' : "*Ticket URL cannot be empty",
      "string.uri": "*Please provide a valid URL",
      "string.min": "*Ticket URL cannot be empty"

    }),
  }).optional(),
});

/**
 * @typedef AcceptData
 * @property {string} ticket_id
 * @property {string} ticket_url
 */

/**
 * @typedef UpdateAcceptRecommendationsReq
 * @property {'aws' | 'azure'} service
 * @property {string} resourceId
 * @property { "unarchive" | "discarded" | "archived" | "accepted" | "inprogress" | "done"} status
 * @property {string=} comment
 * @property {AcceptData=} acceptData
 * @property {string} projected_type
 * @property {string} current_type
 */

export const AcceptRecommendation = (token) => {
  /**
   *
   * @param {UpdateAcceptRecommendationsReq} req
   * @returns {Promise<boolean>}
   */
  return async (req) => {
    const { resource_saving_id,service, ...rest } = req;
    const addFields = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/change-status`,
      {
        method: "PUT",
        body: JSON.stringify(rest),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!addFields.ok) {
      throw new Error((await addFields.json()).message);
    }
    return await addFields.json();
  };
};

/**
 * @param {any[]=} deps
 * @param {UpdateAcceptRecommendationsReq} req
 */
export const useUpdateAcceptRecommendation = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["AcceptRecommendation", appContext.idToken, ...deps],
    mutationFn: AcceptRecommendation(appContext.idToken),
  });
  return mutation;
};
