import React, { useCallback, useContext, useEffect, useMemo } from "react";
import "@/assets/css/customwidget.css";
import { useState } from "react";
import closecross from "@/assets/img/closecross.svg";
import addsymbol from "@/assets/img/addsymbol.svg";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  AddCostExplorerWidgetReq,
  GRANULARITY,
  useAddCostExplorerWidget,
} from "@/services/costExplorer/addCostExplorerWidget.repo";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AppContext } from "@/App.context";
import { CustomWidgetContext } from "../custom-widget/custom-widget.context";
import { useUpdateCostExplorerWidget } from "@/services/costExplorer/updateCostExplorerWidget.repo";
import styles from "./edit-widget.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import clsx from "clsx";
import { useDimensionTagsKeys } from "@/services/costExplorer/getDimensionTags.repo";
import FilterItem from "./filter-item.part";
import TagItem from "./tag-item.part";
import Tooltip from "@/components/tooltip/tooltip";
import { useAllDimensions } from "@/services/costExplorer/getAllDimesionCE.repo";

/**
 * @typedef EditWidgetProps
 * @property {string} service
 * @property {any=} widget
 * @property {string} widgetDimension
 * @property {() => void} onClose
 */

/**
 * @param {EditWidgetProps} props
 */
const EditWidget = ({ widget,dimensionData, widgetDimension, service, onClose }) => {
  const appContext = useContext(AppContext);
  const customWidgetContext = useContext(CustomWidgetContext);
  const { data: dimensionKeys } = useDimensionTagsKeys({ service });

  /** @type {import('react-hook-form').UseFormReturn<AddCostExplorerWidgetReq>} */
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: joiResolver(AddCostExplorerWidgetReq),
    defaultValues: {
      cloud: service,
      userEmail: "",
      interval: "days",
      granularity: "date",
      filters: [{ dimension: "", isInclude: true, values: [] }],
    },
  });
  const watchFilters = watch("filters");
  const watchTags = watch("tags");

  useEffect(() => {
    if (appContext?.user) {
      setValue("userEmail", appContext?.user.emailId);
    }
  }, [appContext?.user, setValue]);

  const {
    fields: filterFields,
    append: filterAppend,
    remove: filterRemove,
  } = useFieldArray({
    control,
    name: "filters",
  });

  const {
    fields: tagFields,
    append: tagAppend,
    remove: tagRemove,
  } = useFieldArray({
    control,
    name: "tags",
  });

  const [isOpen, setIsOpen] = useState(true);

  const watchCloud = watch("cloud");

  useEffect(() => {
    setValue("filters", [{ dimension: "", isInclude: true, values: [] }]);
    setValue("tags", [{ filterOn: "", filterType: "include", values: [] }]);
    setValue("dimension", "");
  }, [setValue, watchCloud]);

  const {
    data: addWidgetData,
    error: addWidgetError,
    isPending: addWidgetLoading,
    mutate: addCostExplorerWidget,
  } = useAddCostExplorerWidget();

  const {
    data: updateWidgetData,
    error: updateWidgetError,
    isPending: updateWidgetLoading,
    mutate: updateCostExplorerWidget,
  } = useUpdateCostExplorerWidget();


  // const query = useMemo(() => {
  //   return {
  //     service: service,
  //   };
  // }, [service]);

  // const { data: dimensionData } = useAllDimensions(query);

  useEffect(() => {
    if (addWidgetData) {
      customWidgetContext.setWidgets((w) => {
        const newWidgets = { ...w };
        if (
          newWidgets[service]?.some((e) => e.id === addWidgetData.widget.id) ===
          false
        ) {
          newWidgets[service]?.push(addWidgetData.widget);
        }
        return newWidgets;
      });
      onClose();
    }
  }, [addWidgetData, customWidgetContext, onClose, service]);

  const handleCloseOverlay = useCallback(() => {
    reset();
    setIsOpen(false);
    onClose();
    customWidgetContext.setSelectedWidget(undefined);
  }, [customWidgetContext, onClose, reset]);

  useEffect(() => {
    if (updateWidgetData) {
      customWidgetContext.setUpdateWidgetId(widget?.id);
      handleCloseOverlay();
    }
  }, [customWidgetContext, handleCloseOverlay, updateWidgetData, widget?.id]);

  useEffect(() => {
    if (
      customWidgetContext.deleteWidgetMutation.isSuccess &&
      customWidgetContext.deleteWidgetId &&
      customWidgetContext.deleteWidgetId === widget?.id
    ) {
      handleCloseOverlay();
    }
  }, [
    customWidgetContext.deleteWidgetId,
    customWidgetContext.deleteWidgetMutation.isSuccess,
  ]);

  useEffect(() => {
    if (widget) {
      setValue("title", widget.title);
      setValue("granularity", widget.granularity);
      setValue("interval", widget.widget_interval);
      setValue("showPrevious", widget.show_previous);
      setValue("graphType", widget.graph_type);
      if (widget.filters.length) {
        setValue("filters", widget.filters);
      } else {
        setValue("filters", [
          {
            dimension: "",
            isInclude: true,
            values: [],
          },
        ]);
      }
      if (widget.tags.length) {
        setValue("tags", widget.tags);
      } else {
        setValue("tags", [
          {
            filterOn: "",
            filterType: "include",
            values: [],
          },
        ]);
      }
      setValue("dimension", widget.dimension);
    }
  }, [setValue, widget, widgetDimension]);




  return (
    <>
      {isOpen && (
        <form
          className="d-flex justify-content-between flex-column w-100 h-100"
          onSubmit={handleSubmit((d) => {
            const filters = (d.filters || []).filter(
              (e) => e.dimension.length > 0
            );
            const tags = (d.tags || []).filter((e) => e.filterOn.length > 0);
            if (widget?.id) {
              updateCostExplorerWidget({
                id: widget?.id,
                cloud: d.cloud,
                costAggregation: d.costAggregation,
                dimension: d.dimension,
                granularity: d.granularity,
                graphType: d.graphType,
                interval: d.interval,
                showPrevious: d.showPrevious,
                title: d.title,
                filters: filters.length ? filters : [],
                tags: tags.length ? tags : [],
              });
            } else {
              addCostExplorerWidget({
                ...d,
                filters: filters.length ? filters : [],
                tags: tags.length ? tags : [],
              });
            }
          })}
        >
          <div className={styles.add_custom_new_div}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={styles.new_custom_widget}>
                {widget ? "Edit Widget" : "New Custom Widget"}
              </div>
              <div
                className={styles.new_custom_widget}
                onClick={handleCloseOverlay}
              >
                <img src={closecross} alt=" "></img>
              </div>
            </div>
            <div className={styles.custom_widget_values}>
              <div className={styles.name_input_box_css}>
                <div className={styles.title_name}>Title</div>
                <Form.Control
                  className={styles.formControl}
                  type="text"
                  placeholder="Provide a title for custom widget"
                  {...register("title")}
                />
                {errors.title && (
                  <small className={clsx(styles.error_message, "mt-2")}>
                    {errors.title.message}
                  </small>
                )}
              </div>

              {!service && (
                <div className={styles.name_input_box_css}>
                  <div className={styles.title_name}>Cloud</div>
                  <Controller
                    control={control}
                    name="cloud"
                    render={({ field }) => (
                      <FilterSearch
                        hideSearch
                        options={[
                          { id: "aws", name: "AWS" },
                          { id: "azure", name: "Azure" },
                          { id: "gcp", name: "Google Cloud" },
                        ]}
                        displayValue="name"
                        selectedValues={[field.value]}
                        onSelect={(selectedItem) => {
                          field.onChange(selectedItem.id);
                        }}
                        toggle={
                          <FilterSearchToggle placeholder="-- Select --" />
                        }
                      />
                    )}
                  />
                  {errors.cloud && (
                    <small className={styles.error_message}>
                      {errors.cloud.message}
                    </small>
                  )}
                </div>
              )}

              <div className="w-100">
                <div className="row">
                  <div className="col-6">
                    <div className={styles.title_name}>Show Previous</div>
                    <Form.Control
                    
                      type="number"
                      max={31}
                      min={1}
                      defaultValue={30}
                      className="form-input"
                      placeholder="30"
                      {...register("showPrevious")}
                    />
                    {errors.showPrevious && (
                      <small className={styles.error_message}>
                        {errors.showPrevious.message}
                      </small>
                    )}
                  </div>
                  <div className="col-6">
                    <div className={styles.title_name}>Interval</div>
                    <Controller
                      control={control}
                      name="interval"
                      render={({ field }) => (
                        <FilterSearch
                          hideSearch
                          options={[
                            { id: "days", name: "Days" },
                            { id: "weeks", name: "Weeks" },
                            { id: "months", name: "Months" },
                          ]}
                          displayValue="name"
                          selectedValues={[field.value]}
                          onSelect={(selectedItem) => {
                            field.onChange(selectedItem.id);
                          }}
                          toggle={<FilterSearchToggle placeholder="Interval" />}
                        />
                      )}
                    />
                    {errors.interval && (
                      <small className={styles.error_message}>
                        {errors.interval.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.name_input_box_css}>
                <div className={styles.title_name}>Granularity</div>
                <Controller
                  control={control}
                  name="granularity"
                  render={({ field }) => (
                    <FilterSearch
                      hideSearch
                      options={Object.entries(GRANULARITY).map(
                        ([label, value]) => ({
                          id: label,
                          name: value,
                        })
                      )}
                      displayValue="name"
                      selectedValues={field.value ? [field.value] : []}
                      onSelect={(selectedItem) => {
                        field.onChange(selectedItem.id);
                      }}
                      toggle={<FilterSearchToggle placeholder="Granularity" />}
                    />
                  )}
                />
                {errors.granularity && (
                  <small className="error-message mt-2">
                    {errors.granularity.message}
                  </small>
                )}
              </div>

              <div className={styles.name_input_box_css}>
                <div className={styles.title_name}>Graph Type</div>
                <Controller
                  control={control}
                  name="graphType"
                  render={({ field }) => (
                    <FilterSearch
                      hideSearch
                      options={[
                        { id: "bar", name: "Bar Chart" },
                        { id: "line", name: "Line Chart" },
                      ]}
                      displayValue="name"
                      selectedValues={field.value ? [field.value] : []}
                      onSelect={(selectedItem) => {
                        field.onChange(selectedItem.id);
                      }}
                      toggle={
                        <FilterSearchToggle placeholder="Choose graph type" />
                      }
                    />
                  )}
                />
                {errors.graphType && (
                  <small className={clsx(styles.error_message, "mt-2")}>
                    {errors.graphType.message}
                  </small>
                )}
              </div>

              <div className={styles.name_input_box_css}>
                <div className={styles.title_name}>Dimension</div>
                <Controller
                  control={control}
                  name="dimension"
                  render={({ field }) => {
                    return (
                      <FilterSearch
                        hideSearch
                        options= {(dimensionData?.dimensions || []).map(({ id, name }) => ({
                          id,
                          name
                        }))}

                        displayValue="name"
                        selectedValues={field.value ? [field.value] : []}
                        onSelect={(selectedItem) => {
                          field.onChange(selectedItem.id);
                        }}
                        toggle={
                          <FilterSearchToggle placeholder="Choose dimension" />
                        }
                      />
                    );
                  }}
                />
                {errors.dimension && (
                  <small className={clsx(styles.error_message, "mt-2")}>
                    {errors.dimension.message}
                  </small>
                )}
              </div>

              <div className={styles.filters_css}>
                <div className={styles.filter_name_div}>
                  <div className={styles.filter_name}>Filters</div>
                  <Button
                    // disabled={
                    //   filterFields.length >= 1 &&
                    //   filterFields.some((e) => e.dimension.length === 0)
                    // }
                    disabled={
                      !watchFilters.every(
                        (filter) => filter?.values?.length > 0
                      )
                    }
                    variant="link"
                    onClick={() => {
                      filterAppend({
                        dimension: "",
                        isInclude: true,
                        values: [],
                      });
                    }}
                  >
                    <img src={addsymbol} alt=" "></img>
                  </Button>
                </div>
                {filterFields.map((e, i) => (
                  <FilterItem
                    watch={watch}
                    field={e}
                    index={i}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    remove={filterRemove}
                    watchCloud={watchCloud}
                    key={e.id}
                    append={filterAppend}
                    dimensionData={dimensionData}
                  />
                ))}
                {errors?.filters?.length && (
                  <small className={styles.error_message}>
                    {errors.filters[0].message}
                  </small>
                )}
              </div>

              <div className={styles.filters_css}>
                <div className={styles.filter_name_div}>
                  <div className={styles.filter_name}>Tags</div>
                  <Button
                    // disabled={
                    //   tagFields.length >= 1 &&
                    //   tagFields.some((e) => e.filterOn.length === 0)
                    // }
                    disabled={
                      !watchTags ||
                      !watchTags?.length ||
                      watchTags.some(
                        (tag) => !tag.values || tag.values.length === 0
                      )
                    }
                    variant="link"
                    onClick={() => {
                      tagAppend({
                        filterOn: "",
                        filterType: "include",
                        values: [],
                      });
                    }}
                  >
                    <img src={addsymbol} alt=" "></img>
                  </Button>
                </div>
                {tagFields.map((e, i) => (
                  <TagItem
                    dimensionKeys={dimensionKeys}
                    watch={watch}
                    field={e}
                    index={i}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    remove={tagRemove}
                    watchCloud={watchCloud}
                    key={e.id}
                    append={tagAppend}
                  />
                ))}
                {errors?.tags?.length && (
                  <small className={styles.error_message}>
                    {errors.tags[0].message}
                  </small>
                )}
              </div>
            </div>

            {addWidgetError && <p>{addWidgetError.message}</p>}
            {updateWidgetError && <p>{updateWidgetError.message}</p>}
          </div>

          <div
            className={clsx(
              styles.buttons_div,
              "justify-content-start gap-1 mt-5"
            )}
          >
            {widget && (
              <Button
                variant="outline-danger"
                type="button"
                className={clsx(styles.btn, styles.delete_btn)}
                disabled={
                  addWidgetLoading ||
                  updateWidgetLoading ||
                  customWidgetContext.deleteWidgetMutation.isPending
                }
                onClick={() => {
                  customWidgetContext.deleteWidget({
                    service,
                    widgetId: widget.id,
                  });
                }}
              >
                Delete
              </Button>
            )}

            <div className="flex-fill" />

            <Button
              disabled={addWidgetLoading || updateWidgetLoading}
              type="reset"
              variant="outline-secondary"
              className={clsx(styles.btn, styles.cancel_btn)}
              onClick={handleCloseOverlay}
            >
              Cancel
            </Button>

            <Button
              disabled={addWidgetLoading || updateWidgetLoading}
              type="submit"
              className={clsx(styles.btn, styles.save_btn)}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default EditWidget;
