// import React from "react";
// import styles from "@/page-components/cost-explorer/cost.explorer.module.css"
// import clsx from "clsx";

// const TabsContainer = ({ selectedCustomTabs, setSelectedCustomTabs , service}) => {
//   return (
//     <div className={styles.tabsDateContainer}>
//       <div className={styles.twoTabsContainer}>
//         <div
//           // className={`${
//           //   !selectedCustomTabs
//           //     ? styles.first_btn_combo_container
//           //     : "second-btn-btn-combo__Container"
//           // } hover`}

//           className={clsx('hover',{
//             [styles.first_btn_combo_container] : !selectedCustomTabs,
//             [styles.second_btn_combo_container] : selectedCustomTabs
//            })}

//           onClick={() => setSelectedCustomTabs(false)}
//         >
//           <div
//             className={clsx({
//               [styles.text_first_btn_combo_container ] : !selectedCustomTabs,
//               [styles.text_second_btn_combo_container] : selectedCustomTabs
//              })}
//           >
//             Daily Costs & Usage
//           </div>
//         </div>
//         {service !== "allclouds" && (
//         <div

//           className={clsx('hover',{
//             [styles.first_btn_combo_container] : selectedCustomTabs,
//             [styles.second_btn_combo_container] : !selectedCustomTabs
//            })}

//           onClick={() => setSelectedCustomTabs(true)}
//         >
//             <div
//                className={clsx({
//                 [styles.text_first_btn_combo_container ] : selectedCustomTabs,
//                 [styles.text_second_btn_combo_container] : !selectedCustomTabs
//                })}
//             >
//               Custom Widgets
//             </div>
//         </div>
//           )}
//       </div>
//       <div className={styles.updatedDate}>Updated Today at 7:05AM</div>
//     </div>
//   );
// };

// export default TabsContainer;

import React, { useState } from "react";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import clsx from "clsx";

const TabsContainer = ({
  selectedCustomTabs,
  setSelectedCustomTabs,
  setSelectedForecastTab,
  selectedForecastTab,
  service,
}) => {
  const handleTabClick = (customTab, forecastTab) => {
    setSelectedCustomTabs(customTab);
    setSelectedForecastTab(forecastTab);
  };

  return (
    <div className={styles.tabsDateContainer}>
      <div className={styles.twoTabsContainer}>
        <div
          className={clsx("hover", {
            [styles.first_btn_combo_container]:
              !selectedCustomTabs && !selectedForecastTab,
            [styles.second_btn_combo_container]:
              selectedCustomTabs || selectedForecastTab,
          })}
          onClick={() => handleTabClick(false, false)}
        >
          <div
            className={clsx({
              [styles.text_first_btn_combo_container]:
                !selectedCustomTabs && !selectedForecastTab,
              [styles.text_second_btn_combo_container]:
                selectedCustomTabs || selectedForecastTab,
            })}
          >
            Daily Costs & Usage
          </div>
        </div>
        {service !== "allclouds" && (
          <div
            className={clsx("hover", {
              [styles.first_btn_combo_container]:
                selectedCustomTabs && !selectedForecastTab,
              [styles.second_btn_combo_container]:
                !selectedCustomTabs || selectedForecastTab,
            })}
            onClick={() => handleTabClick(true, false)}
          >
            <div
              className={clsx({
                [styles.text_first_btn_combo_container]:
                  selectedCustomTabs && !selectedForecastTab,
                [styles.text_second_btn_combo_container]:
                  !selectedCustomTabs || selectedForecastTab,
              })}
            >
              Custom Widgets
            </div>
          </div>
        )}
         {service !== "allclouds" && (
        <div
          className={clsx("hover", {
            [styles.first_btn_combo_container]: selectedForecastTab,
            [styles.second_btn_combo_container]: !selectedForecastTab,
          })}
          onClick={() => handleTabClick(false, true)}
        >
          <div
            className={clsx({
              [styles.text_first_btn_combo_container]: selectedForecastTab,
              [styles.text_second_btn_combo_container]: !selectedForecastTab,
            })}
          >
            Forecasted Costs
          </div>
        </div>
         )}
      </div>
      <div className={styles.updatedDate}>Updated Today at 7:05AM</div>
    </div>
  );
};

export default TabsContainer;
