import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllHeaderStatistics
 * @property {AllStatics} Statics
 * @property {AllStaticsCloud} snapshotByClouds
 */

/**
 * @typedef AllStatics
 * @property {Number} totalSnapshotCount
 * @property {Number} totalSnapshotCost
 * @property {Number} unTaggedCount
 * @property {Number} orphanedCount
 * @property {Number} orphanedSavings
 * @property {Btw10-15}
 * @property {Btw15-30}
 * @property {more_than_thirty}
 *
 */

/**
 * @typedef Btw10-15
 * @property {Number} count
 */

/**
 * @typedef Btw15-30
 * @property {Number} count
 */

/**
 * @typedef more_than_thirty
 * @property {Number} count
 */

/**
 * @typedef AllStaticsCloud
 * @property {Number} AwsSnapshotCount
 * @property {Number} AwsSnapshotCost
 * @property {Number} AzureSnapshotCount
 * @property {Number} AzureSnapshotCost
 */
/**
 * @typedef GetSnapshotsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} diskIds
 * @property {string[]} snapshotAge
 * @property {string[]} snapshottags
 */

/**
 * @typedef AllHeaderStatisticsRes
 * @property {AllHeaderStatistics} statistics
 * @property {GetSnapshotsFilters} filters
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 */

export const getArchivedHeaderStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllHeaderStatisticsRes>}
   */
  return async ({ service, filters }, signal) => {
    const HeaderStats = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/archived-stats`,
      // {
      //   method: "Get",
      //   headers: {
      //     Authorization: token
      //   }
      // }
      {
        method: service === "all-cloud" ? "GET" : "POST",
        body:
          service !== "all-cloud"
            ? JSON.stringify({
                // accounts: filters?.accounts || [],
                // regions: filters?.regions || [],
                // tags: filters?.tags || [],
                filters,
              })
            : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!HeaderStats.ok) {
      if (ERROR_STATUS_CODES.includes(HeaderStats.status)) {
        throw new Error(API_LOADING_ERROR_SNAPSHOT);
      }
      if (HeaderStats.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await HeaderStats.json()).message);
    }
    const res = await HeaderStats.json();
    if (res?.total === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseArchiveheaderStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const myquery = useQuery({
    queryKey: ["getArchivedHeaderStatistics", req, ...deps],
    queryFn: () => getArchivedHeaderStatistics(appContext.idToken)(req),
  });
  return myquery;
};
