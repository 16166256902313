import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {string} filter
 * @property {GetDataStatistics} data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} id
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 * @property {GetIdleFilters} filters
 */

/**
/**
 * @typedef GetAccountStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'} service
 * @property {'account' | 'region' | 'rg' } selectedTittle
 */

export const getAccountStatistics = (token) => {
    /**
   * @param {GetAccountStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service,selectedTittle,filters }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/filter?type=${selectedTittle}`,
      {
        // method: "POST",
        // body: JSON.stringify({
        //   accounts: filters?.accounts || [],
        // }),
        method: service !== "azure" ? "GET" : "POST",
        body:
          service === "azure"
            ? JSON.stringify({
              subscriptions: filters?.accounts || [],
              })
            : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
}

/**

 * @param {GetAccountStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAccountStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAccountStatistics", req, ...deps],
    queryFn: ({ signal }) => getAccountStatistics(appContext.idToken)(req, signal),
    enabled: req.selectedTittle != null
  });
  return query;
};


