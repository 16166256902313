import { useContext } from "react";
import config from "../../../config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import Joi from "joi";
// import { is } from "date-fns/locale";

/**
 * @typedef CreateUserReqGroups
 * @property {string} id
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef CreateUserReqValidator
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {boolean} addAsAdmin
 * @property {CreateUserReqGroups[]} groupIds
 */

export const CreateUserReqValidator = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().required().messages({
    "string.empty": "*Email cannot be empty",
    "any.required": "*Email cannot be empty",
  }),
  description: Joi.string().allow("").optional(),
  addAsAdmin: Joi.boolean().required(),
  enable_create_budget_privilage: Joi.when("addAsAdmin", {
    is: Joi.boolean().valid(true),
    then: Joi.boolean(),
  }),
  groupIds: Joi.when("addAsAdmin", {
    is: Joi.boolean().valid(true),
    then: Joi.array(),
    otherwise: Joi.array()
      .min(1)
      .items(
        Joi.object({
          id: Joi.number(),
          name: Joi.string(),
          description: Joi.string().allow("", null).optional(),
        })
      )
      .required()
      .messages({
        "array.min": "*Groups cannot be empty",
        "any.required": "*Groups cannot be empty",
      }),
  }),
});

const setUser = (token) => {
  return async (data) => {
    const setUser = await fetch(`${config.apiHost}/v1/users/add-user`, {
      method: "POST",
      body: JSON.stringify(data?.body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!setUser.ok) {
      throw new Error((await setUser.json()).message);
    }
    const userStatus = await setUser.json();
    return userStatus;
  };
};

/**
 * @typedef GetUserEmailsReq
 * @property {string} filterQuery
 */

/**
 * @typedef GetUserEmailsItem
 * @property {string} name
 * @property {string} email
 */

/**
 * @typedef GetUserEmailsRes
 * @property {GetUserEmailsItem[]} users
 */

/**
 * @param {data} GetUserEmailsProps
 * @returns
 */

const getUserEmails = (token) => {
  /**
   * @param {GetUserEmailsReq} data
   */
  return async (data) => {
    const getUserEmails = await fetch(`${config.apiHost}/v1/users/get-emails`, {
      method: "POST",
      body: data.filterQuery?.length ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!getUserEmails.ok) {
      throw new Error((await getUserEmails.json()).message);
    }
    const emailStatus = await getUserEmails.json();
    return emailStatus;
  };
};

const searchUserService = (token) => {
  /**
   * @param {string} data
   * @param {string=} data
   */
  return async (data, filterQuery, signal) => {
    const setUserGroup = await fetch(
      `${config.apiHost}/v1/${data}/search${
        filterQuery ? "?filterQuery=" + filterQuery : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!setUserGroup.ok) {
      throw new Error((await setUserGroup.json()).message);
    }
    const userGroupStatus = await setUserGroup.json();
    return userGroupStatus;
  };
};

export const useCreateUser = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["setUser", ...deps],
    mutationFn: (body) => setUser(appContext.idToken)(body),
  });

  return mutate;
};

/**
 * @param {GetUserEmailsReq} data
 * @param {any[]} deps
 * @returns
 */
export const useGetUserEmails = (data, deps = []) => {
  const appContext = useContext(AppContext);

  const query = useQuery({
    queryKey: ["getUserEmails", data, ...deps],
    queryFn: () => getUserEmails(appContext.idToken)(data),
  });
  return query;
};

/**
 * @param {string} type
 * @param {string=} filterQuery
 * @param {any[]=} deps
 * @returns
 */
export const useUserServiceSearch = (
  type,
  filterQuery = undefined,
  deps = []
) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    staleTime: 0,
    queryKey: ["searchUserService", type, ...deps],
    queryFn: ({ signal }) =>
      searchUserService(appContext.idToken)(type, filterQuery, signal),
  });
  return query;
};
