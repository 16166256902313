import React, { useContext } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import BillingBarGraph from "../components/billing-bar-graph.part";
import { BillingContext, BillingProvider } from "../billing-context.part";
import CreditFilterSection from "../components/filter-section/filter-section";
import AzureHeaderComponent from "../components/azure-header-component";
import CollapsibleTable from "../components/mca-billing-details-table.part";

const AzureBillingPage = () => {
  return (
    <BillingProvider>
      <BillingPart />
    </BillingProvider>
  );
};

const BillingPart = () => {
  const context = useContext(BillingContext);
  const service = "azure";
  const name =
    context?.accountDropdown === "Enterprise Agreement" ? "Account" : "Profile";

  return (
    <div className={styles.billing_comp}>
      <AzureHeaderComponent service={service} />
      <>
        <div className={styles.conatining}>
          <div style={{ width: "82%", marginTop:"24px" }}>
            <div className={styles.bar_card} style={{ width: "100%" }}>
              <BillingBarGraph service={service} />
            </div>

            <CollapsibleTable billingName={name} service= {service} />
           
          </div>
          <div style={{ width: "18%" }}>
            <CreditFilterSection name={`Billing ${name}`} service={service} />
          </div>
        </div>
      </>
    </div>
  );
};

export default AzureBillingPage;
