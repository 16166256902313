import React, { useEffect, useMemo } from "react";
import "@/assets/css/filter.css";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import { ReactComponent as RemoveIcon } from "@/assets/img/filter-delete.svg";
import { FilterSearch } from "@/components/filter-search/filter-search";
import styles from "@/page-components/cost-explorer/components/filter-section/filter-section.module.css";
import clsx from "clsx";
import { Button } from "react-bootstrap";
import Tooltip from "@/components/tooltip/tooltip";
import { useTagValues } from "@/services/budget/getBudgetTagKeys.repo";
import { useNavigate } from "react-router-dom";

const TagItem = ({
  tag,
  service,
  index,
  tagItems,
  setSelectedTags,
  selectedTags,
}) => {
  const { data: dimensionValues, isLoading } = useServiceDimensionTags(
    service,
    tag.filterOn
  );

  selectedTags.forEach((filter) => {
    if (filter.filterOn !== " ") {
      tagItems = tagItems.filter((item) => item.name !== filter.filterOn);
    }
  });

  const filteredValues = useMemo(() => {
    return (
      (tag.values || []).map((e) => ({
        id: e,
        name: e,
      })) || []
    );
  }, [tag.values]);

  const tagLabelsFilterOn = tagItems.find((e) => e.id === tag.filterOn);
  const extractionSelectedOptions = (tagItems || []).filter(
    (e) => e !== tagLabelsFilterOn
  );
  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  return (
    <div className={styles.filter_select_box}>
      <div className={styles.service_name_box}>
        <div className={styles.services_name_css}>
          <div className="w-100">
            <FilterSearch
              options={extractionSelectedOptions}
              displayValue="name"
              selectedValues={[tag.filterOn]}
              searchFieldPlaceholder={"Filter Tags"}
              onSelect={(selectedItem) => {
                handleTagSelection()
                setSelectedTags((arr) => {
                  const newArr = [...arr];
                  newArr[index].filterOn = selectedItem.id;
                  newArr[index].values = [];
                  return newArr;
                });
              }}
              toggle={
                <div
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]: tag.filterOn,
                  })}
                >
                  {tag.filterOn ? tag.filterOn : "Add Tags"}
                </div>
              }
            />
          </div>
          {tag.filterOn && (
            <Tooltip
              tooltipContent="Remove"
              overlayProps={{ placement: "bottom-end" }}
            >
              {(_show, setShow) => (
                <Button
                  variant="link"
                  className={clsx("p-0", styles.remove_icon)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  onClick={() => {
                    if (selectedTags.length > 1) {
                      setSelectedTags((arr) => {
                        const newArr = [...arr];
                        newArr.splice(index, 1);
                        return newArr;
                      });
                    } else {
                      setSelectedTags([
                        {
                          filterOn: "",
                          filterType: "include",
                          values: [],
                        },
                      ]);
                    }
                  }}
                >
                  <RemoveIcon />
                </Button>
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.filter_grouping_box}>
        <div className={styles.filter_group_name_css}>
          <FilterSearch
            multiselect
            inclusion={tag.filterType === "include"}
            onInclusionChange={(inclusion) => {
              setSelectedTags((s) => {
                const list = [...s];
                list[index].filterType = inclusion;
                return list;
              });
            }}
            options={(dimensionValues?.data || []).map((e) => ({
              id: e?.id || "null",
              name: e?.name || "null",
            }))}
            displayValue="name"
            loading={isLoading}
            selectedValues={filteredValues}
            searchFieldPlaceholder={"Filter " + tag.filterOn || ""}
            onSelect={(list) => {
              handleTagSelection()
              setSelectedTags((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            onRemove={(list) => {
              setSelectedTags((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            disabled={!tag?.filterOn?.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {!tag?.filterOn?.length && (
                  <span className={styles.placeholder_text}>Choose Values</span>
                )}
                {tag?.filterOn?.length > 0 && filteredValues.length === 0 && (
                  <div className={clsx(styles.chip, "w-100")}>Value</div>
                )}
                {filteredValues.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <img
                      src={
                        tag.filterType === "include" ? includeIcon : excludeIcon
                      }
                      alt=""
                    />
                    <span className={styles.chip_content}>
                      <span className="text-truncate w-100 d-block">
                        {filteredValues
                          .slice(0, 1)
                          .map((e) => e.name || null)
                          .join(",")}
                      </span>
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {string} columnName
 */
const useServiceDimensionTags = (service, columnName) => {
  const {
    data: values,
    refetch: fetchValues,
    isLoading,
  } = useTagValues({
    columnName,
    service,
  });
  useEffect(() => {
    if (service && columnName) {
      fetchValues();
    }
  }, [columnName, fetchValues, service]);
  return { data: values || [], isLoading };
};

export default TagItem;
