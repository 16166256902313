import { useContext } from "react";
import config from "../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

const setFunctionalities = (token) => {
  return async (data) => {
    var setStatus;
    if (data?.selection === "delete") {
      setStatus = await fetch(
        `${config.apiHost}/v1/${data?.service}/budget/delete`,
        {
          method: "DELETE",
          body: JSON.stringify(data?.body),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    }
    if (data?.selection === "notification") {
      setStatus = await fetch(
        `${config.apiHost}/v1/${data?.service}/budget/${data?.type}`,
        {
          method: "POST",
          body: JSON.stringify(data?.body),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    }
    if (data?.selection === "singleRes" && data?.body?.budgetName!==undefined) {
      setStatus = await fetch(
        `${config.apiHost}/v1/${data?.service}/budget/get-budget`,
        {
          method: "POST",
          body: JSON.stringify(data?.body),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    }

    if (!setStatus.ok) {
      throw new Error((await setStatus.json()).message);
    }
    const statusData = await setStatus.json();
    return statusData;
  };
};

export const useCombineQuery = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutate = useMutation({
    mutationKey: ["setFunctionalities", ...deps],
    mutationFn: (body) => setFunctionalities(appContext.idToken)(body),
  });
  return mutate;
};
