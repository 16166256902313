import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetStatistics
 * @property {number | null} total
 * @property {number | null} savings_percent
 * @property {number | null} opportunities_missed_pm
 * @property {number | null} recommendations_active
 * @property {number | null} current_costs_pm
 * @property {number | null} downsize_count
 * @property {number | null} downsize_savings_pm
 * @property {number | null} downsize_cost_pm
 * @property {number | null} upsize_count
 * @property {number | null} upsize_savings_pm
 * @property {number | null} upsize_cost_pm
 * @property {number | null} projected_costs_pm
 * @property {number | null} terminate_count
 * @property {number | null} terminate_savings_pm
 * @property {number | null} terminate_cost_pm
 */

/**
 * @typedef GetStatisticsRes
 * @property {GetStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service 
  */

export const getArchivedStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal} signal
   * @returns {Promise<GetStatisticsRes>}
   */
  return async ({
    service
  }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/recommendations/archived-statistics`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token
        }
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
}


/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useArchivedHeaderStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getArchivedStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getArchivedStatistics(appContext.idToken)(req, signal),
  });
  return query;
}