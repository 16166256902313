import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetSnapshotsListRes
 * @property {string} message
 */

/**
 * @typedef AddAcceptDismissSnapshotReq
 * @property {string} service
 * @property {string} resourceId
 * @property {string} action
 */

export const addAcceptDismissSnapshot = (token) => {
  /**
   * @param {AddAcceptDismissSnapshotReq} req
   * @returns {Promise<GetSnapshotsListRes>}
   */
  return async ({ resourceId, service, action }) => {
    
    
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/saving`,
      {
        method: "PUT",
        body: JSON.stringify({
          resourceId,
          action,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    return await recommendationsList.json();
  };
};






/**
 * @param {any[]=} deps
 */
export const useAcceptDismissSnapshot = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["addAcceptDismissSnapshot", appContext.idToken, ...deps],
    mutationFn: addAcceptDismissSnapshot(appContext.idToken),
  });
  return mutation;
};
