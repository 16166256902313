import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} resource_count
 * @property {string} month
 * @property {string} total_savings
 */

/**
 * @typedef GetSavingFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 * @property {GetSavingFilters} filters
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {string} toDate
 * @property {string} fromDate
 */

export const getIdleGraphStatistics = (token) => {
  return async ({ service, filters, fromDate, toDate }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }

    const response = await fetch(
      `${config.apiHost}/v1/${service}/savings/savings-monthly?from=${fromDate}&to=${toDate}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      if (ERROR_STATUS_CODES.includes(response.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (response.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await response.json()).message);
    }

    const data = await response.json();

    if (data?.data.length === 0) {
      if (body.accounts.length || body.regions.length || body.tags.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      }
      throw new Error("No data available.");
    }

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */

export const useIdleGraphStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);

  const query = useQuery({
    queryKey: ["getIdleGraphStatistics", req, ...deps],
    queryFn: () => getIdleGraphStatistics(appContext.idToken)(req),
  });

  return {
    ...query,
  };
};
