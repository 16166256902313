import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetAccountDataStatistics[]} account
 * @property {GetregionDataStatistics[]} region
 */
/**
 * @typedef GetAccountDataStatistics
 * @property {string} accountId
 * @property {string} accountName
 */
/**
 * @typedef GetregionDataStatistics
 * @property {string} regionId
 * @property {string} regionName
 */
/**
 * @typedef CostExplorerUsageDetailsFilters
 * @property {string} dimension
 * @property {boolean} isInclude
 * @property {string[]} values
 */
/**
 * @typedef CostExplorerUsageDetailsTags
 * @property {string} filterOn
 * @property {string} filterType
 * @property {string[]} values
 *


/**
 * @typedef GetStatisticsReq
 * @property {string} toDate
 * @property {string} fromDate
 * @property {string[] =} region
 * @property {string[] =} accounts
 * @property {string} dimension
 * @property {CostExplorerUsageDetailsFilters[] =} filters
 * @property {CostExplorerUsageDetailsTags[] =} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatisticsRes>}
 */

// const mookdata = {
//   "services": [
//         {
//             "serviceName": "Cloud Read Write Operations",
//             "serviceId": "Cloud Read Write Operations"
//         },
//         {
//             "serviceName": "Cloud Read Write Operations",
//             "serviceId": "Cloud Read Write Operations"
//         },
   
//       ],
//   "accounts": [
//     {
//       accountId: "7a6a1cdf-6c63-4897-8dfa-a0149da6ca76",
//       accountName: "CEQUAT",
//     },
//   ],
//   "regions": [
//     {
//       regionId: "eastus",
//       regionName: "eastus",
//     },
//     {
//       regionId: "eastus",
//       regionName: "eastus",
//     },
//   ],
// };

export const getUsageDetailsfilters = (token) => {
  return async ({ cloud, granularity, ...rest }, signal) => {
    // return mookdata;
    const owners = await fetch(
      `${config.apiHost}/v1/${cloud}/cost/usage-details-filters`,
      {
        method: "POST",
        body: JSON.stringify(rest),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UsageDetailsFilterStatics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getUsageDetailsfilters", appContext.idToken, req, ...deps],
    queryFn: () => getUsageDetailsfilters(appContext.idToken)(req),
  });
  return query;
};
