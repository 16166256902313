import React, { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import styles from "./tooltip.module.css";

/**
 * @callback TooltipRenderFunc
 * @param {boolean} show
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShow
 * @returns {React.ReactElement}
 */

/**
 * @typedef TooltipProps
 * @property {React.ReactElement} tooltipContent 
 * @property {TooltipRenderFunc=} children
 * @property {React.HTMLAttributes<HTMLDivElement>=} tooltipContainerProps
 * @property {import('react-bootstrap').OverlayProps=} overlayProps
 */

/**
 * @param {TooltipProps} props
 */
const Tooltip = ({ children, overlayProps, tooltipContainerProps, tooltipContent }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  return (
    <>
      <div ref={ref} {...tooltipContainerProps}>
        {children(show, setShow)}
      </div>
      <Overlay target={ref.current} show={show} {...overlayProps} >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div className={styles.overlay} {...props} >
            {tooltipContent}
          </div>
        )}
      </Overlay>
    </>
  );
};

export default Tooltip;
