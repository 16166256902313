/** @type {import('./global')} */
import React, { createContext, useEffect, useState } from "react";

export const AiRecommendContext = createContext();
/**
 * @typedef AiRecommendContextType
 * @property {string[]} eventKey
 * @property {React.Dispatch<React.SetStateAction<string>>} setEventkey
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} selectOwner
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectOwner
 * @property {string[]} hideFilter
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setHideFilter
 * @property {string[]} filterWidth
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setFilterWidth
 */

/**
 * @type {React.Context<AiRecommendContextType>}
 */

/**
 * @returns {AiRecommendContextType}
 */
export const UseAiContext = () => {
  const [eventkey, setEventkey] = useState("Usage Patterns");

  /** @type {UseState<string[]>} */
  const [selectAccounts, setSelectAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [hideFilter, setHideFilter] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectOwner, setSelectOwner] = useState([]);

  /** @type {UseState<string[]>} */
  const [filterWidth, setFilterWidth] = useState("");

  useEffect(() => {
    if (hideFilter === true) {
      setFilterWidth("100");
    }
  }, [hideFilter]);

  return {
    eventkey,
    setEventkey,
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    selectedResourceGroup,
    setSelectedResourceGroup,
    selectOwner,
    setSelectOwner,
    hideFilter,
    setHideFilter,
    filterWidth,
    setFilterWidth,
  };
};

export const AiRecommendProvider = ({ children }) => {
  const context = UseAiContext();

  return (
    <>
      <AiRecommendContext.Provider value={context}>
        {children}
      </AiRecommendContext.Provider>
    </>
  );
};
