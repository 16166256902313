import React from 'react'
import { Spinner } from 'react-bootstrap'

const SpinnerComponent = () => {
  return (
    <div className="d-flex justify-content-center align-items-baseline m-auto gap-2">
    <Spinner
      variant="primary"
      as="span"
      // animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading
    </div>
  )
}

export default SpinnerComponent