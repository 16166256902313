import React, { useMemo } from "react";
import RightSizingHeader from "../components/AllCloudsHeader";
import { RightSizingChart } from "../components/rightChart/RightSizingChart";
import { useAllStatistics } from "@/services/right-sizing/getAllClouds.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import clsx from "clsx";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

const AllCloudRightSizing = ({ service = "all-cloud" }) => {
  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);
  const { data, isLoading, error, isError } = useAllStatistics(query);
  return (
    <>
      {isLoading && <AllPageLoading />}
      {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
      )}
      {data && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <RightSizingHeader
                service="all-cloud"
                data={data}
                error={isError}
              />
              <div
                className={clsx(
                  "container-fluid col-lg-12 ",
                  style.ChartSection
                )}
              >
                <div className="row w-100" style={{ margin: "0px" }}>
                  <div
                    className="col-12 p-0 pe-2 pt-4 d-flex  flex-column"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <RightSizingChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllCloudRightSizing;
