import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef GetDimensionReq
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property {string} columnName
 */

export const getTagsValues = (token) => {
  /**
   * @param {GetDimensionReq} req
   * @returns {Promise<Record<string, string | number>[]>}
   */
  return async ({ service, columnName }, signal) => {
    const tableData = await fetch(
      `${config.apiHost}/v1/${service}/kubernetes/dimension-tags?columnName=${columnName}`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!tableData.ok) {
      throw new Error((await tableData.json()).message);
    }
    return await tableData.json();
  };
};

/**
 * @param {GetDimensionReq} req
 * @param {any[]=} deps
 */
export const useTagValues = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTagsValues", req.service, req.columnName, ...deps],
    queryFn: ({ signal }) => getTagsValues(appContext.idToken)(req, signal),
    retry: 0,
    enabled: false,
  });
  return query;
};





export const getTagsKeys = (token) => {
  return async ({ service }) => {
    const filterDropDown = await fetch(
      `${config.apiHost}/v1/${service}/kubernetes/key-tags`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!filterDropDown.ok) {
      throw new Error((await filterDropDown.json()).message);
    }
    const data = await filterDropDown.json();
    return data;
  };
};

export const useTagsKeys = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getKuberneteStatsData", req, ...deps],
    queryFn: () => getTagsKeys(appContext.idToken)(req),
  });
  return query;
};