import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetTotalCountStatistics[]} data
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {String} id
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

export const getFilterBudgetsTag = (token) => {
  return async ({ service,scope }) => {
    const filterAccount = await fetch(
      `${config.apiHost}/v1/${service}/budget/filters/tags`,
      {
        method: "POST",
        body:JSON.stringify({
          scope:scope

        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await filterAccount.json();
  }
}

export const getTagsValues = (token) => {
    return async ({ service, columnName }, signal) => {
      const tableData = await fetch(
        `${config.apiHost}/v1/${service}/budget/filters/tag-values?tagKey=${columnName}`,
        {
          method: "POST",
          signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!tableData.ok) {
        throw new Error((await tableData.json()).message);
      }
      return await tableData.json();
    };
  };

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useFilterBudgetsTag = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getFilterBudgetsTag", req, ...deps],
    queryFn: () => getFilterBudgetsTag(appContext.idToken)(req),
  });
  return query;
};

export const useTagValues = (req, deps = []) => {
    const appContext = useContext(AppContext);
    const query = useQuery({
      queryKey: ["getTagsValues", req.service, req.columnName, ...deps],
      queryFn: ({ signal }) => getTagsValues(appContext.idToken)(req, signal),
      retry: 0,
      enabled: false,
    });
    return query;
  };
