
import React from 'react'
import gcponwork from '@/assets/img/gcpOnwork.svg'
const OnWorking = ({name ,service}) => {
    return (
      // <div className="sign_container" style={{marginTop:"300px"}}>Working on Page </div>
      <div className='My-onworPage' >
        <img src={gcponwork} alt="" />
        <div className='contianer-first-onwork'>
          <h3 className='heading-onWork' style={{textAlign:"center"}}>{name} ({service}) coming soon!</h3>
          <p className='on-work-details'>Sorry, the page you're looking for doesn't exist.</p>
  
        </div>
  
      </div>
  
    )
  }

export default OnWorking
