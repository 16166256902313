import { AppContext } from "@/App.context";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { API_LOADING_ERROR_CUSTOM_WIDGET, API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET, API_NOT_FOUND_ERROR_CUSTOM_WIDGET, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";

const setTopCostList = (token) => {
  return async (Qdata) => {
    // return data;
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/cost-generator-list?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId;
      bodyData.resourceCreation = Qdata?.resourceCreation;
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};
const setNewlyList = (token) => {
  return async (Qdata) => {

    // return data;
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/newly-created-resources-list?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId;
      bodyData.resourceCreation = Qdata?.resourceCreation;
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};
const setSnapshotList = (token) => {
  return async (Qdata) => {
    // return ScatterData;
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/snapshot-list?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId;
      bodyData.resourceCreation = Qdata?.resourceCreation;
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};
const setUnusedList = (token) => {
  return async (Qdata) => {
    // return unusedData;
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/unused-resources-list?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId;
      bodyData.resourceCreation = Qdata?.resourceCreation;
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};

export const TopCostListValues = (queryData, options = {}) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setTopCostList", queryData],
    queryFn: () => setTopCostList(appContext.idToken)(queryData),
    retry: false,
    ...options,
  });
  return query;
};
export const useNewlyListValues = (queryData, options = {}) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setNewlyList", queryData],
    queryFn: () => setNewlyList(appContext.idToken)(queryData),
    retry: false,
    ...options,
  });
  return query;
};

export const useSnapshotListValues = (queryData, options = {}) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setSnapshotList", queryData],
    queryFn: () => setSnapshotList(appContext.idToken)(queryData),
    retry: false,
    ...options,
  });
  return query;
};
export const useUnusedListValues = (queryData, options = {}) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setUnusedList", queryData],
    queryFn: () => setUnusedList(appContext.idToken)(queryData),
    retry: false,
    ...options,
  });
  return query;
};
