import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef Recommendation
 * @property {string} recommendation_id
 * @property {string} account_id
 * @property {string} current_resource_type
 * @property {string} recommended_resource_type
 * @property {string} resource_id
 * @property {string} region
 * @property {string} saving
 * @property {string} monthly_cost
 * @property {string} recommended_action
 * @property {number} restart
 * @property {number} rollback_possible
 * @property {number} monthly_saving
 * @property {number} recommended_resource_summary
 */

/**
 * @typedef GetRecommendationsListRes
 * @property {Recommendation[]} recommendations
 * @property {number} count
 */

/**
 * @typedef GetRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 * @property {GetRecommendationsFilters} filters
 * @property {string} orderBy
 * @property {boolean} desc
 * @property {number} page
 * @property {number} recordsPerPage
 */

export const getRecommendationsList = (token) => {
  /**
   * @param {GetRecommendationsListReq} req
   * @returns {Promise<GetRecommendationsListRes>}
   */
  return async (
    { page, recordsPerPage, orderBy, desc, service, accounts, regions,resource_type,impact },
    signal
  ) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/listing?page=${page}&recordsPerPage=${recordsPerPage}&orderBy=${orderBy}&desc=${desc}`,
      {
        method: "POST",
        body: JSON.stringify({
          accounts: accounts || [],
          regions: regions || [],
          resource_type:resource_type||[],
          impact:impact||[]
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!recommendationsList.ok) {
      if (ERROR_STATUS_CODES.includes(recommendationsList.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (recommendationsList.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await recommendationsList.json()).message);
    }
    const res = await recommendationsList.json();
    if (res?.count?.total_data === 0) {
      if (accounts?.length || regions?.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useCostRecommendationsList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRecommendationsList", req, ...deps],
    queryFn: ({ signal }) =>
      getRecommendationsList(appContext.idToken)(req, signal),
    retry:false
  });
  return query;
};
