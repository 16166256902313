import React, { useState, useContext } from "react";
import { Table } from "react-bootstrap";
import styles from "../admin.module.css";
import editImg from "@/assets/img/edit.svg";
import format from "date-fns/format";
import { Spinner } from "react-bootstrap";
import AddUserModal from "./create.user.modal.part";
import { AdminContext } from "@/common/Admin.context";
import { RolesContext, RolesContextProvider } from "./roles.context";
import { useDeleteUser } from "@/services/admin/Roles/deleteUser.repo";
import SideModalGroup from "@/page-components/admin/roles/user.info.modal.part";
import LoadingRbacTable from "../states/loading.state.part";

const AdminTableComp = ({ data, loading, refetch }) => {
  return (
    <RolesContextProvider>
      <AdminTable data={data} loading={loading} refetch={refetch}/>
    </RolesContextProvider>
  );
};

const AdminTable = ({ data, loading, refetch }) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const adminContext = useContext(RolesContext);

  const {
    data: deleteUserGroup,
    mutate: deleteUser,
    isLoading: deletingUser,
    reset: deleteReset,
  } = useDeleteUser();

  const handleOpenShow = (item) => {
    setSelectedItem(item.id);
    setAddModalOpen(true);
  };
  const handleOpenClose = () => {
    setAddModalOpen(false);
  };

  const handleModalClose = () => {
    adminContext.setCreateUserModal(false);
  };

  const selectedItemData = data.find((item) => item.id === selectedItem);

  const formattedSelectedItem = selectedItemData
    ? {
        groups: [],
        user: selectedItemData,
      }
    : null;

  const handleTableRowClick = (item) => {
    setSelectedItem(item);
    adminContext.handleGroupShow();
    adminContext.setIsAdminInfoModal(true);
  };
  return (
    <>
      <Table responsive className={styles.table}>
        <thead className={styles.tableHeadStyling}>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th
              style={{
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <span>User Description</span>
            </th>
            <th>Creator</th>
            <th>Created on</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data && data.total === 0 && (
            <tr>
              <td colSpan={6} className="elipese">
                <div className="py-5 d-flex align-items-center justify-content-center">
                  No Roles to display
                </div>
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan={6} className="elipese">
                <div className="py-5 d-flex align-items-center justify-content-center">
                  <Spinner variant="primary" />
                </div>
              </td>
            </tr>
          )}

          {(data || []).map((item) => (
            <tr className={styles.tableRowStyling}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => handleTableRowClick(item.id)}
              >
                <u
                  style={{
                    color: "#056EE5",
                    fontWeight: "600",
                    pointer: "cursor",
                  }}
                >
                  {item.name}
                </u>{" "}
              </td>

              <td> {item.email || "-"}</td>
              <td
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                className="elipese"
              >
                {item.description || "-"}
              </td>
              <td className="elipese">{item.creatorName || "-"}</td>
              <td className="elipese">
                {format(new Date(item.createdAt), "dd/MM/yyyy")}
              </td>
              <td
                style={{ textAlign: "center" }}
                className="elipese"
                onClick={() => handleOpenShow(item)}
              >
                <img src={editImg} width="12px" height="12px" alt="" />
              </td>
            </tr>
          ))}
          <tr></tr>
        </tbody>
      </Table>
      {selectedItem && (
        <SideModalGroup
          selectedItem={selectedItem}
          handleClose={adminContext.handleGroupClose}
          refetch={refetch}
          usermodalHide={handleModalClose}
        />
      )}
      <AddUserModal
        show={addModalOpen}
        onHide={() => {
          handleOpenClose();
        }}
        editData={formattedSelectedItem}
        deleteUser={deleteUser}
        refetchAdminTable={refetch}
        UserID={selectedItem}
        deleteGroup={() => {
          deleteUser(formattedSelectedItem?.user?.id);

          refetch();
        }}
        deleteGroupData={deleteUserGroup}
        reset={deleteReset}
        refetchUserTable={refetch}

        // user={selectedUserID}
        // userDeleteData={userDeleteData}
      />
    </>
  );
};

export default AdminTableComp;
