import { useContext } from "react";
import config from "../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import Joi from "joi";

/**
 * @typedef CreatePrivilegeIdsGroups
 * @property {string} name
 * @property {string} description
 * @property {string} id
 */

/**
 * @typedef CreateGroupValidation
 * @property {string} name
 * @property {string} description
 * @property {CreatePrivilegeIdsGroups[]} privilegeIds
 */

export const CreateGroupValidation = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().allow("").optional(),
  privilegeIds: Joi.array().items(
    Joi.object({
      id: Joi.number(),
      name: Joi.string(),
      description: Joi.string(),
    })
  ),
});

const setGroup = (token) => {
  return async (data) => {
    const setGroup = await fetch(`${config.apiHost}/v1/groups/add-group`, {
      method: "POST",
      body: JSON.stringify(data?.body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!setGroup.ok) {
      throw new Error((await setGroup.json()).message);
    }
    const groupStatus = await setGroup.json();
    return groupStatus;
  };
};

export const useCreateGroup = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["setGroup", ...deps],
    mutationFn: (body) => setGroup(appContext.idToken)(body),
  });
  return mutate;
};
