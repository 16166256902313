import React, { useState, useContext, useMemo, useEffect } from "react";
import { useAccountStatistics } from "@/services/IdleResources/getRegionStatistics.repo";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { Button } from "react-bootstrap";
import styles from "@/page-components/idle-resource/idle.module.css";
import { IdleContext } from "../idle-context-file.part";
import add from "@/assets/img/add.svg";
import TagItem from "./tag-item.part";
import clsx from "clsx";
import Form from "react-bootstrap/Form";

const FilterSection = ({ service, name }) => {
  const [selectedTittle, setSelectedTittle] = useState(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const context = useContext(IdleContext);

  const query = useMemo(() => {
    return {
      service,
      selectedTittle: selectedTittle,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
      },
    };
  }, [context.selectAccounts, selectedTittle, service]);

  const { data: dropdown } = useAccountStatistics(query);

  useEffect(() => {
    const disableAddButton = context.selectedTags.some(
      (tag) => tag.filterOn === "" || tag.values.length === 0
    );
    setIsAddButtonDisabled(disableAddButton);
  }, [context.selectedTags]);

  return (
    <>
      <div className={styles.filter_main_div}>
        <Filter
          tittle={name}
          setSelectedTittle={setSelectedTittle}
          data={dropdown?.data}
          filterType="account"
          selectedValues={context.selectAccounts}
          onSelect={(list) => {
            context?.setSelectAccounts(list);
          }}
          onRemove={(list) => {
            context?.setSelectAccounts(list);
          }}
        />
        {service === "azure" && context?.selectAccounts.length > 0 && (
          <Filter
            tittle={"Resource Group"}
            setSelectedTittle={setSelectedTittle}
            data={dropdown?.data}
            filterType="rg"
            selectedValues={context.selectedResourceGroup}
            onSelect={(list) => {
              context?.setSelectedResourceGroup(list);
            }}
            onRemove={(list) => {
              context?.setSelectedResourceGroup(list);
            }}
          />
        )}
        <Filter
          tittle={"Region"}
          setSelectedTittle={setSelectedTittle}
          data={dropdown?.data}
          filterType="region"
          selectedValues={context.selectRegions}
          onSelect={(list) => {
            context?.setSelectRegions(list);
          }}
          onRemove={(list) => {
            context?.setSelectRegions(list);
          }}
        />

        <hr className="w-100 mb-0" />

        <div className={styles.filter_box_div}>
          <div className={styles.filter_name_addsign}>
            <div className={styles.filter_name}>Tag</div>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                context.setSelectedTags((arr) => {
                  const newArr = [...arr];
                  newArr.push({
                    filterOn: "",
                    filterType: "include",
                    values: [],
                  });
                  return newArr;
                });
              }}
              disabled={isAddButtonDisabled || context.showUntaggedOnly}
              style={{
                opacity:
                  isAddButtonDisabled || context.showUntaggedOnly ? 0.4 : "",
                fill:
                  isAddButtonDisabled || context.showUntaggedOnly
                    ? "#CED4DA"
                    : "",
                pointerEvents:
                  isAddButtonDisabled || context.showUntaggedOnly
                    ? "none"
                    : "auto",
              }}
            >
              <img src={add} alt="" />
            </Button>
          </div>
          <div className={styles.untagged_class}>
            <Form.Check
              aria-label="option"
              checked={context.showUntaggedOnly}
              onChange={(e) => context.setShowUntaggedOnly(e.target.checked)}
            />
            <span className={styles.untagged}>Show Untagged Only</span>
          </div>
          {!context.showUntaggedOnly &&
            context.selectedTags.map((tag, index) => (
              <TagItem key={index} service={service} tag={tag} index={index} />
            ))}
        </div>
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const context = useContext(IdleContext);

  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      context.setSelectAccounts(selectedList);
    }
    if (filterType === "region") {
      context.setSelectRegions(selectedList);
    }
    if (filterType === "rg") {
      context.setSelectedResourceGroup(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription Name" || tittle === "Account Name") {
      title = "Account";
    }
    if (title === "Resource Group") {
      title = "rg";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <>
      <div className={clsx(styles.filter_padding_div)}>
        <div className={clsx(styles.dimension_div)}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={clsx(styles.filter_group_name_css)}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.id,
                  name: e.name,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
