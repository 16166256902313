import React, { useContext } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import clsx from "clsx";
import { BillingContext } from "../billing-context.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableErrorState from "@/components/States/TableErrorState";
import { format } from "date-fns";

const ModalExpandTable = ({ data, isLoading, error, service }) => {
  const context = useContext(BillingContext);

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {data && data?.data?.credits.length > 0 && (
        <div>
          <table className="table-hover" id={styles.customers}>
            <thead>
              <tr>
                <th
                  className={clsx(
                    styles.th_data_billing,
                    styles.table_alignment
                  )}
                >
                  Credit Source
                </th>
                {context?.accountDropdown !== "Enterprise Agreement" && (
                  <>
                    <th className={styles.th_data_billing}>Effective Date</th>
                    <th className={styles.th_data_billing}>Expiration Date</th>
                    <th className={styles.th_data_billing}>Current Balance</th>
                  </>
                )}
                <th className={styles.th_data_billing}>Original Amount</th>
                {service === "aws" && (
                  <th className={styles.th_data_billing}>Status</th>
                )}
              </tr>
            </thead>
            <tbody>
              {(data?.data?.credits || []).map((item, index) => (
                <tr key={index}>
                  <td
                    className={clsx(styles.table_data, styles.table_alignment)}
                    style={{
                      color: "#343537",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {item?.source || "--"}
                  </td>
                  {context?.accountDropdown !== "Enterprise Agreement" && (
                    <>
                      <td className={styles.table_data}>
                        {item?.effectiveDate
                          ? format(new Date(item.effectiveDate), "dd/MM/yyyy")
                          : "--"}
                      </td>
                      <td className={styles.table_data}>
                        {item?.expirationDate
                          ? format(new Date(item.expirationDate), "dd/MM/yyyy")
                          : "--"}
                      </td>
                      <td className={styles.table_data}>
                        {(item?.currentBalance || 0).toFixed(2)}
                      </td>
                    </>
                  )}
                  <td className={styles.table_data}>
                    {(item?.originalAmount || 0).toFixed(2)}
                  </td>
                  {service === "aws" && (
                    <td className={styles.table_data}>
                      <span
                        style={{
                          color:
                            item.status === "expired" ? "#D01A1A" : "#127E24",
                          background:
                            item.status === "expired" ? "#D01A1A1A" : "#EBFAED",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      >
                        {item?.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1).toLowerCase()
                          : "--"}
                      </span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {service === "aws" && (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2px" }}
            >
              <span className={styles.account_name_css}>*Account Number:</span>{" "}
              <span className={styles.account_value_css}>
                {context?.rootAccountNumber}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ModalExpandTable;
