import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAWSStatistics} aws
 * @property {GetAzureStatistics} azure
 */

/**
 * @typedef GetAWSStatistics
 * @property {number} resource_count
 * @property {string} month
 */

/**
 * @typedef GetAzureStatistics
 * @property {number} resource_count
 * @property {string} month
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'all-cloud'} service
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatistics>}
 */

export const getStackedStatistics = (token) => {
  return async ({ service }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/six-month-count`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    const hasNoData = Object.values(data || {}).every((serviceData) =>
      serviceData.every(
        (item) => item.resource_count === 0 && item.unblended_cost === null
      )
    );

    if (hasNoData) {
      throw new Error(API_NO_DATA_FOUND_ERROR); 
    }

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStackedStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStackedStatistics", req, ...deps],
    queryFn: () => getStackedStatistics(appContext.idToken)(req),
  });
  return query;
};
