import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} data
 */
/**
 * @typedef GetTotalStatistics
 * @property {string} id
 * @property {string} name
 */




// /**
//  * @typedef GetResultStatistics
//  * @property {number} saving_available  - Email address
//  */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 */

export const getTagsStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/tag-types`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        }
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useTagsStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTagsStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getTagsStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
