// import React, { useContext } from "react";
// import styles from "@/page-components/RI-Capacity/ri.module.css";
// import { RI_Context } from "@/page-components/RI-Capacity/ri.context";

// const YearComponent = ({ modalData,maxSavingsPerMonth }) => {
//   const context = useContext(RI_Context);


//   return (

//     <div className={styles.models_data}>
//       {(modalData || []).map((model) => (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//             textAlign: "center",
//             paddingTop: "12px",
//             borderRadius: "8px",
//           }}
//           key={model.year_model}
//           className={`${styles.model} ${
//             parseInt(model?.monthly_saving) === maxSavingsPerMonth
//               ? styles.green
//               : styles.gray
//           }`}
//         >
//           <span className={styles.year_modal}>{model?.year_model}YEAR MODEL</span>
//           <span className={styles.border_bottom}>${model?.ondemand_cost || 0}</span>
//           <span className={styles.border_bottom}>${model?.monthly_cost || 0}</span>
//           {context?.showBulkModal && (
//             <span className={styles.border_bottom}>{model.breakMonth || 0}</span>
//           )}
//           <span className={styles.border_bottom}>{model?.break_even}</span>
//           <span className={styles.border_bottom}>{model?.savings_vs_ondemand_percent}%</span>
//           <div
//             className={styles.saving_month}
//             style={{
//               backgroundColor:
//                 parseInt(model?.monthly_saving) === maxSavingsPerMonth
//                   ? "#3DA64F"
//                   : "#E9ECEF",
//               color:
//                 parseInt(model?.monthly_saving ) === maxSavingsPerMonth
//                   ? "#FFFFFF"
//                   : "#343A40",
//               borderRadius: "0px 0px 8px 8px",
//               padding: "10px 12px",
//             }}
//           >
//             <span className={styles.saving_name}>Savings Per Month</span>
//             <span className={styles.saving_value}>{Number(model?.monthly_saving).toFixed(2) || 0}</span>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default YearComponent;

import React, { useContext } from "react";
import styles from "@/page-components/RI-Capacity/ri.module.css";
import { RiContext } from "@/page-components/RI-Capacity/ri.context";
import { Spinner } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const YearComponent = ({ modalData = [], maxSavingsPerMonth, isLoading }) => {
  const context = useContext(RiContext);

  // Group modalData by year_model
  const groupedData = modalData.reduce((acc, model) => {
    if (model?.year_model !== undefined && model?.year_model !== null) {
      if (!acc[model.year_model]) {
        acc[model.year_model] = [];
      }
      acc[model.year_model].push(model);
    }
    return acc;
  }, {});

  // Get unique models by year_model
  const uniqueModels = Object.keys(groupedData)
    .map((year_model) => {
      const models = groupedData[year_model] || [];
      if (models.length === 0) {
        return null; // Skip empty groups
      }
      return {
        year_model: models[0].year_model,
        ondemand_cost: models[0].ondemand_cost,
        monthly_cost: models[0].monthly_cost,
        savings_vs_ondemand_percent: models[0].savings_vs_ondemand_percent,
        monthly_saving: models.reduce(
          (max, model) =>
            model.monthly_saving > max ? model.monthly_saving : max,
          0
        ),
        break_even: models[0].break_even,
      };
    })
    .filter((model) => model !== null); // Filter out null entries

  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (count >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (count >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  // const formattedOndemandCost = formatCount(
  //   Number(model?.monthly_saving || 0).toFixed(2)
  // );

  return (
    <>
    
    {isLoading && (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    )}
      {/* {uniqueModels.length  <= 0 && <DataAbsense/>} */}
      {/* {isError && <DataLoading />} */}
      {(uniqueModels || []).length > 0 && (


    <div className={styles.models_data}>
      {uniqueModels?.map((model, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
            paddingTop: "12px",
            borderRadius: "8px",
          }}
          key={index}
          className={`${styles.model} ${
            parseInt(model?.monthly_saving) === maxSavingsPerMonth
              ? styles.green
              : styles.gray
          }`}
        >
          <span className={styles.year_modal}>
            {model?.year_model} YEAR MODEL
          </span>
          <span className={styles.border_bottom}>
            ${Number(model?.ondemand_cost).toFixed(2) || 0}
          </span>
          <span className={styles.border_bottom}>
            ${Number(model?.monthly_cost).toFixed(2) || 0}
          </span>
          {context?.showBulkModal && (
            <span className={styles.border_bottom}>
              {model.breakMonth || 0}
            </span>
          )}
          <span className={styles.border_bottom}>{model?.break_even || "--"}</span>
          <span className={styles.border_bottom}>
            { model?.savings_vs_ondemand_percent ? Number(model?.savings_vs_ondemand_percent || 0).toFixed(2)+"%" :"--"}
          </span>
          <div
            className={styles.saving_month}
            style={{
              backgroundColor:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#3DA64F"
                  : "#E9ECEF",
              color:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#FFFFFF"
                  : "#343A40",
              borderRadius: "0px 0px 8px 8px",
              padding: "10px 12px",
            }}
          >
            <span className={styles.saving_name}>Savings Per Month</span>
            <span className={styles.saving_value}>
              <ToolTipComponent data={model?.monthly_saving || 0}>
                <span style={{ cursor: "context-menu" }}>


                  { model?.monthly_saving ? "$"+Number(model?.monthly_saving || 0).toFixed(2) : "--"}
                </span>
              </ToolTipComponent>
            </span>
          </div>
        </div>
      ))}
    </div>

      )}
    </>
  );
};

export default YearComponent;

