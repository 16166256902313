import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
// {
//   "data": [
//       {
//           "scopeName": "Change Scope from 'Single Resource Group' to Single Subscription",
//           "childrens": [
//               {
//                   "subscriptionId": "005f37cf-cb59-4668-89cb-09d74649fbf9",
//                   "childrens": [
//                       {
//                           "rgName": "NetworkWatcherRG",
//                           "childrens": [
//                               {
//                                   "resourceId": "",
//                                   "resourceName": "",
//                                   "vmType": "Standard_DS5_v2",
//                                   "vmSeries": "DSv2 Series",
//                                   "region": "centralindia",
//                                   "cost": 3,
//                                   "owner": ""
//                               },
//                               {
//                                   "resourceId": "",
//                                   "resourceName": "",
//                                   "vmType": "Standard_DS5_v2",
//                                   "vmSeries": "DSv2 Series",
//                                   "region": "centralindia",
//                                   "cost": 3,
//                                   "owner": ""
//                               }
//                           ]
//                       }
//                   ]
//               }
//           ]
//       }
//   ]
// }

// {
//     "type": "subscription",
//     "data": {
//         "count": 2,
//         "items": [
//             {
//                 "id": "005f37cf-cb59-4668-89cb-09d74649fbf9",
//                 "name": "005f37cf-cb59-4668-89cb-09d74649fbf9"
//             },
//             {
//                 "id": "005f37cf-cb59-4668-89cb-09d74649fbf9",
//                 "name": "005f37cf-cb59-4668-89cb-09d74649fbf9"
//             }
//         ]
//     }
// }
/**
 * @typedef AllBodyStatistics
 * @property {String} scopeName
 * @property {GetDataStatistics} statistics
 */

/**
 * @typedef GetDataStatistics
 * @property {string} scopeName
 * @property {GetDataStatistics[] =} children
 */

// /** 
//  * @typedef getTypeData
//  * @property {Number} count
//  * @property {getDataItems[]} items
//  */
// /** 
//  * @typedef getDataItems
//  * @property {String} id
//  * @property {String} name
//  */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } servicetype
 */

export const getStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service ,servicetype,reservationId}) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/${servicetype}/recommendation/resources-all?reservationId=${reservationId}`,
      {
        method: "POST",
        body: JSON.stringify({
            reservationId:reservationId,
            servicetype:servicetype,
            type: "",
            scopeName: "",
            subscriptionId:"",
            rgName:""
        }
         ),
        headers: {
        "Content-Type": "application/json",

          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiScopeModalTableStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatistics(appContext.idToken)(req),
  });
  return query;
};
