import React, { useContext, useMemo, useState } from "react";
import styles from "@/page-components/search-module/search.module.css";
import clsx from "clsx";
import downArrow from "@/assets/img/rightSizingSavingArrow.svg";
import upArrow from "@/assets/img/rightSizingCostArrow.svg";
import { SearchContext } from "../search-context";
import { useRecommendationsSearch } from "@/services/costExplorer/getRecommendationSearch.repo";
import { Spinner } from "react-bootstrap";

const SidebarSummery = ({ service }) => {
  const context = useContext(SearchContext);
  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data, isLoading, isError } = useRecommendationsSearch(query);

  const tabsObj = {
    // Rightsizing: "Rightsizing",
    IdleResources: "Idle Resources",
    Snapshots: "Snapshots",
  };
  let tabs = Object.freeze(tabsObj);
  const [selectedTab, setSelectedTab] = useState(tabs.IdleResources);

  const tabRendering = useMemo(() => {
    switch (selectedTab) {
      case tabs.Rightsizing:
        return tabs.Rightsizing;
      case tabs.Snapshots:
        return tabs.Snapshots;
      case tabs.IdleResources:
        return tabs.IdleResources;
      default:
        return tabs.Rightsizing;
    }
  }, [selectedTab, tabs.IdleResources, tabs.Rightsizing, tabs.Snapshots]);

  const rightsizingButtonClass = clsx(styles.sidebarButtons, {
    [styles.sidebarButtonsActive]: selectedTab === tabs.Rightsizing,
  });
  const idleResourcesButtonClass = clsx(styles.sidebarButtons, {
    [styles.sidebarButtonsActive]: selectedTab === tabs.IdleResources,
  });
  const snapshotsButtonClass = clsx(styles.sidebarButtons, {
    [styles.sidebarButtonsActive]: selectedTab === tabs.Snapshots,
  });

  const textRendering =
    selectedTab === tabs.Rightsizing ? "Total RECOMMENDATIONS" : selectedTab;

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <Spinner />
        </div>
      )}
      {data && (
        <>
          <div className={styles.sidebarTitle}>
            Active Recommendations
            <div className={styles.sidebarButtonContainer}>
              {/* <span
                className={rightsizingButtonClass}
                onClick={() => setSelectedTab(tabs.Rightsizing)}
              >
                Rightsizing
              </span> */}
              <span
                className={idleResourcesButtonClass}
                onClick={() => setSelectedTab(tabs.IdleResources)}
              >
                Idle Resources
              </span>
              <span
                className={snapshotsButtonClass}
                onClick={() => setSelectedTab(tabs.Snapshots)}
              >
                Snapshots
              </span>
            </div>
          </div>
          <div className={styles.selectedButtonSummery}>
            <div>
              <p className={styles.textRendering}>{textRendering}</p>
              <p className={styles.textCount}>
                {selectedTab === tabs.Rightsizing &&
                  (data?.rightsizingRecommendations?.totalRecommendations ||
                    "--")}
                {selectedTab === tabs.IdleResources &&
                  (data?.idleResourcesRecommendations?.idleCount || "--")}
                {selectedTab === tabs.Snapshots &&
                  (data?.snapshotsRecommendations?.snapshotsCount || "--")}
              </p>
            </div>
            {selectedTab === tabs.IdleResources && (
              <div className={styles.selectedTabRightSizing}>
                <div className={styles.selectedTabRightSizingHeading}>
                  Savings Available:
                  <div className={styles.selectedTabRightSizingSavings}>
                    $
                    {(data?.idleResourcesRecommendations?.savingsAvailable).toFixed(
                      2
                    ) || 0}
                  </div>
                </div>
              </div>
            )}
            {selectedTab === tabs.Snapshots && (
              <TabDescriptionContainerSnapshots data={data} />
            )}
            {selectedTab === tabs.Rightsizing && (
              <TabDescriptionContainerRightsizing data={data} />
            )}
          </div>
          {selectedTab === tabs.Rightsizing && (
            <SidebarTableRightsizing
              heading1={"Current"}
              heading2={"Rightsizing"}
              data={data}
            />
          )}
          {selectedTab === tabs.IdleResources && (
            <SidebarTableIdleResources data={data} />
          )}
          {selectedTab === tabs.Snapshots && (
            <SidebarTableSnapshots data={data} />
          )}
        </>
      )}
    </>
  );
};

export default SidebarSummery;

// helper components

export const SidebarTableRightsizing = ({ heading1, heading2, data }) => {
  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerHeader}>
          <div className={styles.detailsContainerHeading}>{heading1}</div>
          <div className={styles.detailsContainerHeading}>{heading2}</div>
        </div>
        {data?.rightsizingRecommendations?.recommendations.map(
          (item, index) => (
            <div className={styles.detailsContainerBody}>
              <div>
                <div
                  className={clsx(styles.detailsCurrent, "elipese")}
                  title={item?.recommendedType}
                >
                  {item?.recommendedType || "--"}
                </div>
                <div className={styles.detailsCurrentCost}>
                  cost: &nbsp;${(item?.currentCost || 0).toFixed(2)}
                </div>
              </div>
              <div>
                <div
                  className={clsx(styles.detailsCurrent, styles.search_trucate)}
                  style={{ textAlign: "end" }}
                >
                  {item?.currentType || "--"}
                  <img
                    src={
                      item?.recommendationType === "downsize"
                        ? downArrow
                        : upArrow
                    }
                    alt="upsize/downsize"
                    style={{ marginLeft: "8px" }}
                  />
                </div>
                <div className={styles.detailsCurrentCost}>
                  Savings Available:
                  <span className={styles.detailsCurrentCostValue}>
                    {" "}
                    ${(item?.savingsAvailable).toFixed(2) || 0}
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export const SidebarTableIdleResources = ({ data }) => {
  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerHeader}>
          <div className={styles.detailsContainerHeading}>Resource Details</div>
          <div className={styles.detailsContainerHeading}>Savings $</div>
        </div>
        {data?.idleResourcesRecommendations?.resources.map((item, index) => (
          <div className={styles.detailsContainerBody}>
            <div>
              <div
                className={clsx(styles.detailsCurrent, "elipese")}
                title={item?.resourceId}
              >
                {item?.resourceId || "--"}
              </div>
              <div
                className={clsx(
                  styles.detailsCurrentCost,
                  styles.search_trucate
                )}
                title={item?.region}
              >
                {item?.region || "--"}
              </div>
              <div
                className={clsx(
                  styles.detailsCurrentCost,
                  styles.search_trucate
                )}
                title={item?.type}
              >
                {item?.type || "--"}
              </div>
            </div>
            <div>
              <div
                className={styles.detailsCurrent}
                style={{ textAlign: "end" }}
              >
                ${(item?.cost).toFixed(2) || 0}
              </div>
              <div className={styles.detailsCurrentCost}>
                Idle days: {item?.idleDays || "--"}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const SidebarTableSnapshots = ({ data }) => {
  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerHeader}>
          <div className={styles.detailsContainerHeading}>Resource Details</div>
          <div className={styles.detailsContainerHeading}>Cost $</div>
        </div>
        {(data?.snapshotsRecommendations?.snapshots || []).map(
          (item, index) => (
            <div className={styles.detailsContainerBody} key={index}>
              <div>
                <div className={clsx(styles.detailsCurrent, "elipese")}>
                  {item?.snapshotName || "--"}
                </div>
                <div
                  className={clsx(
                    styles.detailsCurrentCost,
                    styles.search_trucate
                  )}
                  title={item.snapshotId}
                >
                  {item?.snapshotId || "--"}
                </div>
              </div>
              <div>
                <div
                  className={styles.detailsCurrent}
                  style={{ textAlign: "end" }}
                >
                  ${item?.cost.toFixed(2) || 0}
                </div>
                <div className={styles.detailsCurrentCost}>
                  Age days: {item?.age}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export const TabDescriptionContainerRightsizing = ({ data }) => {
  return (
    <div className={styles.selectedTabDiscriptionContainer}>
      <div className={styles.selectedTabRightSizing}>
        <div className={styles.selectedTabRightSizingHeading}>
          Downsize
          <div className={styles.selectedTabRightSizingCount}>
            {data?.rightsizingRecommendations?.downsizeCount || "--"}
          </div>
        </div>
        <div className={styles.selectedTabRightSizingHeading}>
          Savings Available:
          <div className={styles.selectedTabRightSizingSavings}>
            $
            {Number(data?.rightsizingRecommendations?.downsizeCost).toFixed(
              2
            ) || 0}
          </div>
        </div>
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.selectedTabRightSizing}>
        <div className={styles.selectedTabRightSizingHeading}>
          Upsize
          <div className={styles.selectedTabRightSizingCount}>
            {data?.rightsizingRecommendations?.upsizeCount || "--"}
          </div>
        </div>
        <div className={styles.selectedTabRightSizingHeading}>
          Costing:
          <div className={styles.selectedTabRightSizingCosting}>
            $
            {Number(data?.rightsizingRecommendations?.upsizeCost).toFixed(2) ||
              0}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TabDescriptionContainerSnapshots = ({ data }) => {
  return (
    <div className={styles.selectedTabDiscriptionContainer}>
      <div className={styles.selectedTabRightSizing}>
        <div className={styles.selectedTabRightSizingHeading}>
          Tagged
          <div className={styles.selectedTabRightSizingCount}>
            {data?.snapshotsRecommendations?.taggedCount || "--"}
          </div>
        </div>
        <div className={styles.selectedTabRightSizingHeading}>
          Cost:
          <div className={styles.selectedTabRightSizingCosting}>
            ${(data?.snapshotsRecommendations?.taggedCost).toFixed(2) || 0}
          </div>
        </div>
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.selectedTabRightSizing}>
        <div className={styles.selectedTabRightSizingHeading}>
          Untagged
          <div className={styles.selectedTabRightSizingCount}>
            {data?.snapshotsRecommendations?.untaggedCount || "--"}
          </div>
        </div>
        <div className={styles.selectedTabRightSizingHeading}>
          Cost:
          <div className={styles.selectedTabRightSizingCosting}>
            ${(data?.snapshotsRecommendations?.untaggedCost).toFixed(2) || 0}
          </div>
        </div>
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.selectedTabRightSizing}>
        <div className={styles.selectedTabRightSizingHeading}>
          Orphaned
          <div className={styles.selectedTabRightSizingCount}>
            {data?.snapshotsRecommendations?.orphanedCount || "--"}
          </div>
        </div>
        <div className={styles.selectedTabRightSizingHeading}>
          Savings:
          <div className={styles.selectedTabRightSizingSavings}>
            ${(data?.snapshotsRecommendations?.savings).toFixed(2) || 0}
          </div>
        </div>
      </div>
    </div>
  );
};
