import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/idle-resource/idle.module.css";
import { parse, compareAsc } from "date-fns";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import creditStyles from "@/page-components/billing-credit/billing-credit.module.css";
import clsx from "clsx";

const BarGraphComponent = ({ barChartData, colorMap }) => {
  const sortedData = barChartData.sort((a, b) => {
    const aDate = parse(a.month || a.formatted_month, "MMM''yy", new Date());
    const bDate = parse(b.month || b.formatted_month, "MMM''yy", new Date());
    return compareAsc(aDate, bDate);
  });

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={sortedData}
        className={styles.legend_custom_color}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        stackOffset="sign"
      >
        <XAxis
          dataKey={sortedData[0]?.formatted_month ? "formatted_month" : "month"}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="var(--Neutral-D3, #495057)"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="var(--Neutral-D3, #495057)"
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
        {sortedData &&
          sortedData.length > 0 &&
          Object.keys(sortedData[0] || {}).map((key) => {
            if (key === "month" || key === "formatted_month") return null;
            return (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={colorMap[key]}
                barSize={80}
              />
            );
          })}
      </BarChart>
    </ResponsiveContainer>
  );
};



const customTooltip = ({ active, payload, label, colorMap, fill }) => {
  if (active && payload && payload.length) {

    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px" }}>
        <div className={tooltipStyles.tooltip_date} style={{ paddingLeft: "-4px" }}>
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>
        <div style={{ width: "92%", paddingLeft: "12px" }}>
          <table style={{ width: "100%", paddingRight: "24px" }}>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`${tooltipStyles.tooltip_row} ${index}`}
                  className={creditStyles.horizontalline_tool}
                  style={{
                    position: "relative",
                    borderBottom: "1px solid #373737",
                    paddingLeft:"12px"
                  }}
                >
                  <td className={tooltipStyles.cloud_cell} style={{ whiteSpace: "nowrap", display: "flex", paddingLeft:"0px" }}>
                    <div
                      className={clsx(creditStyles.tooltipbox, tooltipStyles.cloud_logo)}
                      style={{ background: entry.fill }}
                    ></div>
                    <span style={{ textOverflow: "ellipsis", width: "115px", textWrap: "nowrap", overflow: "hidden" }}>
                      {entry.dataKey}
                    </span>
                  </td>
                  <td className={tooltipStyles.cost_cell} style={{ paddingRight: "24px", paddingLeft:"0px" }}>
                    ${entry.value.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};



        
export default BarGraphComponent;
