import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetTotalCountStatistics[]} data
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {String} id
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */


export const getAccountFilterManagementBudgets = (token) => {
  return async ({ service,scope }) => {
    const filterAccount = await fetch(
      `${config.apiHost}/v1/${service}/budget/filters/mg?scope=${scope}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },

      }
    );
    return await filterAccount.json();
  }
}


export const getAccountFilterBudgets = (token) => {
  return async ({ service,scope }) => {
    let url = `${config.apiHost}/v1/${service}/budget/filters/accounts`
    if (service !=="aws"){
      url += `?scope=${scope}`
    }
    const filterAccount = await fetch(url
      ,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      
      }
    );
    return await filterAccount.json();
  }
}

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAccountFilterManagementBudgets = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAccountFilterManagementBudgets", req, ...deps],
    queryFn: () => getAccountFilterManagementBudgets(appContext.idToken)(req),
    retry:false
  });
  return query;
};

export const useAccountFilterBudgets = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAccountFilterBudgets", req, ...deps],
    queryFn: () => getAccountFilterBudgets(appContext.idToken)(req),
    retry:false
  });
  return query;
};
