import config from "@/config";
import Joi from "joi";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

export const PERMISSIONS = {
  read: 1,
  edit: 2,
};

/**
 * @typedef PostCreatePrivilegeAccountRGItem
 * @property {string=} resourceGroupId
 * @property {string=} resourceGroupName
 * @property {number} permission
 */

/**
 * @typedef PostCreatePrivilegeAccountItem
 * @property {number=} id
 * @property {string} accountId
 * @property {string} accountName
 * @property {string} type
 * @property {number} permission
 * @property {PostCreatePrivilegeAccountRGItem[]} resourceGroups
 */

/**
 * @typedef AddPrivilegeReq
 * @property {string} name
 * @property {string} description
 * @property {PostCreatePrivilegeAccountItem[]} accounts
 */

/**
 * @typedef CreatedPrivilegeRes
 * @property {*} privilege
 */

/** @type {Joi<AddPrivilegeReq>} */
export const AddPrivilegeReq = Joi.object({
  name: Joi.string(),
  description: Joi.string().allow("").optional(),
  accounts: Joi.array()
    .min(1)
    .items(
      Joi.object({
        accountId: Joi.string(),
        accountName: Joi.string(),
        permission: Joi.number().valid(
          PERMISSIONS.read,
          PERMISSIONS.edit,
          null
        ),
        type: Joi.string().valid("aws", "azure", "gcp"),
        resourceGroups: Joi.array().items(
          Joi.object({
            resourceGroupName: Joi.string(),
            resourceGroupId: Joi.string(),
            permission: Joi.number().valid(
              PERMISSIONS.read,
              PERMISSIONS.edit,
              null
            ),
          }).optional()
        ),
      })
    ).messages({
      'array.min': "Selected accounts list cannot be empty",
    }),
});

/**
 * @typedef CreatedPrivilegeRes
 * @property {*} privilege
 */

export const addPrivilege = (token) => {
  /**
   * @param {AddPrivilegeReq} req
   * @returns {Promise<CreatedPrivilegeRes>}
   */
  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/privileges/add-privilege`,
      {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    const json = await totalStats.json();
    return json;
  };
};

/**
 * @param {any[]=} deps
 */
export const useAddPrivilege = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["addPrivilege", ...deps],
    mutationFn: addPrivilege(appContext.idToken),
  });
  return mutation;
};
