import React from "react";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import styles from "../admin.module.css";
import editImg from "@/assets/img/edit.svg";
import { AdminPrivilegeContext } from "./privilege.context";
import format from "date-fns/format";
import { Spinner } from "react-bootstrap";

/**
 * @typedef PrivilegeTableProps
 * @property {any[]} data
 * @property {boolean} loading
 */

/**
 * @param {PrivilegeTableProps} props
 */
const PrivilegesTable = ({ data, loading }) => {
  const adminContext = useContext(AdminPrivilegeContext);

  const handleTableSidebarToggle = (item) => {
    adminContext?.setPrivilegeSidebarSelectedItem(item);
  };

  const handleTableEditToggle = (item) => {
    adminContext?.setPrivilegeEditSelectedItem(item);
    adminContext?.setCreatePrivilegeModal(true);
  };

  return (
    <>
      <Table responsive className={styles.table}>
        <thead className={styles.tableHeadStyling}>
          <tr>
            <th>Name</th>
            <th>Privileges Description</th>
            <th>Creator</th>
            <th>Created on</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {/* {loading && (
            <tr>
              <td colSpan={5}>
                <div className="py-5 d-flex align-items-center justify-content-center">
                  <Spinner variant="primary" />
                </div>
              </td>
            </tr>
          )} */}
          {/* {!loading && data.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                No privileges to display
              </td>
            </tr>
          )} */}
          {data.map((item) => (
            <tr className={styles.tableRowStyling}>
              <td>
                <u
                  style={{
                    color: "#056EE5",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => handleTableSidebarToggle(item.id)}
                >
                  {item.name}
                </u>
              </td>
              <td> {item.description || "--"} </td>
              <td> {item.createdBy}</td>
              <td> {format(new Date(item.createdAt), "dd/MM/yyyy")}</td>
              <td
                style={{ textAlign: "center" }}
                onClick={() => handleTableEditToggle(item.id)}
              >
                <img src={editImg} width="12px" height="12px" alt="" />
              </td>
            </tr>
          ))}
          <tr></tr>
        </tbody>
      </Table>
    </>
  );
};
export default PrivilegesTable;
