import React from "react";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoDataAvailable from "@/components/States/noDataAvailable";

const UsageBarChart = ({ bardata, serviceName }) => {
  const data = (bardata || []).map((item) => ({
    name: item.month_date,
    [serviceName]: item.cost,
  }));

  return (
    <div className={styles.bar_main}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <span className={styles.cost_trend}>Costs Trend</span>
        <span className={styles.last_month}>Last 6 Months</span>
      </div>
      {bardata?.length === 0 && (
        <NoDataAvailable
          message="No Data Available for Selected Date Range or Filters"
          addOnMsg="It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options."
        />
      )}
      {bardata?.length > 0 && (
        <div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontWeight={500}
                stroke="var(--Neutral-D3, #495057)"
              />
              <YAxis
                label={{ fill: "#A3A3A3" }}
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontWeight={400}
                stroke="var(--Neutral-D3, #495057)"
              />
              <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
                iconSize={12}
                iconType="Square"
                fontSize={12}
                fontWeight={400}
                fontStyle="normal"
              />
              <Bar dataKey={serviceName} fill="#4A7B9A" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default UsageBarChart;
