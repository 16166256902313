import React, { useState, useRef, useMemo, useContext } from "react";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import StackedBarGraph from "../../components/bar-graph.component";
import LineChartGraph from "../../components/line-chart.component";
import { FilterContext } from "../../components/filter-section/filter-section.component";
import { randomHexColorCode } from "@/lib/colors";
import { useWorker } from "@jseda/useworker";
import { useQuery } from "@tanstack/react-query";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useAllTableDownload } from "@/services/costExplorer/getTableData.repo";
import { useAllGraphDataStatistics } from "@/services/costExplorer/getGraphData.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const serviceDataFn = (data) => {
  if (data) {
    const init = new Map();
    return (data || []).reduce((map, c) => {
      map.set(c["Service"], c);
      return map;
    }, init);
  }
  return null;
};

const graphDataFn = (dataFiltered, serviceData, selectedDimension) => {
  if (dataFiltered && serviceData) {
    return (dataFiltered || []).map((e) => {
      const obj = {
        label: e,
      };

      Array.from(serviceData?.keys()).forEach((dimension) => {
        if (serviceData?.get(dimension)[e]) {
          obj[dimension] = serviceData?.get(dimension)[e];
        }
      });

      return obj;
    });
  }
  return [];
};

const AWSBarGraph = ({
  service,
  fromDate,
  toDate,
  daysDifference,
  dimensionData,
}) => {
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);
  const filterContext = useContext(FilterContext);

  const query = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });
    if (filterContext?.untagged) {
      tags = [];
    }
    if (filterContext?.untaggable) {
      tags = [];
    }
    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });

    return {
      fromDate,
      toDate,
      service: "aws",
      dimension: filterContext?.selectedDimension,
      granularity: filterContext?.selectedGranularity,
      showUntaggedOnly: filterContext?.untagged ? true : undefined,
      showUntaggableOnly: filterContext?.untaggable ? true : undefined,
      filters: filters?.length > 0 ? filters : undefined,
      tags: tags?.length > 0 ? tags : undefined,
      tagname: filterContext?.selectedGroupTags,
    };
  }, [
    filterContext?.selectedTags,
    filterContext?.untagged,
    filterContext?.untaggable,
    filterContext?.selectedFilters,
    filterContext?.selectedDimension,
    filterContext?.selectedGranularity,
    filterContext?.selectedGroupTags,
    fromDate,
    toDate,
  ]);

  const {
    data,
    isLoading: graphLoading,
    error,
  } = useAllGraphDataStatistics(query);

  const dataFiltered = data?.dates || [];

  const [serviceDataTrigger, { kill: serviceDataKill }] =
    useWorker(serviceDataFn);

  const [graphDataTrigger, { kill: graphDataKill }] = useWorker(graphDataFn);

  const { data: serviceData } = useQuery({
    queryKey: ["serviceData", data?.data],
    queryFn: ({ signal }) => {
      if (signal.aborted) serviceDataKill();
      return serviceDataTrigger(data?.data || []);
    },
  });

  const { data: awsGraph } = useQuery({
    queryKey: [
      "serviceData",
      dataFiltered,
      filterContext?.selectedDimension,
      filterContext.selectedGranularity,
      serviceData,
      filterContext?.selectedTags,
      filterContext?.selectedFilters,
      filterContext?.selectedGroupTags,
      filterContext?.untagged,
      filterContext?.untaggable,
      filterContext?.selectedGroupTags,
      fromDate,
      toDate,
    ],
    queryFn: async ({ signal }) => {
      if (signal.aborted) graphDataKill();
      return (
        (await graphDataTrigger(
          dataFiltered,
          serviceData,
          filterContext?.selectedDimension
        )) || []
      ).map((e) => {
        return {
          ...e,
          label:
            filterContext.selectedGranularity === "date"
              ? format(parse(e.label, "dd-MM-yyyy", new Date()), "dd LLL")
              : e.label,
        };
      });
    },
  });

  const colorMap = useMemo(() => {
    let colorMap = {
      Dimensions: "#EBB78A",
      Others: "#EBB78A",
    };
    if (serviceData) {
      Array.from(serviceData?.keys()).forEach(
        (e, i) => (colorMap[e] = randomHexColorCode(i))
      );
    }
    return colorMap;
  }, [serviceData]);

  const handleDownloadCsv = () => {
    csvLinkRef.current.link.click();
  };

  const days = daysDifference > 1 ? "days" : "day";

  const dimension = (dimensionData?.dimensions || []).find(
    (d) => d.id === filterContext.selectedDimension
  );
  const dimensionName = dimension?.name || "Dimensions";

  // ------------------------------------------------------------------

  const tableQuery = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });
    if (filterContext?.untagged) {
      tags = [];
    }
    if (filterContext?.untaggable) {
      tags = [];
    }
    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });

    return {
      fromDate,
      toDate,
      serviceName: "download",
      tagname: filterContext?.selectedGroupTags,
      service: service,
      dimension: filterContext?.selectedDimension,
      granularity: filterContext?.selectedGranularity,
      showUntaggedOnly: filterContext?.untagged ? true : undefined,
      showUntaggableOnly: filterContext?.untaggable ? true : undefined,
      filters: filters?.length > 0 ? filters : undefined,
      tags: tags?.length > 0 ? tags : undefined,
    };
  }, [
    filterContext?.selectedTags,
    filterContext?.untagged,
    filterContext?.untaggable,
    filterContext?.selectedFilters,
    filterContext?.selectedGroupTags,
    filterContext?.selectedDimension,
    filterContext?.selectedGranularity,
    fromDate,
    toDate,
    service,
  ]);

  const { mutate: downloadJson } = useAllTableDownload(tableQuery);

  const handleDownloadClick = () => {
    downloadJson(tableQuery);
  };

  return (
    <>
      {graphLoading && <BarGraphErrorStates />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!graphLoading && dataFiltered.length > 0 && (
        <div
          className=" d-flex align-items-start flex-column"
          style={{
            gap: "20px",
          }}
        >
          <div className="d-flex flex-row justify-content-between w-100">
            <div className="d-flex gap-2">
              <span className="all_cloud_text">Cost By {dimensionName}</span>
              <span className="last_days_name">
                Last {daysDifference} {days}
              </span>
            </div>
            <span>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-disable-caret"
                  style={{
                    background: "#F0F6FF",
                    border: "none",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={menuicon} alt="Menu" width="3px" height="13px" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: "8px 12px" }}>
                  <Dropdown.Item
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                    className="toggle_drop_down"
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="line"
                    onClick={() => setChartType("line")}
                    className="toggle_drop_down"
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    eventKey="Download as CSV"
                    // onClick={handleDownloadCsv}
                    onClick={handleDownloadClick}
                    className="toggle_drop_down"
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={awsGraph}
                    filename={"GraphData.csv"}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 p-0">
                {chartType === "bar" ? (
                  <StackedBarGraph
                    barChartData={awsGraph}
                    colorMap={colorMap}
                    showTooltip
                    showLegend
                    service={service}
                    dimensionData={dimensionData}
                  />
                ) : (
                  <LineChartGraph
                    lineChartData={awsGraph}
                    colorMap={colorMap}
                    showTooltip
                    showLegend
                    dimensionData={dimensionData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AWSBarGraph;
