import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef ResourceGroupItem
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef Account
 * @property {string} id
 * @property {string} name
 * @property {string} type
 * @property {ResourceGroupItem[]} resourceGroups
 */

/**
 * @typedef GetGroupsByIdRes
 * @property {Account[]} accounts
 */

/**
 * @typedef GetAccountsReq
 * @property {'aws' | 'gcp' | 'azure'} type 
 * @property {string=} filterQuery
 */

export const getAccounts = (token) => {
  /**
   * @param {AbortSignal} signal
   * @param {GetAccountsReq} req
   * @returns {Promise<GetGroupsByIdRes>}
   */
  return async (req, signal) => {
    const groups = await fetch(
      `${config.apiHost}/v1/accounts/get-all`,
      {
        method: "POST",
        signal,
        body: JSON.stringify(req),

        headers: {
          "Content-Type": "application/json",
          Authorization: token
        }
      }
    );
    if (!groups.ok) {
      throw new Error((await groups.json()).message);
    }
    return await groups.json();
  };
}


/**
 * @param {GetAccountsReq} req 
 * @param {any[]=} deps
 */
export const useGetAccounts = (req) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    enabled: Boolean(req.type),
    queryKey: ["getAccounts", req, appContext.idToken],
    queryFn: ({ signal }) => getAccounts(appContext.idToken)(req, signal),
  });
  return query;
}