import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { endOfDay, parse, startOfDay } from "date-fns";
import { useContext } from "react";

/**
 * @typedef {keyof typeof import('./addCostExplorerWidget.repo').AWS_DIMENSIONS} AWS_DIMENSIONS
 */

/**
 * @typedef {keyof typeof import('./addCostExplorerWidget.repo').AZURE_DIMENSIONS} AZURE_DIMENSIONS
 */

/**
 * @typedef GetDimensionReq
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property { AZURE_DIMENSIONS | AWS_DIMENSIONS } columnName
 * @property {BudgetName} BudgetName
 * @property {Id} Id
 * @property {any} dimensionValues
 * @property {Date} fromDate
 * @property {Date} toDate
 */

export const getDimensionValues = (token) => {
  /**
   * @param {GetDimensionReq} req
   * @returns {Promise<Record<string, string | number>[]>}
   */
  return async (
    { service, columnName, BudgetName, Id, dimensionValues, fromDate, toDate },
    signal
  ) => {
    try {
      let tableData;
      let fromDateIns = startOfDay(parse(fromDate, "dd-MM-yyyy", new Date()));
      let toDateIns = endOfDay(parse(toDate, "dd-MM-yyyy", new Date()));
      if (
        BudgetName === undefined ||
        BudgetName === null ||
        Id === undefined ||
        Id === null ||
        (!BudgetName && !Id)
      ) {
        tableData = await fetch(
          `${
            config.apiHost
          }/v1/${service}/cost/dimension-values?columnName=${columnName}&fromDate=${fromDateIns.toISOString()}&toDate=${toDateIns.toISOString()}`,
          {
            method: "GET",
            signal,
            headers: {
              Authorization: token,
            },
          }
        );
        if (!tableData.ok) {
          throw new Error((await tableData.json()).message);
        }
        return await tableData.json();
      }
      if (
        (BudgetName && Id) ||
        BudgetName !== undefined ||
        BudgetName !== null ||
        Id !== undefined ||
        Id !== null
      ) {
        const filteredData = (dimensionValues || []).filter(
          (f) => f.name === columnName
        );
        return filteredData;
      }
    } catch (e) {
      console.error(e);
    }
  };
};

/**
 * @param {GetDimensionReq} req
 * @param {any[]=} deps
 */
export const useDimensionValues = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getDimensionValues",
      req.service,
      req.columnName,
      req.fromDate,
      req.toDate,
      ...deps,
    ],
    queryFn: ({ signal }) =>
      getDimensionValues(appContext.idToken)(req, signal),
    enabled: (Boolean(req.columnName) && Boolean(req.service)) || false,
  });
  return query;
};
