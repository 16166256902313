import React, { createContext, useContext, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  SnapshotContext,
  snapshotsTabsTypes,
  useSnapshotContext,
} from "../../snap.contex";
import { Tab, Tabs } from "react-bootstrap";
import styles from "../../snapshots.module.css";

const TabsComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(SnapshotContext);

  // const defaultTab = useMemo(() => {
  //   const type = context?.eventkey;
  //   // return type ? type : snapshotsTabsTypes.Open;
  //   return type
  // }, [context]);

  const handleChangeTab = (e) => {
    searchParams.delete("page");
    searchParams.delete("itemsPerPage");
    setSearchParams(searchParams);
    context.setEventkey(e);
  };

  return (
    <div className="btn-combo__Container">
      <Tabs
        // id="fill-tab-example"
        className={styles.mytabs_button}
        defaultActiveKey={context.eventkey}
        fill
        onSelect={(e) => handleChangeTab(e)}
        style={{ gap: "4px", marginBottom: "0px" }}
      >
        <Tab eventKey="Open" title="Open"></Tab>
        <Tab eventKey="Discarded" title="Discarded" />
      
      </Tabs>
    </div>
  );
};

export default TabsComponent;
