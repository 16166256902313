import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "@/App.context";

/**
 * @typedef DeletePrivilegeReq
 * @property {number} privilegeId
 */

/**
 * @typedef DeletePrivilegeRes
 * @property {string} message
 */

export const deletePrivilege = (token) => {
  /**
   * @param {DeletePrivilegeReq} req
   * @returns {Promise<DeletePrivilegeRes>}
   */
  return async (req) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/privileges/${req.privilegeId}/delete-privilege`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      throw new Error((await totalStats.json()).message);
    }
    const json = await totalStats.json();
    return json;
  };
};

/**
 * @param {any[]=} deps
 */
export const useDeletePrivilege = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["deletePrivilege", ...deps],
    mutationFn: deletePrivilege(appContext.idToken),
  });
  return mutation;
};
