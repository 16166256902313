import React, { useMemo, useState } from "react";
import CostBarGraph from "../components/Cost&usage/CostBarGraph";
import KubernatesHeader from "../components/KubernatesHeader";
import ServiceTable from "../components/Cost&usage/ServiceTable";
import { addDays, differenceInDays, format, parse } from "date-fns";
import subDays from "date-fns/subDays";
import { useKuberneteStackedData } from "@/services/kubernetes/getCostUsages.repo";
import TableAndGraph from "@/components/States/TableAndGraph";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const AllCloudKubernates = () => {
  const service = "all-cloud";

  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);
  const [loading, setLoading] = useState(false);

  let formattedStartDate = format(range[0].startDate, "dd-MM-yyyy");
  let formattedEndDate = format(range[0].endDate, "dd-MM-yyyy");
  const date2 = parse(formattedEndDate, "dd-MM-yyyy", new Date());
  const date1 = parse(formattedStartDate, "dd-MM-yyyy", new Date());

  const daysDifference = differenceInDays(date2, date1) + 1;

  const query = useMemo(() => {
    return {
      service,
      fromDate: formattedStartDate,
      toDate: formattedEndDate,
    };
  }, [service, formattedStartDate, formattedEndDate]);

  const costAndUseage = useKuberneteStackedData(query);

  const data = Object.values(costAndUseage?.data || {});

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <KubernatesHeader
              costAndUseageLoading={costAndUseage?.isLoading}
              setLoading={setLoading}
              service={service}
              range={range}
              setRange={setRange}
              formattedEndDate={formattedEndDate}
              formattedStartDate={formattedStartDate}
              daysDifference={daysDifference}
            />

            <div
              className="container-fluid col-lg-12 "
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: "24px",
                paddingLeft: "57px",
                paddingRight: "19px",
              }}
            >
                <>
                  <div className="row w-100">
                    <div className="col-12 p-0 pe-2 pt-4 d-flex gap-4 flex-column">
                      {costAndUseage?.isLoading && <TableAndGraph />}
                      {costAndUseage?.error && (
                        <ScenariosErrorState
                          error={costAndUseage?.error.message}
                          messageConfig={{
                            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                              message: "No Data Available for Selected Filters",
                              additionalMessage:
                                "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                            },
                            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                              message:
                                "No Data for the Selected Date Range",
                              additionalMessage:
                                "The selected date range doesn’t contain any data. Try choosing a different range to view available savings.",
                            },
                            [API_LOADING_ERROR]: {
                              message: "Oops!",
                              additionalMessage:
                                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                            },
                            [API_NOT_FOUND_ERROR]: {
                              message: "404 Error",
                              additionalMessage:
                                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                            },
                          }}
                        />
                      )}

                      {!loading && costAndUseage?.data && (
                        <>
                          <CostBarGraph
                            data={
                              (costAndUseage?.data && costAndUseage?.data) || []
                            }
                            daysDifference={daysDifference}
                          />
                          <ServiceTable
                            data={
                              (costAndUseage?.data && costAndUseage?.data) || []
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCloudKubernates;
