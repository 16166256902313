  // const colorMap = {
  //   Meter: "#6EA57C",
  //   "Meter Category": "#E04479",
  //   Region: "#E08267",
  //   "Resource Group": "#B787D2",
  //   Subscription: "#EBB78A",
  // };

export const randomHexColorCode = (index) => {
  const colors = [
    "#6EA57C",
    "#E04479",
    "#E08267",
    // "#EBB78A",

    "#4A7B9A",
    "#A6DDD8",
    "#6D5DED",
    "#BEDDA6",
    "#ECC0B6",
    "#EAED5D",
    "#67ADE0",
    "#B787D2",
    "#E8BF67",
    "#f08080",
    "#dda0dd",
    "#EA8FD6",
    "#8FBBEA",
    // "#EBB78A",
    "#71629D",
  ];
  return colors[index % colors.length];
};