import React, { useMemo, useContext } from "react";
import vertical_line from "@/assets/img/Vector 5.svg";
import SnapshotAge from "./snapshotAge.part";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import { SnapshotContext } from "../snap.contex";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

import TabsComponent from "./state_change/tabs";
import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

function AWSHeaderSection({ service, data, selectedOption, error, isLoading }) {
  const context = useContext(SnapshotContext);

  // const query = useMemo(() => {
  //   const tags = (context?.selectedSnapshotTags || []).filter(
  //     (e) => e.filterOn.length > 0 && e.values.length > 0
  //   );
  //   return {
  //     service,

  //     filters: {
  //       accounts: context?.selectedAccounts?.length
  //         ? context.selectedAccounts.map((e) => e.id)
  //         : undefined,
  //       regions: context?.selectedRegions?.length
  //         ? context.selectedRegions.map((e) => e.id)
  //         : undefined,
  //       diskIds: context?.selectedDisk?.length
  //         ? context.selectedDisk.map((e) => e.id)
  //         : undefined,
  //       snapshotAge: context?.selectedSnapshotAge?.length
  //         ? context.selectedSnapshotAge.map((e) => e.id)
  //         : undefined,
  //         minAge: context?.value?.length ? context?.value[0] : undefined,
  //         maxAge: context?.value?.length ? context?.value[1] : undefined,
  //       tags: tags.length ? tags : undefined,
  //     },
  //   };
  // }, [
  //   context.selectedAccounts,
  //   context.selectedDisk,
  //   context.selectedRegions,
  //   context?.value,
  //   context.selectedSnapshotAge,
  //   context?.selectedSnapshotTags,
  //   service,
  // ]);

  // const {
  //   data,
  //   isLoading,
  //   isError,
  //   refetch: refetchStatistics,
  // } = useHeaderStatistics(query);

  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (count >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (count >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  const formattedTotalCost = formatCount(
    Number((data?.stats?.totalCost || 0).toFixed(2)) || 0
  );
  const formattedUntaggedCost = formatCount(
    Number((data?.stats?.unTaggedCost || 0).toFixed(2)) || 0
  );
  const formattedOrphanedSavings = formatCount(
    Number((data?.stats?.orphanedSavings || 0).toFixed(2)) || 0
  );

  return (
    <>
      {isLoading && data === undefined && (
        <>
          <div style={{ background: "white", borderRadius: "8px" }}>
            <HeaderState />
          </div>
        </>
      )}

      {error && (
        <ScenariosErrorState
        headerItem="header"
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR_SNAPSHOT]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}

      {data && (
        <div className={clsx("row", styles.snap_sec_div)}>
          <div className={styles.snapshots_by_types} style={{ width: "100%" }}>
            <div className={styles.snapshots_main} style={{ width: "14%" }}>
              <span className={styles.snapshot_text}>Snapshots</span>
              <span className={styles.snapshot_value}>
                {data?.stats.totalCount || 0}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.snap_cost_text}>Cost:</span>
                <span className={styles.snap_cost_value}>
                  <ToolTipComponent data={data?.stats?.totalCost || 0}>
                    <span style={{ cursor: "context-menu" }}>
                      ${formattedTotalCost}
                    </span>
                  </ToolTipComponent>
                </span>
              </div>
            </div>

            <img src={vertical_line} alt="" style={{ height: "100%" }} />

            <SnapshotAge
              service={service}
              azureSnapAgeData={data?.stats || 0}
              width="39%"
            />
            <img src={vertical_line} alt="" style={{ height: "100%" }} />

            <div className={styles.untagged_snapshot_main}>
              <span className={styles.snapshot_text}>UNTAGGED SNAPSHOTS</span>
              <span className={styles.snapshot_value}>
                {data?.stats.unTaggedCount || 0}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.snap_cost_text}>Cost:</span>
                <span className={styles.snap_cost_value}>
                  <ToolTipComponent data={data?.stats?.unTaggedCost || 0}>
                    <span style={{ cursor: "context-menu" }}>
                      ${formattedUntaggedCost}
                    </span>
                  </ToolTipComponent>
                </span>
              </div>
            </div>

            <img src={vertical_line} alt="" style={{ height: "100%" }} />

            <div className={styles.untagged_snapshot_main}>
              <span className={styles.snapshot_text}>ORPHANED SNAPSHOTS</span>
              <span className={styles.snapshot_value}>
                {data?.stats.orphanedCount || 0}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.snap_cost_text}>Savings:</span>
                <span
                  className={clsx(
                    styles.snap_cost_value,
                    styles.snapshot_orphaned_color
                  )}
                >
                  <ToolTipComponent data={data?.stats?.orphanedSavings || 0}>
                    <span style={{ cursor: "context-menu" }}>
                      ${formattedOrphanedSavings}
                    </span>
                  </ToolTipComponent>
                </span>
              </div>
            </div>

            {selectedOption === "Active" && (
              <>
                <img src={vertical_line} alt="" style={{ height: "100%" }} />
                <div className={styles.untagged_snapshot_main}>
                  <span className={styles.snapshot_text}>
                    Legally Held snapshots
                  </span>
                  <span className={styles.snapshot_value}>
                    {data?.stats.legallyHeldCount || 0}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AWSHeaderSection;
