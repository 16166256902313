import { AppContext } from "@/App.context";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


const setDimensionValue = (token) => {
  return async (Qdata) => {
    let url =  `${config.apiHost}/v1/${Qdata?.service}/budget/get-dimention-values?dimention=${Qdata?.selectedKey}&account=${Qdata?.account}&scope=${Qdata?.scope}`
    const setBudget = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      console.error("Error response from server:", errorResponse);
      throw new Error(errorResponse.message);
    }
    const res = await setBudget.json();
    return res;
  };
};

export const useTagsValue = (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setDimensionValue", queryData],
    queryFn: () => setDimensionValue(appContext.idToken)(queryData),
    retry:false

  });
  return query;
};
