import { AppContext } from "@/App.context";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { API_LOADING_ERROR_CUSTOM_WIDGET, API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET, API_NOT_FOUND_ERROR_CUSTOM_WIDGET, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";


// const barData1 = {
//   cost: 1234,
//   percentage: 25,
//   data: [

//     {
//       name: "shbjs38728",
//       value: 80,
//     },
//     {
//       name: "272",
//       value: 33,
//     },
//     {
//       name: "272",
//       value: 33,
//     },
//     {
//       name: "272",
//       value: 33,
//     },
//     {
//       name: "272",
//       value: 33,
//     },
//   ],
// };
// const data = {
//     "percentage": 25,
//     "total_resource_created": 8,
//     "total_cost": 36,
//     "total_auto_scaling_count": 5,
//     "total_manually_created_count": 0,
//     "auto_scaling_data": [
//         {
//             "date": "2024-09-11",
//             "count": 6
//         },
//         {
//             "date": "2024-09-12",
//             "count": 6
//         },
//         {
//             "date": "2024-08-2",
//             "count": 3
//         },
//         {
//             "date": "2024-08-6",
//             "count": 1
//         },
//         {
//             "date": "2024-08-10",
//             "count": 2
//         },
//     ],
//     "manually_created_data": [
//         {
//             "date": "2024-09-16",
//             "count": 2
//         },
//         {
//             "date": "2024-08-2",
//             "count": 4
//         },
//         {
//             "date": "2024-09-4",
//             "count": 4
//         },
//         {
//             "date": "2024-09-7",
//             "count": 4
//         },
//     ]
// }

// const ScatterData = {
//     cost: 1234,
//     percentage: 25,
//     data: [
//       {
//         idle_days: "503",
//         cost: 7.84,
//       },
//       {
//         idle_days: "100",
//         cost: 20,
//       },
//       {
//         idle_days: "200",
//         cost: 60,
//       },
//       {
//         idle_days: "300",
//         cost: 84,
//       },
//       {
//         idle_days: "400",
//         cost: 12,
//       },
//       {
//         idle_days: "500",
//         cost: 11,
//       },
//     ],
//   };
//   const unusedData={
//     "percentage": 20,
//     "total_cost": 38.77,
//     "data": [
//         {
//             "idle_days": 231,
//             "cost": 125.28
//         },
//         {
//             "idle_days": 47,
//             "cost": 4.78
//         },
//         {
//             "idle_days": 259,
//             "cost": 30.12
//         },
//         {
//             "idle_days": 104,
//             "cost": 12.6
//         },
//         {
//             "idle_days": 49,
//             "cost": 5.77
//         },
//         {
//             "idle_days": 48,
//             "cost": 5.64
//         },
//         {
//             "idle_days": 38,
//             "cost": 4.68
//         },
//         {
//             "idle_days": 253,
//             "cost": 30.1
//         },
//         {
//             "idle_days": 32,
//             "cost": 3.57
//         },
//         {
//             "idle_days": 42,
//             "cost": 0.12
//         },
//         {
//             "idle_days": 9,
//             "cost": 0.31
//         },
//         {
//             "idle_days": 19,
//             "cost": 0.57
//         },
//         {
//             "idle_days": 96,
//             "cost": 14.75
//         },
//         {
//             "idle_days": 49,
//             "cost": 10
//         }
//     ]
// }
const setTopCostChart = (token) => {
  return async (Qdata) => {
    console.log("scopee",Qdata?.scope)
    // return barData1
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/cost-generator-graph?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body:JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    
    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};
const setNewlyChart = (token) => {
  return async (Qdata) => {
    // return data
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/newly-created-resources-graph?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body:JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    
    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if(Qdata?.service === "aws"){
    //   if (res?.auto_scaling_data.length === 0) {
    //     throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    //   }
    // }
    // else{
    //   if (res?.data.length === 0) {
    //     throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    //   }
    // }
    return res;
  };
};
const setSnapshotChart = (token) => {
  return async (Qdata) => {
    // return ScatterData
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/snapshot-graph?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body:JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    
    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};
const setUnusedChart = (token) => {
  return async (Qdata) => {
    // return unusedData
    let url = `${config.apiHost}/v1/${Qdata?.service}/budget/unused-resources-graph?page=${Qdata?.page}&recordsPerPage=${Qdata?.recordsPerPage}&orderBy=${Qdata?.orderBy}&desc=${Qdata?.desc}`;
    let bodyData = {
      budgetName: Qdata?.budgetName,
    };
    if (Qdata?.service === "azure") {
      bodyData.subscriptionId = Qdata?.subscriptionId || "null";
      bodyData.managementGroup = Qdata?.managementGroup || "null";
      bodyData.resourceGroup = Qdata?.resourceGroup || "null" ;
      bodyData.scope = Qdata?.scope;
    }
    if (Qdata?.service === "aws") {
      bodyData.accountId= Qdata?.accountId
    }
    const setBudget = await fetch(url, {
      method: "POST",
      body:JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    
    if (!setBudget.ok) {
      if (ERROR_STATUS_CODES.includes(setBudget.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (setBudget.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await setBudget.json()).message);
    }
    const res = await setBudget.json();
    // if (res?.data.length === 0) {
    //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    // }
    return res;
  };
};

export const useTopCostChartValues= (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setTopCostChart", queryData],
    queryFn: () => setTopCostChart(appContext.idToken)(queryData),
    retry:false
  });
  return query;
};
export const useNewlyChartValues= (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setNewlyChart", queryData],
    queryFn: () => setNewlyChart(appContext.idToken)(queryData),
    retry:false
  });
  return query;
};

export const useSnapshotChartValues= (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setSnapshotChart", queryData],
    queryFn: () => setSnapshotChart(appContext.idToken)(queryData),
    retry:false
  });
  return query;
};
export const useUnusedChartValues= (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setUnusedChart", queryData],
    queryFn: () => setUnusedChart(appContext.idToken)(queryData),
    retry:false
  });
  return query;
};




