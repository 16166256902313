import React, { useMemo } from "react";
import styles from "./states.module.css";
import clsx from "clsx";
import NoEntry from "@/assets/img/noEntry.svg";
import LoadingCloud from "@/assets/img/loadingCloud.svg";
import reloadImg from "@/assets/img/reload-img.svg";
import { Button } from "react-bootstrap";
import ResponseBad from "@/assets/img/responseBad.svg";
import NoDataImage from "@/assets/img/noDataImg.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_LOADING_ERROR_SNAPSHOT,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

/**
 * @typedef DataAbsenseProps
 * @property {string} containerClasses
 */

/**
 * @param {DataAbsenseProps} props
 */

export const CustomNoData = () => {
  return (
    <div className={clsx(styles.main_container)}>
      <img src={NoEntry} width={"32px"} height={"32px"} alt="" />
      <div className={styles.sec_container}>
        <div className={styles.text_css}>No Data Available</div>
        <div className={styles.sec_text_css}>
          We couldn’t retrieve data at this moment. This might be due to an
          absence of data or a temporary issue. Please try again later.
        </div>
      </div>
    </div>
  );
};


const ScenariosErrorState = ({ error, messageConfig, headerItem }) => {
  const { message, additionalMessage } = useMemo(() => {
    const fallbackMessage = {
      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
        message: "No Data Available for Selected Filters",
        additionalMessage:
          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
      },
      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET]: {
        message: "No Data Available for Selected Filters",
        additionalMessage:
          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
      },
      [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
        message: "No Data for the Selected Date Range",
        additionalMessage:
          "The selected date range doesn’t contain any data. Try choosing a different range to view available savings.",
      },
      [API_LOADING_ERROR_CUSTOM_WIDGET]: {
        message: "Oops!",
        additionalMessage:
          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
      },
      [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
        message: "404 Error",
        additionalMessage:
          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
      },

      [API_LOADING_ERROR]: {
        message: "Oops!",
        additionalMessage:
          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
      },
      [API_LOADING_ERROR_SNAPSHOT]: {
        message: "Oops!",
        additionalMessage:
          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
      },
      [API_NOT_FOUND_ERROR]: {
        message: "404 Error",
        additionalMessage:
          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
      },
      [API_NO_DATA_FOUND_ERROR || API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
        message: "No Data Available",
        additionalMessage:
          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
      },
      [API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS]: {
        message: "No Data Available for Selected Resource Type",
        additionalMessage:
          "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
      },
      [API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS]: {
        message: "No Data Available for Selected Idle Days",
        additionalMessage:
          "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
      },
    };

    return (
      messageConfig?.[error] ||
      fallbackMessage?.[error] || {
        message: "Error",
        additionalMessage: "An unexpected error occurred.",
      }
    );
  }, [error, messageConfig]);

  return (
    <div
      className={clsx(styles.main_container)}
      style={{
        borderRadius: headerItem === "header" && "8px",
        padding: headerItem === "header" && "16px 10px",
      }}
    >
      {error !== API_LOADING_ERROR &&
        error !== API_LOADING_ERROR_CUSTOM_WIDGET &&
        error !== API_NOT_FOUND_ERROR &&
        error !== API_NO_DATA_FOUND_ERROR &&
        error !== API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET &&
        error !== API_NOT_FOUND_ERROR_CUSTOM_WIDGET && (
          <img src={NoEntry} width={"32px"} height={"32px"} alt="" />
        )}
      {error === API_LOADING_ERROR && <img src={LoadingCloud} alt=""></img>}
      {error === API_LOADING_ERROR_SNAPSHOT && <></>}
      {error === API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET && <></>}
      {error === API_NOT_FOUND_ERROR_CUSTOM_WIDGET &&
        error === API_LOADING_ERROR_CUSTOM_WIDGET && <></>}
      {error === API_NO_DATA_FOUND_ERROR && <img src={NoDataImage} alt="" />}
      {error === API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET && (
        <>
          <img src={NoEntry} width={"32px"} height={"32px"} alt="" />
        </>
      )}
      {error === API_NOT_FOUND_ERROR && <img src={ResponseBad} alt="" />}
      <div className={styles.sec_container}>
        <div className={styles.text_css}>{message}</div>
        <div className={styles.sec_text_css}>{additionalMessage}</div>
        {(error === API_LOADING_ERROR ||
          error === API_LOADING_ERROR_SNAPSHOT ||
          error === API_NOT_FOUND_ERROR ||
          error === API_NOT_FOUND_ERROR_CUSTOM_WIDGET ||
          error === API_LOADING_ERROR_CUSTOM_WIDGET) && (
          <div className={styles.sec_container}>
            <Button
              style={{
                width: "100px",
                height: "40px",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
              }}
              onClick={() => window.location.reload()}
            >
              <img src={reloadImg} alt="" />

              <span className={styles.reload_text_css}>Reload</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScenariosErrorState;
