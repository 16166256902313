import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAWSStatistics} data
 */

/**
 * @typedef GetAWSStatistics
 * @property {number} forecastedCost
 * @property {string} month
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'all-cloud'} service
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatistics>}
 */

export const getForecastedBarGraph = (token) => {
  return async ({ service, accounts }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost/get-forecast`,
      {
        method: "POST",
        body: JSON.stringify({
            accounts,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
        if (ERROR_STATUS_CODES.includes(owners.status)) {
          throw new Error(API_LOADING_ERROR);
        }
        if (owners.status === 404) {
          throw new Error(API_NOT_FOUND_ERROR);
        }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data?.length === 0) {
      if (accounts.length ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR)
      } else {

        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }


    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useForecastedBarGraph = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getForecastedBarGraph", req, ...deps],
    queryFn: () => getForecastedBarGraph(appContext.idToken)(req),
  });
  return query;
};
