import { useContext } from "react";
import config from "../../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

/**
 * @typedef EditGroupReq
 * @property {string} name
 * @property {string} description
 * @property {number[]} privilegeIds
 * @property {number[]} userIds
 */

/**
 * @typedef EditGroupRes
 * @property {string} message
 */
const editGroupDetails = (token) => {
  /**
   * @param {EditGroupReq} req
   * @returns {Promise<EditGroupRes>}
   */
  return async (data) => {
    const editGroup = await fetch(
      `${config.apiHost}/v1/groups/${data.id}/update-group`,
      {
        method: "PUT",
        body: JSON.stringify(data?.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!editGroup.ok) {
      throw new Error((await editGroup.json()).message);
    }
    const groupStatus = await editGroup.json();
    return groupStatus;
  };
};

export const useEditGroupInfo = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["editGroupDetails", ...deps],
    mutationFn: (body) => editGroupDetails(appContext.idToken)(body),
  });
  return mutate;
};
