import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {string} filter
 * @property {GetDataStatistics} data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */

/**
/**
 * @typedef GetAccountStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'} service
 * @property {string} columnName
 */

export const getTagValueStatistics = (token) => {
  /**
   * @param {GetAccountStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, columnName }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/tag-values?columnName=${columnName}`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**

 * @param {GetAccountStatisticsReq} req
 * @param {any[]=} deps
 */
export const useTagValueStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getTagValueStatistics", req, ...deps],
    queryFn: ({ signal }) =>
      getTagValueStatistics(appContext.idToken)(req, signal),
    enabled: req.selectedTittle != null,
  });
  return query;
};
