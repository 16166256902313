import { useContext } from "react";
import config from "../../config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import Joi from "joi";

/**
 * @typedef CreateUserReqGroups
 * @property {string} id
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef CreateUserReqValidator
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {boolean} addAsAdmin
 * @property {CreateUserReqGroups[]} groupIds
 */

export const CreateUserReqValidator = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().required(),
  description: Joi.string().optional(),
  addAsAdmin: Joi.boolean(),
  groupIds: Joi.array().items(
    Joi.object({
      id: Joi.number(),
      name: Joi.string(),
      description: Joi.string(),
    })
  ),
});

const setUser = (token) => {
  return async (data) => {
    const setUser = await fetch(`${config.apiHost}/v1/users/add-user`, {
      method: "POST",
      body: JSON.stringify(data?.body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!setUser.ok) {
      throw new Error((await setUser.json()).message);
    }
    const userStatus = await setUser.json();
    return userStatus;
  };
};

/**
 * @typedef GetUserEmailsReq
 * @property {string} filterQuery
 */

/**
 * @typedef GetUserEmailsItem
 * @property {string} name
 * @property {string} email
 */

/**
 * @typedef GetUserEmailsRes
 * @property {GetUserEmailsItem[]} users
 */

/**
 * @param {data} GetUserEmailsProps
 * @returns
 */

const getUserEmails = (token) => {
  /**
   * @param {GetUserEmailsReq} data
   */
  return async (data) => {
    const getUserEmails = await fetch(`${config.apiHost}/v1/users/get-emails`, {
      method: "POST",
      body: data.filterQuery?.length ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!getUserEmails.ok) {
      throw new Error((await getUserEmails.json()).message);
    }
    const emailStatus = await getUserEmails.json();
    return emailStatus;
  };
};

const setUserGroups = (token) => {
  return async (data) => {
    const setUserGroup = await fetch(`${config.apiHost}/v1/${data}/search`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!setUserGroup.ok) {
      throw new Error((await setUserGroup.json()).message);
    }
    const userGroupStatus = await setUserGroup.json();
    return userGroupStatus;
  };
};

export const useCreateUser = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["setUser", ...deps],
    mutationFn: (body) => setUser(appContext.idToken)(body),
  });

  return mutate;
};

/**
 * @param {GetUserEmailsReq} data
 * @param {any[]} deps
 * @returns
 */
export const useGetUserEmails = (data, deps = []) => {
  const appContext = useContext(AppContext);

  const query = useQuery({
    queryKey: ["getUserEmails", data, ...deps],
    queryFn: () => getUserEmails(appContext.idToken)(data),
  });
  return query;
};

export const usePrivilegeGroups = (data, deps = []) => {
  const appContext = useContext(AppContext);

  const query = useQuery({
    queryKey: ["setUserGroups", ...deps],
    queryFn: () => setUserGroups(appContext.idToken)(data),
  });
  return query;
};
