import { useState, useMemo, useEffect } from "react";
import styles from "@/page-components/admin/admin.module.css";
import { Button } from "react-bootstrap";
import addUser from "@/assets/img/c-remove 2.svg";
import Pagination from "@/components/pagination/pagination.component";
import CreateGroupModal from "@/page-components/admin/groups/create.group.modal";
import GroupTableComp from "./group.table";
import SearchBar from "../components/searchbar.modal";
import { useGroupsList } from "@/services/admin/Groups/getGroupsList.repo";
import { useSearchParams } from "react-router-dom";
import { useDebouncedValue } from "rooks";
import {
  GroupsContext,
  useGroupsContext,
} from "@/page-components/admin/groups/group.context";
import { DeleteMessageModal } from "../components/delete.modal.part";
import { useDeleteGroup } from "@/services/admin/Groups/deleteGroup.repo";
import GroupModalData from "./group.info.modal.part";
import LoadingRbacTable from "../states/loading.state.part";
import EmptyTable from "../states/empty.state.part";

const GroupComp = () => {
  const adminContext = useGroupsContext();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [filterQuery, setFilterQuery] = useState("");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [debouncedFilter] = useDebouncedValue(filterQuery, 500);

  var query = useMemo(() => {
    return {
      filterQuery: debouncedFilter?.length ? debouncedFilter : undefined,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
    };
  }, [debouncedFilter, parsedItemsPerPage, parsedPage]);

  const {
    data: groupsData,
    isLoading: groupsLoading,
    refetch: refetchGroups,
  } = useGroupsList(query);

  const {
    data: deleteGroupData,
    mutate: deleteGroup,
    isPending: deleteLoading,
    reset: deleteReset,
  } = useDeleteGroup();

  useEffect(() => {
    if (deleteGroupData) {
      refetchGroups();
      deleteReset();
      adminContext.setCreateGroupModal(false);
      adminContext.setGroupDeleteItem(undefined);
      adminContext.setGroupEditItem(undefined);
      adminContext.setGroupSidebarItem(undefined);
    }
  }, [adminContext, deleteGroupData, deleteReset, refetchGroups]);

  return (
    <GroupsContext.Provider
      value={{
        ...adminContext,
        refetchGroups,
      }}
    >
      <div className={styles.mainRolesContainer}>
        <div className={styles.textAndButtonContainer}>
          <div className={styles.labelHolder}>
            <span className={styles.manageRoles}>Groups</span>
            <span className={styles.text}>
              Groups serve as containers for Policies/Privileges, which define
              permissions for users, enabling precise specification of access
              rights.
            </span>
          </div>

          <Button
            className={styles.addUserButton}
            onClick={() => {
              adminContext.setCreateGroupModal(true);
            }}
          >
            <img src={addUser} className={styles.AddButtonImg} alt="" />
            Create Group
          </Button>
        </div>
        <SearchBar setFilterQuery={setFilterQuery} />
        {/*Empty State for table*/}
        {!groupsData?.groups?.length &&
          !debouncedFilter?.length &&
          !groupsLoading && (
            <EmptyTable
              name="No group created yet"
              message="Create privileges to create and view groups."
            />
          )}

        {groupsData?.groups?.length == 0 && Boolean(filterQuery?.length) && (
          <EmptyTable
            name="Result not found"
            message="Try searching for more."
          />
        )}
        {/*Table has Data */}
        {groupsLoading && <LoadingRbacTable />}

        {Boolean(groupsData?.groups?.length) && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <GroupTableComp
              data={groupsData?.groups || []}
              loading={groupsLoading}
            />
            <Pagination totalItems={groupsData?.total || 0} />
          </div>
        )}
        <CreateGroupModal
          groupID={adminContext.groupEditItem}
          show={adminContext.createGroupModal}
          onHide={() => {
            adminContext.setCreateGroupModal(false);
            adminContext.setGroupEditItem(undefined);
          }}
        />
        <GroupModalData
          handleClose={() => {
            adminContext.setGroupSidebarItem(undefined);
          }}
          selectedItem={adminContext.groupSidebarItem}
        />
        <DeleteMessageModal
          deleteLoading={deleteLoading}
          deleteAction={() =>
            deleteGroup({
              groupId: adminContext.groupDeleteItem,
            })
          }
          deleteData={deleteGroupData}
          show={adminContext.groupDeleteItem}
          onHide={() => {
            adminContext.setGroupDeleteItemName(undefined);
            adminContext.setGroupDeleteItem(undefined);
          }}
          title={adminContext.groupDeleteItemName}
          type="group"
        />
      </div>
    </GroupsContext.Provider>
  );
};

export default GroupComp;
