import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetStatistics
 * @property {number | null} total
 * @property {number | null} savings_percent
 * @property {number | null} savings_till_date_pm
 * @property {number | null} current_costs_pm
 * @property {number | null} downsize_count
 * @property {number | null} downsize_savings_pm
 * @property {number | null} downsize_cost_pm
 * @property {number | null} upsize_count
 * @property {number | null} upsize_savings_pm
 * @property {number | null} upsize_cost_pm
 * @property {number | null} projected_costs_pm
 * @property {number | null} projected_savings_pm
 * @property {number | null} terminate_count
 * @property {number | null} terminate_savings_pm
 * @property {number | null} terminate_cost_pm
 */

/**
 * @typedef GetStatisticsRes
 * @property {GetStatistics} statistics
 * @property {GetIdleFilters} filters
 */

export const getStatistics = (token) => {
  /**
   * @param {import('./getRecommendationsList.repo').GetRecommendationsListReq} req
   * @param {AbortSignal} signal
   * @returns {Promise<GetStatisticsRes>}
   */
  return async (req, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${req.service}/recommendations/statistics`,
      // {
      //   method: "GET",
      //   signal,
      //   headers: {
      //     Authorization: token
      //   }
      // }
      {
        method: req.service === "all-cloud" ? "GET" : "POST",
        body:
          req.service !== "all-cloud"
            ? JSON.stringify(req)
            : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
