
import React from "react";
import { Dropdown } from "react-bootstrap";
import styles from "@/page-components/idle-resource/idle.module.css"

const DropdownComponent = ({ menuIcon, items, onItemClick }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        id={styles.custom_dropdown_toggle}
        className={styles.custom_dropdown_toggle}
        style={{ background: "transparent", border: "none" }}
      >
        <img src={menuIcon} alt="Menu" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdown_menu}>
        {items.map((item, index) => (
          <Dropdown.Item
            key={index}
            className={styles.dropdown_item}
            eventKey={item.eventKey}
            onClick={() => onItemClick(item.eventKey)}
          >
            <img
              src={item.icon}
              alt=""
              style={{ marginRight: "10px" }}
            />
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownComponent;
