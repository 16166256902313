import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";

const TableErrorState = () => {
  return (
    <div style={{width:"100%"}}>
      <Table style={{ borderBottom: "1px solid #fff" }}>
        <thead>
          <tr>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
            <th>
              <Placeholder
                style={{ width: "100%", height: 32 }}
                className={styles.placeholder_css}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(7)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableErrorState;
