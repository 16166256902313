import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_NO_DATA_FOUND_ERROR, API_NOT_FOUND_ERROR, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
/**
 * @typedef AllTableStatistics
 * @property {Number} total_recommendations
 * @property {Number} total_ondemand_cost
 * @property {Number} total_ri_cost
 * @property {Number} savings_vs_ondemand_percent
 *
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllTableStatistics} statistics
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' |'gcp' } service
 * @property {number} page
 * @property {number} limit
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<AllBodyStatisticsRes>}
 */

export const getStatistics = (token) => {
  return async ({ service}) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/total-stats-ri`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiRecommendationStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatistics(appContext.idToken)(req),
  });
  return query;
};
