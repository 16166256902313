import React, { useState } from "react";

/**
 * @typedef AdminPrivilegeContextType
 * @property {boolean=} createPrivilegeModal
 * @property {React.Dispatch<boolean>} setCreatePrivilegeModal
 * @property {number=} privilegeDeleteModal
 * @property {React.Dispatch<number>} setPrivilegeDeleteModal
 * @property {string=} privilegeDeleteName
 * @property {React.Dispatch<string>} setPrivilegeDeleteName
 * @property {() => void} refetchPrivileges
 * @property {number=} privilegeSidebarSelectedItem
 * @property {React.Dispatch<number>} setPrivilegeSidebarSelectedItem
 * @property {number=} privilegeEditSelectedItem
 * @property {React.Dispatch<number>} setPrivilegeEditSelectedItem
 * @property {boolean=} deletePrivilegeLoading
 * @property {any=} deletePrivilegeData
 */

/** @type {import("react").Context<AdminPrivilegeContextType>} */
export const AdminPrivilegeContext = React.createContext();

export const useAdminPrivilegeContext = () => {
  const [createPrivilegeModal, setCreatePrivilegeModal] = useState(false);
  const [privilegeDeleteModal, setPrivilegeDeleteModal] = useState();
  const [privilegeDeleteName, setPrivilegeDeleteName] = useState();
  const [privilegeSidebarSelectedItem, setPrivilegeSidebarSelectedItem] = useState();
  const [privilegeEditSelectedItem, setPrivilegeEditSelectedItem] = useState();
  const [failureMessage, setFailureMessage] = useState("");
  return {
    createPrivilegeModal,
    setCreatePrivilegeModal,
    privilegeDeleteModal,
    setPrivilegeDeleteModal,
    privilegeSidebarSelectedItem, 
    setPrivilegeSidebarSelectedItem,
    privilegeEditSelectedItem, 
    setPrivilegeEditSelectedItem,
    privilegeDeleteName, 
    setPrivilegeDeleteName,
    failureMessage,
    setFailureMessage
  };
};
