import { useMemo, useState, useEffect } from "react";
import style from "@/page-components/search-module/search.module.css";
import clsx from "clsx";
import compute from "@/assets/img/computepic.svg";
import storage from "@/assets/img/storage-Icon.svg";
import database from "@/assets/img/database.svg";
import serverless from "@/assets/img/Containers-Icon.svg";
import network from "@/assets/img/Network-Icon.svg";
import other from "@/assets/img/three-dot-icon.svg";
import { useAllSearchStatics } from "@/services/costExplorer/getSearchStatistics.repo";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SideSearchSection = (props) => {
  const navigate = useNavigate();

  const tabsObj = {
    all: "all",
    Category: "Category",
    Services: "Services",
    Resources: "Resources",
  };
  let tabs = Object.freeze(tabsObj);
  const [selectedTab, setSelectedTab] = useState(tabs.all);
  const allButtonClass = clsx(style.sidebarButtons, {
    [style.sidebarButtonsActive]: selectedTab === tabs.all,
  });
  const CategoryButtonClass = clsx(style.sidebarButtons, {
    [style.sidebarButtonsActive]: selectedTab === tabs.Category,
  });
  const ServicesButtonClass = clsx(style.sidebarButtons, {
    [style.sidebarButtonsActive]: selectedTab === tabs.Services,
  });
  const ResourcesButtonClass = clsx(style.sidebarButtons, {
    [style.sidebarButtonsActive]: selectedTab === tabs.Resources,
  });

  const [counts, setCounts] = useState({
    all: 0,
    Category: 0,
    Services: 0,
    Resources: 0,
  });

  console.log("select",selectedTab)
  const total =
    (counts?.Category || 0) + (counts?.Services || 0) + (counts.Resources || 0);

  return (
    <div className={style.MainSearchDiv}>
      <div
        className={style.overlayContainer}
        onClick={() => {
          props.settingtext();
          props.setModalFalse();
        }}
      ></div>
      <div className={style.sideSearch} style={{ height: "100vh" }}>
        <div className={style.mainheaderr}>
          <span style={{ fontWeight: "600", fontSize: "18px" }}>
            Search Results
          </span>
          <div>
            <div>
              <span className={style.searchHeading}>
                Showing results for “{props?.searchText || "Search"}”
              </span>
              <div className={style.sidebarButtonContainer}>
                <span
                  className={allButtonClass}
                  onClick={() => setSelectedTab(tabs.all)}
                >
                  All Results ({total})
                </span>
                <span
                  className={CategoryButtonClass}
                  onClick={() => setSelectedTab(tabs.Category)}
                >
                  Service Category ({counts.Category})
                </span>
                <span
                  className={ServicesButtonClass}
                  onClick={() => setSelectedTab(tabs.Services)}
                >
                  Services ({counts.Services})
                </span>
                <span
                  className={ResourcesButtonClass}
                  onClick={() => setSelectedTab(tabs.Resources)}
                >
                  Resources ({counts.Resources})
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={style.offcanva_body}
          style={{
            marginTop: "24px",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflowY: "scroll",
            height: "520px",
          }}
        >
          <>
            {(selectedTab === tabs.all || selectedTab === tabs.Category) && (
              <TableComponent
                props={props}
                type="serviceCategory"
                text={props.searchText}
                service={props.service}
                name="SERVICE CATEGORY"
                setcount={setCounts}
                removeInput = {props.settingtext}
                setmodalFalse={props.setModalFalse}
              />
            )}
            {(selectedTab === tabs.all || selectedTab === tabs.Services) && (
              <TableComponent
              removeInput = {props.settingtext}

                props={props}
                type="service"
                text={props.searchText}
                service={props.service}
                name="SERVICES"
                setcount={setCounts}
                setmodalFalse={props.setModalFalse}
              />
            )}

            {(selectedTab === tabs.all || selectedTab === tabs.Resources) && (
              <TableComponent
              removeInput = {props.settingtext}

                props={props}
                type="resource"
                text={props.searchText}
                service={props.service}
                name="RESOURCES"
                setcount={setCounts}
                setmodalFalse={props.setModalFalse}
              />
            )}

            <hr className={style.searchHrLine} />
          </>
        </div>
      </div>
    </div>
  );
};

export default SideSearchSection;

function TableComponent(props) {
  const [categoryresult, setCategoryresult] = useState(true);

  const queries = useMemo(() => {
    return {
      service: props?.service,
      query: props.text,
      type: props.type,
      all: categoryresult,
    };
  }, [props?.text, props?.service, categoryresult]);

  const { data: serviceData, isLoading: categoryDataLoading } =
    useAllSearchStatics(queries);

  const navigate = useNavigate();

  const handleusageDetails = (name, type) => {
    props.setmodalFalse();
    navigate(`/${props.service}/search?name=${name}&type=${type}`);
  };

  useEffect(() => {
    if (serviceData?.count !== undefined) {
      props.setcount((prevCounts) => ({
        ...prevCounts,
        [props.type === "serviceCategory"
          ? "Category"
          : props.type.charAt(0).toUpperCase() + props.type.slice(1)]:
          serviceData.count,
        [props.type === "service"
          ? "Services"
          : props.type.charAt(0).toUpperCase() + props.type.slice(1)]:
          serviceData.count,
        [props.type === "resource"
          ? "Resources"
          : props.type.charAt(0).toUpperCase() + props.type.slice(1)]:
          serviceData.count,
      }));
    }
  }, [props.type, serviceData?.count]);

  // Function to highlight the search text in the title
  const highlightText = (title, searchText) => {
    if (!searchText) return title;
    const regex = new RegExp(`(${searchText})`, "gi");
    return title.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ fontWeight: "700", color: "black" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className={style.searchOffcanvas}>
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "13px",
            color: "#797B80",
          }}
        >
          {props.name}
        </span>
        {categoryDataLoading && (
          <div className={style.mySpinner}>
            <Spinner />
          </div>
        )}
        {(serviceData?.results || []).map((cat, index) => (
          <div className={style.searchrows} key={index}>
            <div
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#495057",
                display: "flex",
                gap: "5px",
              }}
            >
             {props?.type === "serviceCategory" &&
                cat?.title === "Compute" && <img src={compute} alt="Compute" />}
              {props?.type === "serviceCategory" &&
                cat?.title === "Storage" && <img src={storage} alt="Storage" />}
              {props?.type === "serviceCategory" &&
                cat?.title === "Database" && (
                  <img src={database} alt="Database" />
                )}
              {props?.type === "serviceCategory" &&
                cat?.title === "Serverless" && (
                  <img src={serverless} alt="Serverless" />
                )}
              {props?.type === "serviceCategory" &&
                cat?.title === "Network" && <img src={network} alt="Network" />}
              {props?.type === "serviceCategory" &&
                ![
                  "Compute",
                  "Storage",
                  "Database",
                  "Serverless",
                  "Network",
                ].includes(cat?.title) && <img src={other} alt="Other" />}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleusageDetails(cat?.id, props?.type);
                  props.removeInput()
                }}
              >
                <p>{highlightText(cat?.title, props?.text) || "--"}</p>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    lineHeight: 12,
                  }}
                >
                  {highlightText(cat?.description || "", props?.text) || "--"}
                </p>
              </div>
            </div>
          </div>
        ))}
        {categoryresult === false && serviceData?.results?.length >= 5 && (
          <span
            className={style.showLessResult}
            onClick={() => setCategoryresult(true)}
          >
            View less results
          </span>
        )}

        {categoryresult === true && serviceData?.results?.length >= 5 && (
          <span
            className={style.showLessResult}
            onClick={() => setCategoryresult(false)}
          >
            View all results
          </span>
        )}
      </div>
      <hr style={{ border: "1px solid #DEE2E6" }} />
    </div>
  );
}
