import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} resourcegroup
 * @property {string[]} region
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

const awsData = {
  data: {
    count: 3,
    DiskRecommendationList: [
      {
        subscriptionId: "2b5a1cdf-7f73-4897-9dfb-b1249da6ba45",
        accountname: "AWS-Production",
        resourcename: "prod-instance-01",
        resourceid:
          "arn:aws:ec2:us-west-2:123456789012:instance/prod-instance-01",
        disk_region: "us-west-2",
        datadisk_name: "prod-instance-01_OsDisk_1_abc123def456",
        disk_size_gb: 256,
        disk_max_iops: 750,
        disk_max_tp: 150,
        disk_max_used_iops: 200,
        disk_max_used_tp: 50,
        recommendation: "optimize_iops_tp",
        recommendation_disk_type:
          "Upgrade the disk from General Purpose SSD to Provisioned IOPS SSD",
        disk_bursting: "Enable",
      },
      {
        subscriptionId: "3c6b1edf-8g84-5798-8gfc-c2359ea7cb56",
        accountname: "AWS-Development",
        resourcename: "dev-instance-02",
        resourceid:
          "arn:aws:ec2:us-east-1:123456789012:instance/dev-instance-02",
        disk_region: "us-east-1",
        datadisk_name: "dev-instance-02_OsDisk_1_def789ghi012",
        disk_size_gb: 512,
        disk_max_iops: 1000,
        disk_max_tp: 250,
        disk_max_used_iops: 800,
        disk_max_used_tp: 150,
        recommendation: "no_change",
        recommendation_disk_type: "Current disk type is optimal",
        disk_bursting: "Disable",
      },
      {
        subscriptionId: "4d7c2fgh-9h95-6819-9ghd-d3469fb8dc67",
        accountname: "AWS-Staging",
        resourcename: "stage-instance-03",
        resourceid:
          "arn:aws:ec2:eu-central-1:123456789012:instance/stage-instance-03",
        disk_region: "eu-central-1",
        datadisk_name: "stage-instance-03_OsDisk_1_ghi345jkl678",
        disk_size_gb: 128,
        disk_max_iops: 300,
        disk_max_tp: 80,
        disk_max_used_iops: 50,
        disk_max_used_tp: 20,
        recommendation: "decrease_iops_tp",
        recommendation_disk_type:
          "Downsize the disk from General Purpose SSD to Magnetic Disk",
        disk_bursting: "Disable",
      },
    ],
  },
};

export const getDiskRecommendStats = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters, page, recordsPerPage }, signal) => {
    if (service === "aws") {
      return awsData;
    }
    const body = {
      accounts: filters?.accounts || [],
      region: filters?.region || [],
    };

    if (service === "azure") {
      body.resourcegroup = filters?.resourcegroup || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ai-recommendations/disk-recommendations/list?page=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data?.count === 0) {
      if (
        body.accounts?.length ||
        body.region?.length ||
        body.owner?.length ||
        body.resourcegroup?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    return data;
  };
};
/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useDiskRecommendStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getDiskRecommendStats", req, ...deps],
    queryFn: ({ signal }) =>
      getDiskRecommendStats(appContext.idToken)(req, signal),
  });
  return query;
};
