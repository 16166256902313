import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetUsers
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {string} creatorName
 * @property {number} id
 * @property {date} createdAt
 */

/**
 * @typedef GetGroups
 * @property {string} name
 * @property {string} description
 * @property {number} id
 */

/**
 * @typedef GetUsersByIdRes
 * @property {GetUsers[]} user
 * @property {GetGroups[]} groups
 */

export const getUsersById = (token) => {
  /**
   * @param {number} id
   * @param {AbortSignal} signal
   * @returns {Promise<GetUsersByIdRes>}
   */
  return async (id, signal) => {
    const users = await fetch(`${config.apiHost}/v1/users/${id}/get-user`, {
      method: "GET",
      signal,
      headers: {
        Authorization: token,
      },
    });
    if (!users.ok) {
      throw new Error((await users.json()).message);
    }
    return await users.json();
  };
};

export const useUsersById = (id, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    staleTime: 0,
    enabled: Boolean(id),
    queryKey: ["getUsersById", id, appContext.idToken, ...deps],
    queryFn: ({ signal }) => getUsersById(appContext.idToken)(id, signal),
  });
  return query;
};
