import React from "react";
import styles from "@/page-components/RI-Capacity/ri.module.css";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";

const InstanceComponent = ({
  data,
  dataisLoading,
  maxSavingsPerMonth,
  service,
}) => {
  return (
    <div>
      <div className={styles.container}>
        <div style={{ display: "flex", flexDirection: "row", width: "38%" }}>
          <div
            className={styles.specs}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              textAlign: "left",
              paddingTop: "12px",
              borderRadius: "8px",
            }}
          >
            <span className={styles.specs_name}>SPECS</span>
            <span
              className={styles.single_rg}
              style={{ paddingBottom: "22px" }}
            >
              &nbsp;
            </span>
            <span className={styles.on_demand_cost}>Instance Flexibility</span>
            <span className={styles.on_demand_cost}>Unused Quantity</span>
            <span className={styles.on_demand_cost}>Unused Cost</span>
            {/* <span className={styles.on_demand_cost}>Savings vs. On-Demand</span> */}
            <span className={styles.on_demand_cost}>Resource Group</span>
          </div>
          <div
            className={styles.specs}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              textAlign: "center",
              paddingTop: "12px",
              borderRadius: "8px",
            }}
          >
            <span className={styles.specs_name}>
              {service === "azure"
                ? "CURRENT COST"
                : service === "aws"
                ? "CURRENT SCOPE"
                : "--"}
            </span>
            <span
              className={styles.specs_name}
              style={{ paddingBottom: "22px" }}
            >
              {data?.reservation?.scope || "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              {data?.reservation?.instanceFlexibility || "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              {data?.reservation?.unusedQuantity || "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              {data?.reservation?.unusedCost || "--"}
            </span>

            <span className={styles.Subscription}>Subscription Name </span>
            <span className={styles.on_demand_cost}>
              {data?.reservation?.resourceGroup || "--"}
            </span>
          </div>
        </div>
        <div style={{ width: "62%" }}>
          <div>
            <div className={styles.models_data2}>
              {dataisLoading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {(data?.recommendations || []).map((rec, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "12px",
                    borderRadius: "8px",
                  }}
                  key={index}
                  // className={`${styles.block} ${
                  //   parseInt(rec.savingsPerMonth.slice(1)) === 450
                  //     ? styles.blue
                  //     : styles.gray
                  // }`}
                >
                  <span className={styles.specs_name}>ON RECOMMENDATION</span>
                  <p
                    className={styles.single_rg}
                    style={{ paddingBottom: "21px" }}
                  >
                    {rec?.recommendedScope || "--"}
                  </p>
                  <span className={styles.instance_data}>
                    {rec?.instanceFlexibility || "--"}
                  </span>
                  <span className={styles.instance_data}>
                    {rec?.unusedQuantity || "--"}
                  </span>
                  <span className={styles.instance_data}>
                    ${Number(rec?.unusedCost).toFixed(2) || 0}
                  </span>
                  <span className={styles.instance_data}>
                    {rec?.subscriptionName || "--"}
                  </span>
                  <div
                    className={styles.saving_month}
                  >
                    <span className={styles.saving_name}>
                      Savings Per Month
                    </span>
                    <span className={styles.saving_value}>
                      {rec?.savingsPerMonth || "--"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstanceComponent;
