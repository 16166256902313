/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useMemo, useEffect } from "react";
import styles from "@/page-components/search-module/search.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import select from "@/assets/img/selectOption.svg";
import GraphTableSection from "./graph-table.part";
import { SearchContext } from "../search-context";
import { useSpendBySearch } from "@/services/costExplorer/getSpendBySearch.repo";
import { useCostTrend } from "@/services/costExplorer/getCostTrendSearch.repo";
import { useSearchResultTags } from "@/services/costExplorer/getSearchResultTags.repo";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import SearchTableState from "@/components/States/searchTableState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

const CostByServices = ({ service }) => {
  const context = useContext(SearchContext);

  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context?.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context?.selectRegions.map((e) => e.id)
        : undefined,
      spendingFilterTag: context?.tagsSelected || undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      spendingFilterService: context?.servicesSelected || undefined,
    };
  }, [
    context.filterMapping,
    context?.filterSelectedValue,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.tagsSelected,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data, isLoading, error } = useSpendBySearch(query);

  const { data: costTrendData } = useCostTrend(query);

  const { data: tagsData } = useSearchResultTags(query);

  const filterData = [service === "azure" ? "Subscription Name" : "Account Name", "Tags", "Resources", "Region"];

  useEffect(() => {
    context?.setFilterSelectedValue(service === "azure" ? "Subscription Name" : "Account Name");
  }, [service]);


  return (
    <>
      <div className={styles.costServiceRow}>
        <div className={styles.costRowCss}>
          <span className={styles.rowTitle}>Spend By</span>
          <DropdownButton
            id={styles.filterServices_button}
            title={context?.filterSelectedValue}
          >
            {filterData.map((data, index) => (
              <Dropdown.Item
                key={index}
                className={styles.dropdown_item}
                as="button"
                onClick={() => {
                  context?.setFilterSelectedValue(data);
                  context?.setFilteredData(data);
                }}
              >
                {data}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <img
            style={{ marginTop: "3px" }}
            src={select}
            width={"6px"}
            height={"10px"}
            alt="icon"
          />
        </div>
        {context?.type === "serviceCategory" && (
          <DropdownButton
            id={styles.dropdown_item_button}
            title={context?.servicesSelected || "Select Service"}
          >
            {(costTrendData?.services || []).map((service, index) => (
              <Dropdown.Item
                key={index}
                className={styles.dropdown_item}
                onClick={() => context?.setServicesSelected(service.service)}
              >
                {service.service}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
        {context?.filterSelectedValue === "Tags" && (
          <DropdownButton
            id={styles.dropdown_item_button}
            title={context?.tagsSelected || "Select Tags"}
          >
            {(tagsData?.tags || []).map((item, index) => (
              <Dropdown.Item
                key={index}
                className={styles.dropdown_item}
                onClick={() => context?.setTagsSelected(item.id)}
              >
                {item.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "16px",
            width: "100%",
          }}
        >
          <VerticalBarGraph />

          <div style={{ width: "40%" }}>
            <SearchTableState />
          </div>
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {data?.spendings.length > 0 && (
        <div className={styles.costServiceGraphContainer}>
          <GraphTableSection
            data1={data}
            service={service}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default CostByServices;
