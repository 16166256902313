import React from "react";
import Navbar from "@/common/Navbar";
import AwsDashboard from "@/page-components/cost-explorer/aws/aws-cost-explorer.page";
import AzureDashboard from "@/page-components/cost-explorer/azure/azure-cost-explorer.page";
import AwsKubernates from "@/components/kubernates/awsKubernates/AwsKubernates";
import AzureKubernates from "@/components/kubernates/azureKubernates/AzureKubernates";
import AllCloudKubernates from "@/components/kubernates/allCloudKubernates/AllCloudKubernates";
import LoginPage from "@/page-components/login/login.page";
import AllCloudRightSizing from "@/page-components/RightSizing/AllCloudRightSizing/AllCloudRightSizing";
import OnWork from "@/common/OnWork";
import AWSRightSizing from "@/page-components/RightSizing/AWSRightSizing/AWSRightSizing";
import AzureScreen from "@/page-components/RightSizing/AzureRightSizing/AzureScreen";
import {
  AuthGuard,
  AuthGuardWithRole,
} from "@/components/auth-guard/auth-guard.component";
import BudgetsPage from "@/page-components/budgets/Aws/budgets.page";
import SnapshotsMainPage from "@/page-components/snapshot/AllCloud/snapshot.page";
import SnapshotPage from "@/page-components/snapshot/Aws/snapShot.page";
import AzureBudgetPage from "@/page-components/budgets/Azure/azureBudget.page";
import LogoutPage from "@/page-components/logout/logout.page";
import AzurePage from "@/page-components/snapshot/Azure/azure.page";
import AllCloudsDashboard from "@/page-components/cost-explorer/all-clouds/all-clouds-cost-explorer.page";
import { Navigate } from "react-router-dom";
import AllCloudSaving from "@/page-components/savings/all-cloud-savings/all-cloud-saving.page";
import AWSCloudSaving from "@/page-components/savings/aws-cloud-savings/aws-cloud-saving.page";
import AzureCloudSaving from "@/page-components/savings/azure-cloud-savings/azure-cloud-saving.page";
import AwsIdleResource from "@/page-components/idle-resource/aws-idle-resource/aws-idle-resource.page";
import AzureIdleResource from "@/page-components/idle-resource/azure-idle-resource/azure-idle-resource.page";
import AllCloudIdleResource from "@/page-components/idle-resource/all-cloud-idle-resource/all-cloud-idle-resource.page";
import AdminLayout from "@/common/AdminLayout";
import Groups from "@/page-components/admin/groups/groups.page";
import Roles from "@/page-components/admin/roles/roles.page";
import Provisions from "@/page-components/admin/privileges/privileges.page";
import UserProfile from "@/page-components/admin/user profile/admin.page";
import { ROLES } from "@/App.context";
import { UsageDetailsComponent } from "@/page-components/cost-explorer/components/usage-details/usage.details.page";
import DetailedBudgetCost from "@/page-components/budgets/components/detailedList";
import AwsDetailedBudgetCost from "@/page-components/budgets/components/awsDetailsPage";
import Azure_RI_Screen from "@/page-components/RI-Capacity/azure-part/azure.screen";
import SearchComponents from "@/page-components/search-module/search-components.page";
import OnWorking from "@/common/OnWorking";
import AzureAiRecommendations from "@/page-components/ai-recommendations/azure-ai-recommend/azure-ai-recommend.page";
import AWSAiRecommendations from "@/page-components/ai-recommendations/aws-ai-recommend/aws-ai-recommendation.page";
import AzureBillingPage from "@/page-components/billing-credit/azure-biling-credit/azure-billing-credit.page";
import AzureRiScreenComponent from "@/page-components/RI-Capacity/azure-part/azure.screen";
import AwsCostPage from "@/page-components/cost-Recommendation/aws/awsCostPage";
import AzureCostPage from "@/page-components/cost-Recommendation/azure/azureCostPage";
import OnWorkallcloud from "@/common/OnWork.allcloud";
import AwsBillingPage from "@/page-components/billing-credit/aws-billing-credit/aws-billing-credit.page";

/**
 * @typedef RoutesData
 * @property {string} path
 * @property {boolean=} redirectToService
 * @property {React.ReactNode=} component
 * @property {React.ReactNode=} outlet
 * @property {React.ReactNode=} wrapper
 */

/** @type {RoutesData[]} */

const routesData = [
  {
    path: "/",
    component: <LoginPage />,
  },
  {
    path: "/logout",
    component: <LogoutPage />,
  },
  {
    path: "/all-clouds/dashboard",
    component: <Navbar />,
    outlet: <AllCloudsDashboard />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/dashboard",
    component: <Navbar />,
    outlet: <AwsDashboard />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/dashboard",
    component: <Navbar />,
    outlet: <AzureDashboard />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/azure/dashboard/:BudgetName/:Id",
    component: <Navbar />,
    outlet: <AzureDashboard />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/aws/dashboard/:BudgetName/:Id",
    component: <Navbar />,
    outlet: <AwsDashboard />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/gcp/dashboard",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/aws/idleResource",
    component: <Navbar />,
    outlet: <AwsIdleResource />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/azure/idleResource",
    component: <Navbar />,
    outlet: <AzureIdleResource />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/all-clouds/idleResource",
    component: <Navbar />,
    outlet: <AllCloudIdleResource />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/idleResource",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/budgets",
    component: <Navbar />,
    outlet: <BudgetsPage />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/azure/budgets",
    component: <Navbar />,
    outlet: <AzureBudgetPage />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  // {
  //   path: "/gcp/budgets",
  //   component: <Navbar />,
  //   outlet: <GCPBudgetsPage />,
  // wrapper: AuthGuard,
  // },
  {
    path: "/all-clouds/budgets",
    component: <Navbar />,
    outlet: <Navigate to="/aws/budgets" replace={true} />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/budgets/:BudgetName/:Id",
    component: <Navbar />,
    outlet: <DetailedBudgetCost service={"azure"} />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/aws/budgets/:BudgetName/:Id",
    component: <Navbar />,
    outlet: <AwsDetailedBudgetCost service={"aws"} />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/gcp/budgets",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/all-clouds/cost-recommendations",
    component: <Navbar />,
    outlet: <OnWorkallcloud />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/cost-recommendations",
    component: <Navbar />,
    outlet: <AwsCostPage service="aws" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/cost-recommendations",
    component: <Navbar />,
    // outlet: <OnWorking name = "Cost-Recommendations"service="Azure" />,
    outlet: <AzureCostPage/>,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/cost-recommendations",
    component: <Navbar />,
    outlet: <OnWorking name="Cost-Recommendations" service="GCP" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/aws/recommendations/:resourceId",
    component: <Navbar />,
    outlet: <AWSRightSizing service="aws" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/recommendations",
    component: <Navbar />,
    outlet: <AWSRightSizing service="aws" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/recommendations",
    component: <Navbar />,
    outlet: <AzureScreen />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/all-clouds/recommendations",
    component: <Navbar />,
    outlet: <AllCloudRightSizing />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/recommendations",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/all-clouds/snapshots",
    component: <Navbar />,
    outlet: <SnapshotsMainPage />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/snapshots",
    component: <Navbar />,
    outlet: <SnapshotPage />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/snapshots",
    component: <Navbar />,
    outlet: <AzurePage />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/snapshots",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/all-clouds/saving",
    component: <Navbar />,
    outlet: <AllCloudSaving />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/saving",
    component: <Navbar />,
    outlet: <AWSCloudSaving service="aws" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/azure/saving",
    component: <Navbar />,
    outlet: <AzureCloudSaving service="aws" />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/saving",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/aws/anomalies",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/azure/anomalies",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/all-clouds/anomalies",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/anomalies",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/kubernetes",
    component: <Navbar />,
    outlet: <AwsKubernates />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  {
    path: "/azure/kubernetes",
    component: <Navbar />,
    outlet: <AzureKubernates />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/all-clouds/kubernetes",
    component: <Navbar />,
    outlet: <AllCloudKubernates />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/gcp/kubernetes",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuard,
    redirectToService: true,
  },

  // {
  //   path: "/aws/reports",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },

  // {
  //   path: "/azure/reports",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/allClouds/reports",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/gcp/reports",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/aws/taggings",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/azure/taggings",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/allClouds/taggings",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  // {
  //   path: "/gcp/taggings",
  //   component: <Navbar />,
  //   outlet: <OnWork />,
  //   wrapper: AuthGuard,
  // },
  {
    path: "/admin",
    component: <AdminLayout />,
    outlet: <Roles />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/admin/groups",
    component: <AdminLayout />,
    outlet: <Groups />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/admin/users",
    component: <AdminLayout />,
    outlet: <Roles />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/admin/privileges",
    component: <AdminLayout />,
    outlet: <Provisions />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/profile",
    component: <Navbar />,
    outlet: <UserProfile />,
    wrapper: AuthGuardWithRole(ROLES.user),
  },
  {
    path: "/aws/details",
    component: <Navbar />,
    outlet: <UsageDetailsComponent />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/azure/details",
    component: <Navbar />,
    outlet: <UsageDetailsComponent />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/all-clouds/ri-recommendations",
    component: <Navbar />,
    outlet: <Navigate to="/azure/ri-recommendations" replace={true} />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/ri-recommendations",
    component: <Navbar />,
    outlet: <OnWorking name="Ri-Recommendations" service="AWS" />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/gcp/ri-recommendations",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/azure/ri-recommendations",
    component: <Navbar />,
    outlet: <AzureRiScreenComponent />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/aws/search",
    component: <Navbar />,
    outlet: <SearchComponents service="aws" />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/azure/search",
    component: <Navbar />,
    outlet: <SearchComponents service="azure" />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/gcp/search",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/all-clouds/ai-recommendations",
    component: <Navbar />,
    outlet: <Navigate to="/azure/ai-recommendations" replace={true} />,
    wrapper: AuthGuard,
    redirectToService: true,
  },
  {
    path: "/aws/ai-recommendations",
    component: <Navbar />,
    outlet: <OnWorking name="AI-Recommendations" service="AWS" />,
    // outlet: <AWSAiRecommendations/>,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/azure/ai-recommendations",
    component: <Navbar />,
    outlet: <AzureAiRecommendations />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/gcp/ai-recommendations",
    component: <Navbar />,
    outlet: <OnWork />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/azure/billing",
    component: <Navbar />,
    outlet: <AzureBillingPage />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/aws/billing",
    component: <Navbar />,
    // outlet: <OnWorking name= "AWS Billing-Credit"/>,
    outlet: <AwsBillingPage/>,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/all-clouds/billing",
    component: <Navbar />,
    outlet: <OnWorking name="Billing-Credit" service="All Cloud"/>,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
  {
    path: "/gcp/billing",
    component: <Navbar />,
    outlet: <OnWorking name="Billing-Credit" service="GCP" />,
    wrapper: AuthGuardWithRole(ROLES.admin),
  },
];

export default routesData;