import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import routesData from "./routes";
import React from "react";
import { RedirectForService } from "@/components/auth-guard/auth-guard.component";

const Myroutes = () => {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Routes>
        {routesData.map(
          ({ redirectToService, path, component, outlet, wrapper }, index) => {
            const WrapperComponent = wrapper ?? React.Fragment;
            return (
              <Route key={path} path={path} element={component}>
                {outlet && (
                  <Route
                    index
                    element={
                      redirectToService ? (
                        <RedirectForService path={path}>
                          <WrapperComponent>{outlet}</WrapperComponent>
                        </RedirectForService>
                      ) : (
                        <WrapperComponent>{outlet}</WrapperComponent>
                      )
                    }
                  />
                )}
              </Route>
            );
          }
        )}
        <Route path="*" element={<p>Not found</p>}></Route>
      </Routes>
    </Router>
  );
};

export default Myroutes;
