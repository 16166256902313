import { useUsersList } from "@/services/admin/Roles/getRolesList.repo";
import React, { useState } from "react";
/**
 * @typedef RolesContextTypeOf
 * @property {string=} createUserModal
 * @property {React.Dispatch<string?>} setCreateUserModal
 * @property {string=} showGroupModal
 * @property {React.Dispatch<string?>} setShowGroupModal
 * @property {boolean} createBudgetModal
 * @property {React.Dispatch<string?>} setCreateBudgetModal
 * @property {boolean} isBudgetInfoModal
 * @property {React.Dispatch<string?>} setIsBudgetInfoModal
 * @property {boolean} isAdminInfoModal
 * @property {React.Dispatch<string?>} setIsAdminInfoModal
 */

/** @type {import("react").Context<RolesContextTypeOf>} */
export const RolesContext = React.createContext();

export const useRolesContext = () => {
  const [createUserModal, setCreateUserModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [createBudgetModal, setCreateBudgetModal] = useState(false);
  const [userDeleteItem, setUserDeleteItem] = useState();
  const [isAdminInfoModal, setIsAdminInfoModal] = useState(false);
  const [isBudgetInfoModal, setIsBudgetInfoModal] = useState(false);

  const handleGroupClose = () => setShowGroupModal(false);
  const handleGroupShow = () => setShowGroupModal(true);

  return {
    setCreateUserModal,
    createUserModal,
    isAdminInfoModal,
    setIsAdminInfoModal,
    handleGroupShow,
    handleGroupClose,
    showGroupModal,
    userDeleteItem,
    setUserDeleteItem,
    createBudgetModal,
    setCreateBudgetModal,
    isBudgetInfoModal,
    setIsBudgetInfoModal,
  };
};

const useApiFetching = () => {
  const [query, setQuery] = useState("");
  const {
    data: rolesData,
    isLoading: loadingRoles,
    refetch,
  } = useUsersList(query);

  return {
    query,
    setQuery,
    refetch,
    rolesData,
    loadingRoles,
  };
};

export const RolesContextProvider = ({ children }) => {
  const context = useRolesContext();
  return (
    <RolesContext.Provider value={context}>{children}</RolesContext.Provider>
  );
};
