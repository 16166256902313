import React, { useContext } from "react";
import styles from "@/page-components/ai-recommendations/ai-recommend.module.css";
import AiRecommendHeader from "../components/ai-header.part";
import {
  AiRecommendContext,
  AiRecommendProvider,
} from "../components/ai-recom-context-file";
import UsagePatternsTable from "../components/usage-patterns-table.part";
import DiskRecommendationsTable from "../components/disk-recommend-table.part";
import FilterSection from "@/page-components/ai-recommendations/components/filter-section/filter-section.part";
import clsx from "clsx";

const AzureAiRecommendations = () => {
  return (
    <AiRecommendProvider>
      <AzureAiPage />
    </AiRecommendProvider>
  );
};

const AzureAiPage = () => {
  const context = useContext(AiRecommendContext);
  const service = "azure";

  return (
    <div className={styles.ai_main_component}>
      <AiRecommendHeader service={service} />
      <div
        className={clsx(styles.css_tables, styles.table_filter)}
        style={{
          padding: context?.hideFilter
            ? "0px 0px 0px 0px"
            : "0px 10px 0px 40px",
        }}
      >
        {context?.eventkey === "Usage Patterns" && (
          <UsagePatternsTable service={service} accountName="Subscription" />
        )}
        {context?.eventkey === "Disk Recommendations" && (
          <DiskRecommendationsTable
            service={service}
            accountName="Subscription"
          />
        )}
        {!context?.hideFilter && (
          <div
            style={{
              width: "18%",
              borderLeft: "1px solid #dee2e6",
              height: "100vh",
              boxShadow: "-1px 0px 0px 0px #0000000A",
            }}
          >
            <FilterSection name="Subscription" service={service} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AzureAiRecommendations;
