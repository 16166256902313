import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
const Piechart = ({mydata}) => {
  return (
    <div className="w-100 h-100">
      <ResponsiveContainer width="100%" height={123}>
        <PieChart>
          <Pie
            data={mydata}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={50}
            fill=""
          >
            {mydata.map((entry, index) => (<>
              <Cell key={`cell-${index}`} fill={entry.color} />   
            </>
            ))}
            
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Piechart