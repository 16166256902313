import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import setWeek from "date-fns/setWeek";
import format from "date-fns/format";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import { useContext } from "react";
import { AppContext } from "@/App.context";
import { parse, isBefore } from "date-fns";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetWidgetById
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property {string} id
 */
export const getWidgetById = (token) => {
  /**
   * @param {GetWidgetById} req
   * @param {AbortSignal=} signal
   * @returns {Promise<Record<string, string | number>[]>}
   */
  return async ({ service, id }, signal) => {
    try {
      const fromDateIns = new Date();
      const tableData = await fetch(
        `${config.apiHost}/v1/${service}/cost/widget?id=${id}`,
        {
          method: "GET",
          signal,
          headers: {
            Authorization: token,
          },
        }
      );
      if (!tableData.ok) {
        if (ERROR_STATUS_CODES.includes(tableData.status)) {
          throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
        }
        if (tableData.status === 404) {
          throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
        }
        throw new Error((await tableData.json()).message);
      }

      const data = await tableData.json();

      // if (data?.widget.length === 0) {
      //   throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
      // }

      let dimension = data.widgetMetaData?.dimension;
      if (!dimension.length) {
        dimension = data.widget.length
          ? Object.keys(data.widget[0]).filter((e) => {
              return (
                e !== "granularity" || "costInBillingCurrency" || "total_cost"
              );
            })[0]
          : "";
      }
      const granularity = data.widgetMetaData.Granularity;
      const [serviceMap, total, dateSet] = data.widget.reduce(
        ([map, total, dateSet], cur) => {
          let obj = {};
          if (map.has(cur[dimension])) {
            obj = map.get(cur[dimension]);
          }
          let key = cur.usage_start_date || cur.date || cur.granularity;
          if (granularity === "date") {
            key = format(new Date(key), "dd-MM-yyyy");
          } else if (granularity === "week") {
            const week = setWeek(fromDateIns, cur.granularity + 1);
            key =
              format(startOfWeek(week), "dd LLL") +
              " - " +
              format(endOfWeek(week), "dd LLL");
          }
          dateSet.add(key);
          obj[key] = cur.total_cost || cur.costInBillingCurrency;
          total += cur.total_cost || cur.costInBillingCurrency || 0;
          map.set(cur[dimension], obj);
          return [map, total, dateSet];
        },
        [new Map(), 0, new Set()]
      );

      const graph = Array.from(serviceMap.keys()).map((e) => ({
        Service: e,
        ...(serviceMap?.get(e) || {}),
      }));

      return {
        widget: data.widgetMetaData,
        data: graph,
        total,
        selectedDimension: dimension,
        granularity,
        dates: Array.from(dateSet).sort((a, b) => {
          if (granularity === "date") {
            a = parse(a, "dd-MM-yyyy", new Date());
            b = parse(b, "dd-MM-yyyy", new Date());
            if (isBefore(a, b)) {
              return -1;
            } else if (isBefore(b, a)) {
              return 1;
            }
            return 0;
          } else if (granularity === "week") {
            return 0;
          } else {
            return 0;
          }
        }),
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

/**
 * @param {GetWidgetById} req
 * @param {any[]=} deps
 */
export const useGetWigdetById = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getWidgetById", req, req, appContext.idToken, ...deps],
    queryFn: ({ signal }) => getWidgetById(appContext.idToken)(req, signal),
  });
  return query;
};
