import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "../../snapshots.module.css";
import { InputFields, PrevHistoryContainers } from "./helper";
import { useForm, Controller } from "react-hook-form";
import  Joi  from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import HistoryModal from "./historyModal";

/** @typedef {import('@/services/snapshots/addAcceptFields.repo').UpdateAcceptRecommendationsReq} UpdateAcceptRecommendationsReq */


/**
 * @typedef IAcceptStatusReq
 * @property {string} ticket_id
 * @property {string} ticket_url
 * @property {string} comment
 */

const AcceptStatusReq = Joi.object({
  comment: Joi.string(),
  ticket_id: Joi.string(),
  ticket_url: Joi.string(),
});

/**
 * @callback AcceptStatusCallback
 * @param {IAcceptStatusReq} values
 * @returns {void}
 */

/**
 * @typedef IAcceptComponentProps
 * @property {AcceptStatusCallback} onSubmit
 * @property {boolean} enable
 * @property {number} checkHistory
 * @property {VoidFunction} disable
 */

/**
 * @param {IAcceptComponentProps} props
 * @returns
 */
const DiscardedModal = (props) => {
  const [enableHistoryModal, setEnableHistoryModal] = useState(false);

  /** @type {import('react-hook-form').UseFormReturn<UpdateAcceptRecommendationsReq>} */
  const formData = useForm({
    resolver: joiResolver(AcceptStatusReq),
    defaultValues: {},
  });

  // useEffect(() => {
  //   formData?.setValue("resourceId", props?.resourceId);
  // });

  const handleCancel = () => {
    props?.disable();
    formData.reset();
    setEnableHistoryModal(false);
  };

  useEffect(() => {
    if (props?.data) {
      formData.resetField("comment","");
      props?.disable();
    }
    if (props?.isErrorArchive) {
      formData.resetField("comment","");
    }
  }, [props?.data, formData.reset, props?.isErrorArchive]);

  const handleCloseforModals = () => {
    props?.disable();
    setEnableHistoryModal(false);
  };

  return (
    <Modal
      show={props?.enable}
      onHide={handleCloseforModals}
      contentClassName={styles.modalContent}
      centered
      size={enableHistoryModal ? "lg" : "m"}
      backdrop="static"
    >
      <form
        onSubmit={formData.handleSubmit((data) => {
          props.onSubmit({
            comment: data.comment,
            ticket_id: data.ticket_id,
            ticket_url: data.ticket_url,
          });
        })}
        className={styles.contentContainer}
        style={{ width: enableHistoryModal ? "400px" : "100%" }}
      >
        <div className={styles.headerSection}>
          <p className={styles.tittle}>Discard {props?.name} Recommendation</p>
          <p className={styles.tagline}>
            Add a comment to discard the recommendation.{" "}
          </p>
        </div>

        <div style={{ width: "100%" }}>
          <Controller
            name="comment"
            control={formData.control}
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
              <InputFields
                tittle={"Comment"}
                symbol={"*"}
                placeholder={"Add the Comment"}
                {...field}
                textValue={field.value}
                textChange={field.onChange}
              />
            )}
          />
          {formData?.formState?.errors?.comment && (
            <p className={styles.errorMessage}>
              {formData?.formState?.errors?.comment?.message}
            </p>
          )}
        </div>
        {/* </div> */}
        <div className={styles.buttonsContainer}>
          <button
            id={styles.AcceptId}
            className={styles.cancleButton}
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            id={styles.AcceptId}
            className={styles.submitButton}
            type="submit"
          >
            Submit
          </button>
        </div>
        {props?.checkHistory > 0 && (
          <span
            className={styles.recHistoryTittle}
            onClick={() => setEnableHistoryModal(!enableHistoryModal)}
          >
            {enableHistoryModal
              ? "Hide Recommendation History >"
              : "View Recommendation History >"}
          </span>
        )}
      </form>
      {enableHistoryModal && props?.savingid != null && (
        <HistoryModal historyData={props?.historyData} />
      )}
    </Modal>
  );
};

export default DiscardedModal;
