import React from "react";
import styles from "@/page-components/RI-Capacity/ri.module.css";

const BulkYearComponent = ({service,data}) => {

  const maxSavingsPerMonth = Math.max(
    ...(data?.recommendations || []).map((i) =>
      parseInt(i?.monthly_saving || 0)
    )
  );

  return (
    <div className={styles.models_data}>
      {(data?.recommendations || []).map((model, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
            paddingTop: "12px",
            borderRadius: "8px",
          }}
          key={index}
          className={`${styles.model} ${
            parseInt(model?.monthly_saving) === maxSavingsPerMonth
              ? styles.green
              : styles.gray
          }`}
        >
          <span className={styles.year_modal}>
            {model?.year_model} YEAR MODEL
          </span>
          <span className={styles.border_bottom}>
            ${Number(model?.cost).toFixed(2) || 0}
          </span>
          <span className={styles.border_bottom}>
            ${Number(model?.monthly_cost).toFixed(2) || 0}
          </span>
          <span className={styles.border_bottom}>
            {Number(model?.break_even || 0).toFixed(2)}
          </span>
          <span className={styles.border_bottom}>
            {Number(model?.savings_vs_ondemand_percent || 0).toFixed(2)}%
          </span>
          <div
            className={styles.saving_month}
            style={{
              backgroundColor:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#3DA64F"
                  : "#E9ECEF",
              color:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#FFFFFF"
                  : "#343A40",
              borderRadius: "0px 0px 8px 8px",
              padding: "10px 12px",
            }}
          >
            <span className={styles.saving_name}>Savings Per Month</span>
            <span className={styles.saving_value}>
              {model?.monthly_saving
                ? "$" + Number(model?.monthly_saving || 0).toFixed(2)
                : "--"}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BulkYearComponent;
