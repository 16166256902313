import React, { useContext, useMemo, useState } from "react";
import styles from "@/page-components/cost-explorer/components/filter-section/filter-section.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import clsx from "clsx";
import { FilterContext } from "../filter-section/filter-section.component";
import { useForecastedAccounts } from "@/services/costExplorer/getForecastAccountFilter.repo";
import FilterState from "@/components/States/FilterState";

const ForecastFilterSection = ({ service, name }) => {
  const filterContext = useContext(FilterContext);
  const query = useMemo(() => {
    return {
      service: service,
    };
  }, [service]);
  const { data, isLoading, error } = useForecastedAccounts(query);
  const [selectedTittle, setSelectedTittle] = useState(null);

  return (
    <>
      {isLoading && <FilterState />}
      {(data || error) && (
        <div className={styles.filter_main_div}>
          <Filter
            tittle={name}
            setSelectedTittle={setSelectedTittle}
            data={data?.data}
            filterType="account"
            selectedValues={filterContext.selectForecastAccount}
            onSelect={(list) => {
              filterContext?.setSelectForecastAccount(list);
            }}
            onRemove={(list) => {
              filterContext?.setSelectForecastAccount(list);
            }}
          />
        </div>
      )}
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const filterContext = useContext(FilterContext);
  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      filterContext.setSelectForecastAccount(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription Name" || tittle === "Account Name") {
      title = "Account";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  return (
    <>
      <div className={clsx(styles.filter_padding_div)}>
        <div className={clsx(styles.dimension_div)}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={clsx(styles.filter_group_name_css)}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.account_id,
                  name: e.account_name,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForecastFilterSection;
