/** @type {import('@/global')} */
import React, { useMemo, useContext, useEffect, useState } from "react";
import cpulogo from "@/assets/img/cpulogo.svg";
import disklogo from "@/assets/img/Disklogo.svg";
import memorylogo from "@/assets/img/memorylogo.svg";
import downArrow from "@/assets/img/GreenGroup.svg";
import upArrow from "@/assets/img/RedGroup.svg";
import terminateArrow from "@/assets/img/TerminateGroup.svg";
import "@/assets/css/right-sizing-table.css";
import { useRecommendationsList } from "@/services/right-sizing/getRecommendationsList.repo";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { RightSizingContext } from "../../right-sizing.context";
import RightSizingModal from "./RightSizingModal";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@/components/pagination/pagination.component";
import TableErrorState from "@/components/States/TableErrorState";
import DataLoading from "@/components/States/DataLoading";
import UpSizeVMRecommendations from "@/components/States/UpSizeVMRecommendations";
import ToastComponent from "@/page-components/budgets/components/toast";
import copyicon from "@/assets/img/copyicon.svg";
import { SlOptionsVertical } from "react-icons/sl";
import AcceptModalComponent from "@/page-components/snapshot/components/state_change/AcceptModal2";
import ArchiveModal from "@/page-components/snapshot/components/state_change/archiveModal";
import DiscardedModal from "@/page-components/snapshot/components/state_change/discardModal";
import { useGetHistory } from "@/services/right-sizing/getVmHistory.repo";
import { useUpdateAcceptRecommendation } from "@/services/right-sizing/addAcceptStateField.repo";
import sorting from "@/assets/img/sorting.svg";
import { useDiscardedTable } from "@/services/right-sizing/getDiscardedTable.repo";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

// import ellipse from "@/assets/img/status_ellipse.svg"
// import pending_status from "@/assets/img/status_pending.svg"

const RightSizingTable = ({ service, name, refreshHeaders, resource }) => {
  const context = useContext(RightSizingContext);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const resourceId = searchParams.get("resourceId");
  const [savingsId, setSavingsId] = useState(null);
  const [enableAcceptModal, setEnableAcceptModal] = useState(false);
  const [enableDiscardModal, setEnableDiscardModal] = useState(false);
  const [enableArchiveModal, setEnableArchiveModal] = useState(false);
  const [resourceid, setResourceId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [projectedType, setProjectedType] = useState(null);
  const [orderBy, setOrderBy] = useState("region");
  const desc = searchParams.get("desc");

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleCopyClick = (event, resourceId) => {
    event.stopPropagation();
    const textarea = document.createElement("textarea");
    textarea.value = resourceId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(resourceId);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      recommendationType: context?.recommendationType || "downsize",
      orderBy: orderBy || "region",
      desc: searchParams.get("desc") || "true",
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        resourceGroupName:
          context?.selectedResourceGroup?.length > 0 &&
          context?.selectedResourceGroup?.length
            ? context.selectedResourceGroup.map((e) => e.id)
            : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context?.selectedTags,
    context?.recommendationType,
    context.selectedAccounts,
    context.selectedResourceGroup,
    context.selectedRegions,
    service,
    parsedPage,
    parsedItemsPerPage,
    orderBy,
    searchParams,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refetchActiveTable,
  } = useRecommendationsList(query);

  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    error: acceptDismissError,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: resourceid,
      currentType: currentType,
      projectedType: projectedType,
    };
  }, [service, resourceid, currentType, projectedType]);

  const { data: historyData } = useGetHistory(queryParam);

  const discardquery = useMemo(() => {
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
    };
  }, [parsedItemsPerPage, parsedPage, service]);

  const { refetch: refetchDiscarded } = useDiscardedTable(discardquery);

  /**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */

  const handleModal = (
    resourceId,
    snapshotId,
    history,
    projected_type,
    current_type
  ) => {
    setEnableAcceptModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };

  const disableModal = () => {
    setEnableAcceptModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleDiscardModal = (
    resourceId,
    snapshotId,
    history,
    projected_type,
    current_type
  ) => {
    setEnableDiscardModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };

  const handleDiscardedModalClose = () => {
    setEnableDiscardModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleArchiveModal = (
    resourceId,
    snapshotId,
    history,
    projected_type,
    current_type
  ) => {
    setEnableArchiveModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
    setProjectedType(projected_type);
    setCurrentType(current_type);
  };

  const handleArchiveModalClose = () => {
    setEnableArchiveModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };
  useEffect(() => {
    if (updateData) {
      refetchActiveTable();
      refreshHeaders();
      refetchDiscarded();
    }
  }, [refetchActiveTable, refetchDiscarded, refreshHeaders, updateData]);

  /**
   * @param {string} resourceId
   */

  const showRecommendationModal = (resourceId) => {
    searchParams.set("resourceId", resourceId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (acceptDismissError) {
      setMessage({
        message: acceptDismissError?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissError, setMessage]);

  const invalidStatus = (status) => {
    switch (status) {
      case "done":
      case "archived":
      case "open":
      case "inprogress":
      case "accepted":
      case "discarded":
        return false;
      default:
        return true;
    }
  };

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      {isLoading && (
        <div className={styles.wrapper}>
          <TableErrorState />
        </div>
      )}
      {data?.recommendations.length === 0 && (
        <div className={styles.wrapper}>
          <UpSizeVMRecommendations
            name={
              query.recommendationType === "downsize" ? "Downsize" : "Upsize"
            }
          />
        </div>
      )}
       {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}

      {data?.recommendations.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.scroll}>
              <table
                id="customers"
                className={clsx(styles.scrollable, "table-hover")}
              >
                <thead>
                  <tr>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      {name}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    {service === "azure" && (
                      <>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Name
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceName")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          {resource}
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceId")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource Group
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() =>
                              handleOrderByClick("resourceGroupName")
                            }
                          ></img>
                        </th>
                      </>
                    )}
                    {service === "aws" && (
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        {resource}
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("account")}
                        ></img>
                      </th>
                    )}
                    <th className={styles.tableHead} style={{ width: "11%" }}>
                      Region
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "14%" }}>
                      Utilization
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "15%" }}>
                      Owner
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "7%" }}>
                      Current Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("currentType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "20%" }}>
                      Recommended Type
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("recommendedType")}
                      ></img>
                    </th>
                    <th className={styles.tableHead} style={{ width: "8%" }}>
                      Cost
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("cost")}
                      ></img>
                    </th>
                    <th
                      className={styles.tableHead}
                      style={{ width: "10%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={9}>
                        <div
                          style={{
                            width: "100%",
                            marginBottom: 40,
                            marginTop: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                            style={{ top: "50" }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}

                  {!isLoading &&
                    !error &&
                    (data?.recommendations || []).length === 0 && (
                      <tr>
                        <td colSpan={9}>
                          <p className="text-center my-3">
                            No records to display
                          </p>
                        </td>
                      </tr>
                    )}

                  {!isLoading && error && (
                    <tr>
                      <td colSpan={9}>
                        <p className="text-center my-3">{error.message}</p>
                      </td>
                    </tr>
                  )}

                  {(data?.recommendations || []).map((item, i) => (
                    <tr
                      id={styles.table_body_tr}
                      className={clsx(styles.accountCol, {
                        [styles.hovered]: i === hoveredRowIndex,
                      })}
                      key={item.resourceId + "-" + i}
                      onClick={() => {
                        searchParams.set("resourceId", item.resourceId);
                        setSearchParams(searchParams);
                      }}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData,
                          styles.my_white_space
                        )}
                      >
                        <span title={item.account}>{item.account}</span>
                      </td>
                      {service === "azure" && (
                        <>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resourceName}>
                              {item.resourceName || "NULL"}
                            </span>
                          </td>

                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={(event) =>
                                handleCopyClick(event, item?.resourceId)
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item.resourceId}>
                              {item?.resourceGroupName || "Null"}
                            </span>
                          </td>
                        </>
                      )}
                      {service === "aws" && (
                        <>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={(event) =>
                                handleCopyClick(event, item?.resourceId)
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                        </>
                      )}
                      {/* <td
                        className={clsx(
                          styles.accountCol,
                          styles.accountColNowrap,
                          styles.accountTitleCol,
                          styles.accountTitleColData
                        )}
                      >
                        <span title={item.resourceId}>
                          {item?.resourceGroupName || "Null"}
                        </span>
                      </td> */}
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item?.region || "Null"}
                      </td>
                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        <div className={styles.percentageBadgeWrapper}>
                          <PercentBadge
                            type="cpu"
                            value={item?.utilization_cpu}
                          />
                          <PercentBadge
                            type="mem"
                            value={item?.utilization_mem}
                          />
                        </div>
                      </td>

                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item?.owner || "--"}
                      </td>

                      <td
                        className={clsx(
                          styles.accountCol,
                          styles.my_white_space
                        )}
                      >
                        {item?.currentType || "--"}
                      </td>

                      <td className={styles.accountCol}>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          {item?.recommendationType === "upsize" && (
                            <img
                              src={upArrow}
                              alt=""
                              className={styles.upArrow}
                            />
                          )}
                          {item?.recommendationType === "downsize" && (
                            <img
                              src={downArrow}
                              alt=""
                              className={styles.downArrow}
                            />
                          )}
                          {item.recommendationType === "terminate" && (
                            <img src={terminateArrow} alt="" />
                          )}
                          <span className={styles.accountCol1}>
                            {item?.recommendedType || "--"}
                          </span>
                        </span>
                      </td>
                      <td className={styles.accountCol}>
                        ${item.cost?.toFixed(2) || "--"}
                      </td>
                      <td style={{ width: "100px" }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.fixed}>
                <table
                  id="customers"
                  style={{ width: "100%" }}
                  className="table-hover"
                >
                  <thead>
                    <tr>
                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                      >
                        Savings
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("savings")}
                        ></img>
                      </th>
                      {/* <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_end
                        )}
                      >
                        RI Savings
                      </th> */}
                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                        style={{
                          textAlign: "left",
                          paddingRight: "10px",
                        }}
                      >
                        Status
                      </th>
                      <th className={styles.tableHead}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan={3}></td>
                      </tr>
                    )}

                    {!isLoading &&
                      (data?.recommendations || []).length === 0 && (
                        <tr>
                          <td colSpan={3}> </td>
                        </tr>
                      )}

                    {!isLoading &&
                      (data?.recommendations || []).map((item, i) => (
                        <tr
                          style={{ position: "relative" }}
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={item.resourceId + "-" + i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            style={{ textAlign: "center" }}
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,
                              styles.accountCol_center,
                              Number(item?.savings || 0) < 0 &&
                                styles.accountCol_red,
                              Number(item?.savings || 0) > 0 &&
                                styles.accountCol_green
                            )}
                            onClick={() =>
                              showRecommendationModal(item.resourceId)
                            }
                          >
                            ${(item.savings || 0).toFixed(2)}
                          </td>
                          {/* <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountCol_weight,
                              styles.accountCol_end,
                              Number(item?.riSavings || 0) < 0 &&
                                styles.accountCol_red,
                              Number(item?.riSavings || 0) >= 0 &&
                                styles.accountCol_green
                            )}
                            onClick={() => {}}
                          >
                            ${(item.riSavings || 0).toFixed(2)}
                          </td> */}

                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight,
                              styles.snapshot_col_end
                            )}
                            style={{
                              textAlign: "left",
                              paddingLeft: "8px",
                            }}
                          >
                            {item?.status == "open" && (
                              <span className={styles.badgeName}> Open</span>
                            )}
                            {item?.status == "accepted" && (
                              <span className={styles.badgeNamePending}>
                                Pending Approval
                              </span>
                            )}
                            {item?.status == "inprogress" && (
                              <span className={styles.badgeNameProgress}>
                                In Progress
                              </span>
                            )}
                            {item?.status == "done" && "Done"}
                            {invalidStatus(item?.status) && (
                              <span className={styles.badgeName}>
                                {item?.status}
                              </span>
                            )}
                            {/* </span> */}
                          </td>
                          {!invalidStatus(item?.status) && (
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                styles.snapshot_col_weight
                              )}
                            >
                              <Dropdown
                                style={{ marginTop: "-6px" }}
                                show={dropdownOpen[i]}
                                onToggle={() => handleDropdownToggle(i)}
                              >
                                <Dropdown.Toggle
                                  className={styles.dropdown_toggle}
                                  variant="link"
                                  id={`dropdown-basic-${i}`}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: 0,
                                    marginTop: "5px",
                                  }}
                                >
                                  <SlOptionsVertical />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  id={styles.dropdown_menu_item}
                                  style={{
                                    border: "none",
                                    boxShadow: "0 19px 14px rgba(0, 0, 0, 0.1)",
                                    background: "#fff",
                                  }}
                                >
                                  {item?.status == "open" && (
                                    <>
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() => {
                                          handleModal(
                                            item?.resourceId,
                                            item?.resourceId,
                                            item?.history_count,
                                            item?.recommendedType,
                                            item?.currentType
                                          );
                                        }}
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          Accept
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() => {
                                          handleArchiveModal(
                                            item?.resourceId,
                                            item?.resourceId,
                                            item?.history_count,
                                            item?.recommendedType,
                                            item?.currentType
                                          );
                                        }}
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          Archive
                                        </span>
                                      </Dropdown.Item>
                                    </>
                                  )}

                                  {item?.status == "accepted" && (
                                    <>
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() => {
                                          acceptMutate({
                                            resourceId: item?.resourceId,
                                            status: "inprogress",
                                            service: service,
                                            projected_type:
                                              item?.recommendedType,
                                            current_type: item?.currentType,
                                          });
                                        }}
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          Approve
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() =>
                                          handleDiscardModal(
                                            item?.resourceId,
                                            item?.resourceId,
                                            item?.history_count,
                                            item?.recommendedType,
                                            item?.currentType
                                          )
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "#D01A1A",
                                            fontWeight: "500",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Discard
                                        </span>
                                      </Dropdown.Item>
                                    </>
                                  )}

                                  {item?.status == "inprogress" && (
                                    <Dropdown.Item
                                      id={styles.dropdown_item}
                                      onClick={() => {
                                        acceptMutate({
                                          resourceId: item?.resourceId,
                                          status: "done",
                                          service: service,
                                          projected_type: item?.recommendedType,
                                          current_type: item?.currentType,
                                        });
                                      }}
                                    >
                                      <span
                                        className={styles.dropdown_names_css}
                                      >
                                        Done
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <Pagination totalItems={data?.total || 0} />
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </div>
      )}

      {/**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */}

      <AcceptModalComponent
        enable={enableAcceptModal}
        disable={disableModal}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        name="VM"
        onSubmit={(data) => {
          acceptMutate({
            current_type: currentType,
            projected_type: projectedType,
            resourceId: resourceid,
            service,
            status: "accepted",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
      <ArchiveModal
        enable={enableArchiveModal}
        // resourceId={resourceid}
        // service={service}
        disable={handleArchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        // projectedType={projectedType}
        // currentType={currentType}
        name="VM"
        onSubmit={(data) => {
          acceptMutate({
            current_type: currentType,
            projected_type: projectedType,
            resourceId: resourceid,
            service,
            status: "archived",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
      <DiscardedModal
        enable={enableDiscardModal}
        disable={handleDiscardedModalClose}
        // service={service}
        // resourceId={resourceid}
        mutate={acceptMutate}
        data={updateData}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        name="VM"
        // projectedType={projectedType}
        // currentType={currentType}
        onSubmit={(data) => {
          acceptMutate({
            current_type: currentType,
            projected_type: projectedType,
            resourceId: resourceid,
            service,
            status: "discarded",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />

      <Offcanvas
        placement="end"
        show={resourceId}
        onHide={() => {
          searchParams.delete("resourceId");
          setSearchParams(searchParams);
        }}
        style={{
          width: 884,
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            width: 884,
          }}
        >
          <RightSizingModal resourceId={resourceId} service={service} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

/**
 * @typedef PercentBadgeProps
 * @property {number} value
 * @property {'cpu' | 'mem' | 'disc'} type
 */

/**
 * @param {PercentBadgeProps} props
 * @returns {import("react").ReactNode}
 */
const PercentBadge = ({ value, type }) => {
  const img = useMemo(() => {
    switch (type) {
      case "cpu":
        return cpulogo;
      case "mem":
        return memorylogo;
      case "disc":
        return disklogo;
      default:
        return null;
    }
  }, [type]);

  return (
    <span className={styles.percentageBadgeCont}>
      {img && (
        <img
          src={img}
          width="16px"
          height="16px"
          style={{ marginRight: "6px" }}
          alt=""
        />
      )}

      <span
        className={clsx(styles.badge, {
          [styles.badgeOk]: value < 50 && value >= 0,
          [styles.badgeWarning]: value >= 50 && value < 80,
          [styles.badgeDanger]: value >= 80,
          [styles.badgeNA]: value === undefined || value === null,
        })}
      >
        {(value === undefined || value === null) && "--"}
        {value !== undefined &&
          value !== null &&
          Number(value).toFixed(1) + "%"}
      </span>
    </span>
  );
};

export default RightSizingTable;
