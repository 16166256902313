import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef DeleteWidgetReq
 * @property {string} service
 * @property {string} widgetId
 */

export const deleteWidget = (token) => {
  /**
   * @param {DeleteWidgetReq} req
   * @returns {Promise<string>}
   */
  return async ({ service, widgetId }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost/widget?id=${widgetId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    await owners.json();
    return widgetId;
  };
};

/**
 * @param {any[]=} deps
 * @returns
 */
export const useDeleteWidget = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutate = useMutation({
    mutationKey: ["deleteWidget", ...deps],
    mutationFn: deleteWidget(appContext.idToken),
  });
  return mutate;
};
