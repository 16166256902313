import React from 'react'
import menubutton from '@/assets/img/skeleton-menu-dot.svg';
import circle from '@/assets/img/Group 2310.svg';
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";

const BudgetsErrorStates = () => {
   

  return (
    <div style={{margin:"16px 40px"}}>
        
      
        <Placeholder as="p" animation="glow">
            <Placeholder  className={styles.placeholder_css} style={{ height: "25px",marginBottom:"8px" }} xs={5}/>
        <div className=" d-flex flex-column gap-2" style={{marginBottom:"20px"}}>
          <Row >
            <Col xs={11}>
              <Placeholder
                className={styles.placeholder_css}
                xs={9}
                style={{ height: "25px" }}
              />
            </Col>
            <Col xs={1}>
              <Placeholder
                className={styles.placeholder_css}
                xs={1}
                style={{ height: "25px", width: "100%" }}
              />
            </Col>
          </Row>
          </div>
      
       <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
      {[...Array(4)].map((_, index) => (
        <BudgetSkeleton key={index} />
      ))}
    </div>
      </Placeholder>
    </div>
  )
}

export default BudgetsErrorStates


const BudgetSkeleton =(index)=>{
    return(
         <div className='container p-0'>
        <div className={styles.skeleton_container }>
        <div className={styles.skeleton_header}>
        
          <Placeholder  className={styles.placeholder_css} style={{ height: "20px",marginBottom:"8px", width:"80%" }} />
          <img style={{ height: '16px' }} src={menubutton} alt='' />
        </div>
        <div className={styles.sec_container}>
          <Stack direction="vertical" gap={1}>
          <Placeholder  className={styles.placeholder_css} style={{ height: "20px",marginBottom:"8px", width:"40%" }} />
          <Placeholder  className={styles.placeholder_css} style={{ height: "36px",marginBottom:"8px", width:"75%" }} />
          <Placeholder  className={styles.placeholder_css} style={{ height: "18px",marginBottom:"8px", width:"88%" }} />
          </Stack>
          <img src={circle} alt='' />
        </div>
        <Placeholder className={styles.third_container}>
       
          <Stack direction="vertical" gap={0} >
          <Placeholder  style={{ height: "20px",marginBottom:"8px", width:"60%" ,background:"#FFF"}} />
          <Placeholder  style={{ height: "20px",marginBottom:"8px", width:"70%" ,background:"#FFF"}} />
          <Placeholder  style={{ height: "8px",marginBottom:"8px", width:"100%" ,background:"#FFF"}} />
          </Stack>
        <Row>
         <Col xs={8}> <Placeholder  style={{ height: "16px",marginBottom:"8px", width:"40%" ,background:"#FFF", marginRight:"25px"}} /></Col>
         <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"80%" ,background:"#FFF"}} /></Col>
          </Row>
        </Placeholder>
        <Placeholder className={styles.third_container}>
        <Row>
          <Col xs={8}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"30%" ,background:"#FFF"}} /></Col>
          <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"80%" ,background:"#FFF"}} /></Col>
          </Row>
          <Row>
          <Col xs={8}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"55%" ,background:"#FFF"}} /></Col>
          <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"80%" ,background:"#FFF"}} /></Col>
          </Row>
          <Row>
          <Col xs={8}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"70%" ,background:"#FFF"}} /></Col>
          <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"90%" ,background:"#FFF"}} /></Col>
          </Row>
          <Row>
          <Col xs={8}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"45%" ,background:"#FFF"}} /></Col>
          <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"85%" ,background:"#FFF"}} /></Col>
          </Row>
          <Row>
          <Col xs={8}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"65%" ,background:"#FFF"}} /></Col>
          <Col xs={4}><Placeholder  style={{ height: "16px",marginBottom:"8px", width:"80%" ,background:"#FFF"}} /></Col>
          </Row>
        </Placeholder>
       
      </div>
      </div>
    )
}