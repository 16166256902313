import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";

const SearchTableState = () => {
  return (
    <div>
      <Table style={{ borderBottom: "1px solid #fff" }}>
        <thead>
          <tr>
          </tr>
        </thead>
        <tbody>
          {[...Array(6)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <Placeholder
                  style={{ width: "100%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              <td style={{textAlign:"right"}}>
                <Placeholder
                  style={{ width: "20%", height: 20 }}
                  className={styles.placeholder_css}
                />
              </td>
              
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SearchTableState;
