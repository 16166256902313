import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef Groups
 * @property {string} name
 * @property {string} description
 * @property {number} privileges
 * @property {number} users
 * @property {string} createdBy
 * @property {date} createdAt
 * @property {string} id
 */

/**
 * @typedef GetGroupsListRes
 * @property {Groups[]} groups
 * @property {number} total
 */

/**
 * @typedef GetGroupsListReq
 * @property {number} page
 * @property {number} limit
 * @property {string} filterQuery
 */

export const getGroupsList = (token) => {
  /**
   * @param {GetGroupsListReq} req
   * @returns {Promise<GetGroupsListRes>}
   */
  return async ({ page, limit , filterQuery}, signal) => {
    const groupsList = await fetch(
      `${config.apiHost}/v1/groups/get-all`,
      {
        method: "POST",
        body: JSON.stringify({
          page,
          limit,
          filterQuery
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!groupsList.ok) {
      throw new Error((await groupsList.json()).message);
    }
    var result= await groupsList.json();
    return result;
  };
};

/**
 * @param {GetGroupsListReq} req
 * @param {any[]=} deps
 */
export const useGroupsList = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getGroupsList", req, ...deps],
    queryFn: ({ signal }) => getGroupsList(appContext.idToken)(req, signal),
  });
  return query;
};
