import React from "react";
import styles from './emptycustomwidget.module.css';
import cremove from "@/assets/img/c-remove 2.svg";
import { Button } from "react-bootstrap";
import clsx from "clsx";

const SkeletonWidget = ({ onClick }) => {
  return (
    <div className={styles.mn_container}>
      <div className={styles.first_container}>
        <div className={clsx(styles.first_div ,"col-sm-12 col-md-12 ")}>
          <div className={styles.one_cont}></div>
          <div className={styles.sec_cont}></div>
        </div>
        <div className="">
          <div className={clsx(styles.second_div, "col-sm-12 col-md-12 ")}></div>
        </div>
      </div>

      {/* Graph-Div */}

      <div className="" style={{ flex: "1 1 0", position: "relative" }}>
        <div className={styles.horizontal_line_one }></div>
        <div className={styles.horizontal_line_two}></div>
        <div className={styles.horizontal_line_three}></div>
        <div className={styles.horizontal_line_four}></div>
        <div className={styles.horizontal_line_five}></div>
        <div className={styles.horizontal_line_six}></div>
        <div className={styles.vertical_line_one}></div>
        <div className={styles.vertical_line_two}></div>
        <div className={styles.vertical_line_three}></div>
        <div className={styles.vertical_line_four}></div>
        <div className={styles.vertical_line_five}></div>
        <div className={styles.vertical_line_six}></div>
        <div className={styles.vertical_line_seven}></div>
        <div className={styles.vertical_line_eight}></div>
        <div className={styles.vertical_line_nine}></div>
        <div className={styles.left_bar_one}></div>
        <div className={styles.left_bar_two}></div>
        <div className={styles.left_bar_three}></div>
        <div className={styles.left_bar_four}></div>
        <div className={styles.left_bar_five}></div>
        <div className={styles.left_bar_six}></div>
        <div className={styles.right_bar_one}></div>
        <div className={styles.right_bar_two}></div>
        <div className={styles.right_bar_three}></div>
        <div className={styles.right_bar_four}></div>
        <div className={styles.right_bar_five}></div>
        <div className={styles.right_bar_six}></div>
      </div>

      <button className={styles.butoon_first__Cont} onClick={onClick}>
        <span>
          <img src={cremove} className={styles.image} alt="removeCross" />
        </span>
        <span  className={styles.button_text}>Add New Widget</span>
      </button>
    </div>
  );
};

export default SkeletonWidget;
