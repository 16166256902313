import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef Group
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {string} createdBy
 * @property {date} createdAt
 * @property {number} id
 */

/**
 * @typedef GetPrivileges
 * @property {string} name
 * @property {string} description
 * @property {number} id
 * @property {number} permission
 * @property {date} createdAt
 * @property {date} updatedAt
 */

/**
 * @typedef GetUsers
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {date} createdAt
 * @property {string} roles
 */

/**
 * @typedef GetGroupsByIdRes
 * @property {Group} group
 * @property {GetPrivileges[]} privileges
 * @property {GetUsers[]} users
 */

export const getGroupsById = (token) => {
  /**
   * @param {AbortSignal} signal
   * @param {number} id
   * @returns {Promise<GetGroupsByIdRes>}
   */
  return async (id, signal) => {
    const groups = await fetch(`${config.apiHost}/v1/groups/${id}/get-group`, {
      method: "GET",
      signal,
      headers: {
        Authorization: token,
      },
    });
    if (!groups.ok) {
      throw new Error((await groups.json()).message);
    }
    return await groups.json();
  };
};

/**
 * @param id
 * @param {any[]=} deps
 */
export const useGroupsById = (id) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    enabled: Boolean(id),
    queryKey: ["getGroupsById", id, appContext.idToken],
    queryFn: ({ signal }) => getGroupsById(appContext.idToken)(id, signal),
  });
  return query;
};
