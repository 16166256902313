import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "./savings.module.css"

const SavingBarGraphPart = ({ showLegend, barData, colorMap, service ,AllcolorMap,name}) => {

  const customTooltip = ({ active, payload, label}) => {
    if (active) {
      return (
        <div className={styles.custom_tooltip}>
        <div className={styles.tooltip_date} style={{paddingLeft:"8px"}}>
          <div className={styles.tooltip_date_css}>{label}</div>
        </div>
        <div
          style={{  width: "100%", paddingRight: "16px",paddingLeft:"8px" }}
        >
          <table>
            <thead>
              <tr>
                <th className={styles.cloud_cell} style={{ paddingRight: "24px" }}>
                  {name}
                </th>
                <th className={styles.cost_cell} style={{ paddingRight: "24px" }}>
                  Cost
                </th>
              </tr>
            </thead>
              <tbody>
                {payload.map((entry, index) => (
                  <tr
                  key={`${styles.tooltip_row} ${index}`}
                  className={styles.horizontalline_tool}
                >
                    <td className={styles.cloud_cell} style={{paddingRight:"24px"}}>
                      {(service === "aws" || service==="all-cloud") && (
                        <img src={awsLogo} alt=" " className={styles.cloud_logo} />
                      )}
                      {(service === "azure" || service==="all-cloud") &&  (
                        <img src={azureLogo} alt=" " className={styles.cloud_logo} />
                      )}
                      {/* {entry.dataKey === "GCP" && (
                        <img src={gcpLogo} alt=" " className={styles.cloud_logo}} />
                      )} */}
                      {entry.dataKey.toUpperCase()}
                    </td>
                    <td className={styles.cost_cell} style={{paddingRight:"24px"}}>${Number(entry.value).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={barData}
        className="legend-custom-color"
        margin={{ top: 0, right: 3, left: -10, bottom: 0 }}
      >
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="#495057"
        />
        <YAxis
          label={{ fill: "#495057" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
          // tickFormatter={(tick) => tick.toFixed(3)}
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        {showLegend && (
          <Legend
            align="center"
            verticalAlign="bottom"
            layout="horizontal"
            iconSize={12}
            iconType="Square"
            fontSize={12}
            fontWeight={400}
          />
        )}
     
        {(service === "aws" || service=== "all-cloud") && (
          <Bar dataKey="aws" stackId="a" fill={colorMap.AWS} barSize={144} />
        )}

        {(service === "azure" || service=== "all-cloud" )&& (
          <Bar
            dataKey="azure"
            stackId="a"
            fill={colorMap.Azure}
            barSize={144}
          />
        )}
        {(service === "gcp" || service=== "all-cloud") && (
          <Bar dataKey="gcp" stackId="a" fill={colorMap.GCP} barSize={144} />
        )}
        

        
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SavingBarGraphPart;
