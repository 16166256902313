import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetCountStatistics} count
 * @property {number} potential_saving
 * @property {number}  resource
 */
/**
 * @typedef GetCountStatistics
 * @property {GetFirstStatistics}
 * @property {GetSecondStatistics}
 * @property {GetThirdStatistics}
 * @property {GetForthStatistics}
 */
/**
 * @typedef GetFirstStatistics
 * @property {number} count
 */

/**
 * @typedef GetSecondStatistics
 * @property {number} count
 */

/**
 * @typedef GetThirdStatistics
 * @property {number} count
 */

/**
 * @typedef GetForthStatistics
 * @property {number} count
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 * @property {GetIdleFilters} filters
 */

/**
 * @typedef GetPotentialStatisticsReq
 *  @property {'ip' | 'disk' | 'vm' | 'lb'} resourceType
 * @property { 'false' | 'true' } untagged
 */

export const getCardStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, resourceType, filters, untagged }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === 'azure') {
      body.rg = filters?.rg || [];
    }

    const url = new URL(`${config.apiHost}/v1/${service}/idle-resources/potential-savings?resourceType=${resourceType}`);

    if (untagged) {
      url.searchParams.append('untagged', 'true');
    }

    const owners = await fetch(url.toString(), {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useCardStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getCardStatistics", req, ...deps],
    queryFn: ({ signal }) => getCardStatistics(appContext.idToken)(req, signal),
  });
  return query;
};