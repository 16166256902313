import React, { useState, useRef } from "react";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import StackedBarGraph from "./bar-graph.part";
import LineChartGraph from "./line-chart-graph.part";

const AllCloudBarGraph = ({ fromDate, toDate, data, graphData, dates }) => {
  const [chartType, setChartType] = useState("bar");
  const [activeLegend, setActiveLegend] = useState(null);
  const csvLinkRef = useRef(null);
  const services = ["AWS", "Azure"];

  const colorMap = {
    AWS: "#F90",
    Azure: "#1F78B4",
  };

  const handleDownloadCsv = () => {
    // Modify the graph data to replace null values with 0
    const modifiedData = graphData.map(({ label, AWS, Azure, GCP }) => ({
      label,
      AWS: AWS !== null ? AWS : 0,
      Azure: Azure !== null ? Azure : 0,
      GCP: GCP !== null ? GCP : 0,
    }));

    // Create CSV data
    const csvData = [
      ["Month", "AWS", "Azure", "GCP"],
      ...modifiedData.map(({ label, AWS, Azure, GCP }) => [
        label,
        AWS,
        Azure,
        GCP,
      ]),
    ];

    // Trigger download
    csvLinkRef.current.link.click();
  };

  return (
    <div
      className=" p-0"
      style={{
        display: "flex",
        alignItems: "stretch",
        flexDirection: "column",
        gap: "20px",
        // marginLeft: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "8px", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", gap: "8px" }}>
              <span className="all_cloud_text">All Cloud Cost & Usage</span>
            </div>

            <div>
              <Dropdown >
                <Dropdown.Toggle
                  variant="light"
                  className="custom-dropdown-toggle dropdown-disable-caret"
                  style={{
                    background: "#F0F6FF",
                    border: "none",
                    width: "24px",
                    height: "24px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                  }}
                >
                  <img src={menuicon} alt="Menu"   width= "3px" height= "13px" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: "8px 12px" }}>
                  <Dropdown.Item
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="line"
                    onClick={() => setChartType("line")}
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    eventKey="Download as CSV"
                    onClick={handleDownloadCsv}
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={graphData}
                    filename={"GraphData.csv"}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 p-0 ">
            {data && chartType === "bar" ? (
              <StackedBarGraph
                barChartData={graphData}
                colorMap={colorMap}
                services={services}
              />
            ) : (
              <LineChartGraph
                lineChartData={graphData}
                colorMap={colorMap}
                activeLegend={activeLegend}
                services={services}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCloudBarGraph;
