import React, { useEffect, useMemo } from "react";
import "@/assets/css/customwidget.css";
import Button from "react-bootstrap/Button";
import styles from "./edit-widget.module.css";
import { FilterSearch } from "@/components/filter-search/filter-search";
import clsx from "clsx";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import { ReactComponent as AddIcon } from "@/assets/img/add.svg";
import { ReactComponent as RemoveIcon } from "@/assets/img/filter-delete.svg";
import Tooltip from "@/components/tooltip/tooltip";
import { useTagValues } from "@/services/costExplorer/getDimensionTags.repo";

/**
 * @typedef {import('@/services/costExplorer/addCostExplorerWidget.repo').AddCostExplorerWidgetReq} AddCostExplorerWidgetReq
 */

/**
 * @typedef TagItemProps
 * @param {import('react-hook-form').FieldArrayWithId<AddCostExplorerWidgetReq, "tags", "id">} field
 */

const TagItem = ({
  field,
  watch,
  setValue,
  append,
  remove,
  watchCloud,
  errors,
  index,
  dimensionKeys,
}) => {
  const watchTags = watch(`tags`);
  const watchFilterType = watch(`tags.${index}.filterType`);
  const watchFilterOn = watch(`tags.${index}.filterOn`);
  const watchValues = watch(`tags.${index}.values`);
  const { data: dimensionValues, isLoading } = useServiceDimensionTags(
    watchCloud,
    watchFilterOn
  );
  const filteredValues =
    (watchValues || []).map((e) => ({
      id: e,
      name: e,
    })) || [];


    const selectedFilterDimensions = useMemo(() => {
      return watchTags.map((e) => e.filterOn);
    }, [watchTags]);
  
  return (
    <>
      <div className={styles.filter_select_div}>
        <div className={styles.filter_select_css}>
          <div className="w-100">
            <FilterSearch
              // options={dimensionKeys}
              options={dimensionKeys?.filter(
                (tagLabel) => !selectedFilterDimensions.includes(tagLabel.id)
              )}
              displayValue="name"
              selectedValues={[watchFilterOn]}
              searchFieldPlaceholder={"Filter Grouping"}
              onSelect={(selectedItem) => {
                setValue(`tags.${index}.filterOn`, selectedItem.id);
                setValue(`tags.${index}.filterType`, "include");
                setValue(`tags.${index}.values`, []);
              }}
              toggle={
                <div
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]:
                      watchFilterOn?.length > 0,
                  })}
                >
                  {Boolean(watchFilterOn?.length) === false && (
                    <AddIcon className={styles.filter_dimension_toggle_icon} />
                  )}
                  {watchFilterOn ? watchFilterOn : "Select Tags"}
                </div>
              }
            />
          </div>
          {watchFilterOn?.length > 0 && (
            <Tooltip
              tooltipContent="Remove"
              overlayProps={{ placement: "bottom-end" }}
            >
              {(_show, setShow) => (
                <Button
                  variant="link"
                  className={clsx("p-0", styles.remove_icon)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  onClick={() => {
                    remove(index);

                    if (watchTags.length === 1) {
                      append({
                        filterOn: "",
                        filterType: "include",
                        values: [],
                      });
                    }
                  }}
                >
                  <RemoveIcon />
                </Button>
              )}
            </Tooltip>
          )}
        </div>
        <div className="d-block w-100">
          <FilterSearch
            multiselect
            searchFieldPlaceholder="Filter Service"
            inclusion={watchFilterType === "include"}
            onInclusionChange={(inclusion) => {
              setValue(`tags.${index}.filterType`, inclusion);
            }}
            options={(dimensionValues || []).map((e) => ({
              id: e?.columnName || "null",
              name: e.columnName || "null",
            }))}
            displayValue="name"
            loading={isLoading}
            selectedValues={filteredValues}
            onSelect={(list) => {
              setValue(
                `tags.${index}.values`,
                list.map((e) => e.id)
              );
            }}
            onRemove={(list) => {
              setValue(
                `tags.${index}.values`,
                list.map((e) => e.id)
              );
            }}
            disabled={!watchFilterOn?.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {filteredValues?.length === 0 && (
                  <span
                    className={clsx(styles.placeholder_text_filter, "px-3")}
                  >
                    Choose Values
                  </span>
                )}
                {filteredValues?.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <img
                      src={watchFilterType ? includeIcon : excludeIcon}
                      alt=""
                    />
                    <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "300px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    title={filteredValues.map((e) => e.name)}
                    >
                      {filteredValues
                        .slice(0, 1)
                        .map((e) => e.name || null)
                        .join(", ")}
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
        {errors.tags && errors.tags[index].values && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.tags[index].values.message}
          </small>
        )}
        {errors.tags && errors.tags[index].dimension && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.tags[index].dimension.message}
          </small>
        )}
      </div>
    </>
  );
};

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {string} columnName
 */
const useServiceDimensionTags = (service, columnName) => {
  const {
    data: values,
    refetch: fetchValues,
    isLoading,
  } = useTagValues({
    columnName,
    service,
  });
  useEffect(() => {
    if (service && columnName) {
      fetchValues();
    }
  }, [columnName, fetchValues, service]);
  return { data: values || [], isLoading };
};

export default TagItem;
