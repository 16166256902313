import React from "react";
import styles from "../../kubernates.module.css";

const ServiceTable = ({ data }) => {
  const awsDataK =
    (data?.dimensionValuesAws && data?.dimensionValuesAws[0]) || [];
  const azureDataK =
    (data?.dimensionValuesAzure && data?.dimensionValuesAzure[0]) || [];
  const awsDataV = (data?.dimensionValuesAws && data?.dimensionValuesAws) || [];
  const azureDataV =
    (data?.dimensionValuesAzure && data?.dimensionValuesAzure) || [];

  const totalAws = Object.values(awsDataV || []);
  const totalAzure = Object.values(azureDataV || []);
  const allMerge = [...totalAws, ...totalAzure];


  function dynamicSum(data) {
    var removeIndex
    (data || []).filter((item) => removeIndex  =Object.keys(item).indexOf('total_cluster'))
    return (
      (data || [])
        .map((item) =>
          Object.values(item).filter((e, index, arr) => e !== arr[removeIndex])
        )
        ?.at(0) || []
    ).sort((a, b) => new Date(a) - new Date(b));
  }

  const awsData = dynamicSum(awsDataV);
  const azureData = dynamicSum(azureDataV);
  const awsSum = ((awsData && awsData) || []).reduce(
    (acc, cur) => acc + cur,
    0
  );

  const azureSum = ((azureData && azureData) || []).reduce(
    (acc, cur) => acc + cur,
    0
  );

  const azureKeys = Object.keys(azureDataK);
  const awsKeys = Object.keys(awsDataK);
  const mergedKeys = [...azureKeys, ...awsKeys];
  const dataKeys = Array.isArray(mergedKeys) ? [...new Set(mergedKeys)] : [];

  const totalKeys = dataKeys
    .map((e) => (e.includes("total_cluster") ? "Cluster" : e))
    .filter((e) => e !== "0")
    .sort((a, b) => new Date(a) - new Date(b));

  const calculateTotalForDate = (data, index) => {
    return (data || [])
      .map((item) => item[index] || 0)
      .reduce((acc, cur) => acc + cur, 0);
  };
  const totalCost = awsSum + azureSum;

  return (
    <div
      className="container-fluid table_main_container p-0"
      style={{ minWidth: "30%", overflowX: "auto" }}
    >
      <table border="0" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className={styles.allCloudsTableHeader}>Cloud</th>
            <th className={styles.allCloudsTableHeader}>Total Cost</th>
            {(totalKeys || []).map((e, i) => (
              <th key={i} className={styles.allCloudsTableHeader}>
                {e}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="border-bottom">
            <td className={styles.allCloudsServices}>AWS</td>
            <td className={styles.allCloudsTableBody}>
              ${awsSum?.toFixed(2)}{" "}
            </td>
            <td className={styles.allCloudsTableBody}>
              {awsDataK["total_cluster"] || ""}
            </td>
            {totalKeys
              .filter((e) => e !== "Cluster")
              .map((date, index) => (
                <td key={date} className={styles.allCloudsTableBody}>
                  ${(awsDataK[date] || 0)?.toFixed(2)}{" "}
                </td>
              ))}
          </tr>
          <tr className="border-bottom">
            <td className={styles.allCloudsServices}>Azure</td>
            <td className={styles.allCloudsTableBody}>
              ${azureSum?.toFixed(2)}{" "}
            </td>
            <td className={styles.allCloudsTableBody}>
              {azureDataK["total_cluster"] || ""}
            </td>
            {totalKeys
              .filter((e) => e !== "Cluster")
              .map((date, index) => (
                <td key={date} className={styles.allCloudsTableBody}>
                  ${(azureDataK[date] || 0).toFixed(2)}{" "}
                </td>
              ))}
          </tr>
        </tbody>
        <tfoot className="border-bottom">
          <tr>
            <td className={styles.totalCosts}>Total Costs</td>
            <td className={styles.totalCosts}>${totalCost?.toFixed(2)} </td>
            <td className={styles.footer}>
              {calculateTotalForDate(awsDataV, "total_cluster") +
                calculateTotalForDate(azureDataV, "total_cluster") || " "}
            </td>
            {totalKeys
              .filter((e) => e !== "Cluster")
              .map((date, index) => (
                <td key={date} className={styles.footer}>
                  $
                  {(
                    calculateTotalForDate(awsDataV, date) +
                    calculateTotalForDate(azureDataV, date)
                  )?.toFixed(2) || " "}{" "}
                </td>
              ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ServiceTable;
