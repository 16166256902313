import React from "react";

const ChartDetails = ({ chartDetails, index }) => {
  return (
    <>
      <div key={index} className="chartDetails">
        <div className="chart-info" style={{ textTransform: "capitalize" }}>
          <span className="detail-field">type</span>
          <span className="detail-text">{chartDetails.Type||chartDetails.type}</span>
        </div>
        {/* <div className="chart-info">
          <span className="detail-field">Threshold</span>
          <span
            className="detail-text"
            style={{ fontWeight: "600", color: "green" }}
          >
            {(chartDetails.Threshold)?.toFixed(1) || "--"}%
          </span> 
        </div> */}
        <div className="chart-info">
          <span className="detail-field">Reset Period</span>
          <span className="detail-text">{chartDetails.TimeUnit||chartDetails.timeUnit}</span>
        </div>
        <div className="chart-info">
          <span className="detail-field">Start Date</span>
          <span className="detail-text">
            {chartDetails.StartDate||chartDetails.startDate}
          </span>
        </div>
        <div className="chart-info">
          <span className="detail-field">Reset Period</span>
          <span className="detail-text">
            {chartDetails.EndDate||chartDetails.endDate}
          </span>
        </div>
      </div>
    </>
  );
};

export default ChartDetails;
