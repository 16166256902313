import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef AllBodyStatistics
 * @property {String} account
 * @property {String} snapshotName
 * @property {String} snapshotId
 * @property {String} region
 * @property {String} diskId
 * @property {Number} age
 * @property {String} snapshotClass
 * @property {String} snapshotCost
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' |'all'} resourceType
 * @property {GetIdleFilters} filters
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 * @property {'true' | 'false'} desc
 * @property {'account_name' | 'name' | 'resource_group_name' |'resource_id' | 'resource_type' | 'region' | 'idle_days' | 'saving'} orderBy
 * @property { 'false' | 'true' } untagged
 */

// ------------------------------api for the saving details download table-------------------------------------------------------------
export const getSnapshotDownload = (token) => {
    return async ({ service, page, limit, tableName }) => {
      const owners = await fetch(
        `${config.apiHost}/v1/${service}/snapshots/${tableName}`,
        {
          method: "POST",
          body: JSON.stringify({
            page,
            limit,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `snapshot-${tableName}-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseSnapshotsDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getSnapshotDownload", req, ...deps],
    mutationFn: ({ signal }) =>
        getSnapshotDownload(appContext.idToken)(req, signal),
  });
  return mutation;
};
