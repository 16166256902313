/** @type {import('./global')} */
import {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import Cookie from "js-cookie";
import { addSeconds } from "date-fns";
import { useRefreshToken } from "./services/sso/refreshToken.repo";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import subSeconds from "date-fns/subSeconds";

export const ROLES = {
  admin: 'Admin',
  user: 'User',
};

/**
 * @callback UpdateCode
 * @param {string} accessToken
 * @param {string} refreshToken
 * @param {number} expiresIn
 * @param {string} idToken
 * @returns
 */

/**
 * @callback UpdateUser
 * @param {User?} user
 */

/**
 * @typedef Logout
 * @type {() => void}
 */
/**
 * @typedef User
 * @property {string} emailId
 * @property {string} id
 * @property {string} name
 * @property {'Admin' | 'User'} roles
 */

/**
 * @typedef AppContext
 * @property {boolean} isAuthLoading
 * @property {boolean} isLoggedIn
 * @property {string?} refreshToken
 * @property {string?} accessToken
 * @property {string?} idToken
 * @property {User?} user
 * @property {Logout} logout
 * @property {UpdateCode} updateCode
 * @property {UpdateUser} updateUser
 */

/** @type {React.Context<AppContext>} */
export const AppContext = createContext();

/** @return {AppContext} */
export const useAppContext = () => {
  /** @type {UseState<string?>} */
  const [accessToken, setAccessToken] = useState(null);

  /** @type {UseState<string?>} */
  const [refreshToken, setRefreshToken] = useState(null);

  /** @type {UseState<string?>} */
  const [enableSearchDropdown, setEnableSearchDropdown] = useState(false);

  /** @type {UseState<Date?>} */
  const [expiresIn, setExpiresIn] = useState(null);


  /** @type {UseState<Date?>} */
  const [fromDate, setFromDate] = useState(null);

  /** @type {UseState<Date?>} */
  const [toDate, setToDate] = useState(null);

  /** @type {UseState<string?>} */
  const [idToken, setIdToken] = useState(null);

  /** @type {UseState<boolean>} */
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  /** @type {React.Dispatch<User>} */
  const [user, setUser] = useState(null);

  const isLoggedIn = useMemo(() => {
    return !isAuthLoading && Boolean(accessToken);
  }, [accessToken, isAuthLoading]);

  const {
    data: refreshTokenData,
    mutate: updateRefreshToken,
    isError: updateRefreshTokenFailed,
  } = useRefreshToken();

  /** @type {UpdateCode} */
  const updateCode = useCallback(
    (accessToken, refreshToken, expiresIn, newIdToken) => {
      const expiryDate = addSeconds(new Date(), Number(expiresIn));
      // // @TODO: DANGER!!!!!!!!!!! REMOVE THIS
      // const expiryDate = addSeconds(new Date(), 604_800);
      Cookie.set("expiresIn", expiryDate.toISOString(), {
        sameSite: true,
      });
      Cookie.set("accessToken", accessToken, { secure: true, sameSite: true });
      Cookie.set("refreshToken", refreshToken, {
        sameSite: true,
        expires: expiryDate,
      });
      Cookie.set("idToken", newIdToken, { sameSite: true });
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setIdToken(newIdToken);
      setExpiresIn(expiryDate);
    },
    []
  );

  const updateUser = (user) => {
    Cookie.set("user", JSON.stringify(user));
    setUser(user);
  };

  const logout = () => {
    Cookie.remove("user");
    Cookie.remove("accessToken");
    Cookie.remove("refreshToken");
    Cookie.remove("expiresIn");
    Cookie.remove("idToken");
    setUser(undefined);
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setExpiresIn(undefined);
    setIsAuthLoading(false);
  };

  useEffect(() => {
    let timeout;
    if (refreshToken && expiresIn) {
      let timer = differenceInMilliseconds(
        subSeconds(expiresIn, 4),
        new Date()
      );
      if (timer <= 0) timer = 1000;
      timeout = setTimeout(() => {
        const refreshToken = Cookie.get("refreshToken");
        if (refreshToken) {
          updateRefreshToken({ refreshToken: refreshToken });
        } else {
          logout();
        }
      }, timer);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [expiresIn, refreshToken, updateRefreshToken]);

  useEffect(() => {
    if (refreshTokenData) {
      updateCode(
        refreshTokenData.access_token,
        refreshTokenData.refresh_token,
        refreshTokenData.expires_in,
        refreshTokenData.id_token
      );
    }
  }, [updateCode, refreshTokenData]);

  useEffect(() => {
    if (updateRefreshTokenFailed) {
      logout();
    }
  }, [updateRefreshTokenFailed]);

  useEffect(() => {
    setIsAuthLoading(true);
    const expiresIn = Cookie.get("expiresIn");
    const user = Cookie.get("user");
    const accessToken = Cookie.get("accessToken");
    const refreshToken = Cookie.get("refreshToken");
    const idToken = Cookie.get("idToken") || "";
    const expiryDate =
      expiresIn !== undefined ? new Date(expiresIn) : addSeconds(new Date(), 1);
    if (accessToken && refreshToken && user && expiresIn) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setExpiresIn(expiryDate);
      setUser(JSON.parse(user));
      setIdToken(idToken);
      updateRefreshToken({
        refreshToken,
      });
    }
    setIsAuthLoading(false);
  }, [updateRefreshToken]);

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    isAuthLoading,
    isLoggedIn,
    accessToken,
    refreshToken,
    idToken,
    user,
    enableSearchDropdown,
    logout,
    updateCode,
    updateUser,
    setEnableSearchDropdown
  };
};

export const AppContextProvider = ({ children }) => {
  const appContext = useAppContext();
  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};
  