import style from "@/page-components/idle-resource/idle.module.css";
import styles from "@/page-components/budgets/components/budgets.module.css";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";
import clsx from "clsx";

export const TableSEction = ({ type, data }) => {

  return (
    <>
      <div className="container-fluid p-0" style={{ marginTop: "-20px" }}>
        <div className={clsx(styles.tableContainer)} style={{ minWidth: "30%",overflowY:"scroll",height:"361px" }}>
          <table
            className="table table-hover"
            id={styles.customers}
            style={{ width: "100%",margin:"0px" }}
          >
            <thead className={clsx(styles.stickyHeader)}>
              <tr>
                {(type === "topcost" ||
                  type === "Newly" ||
                  type === "unused") && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "50%" }}
                  >
                    Resource Name
                  </th>
                )}
                {type === "snap" && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "56%" }}
                  >
                    Snashot Name
                  </th>
                )}
                {type === "Newly" && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "20%" }}
                  >
                    Region
                  </th>
                )}
                {type === "snap" && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "20%" }}
                  >
                    Snapshot Age
                  </th>
                )}
                {type === "unused" && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "20%" }}
                  >
                    Resource Type
                  </th>
                )}
                {type === "unused" && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "10%" }}
                  >
                    Idle Days
                  </th>
                )}

                <th
                  className={clsx(styles.th_class)}
                  style={{ width: "20%", textAlign: "end" }}
                >
                  Cost $
                </th>
                {(type === "topcost" || type === "Newly") && (
                  <th
                    className={clsx(styles.th_class)}
                    style={{ width: "20%", textAlign: "end" }}
                  >
                    Contribution %
                  </th>
                )}
              </tr>
            </thead>

            <tbody className={styles.scrollableBody}>
              {data?.data?.map((i) => (
                <tr key={i} className={clsx(style.table_row, "p-0")}>
                  {(type === "topcost" ||
                    type === "Newly" ||
                    type === "unused") && (
                    <td
                      style={{
                        color: "#212224",
                        cursor: "pointer",
                      }}
                      className={style.table_data__Class}
                    >
                      {i?.resource_name !== null
                        ? (i.resource_name || i.name) || "--"
                        : "--"}
                    </td>
                  )}
                  {type === "snap" && (
                    <td
                      style={{
                        color: "#212224",
                        cursor: "pointer",
                      }}
                      className={style.table_data__Class}
                    >
                      {i.snapshot_name !== null
                        ? i.snapshot_name || "--"
                        : "--"}
                    </td>
                  )}
                  {type === "Newly" && (
                    <td
                      style={{
                        color: "#212224",
                        cursor: "pointer",
                      }}
                      className={style.table_data__Class}
                    >
                      {i.region !== null ? i.region || "--" : "--"}
                    </td>
                  )}
                  {type === "snap" && (
                    <td
                      style={{
                        color: "#212224",
                        cursor: "pointer",
                      }}
                      className={style.table_data__Class}
                    >
                      {i.snapshot_age !== null
                        ? Number(i.snapshot_age || 0)
                        : "--"}
                    </td>
                  )}
                  {type === "unused" && (
                    <>
                      <td
                        style={{
                          color: "#212224",
                          cursor: "pointer",
                        }}
                        className={style.table_data__Class}
                      >
                        {i.resource_type}
                      </td>
                      <td
                        style={{
                          color: "#212224",
                          cursor: "pointer",
                        }}
                        className={style.table_data__Class}
                      >
                        {i.idle_days !== null ? i.idle_days || 0 : "--"}
                      </td>
                    </>
                  )}
                  <td
                    style={{
                      color: "#212224",
                      cursor: "pointer",
                      textAlign: "end",
                    }}
                    className={style.table_data__Class}
                  >
                    {i.cost !== null ? Number(i.cost || 0).toFixed(2) : "--"}
                  </td>
                  {(type === "topcost" || type === "Newly") && (
                    <td
                      style={{
                        color: "#212224",
                        cursor: "pointer",
                        textAlign: "end",
                      }}
                      className={style.table_data__Class}
                    >
                      {i.contribution !== null
                        ? Number(i.contribution || 0).toFixed(2)
                        : "--"}
                      %
                    </td>
                  )}
                </tr>
              ))}
            </tbody>

            <tfoot className={clsx(styles.stickyFooter)}>
              <tr>
                <td
                  className={clsx(
                    style.table_data__Class,
                    styles.footStyle,
                    "fw-bold"
                  )}
                  style={{ fontSize: "13px", fontWeight: "700" }}
                >
                  Total
                </td>
                {type === "Newly" && (
                  <td
                    className={clsx(
                      style.table_data__Class,
                      styles.footStyle,
                      "fw-bold"
                    )}
                    style={{ fontSize: "13px", fontWeight: "700" }}
                  ></td>
                )}
                {type === "snap" && (
                  <td
                    className={clsx(
                      style.table_data__Class,
                      styles.footStyle,
                      "fw-bold"
                    )}
                    style={{ fontSize: "13px", fontWeight: "700" }}
                  ></td>
                )}
                {type === "unused" && (
                  <>
                    <td
                      className={clsx(
                        style.table_data__Class,
                        styles.footStyle,
                        "fw-bold"
                      )}
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    ></td>
                    <td
                      className={clsx(
                        style.table_data__Class,
                        styles.footStyle,
                        "fw-bold"
                      )}
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    ></td>
                  </>
                )}

                <td
                  className={clsx(
                    style.table_data__Class,
                    styles.footStyle,
                    "fw-bold"
                  )}
                  style={{
                    fontSize: "13px",
                    fontWeight: "700",
                    textAlign: "end",
                  }}
                >
                  {Number(data?.total_cost).toFixed(2)}
                </td>
                {(type === "topcost" || type === "Newly") && (
                  <td
                    className={clsx(
                      style.table_data__Class,
                      styles.footStyle,
                      "fw-bold"
                    )}
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      textAlign: "end",
                    }}
                  >
                    {Number(data?.total_contribution || 0).toFixed(2)}%
                  </td>
                )}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
