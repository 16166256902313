import React from 'react'
import Placeholder from "react-bootstrap/Placeholder";
import { Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";

const FilterState = () => {
  return (
    <div className=" d-flex flex-column gap-5" style={{margin:"12px 12px"}}>
      <Placeholder animation="glow">
      <div style={{display:"flex",flexDirection:"column", gap:"18px", marginTop:"12px"}}>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>
          <div style={{display:"flex",flexDirection:"column", gap:"8px"}} >
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "20px", width:"100px"}}
          />
          <Placeholder
            className={styles.placeholder_css}
            style={{ height: "36px", width:"200px"}}
          />
          </div>

        </div>
      </Placeholder>
      </div>
  )
}

export default FilterState