import React, { useContext, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/search-module/search.module.css";
import { SearchContext } from "../search-context";
import { useCostTrend } from "@/services/costExplorer/getCostTrendSearch.repo";
import { TableComponent } from "@/page-components/search-module/search-components/table-component.part";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import SearchTableState from "@/components/States/searchTableState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const BarGraphSearch = ({ service }) => {
  const context = useContext(SearchContext);
  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data, isLoading, error } = useCostTrend(query);

  const graphData = useMemo(() => {
    if (!data || !data.costTrends) return [];
    return data.costTrends.map((item) => ({
      name: item.service,
      [context?.name]: item.number,
    }));
  }, [data]);

  // const customTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     const topPayload =data?.services.slice().sort((a, b) => b.cost - a.cost).slice(0, 5);

  //     return (
  //        <div className="custom-tooltip">
  //         <div className="tooltip_date">
  //           <div className="tooltip_date_css">{label}</div>
  //         </div>
  //         <div style={{ paddingRight: "12px", width: "100%", paddingLeft: "8px" }}>
  //           <table>
  //             <thead>
  //               <tr>
  //                 <th className={styles.cloud_cell} style={{ paddingRight: "24px" }}>Top Services</th>
  //                 <th className={styles.cost_cell} style={{ paddingRight: "24px" }}>Costs</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {(topPayload || []).map((entry, index) => (
  //                 <tr
  //                 key={`tooltip-row-${index}`}
  //                 className={styles.horizontal_line}
  //               >
  //                   <td className={styles.cloud_cell} style={{ paddingRight: "24px" }}>
  //                     {entry.service}
  //                   </td>
  //                   <td className={styles.cost_cell} style={{ paddingRight: "24px" }}>
  //                     ${(entry?.cost || 0).toFixed(2)}
  //                   </td>
  //                 </tr>
  //               ))}
  //             </tbody>
  //           </table>
  //         </div>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  console.log("setTitle",context?.title)

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <BarGraphErrorStates />
          {context?.type !== "resource" && <SearchTableState />}
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {data?.costTrends.length > 0 && (
        <div className={styles.bar_table_css}>
          <div className={styles.bar_div}>
            <div className={styles.align_center}>
              <span className={styles.cost_trend}>Costs Trend</span>
              <span className={styles.LDaysDiv}>
                Last {context?.monthDifference} Months
              </span>
            </div>

            <ResponsiveContainer height={250} width="100%">
              <BarChart
                data={graphData}
                margin={{ top: 0, right: 3, left: -10, bottom: 0 }}
              >
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={500}
                  stroke="#495057"
                />
                <YAxis
                  label={{ fill: "#495057" }}
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={400}
                  stroke="#495057"
                />
                <CartesianGrid strokeDasharray="0 0" stroke="#F0F0F0" />
                {/* <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} /> */}
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  layout="horizontal"
                  iconSize={12}
                  iconType="Square"
                  fontSize={12}
                  fontWeight={400}
                  fontFamily="Inter,sans-serif"
                  fontStyle="normal"
                />
                <Bar dataKey={context?.name} fill="#056EE5" barSize={80}  name={context?.title}/>
              </BarChart>
            </ResponsiveContainer>
          </div>
          {context?.type !== "resource" && (
            <TableComponent data1={data} service={service} />
          )}
        </div>
      )}
    </>
  );
};

export default BarGraphSearch;
