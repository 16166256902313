import React from "react";
import styles from "./states.module.css";

import absenseImg from "../../assets/img/absense-img.svg";
import clsx from "clsx";

/**
 * @typedef DataAbsenseProps
 * @property {string} containerClasses  
 */

/**
 * @param {DataAbsenseProps} props 
 */
const DataAbsense = (props) => {
  return (
    <div className={clsx(styles.main_container,styles.main_container_absence, props.containerClasses)}>
      {/* <div className={styles.img_Container}> */}
        <img src={absenseImg} width={"32px"} height={"32px"} alt="" />
      {/* </div> */}
      <div className={styles.sec_container}>
        {/* <div className="sec_container-first"> */}
        <div className={styles.text_css}>Data Absence</div>
        <div className={styles.sec_text_css}>
          Sorry, no relevant data found. Please check your query parameters or
          ensure data is available for the selected timeframe.
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default DataAbsense;
