import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button } from "react-bootstrap";
import calender_side_arrow from "@/assets/img/calender_side_arrow.svg";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  subDays,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);

export const defaultInputRanges = [
  {
    label: "days up to today",
    range(value) {
      return {
        startDate: addDays(
          defineds.startOfToday,
          (Math.max(Number(value)) - 1) * -1 // Corrected calculation
        ),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
      if (!range.startDate) return "∞";
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1; // Add 1 to include today
    },
  },
  {
    label: "days starting today",
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value))),
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
      if (!range.endDate) return "∞";
      return differenceInCalendarDays(range.endDate, defineds.startOfToday);
    },
  },
];

const ReactCalender = ({ range, setRange }) => {
  const [show, setShow] = useState(false);
  const refCalender = useRef(null);
  const [initialRange, setInitialRange] = useState(range);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEsacape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEsacape = (e) => {
    if (e.key === "Escape") {
      setShow(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refCalender.current && !refCalender.current.contains(e.target)) {
      setShow(false);
    }
  };
  const handleApply = () => {
    setRange(initialRange);
  };

  return (
    <>
      <div style={{display:"flex"}}>
        <input
          value={`${format(initialRange[0].startDate, "dd LLL")} - ${format(
            initialRange[0].endDate,
            "dd LLL''yy"
          )}`}
          readOnly
          className="inputBox"
          onClick={() => setShow(!show)}
          style={{
            backgroundColor: "#101D4F",
            color: "white",
            width: "135px",
            border: "none",
          }}
        />
        <div onClick={() => setShow(!show)}>
          <img src={calender_side_arrow} alt="icon"></img>
        </div>
      </div>
      <div ref={refCalender}>
        {show && (
          <>
            <DateRangePicker
              staticRanges={defaultStaticRanges}
              showDateDisplay={false}
              inputRanges={defaultInputRanges}
              onChange={(item) => {
                if (item.selection.startDate && item.selection.endDate) {
                  if (item.selection.startDate !== item.selection.endDate) {
                    setInitialRange([item.selection]);
                  } else {
                    // Both start and end dates are the same, treat as a single date range
                    setInitialRange([]);
                  }
                } else {
                  setRange([]);
                }
              }}
              showSelectionPreview={true}
              maxDate={subDays(new Date(), 0)}
              ranges={initialRange}
              direction="horizontal"
              className="calenderElement"
            />

            <Button
              style={{
                width: "100px",
                height: "30px",
                position: "absolute",
                textAlign: "center",
                top: "210px",
                marginTop: "100%",
                zIndex: "10000",
                right: "120px",
                fontWeight: "bold",
                // padding: "5px",
                lineHeight: "normal",
                backgroundColor: "rgb(61, 145, 255)",
                border: "none",
              }}
              onClick={handleApply}
            >
              APPLY
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default ReactCalender;
