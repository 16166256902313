/** @type {import('./global')} */
import React, { createContext, useState,useEffect } from "react";

export const snapshotsTabsTypes = {
  Open: "Open",
  Discarded: "Discarded",
};

export const statusCode = {
  Open: "Open",
  [`In Progress`]: "In Progress",
  [`Pending Approval`]: "Pending Approval",
};

/**
 * @typedef SnapshotContextType
 * @property {string[]} selectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedAccounts
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedRegions
 * @property {string[]} selectedDisk
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedDisk
 * @property {string[]} selectedSnapshotAge
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedSnapshotAge
 * @property {string[]} selectedSnapshotTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedSnapshotTags
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} snapshotTabs
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSnapshortTabs
 * @property {string[]} legallyHeldSnapshot
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setLegallyHeldSnapshot
 * @property {string[]} downloadName
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setDownloadName
 */

/**
 * @type {React.Context<SnapshotContextType>}
 */
export const SnapshotContext = createContext();

/**
 * @returns {SnapshotContextType}
 */
export const useSnapshotContext = () => {
  /** @type {UseState<string[]>} */
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedDisk, setSelectedDisk] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedSnapshotAge, setSelectedSnapshotAge] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

   /** @type {UseState<string[]>} */
   const [showUntaggedOnly, setShowUntaggedOnly] = useState(false);

    /** @type {UseState<string[]>} */
  const [showOrphanedOnly, setShowOrphanedOnly] = useState(false);

  /** @type {UseState<string[]>} */
  const [legallyHeldSnapshot, setLegallyHeldSnapshot] = useState(false);

   /** @type {UseState<string[]>} */
   const [downloadName, setDownloadName] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedSnapshotTags, setSelectedSnapshotTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  const [value, setValue] = useState([undefined, undefined]);


  const [eventkey, setEventkey] = useState("Open");

  useEffect(() => {
    setSelectedResourceGroup([]);
}, [selectedAccounts]);

  return {
    value,
    setValue,
    eventkey,
    setEventkey,
    selectedAccounts,
    snapshotsTabsTypes,
    setSelectedAccounts,
    selectedRegions,
    setSelectedRegions,
    selectedDisk,
    setSelectedDisk,
    selectedSnapshotAge,
    setSelectedSnapshotAge,
    selectedSnapshotTags,
    setSelectedSnapshotTags,
    selectedResourceGroup,
    setSelectedResourceGroup,
    showUntaggedOnly,
    setShowUntaggedOnly,
    showOrphanedOnly,
    setShowOrphanedOnly,
    legallyHeldSnapshot,
    setLegallyHeldSnapshot,
    downloadName,
    setDownloadName
  };
};

export const SnapshotProvider = ({ children }) => {
  const context = useSnapshotContext();
  return (
    <>
      <SnapshotContext.Provider value={context}>
        {children}
      </SnapshotContext.Provider>
    </>
  );
};
