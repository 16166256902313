import { AppContext } from "@/App.context";
import config from "../../config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

const setBudgetListAzure = (token) => {
  return async (Qdata) => {
    const setBudget = await fetch(
      `${config.apiHost}/v1/${Qdata?.service}/budget/download?scope=${Qdata?.scope}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      throw new Error(errorResponse.message);
    }
    const reader = setBudget.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${Qdata?.scope}-${Qdata?.tableName}-data.csv`;
    DownloadFile(blob, fileName);

  };
};

const setBudgetListAws = (token) => {
  return async (Qdata) => {
    const setBudget = await fetch(
      `${config.apiHost}/v1/${Qdata?.service}/budget/download`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      throw new Error(errorResponse.message);
    }
    const reader = setBudget.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `Budget-${Qdata?.tableName}-data.csv`;
    DownloadFile(blob, fileName);

  };
};

export const useListApiAwsdownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["setBudgetListAws", req, ...deps],
    mutationFn: ({signal}) => setBudgetListAws(appContext.idToken)(req,signal),
  });
  return query;
};

export const useListApiAzuredownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["setBudgetListAzure", req, ...deps],
    mutationFn: ({signal}) => setBudgetListAzure(appContext.idToken)(req,signal),
  });
  return query;
};
