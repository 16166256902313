import React, { useContext, useEffect, useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import policyEyeSymbol from "@/assets/img/policyEyeSymbol.svg";
import userProfile_img from "@/assets/img/userProfile_img.svg";
import vector from "@/assets/img/right_Vector_userAdmin.svg";
import users from "@/assets/img/users_profiles.svg";
import clsx from "clsx";
import { useGroupsById } from "@/services/admin/Groups/getGroupsById.repo";
import format from "date-fns/format";
import SpinnerComponent from "../components/spinner.component";
import { GroupsContext } from "@/page-components/admin/groups/group.context";
import CreateGroupModal from "@/page-components/admin/groups/create.group.modal";
import privilege from "@/assets/img/privileges_button.svg";
import privilegena from "@/assets/img/privilege_na.svg";
import userb from "@/assets/img/user_button.svg";

/**
 * @typedef GroupInfoModalProps
 * @property {number} selectedItem
 * @property {() => void} handleClose
 */

/**
 * @param {GroupInfoModalProps} props
 */
const GroupsInfoModalData = ({ handleClose, selectedItem }) => {
  const adminContext = useContext(GroupsContext);

  const { data, isLoading: groupLoading } = useGroupsById(selectedItem);

  const [activeTab, setActiveTab] = useState("Privileges");
  const id = selectedItem;

  const handleCreateGroupModalShow = () => {
    handleClose();
    adminContext.setGroupEditItem(id);
    adminContext.setCreateGroupModal(true);
  };


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Offcanvas
        placement="end"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={Boolean(id)}
        onHide={handleClose}
      >
        {groupLoading && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <SpinnerComponent />
          </div>
        )}
        {!groupLoading && data?.group && (
          <>
            <Offcanvas.Header closeButton className={styles.modal_header_css}>
              <div className={styles.groupmainModal}>
                <img src={userProfile_img} alt="" />
                <div className={styles.group_modal}>
                  <span className={styles.groupuser_name}>
                    {data?.group?.name || ""}
                  </span>
                  <span className={styles.last_modified_name}>
                    Last modified on {}
                  </span>
                </div>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body className={styles.modal_body_css}>
              {id && (
                <>
                  <div className={styles.table_div} style={{ width: "100%" }}>
                    <div style={{ paddingTop: "16px" }}>
                      <span className={styles.discription_name}>
                        Description
                      </span>
                      <p
                        className={styles.discription_details}
                        style={{ paddingTop: "4px" }}
                      >
                        {data?.group?.description || "--"}
                      </p>
                      <div className={styles.creater_div_groups}>
                        <div className={styles.creater_box}>
                          <span className={styles.creater_name}>Creator:</span>
                          <span className={styles.creater_name_value}>
                            <span
                              className={clsx(
                                styles.myselected_user_data,
                                styles.padding_left
                              )}
                            >
                              {data?.group?.createdBy || ""}
                            </span>
                            {/* <img src={vector} alt="" /> */}
                          </span>
                        </div>
                        <div className={styles.creater_box}>
                          <span className={styles.creater_name}>
                            Creation Date:{" "}
                          </span>
                          <span
                            className={clsx(
                              styles.creater_name_value,
                              styles.createdOn_styling
                            )}
                          >
                            {data?.group?.createdAt
                              ? format(
                                  new Date(data?.group?.createdAt),
                                  "dd/MM/yyyy"
                                )
                              : "--"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        width: "100%",
                      }}
                    >
                      <div className={styles.myTabs_UserGroup}>
                        <Button
                          id={styles.mybutton_groups}
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="outline-primary"
                          onClick={() => handleTabChange("Privileges")}
                          active={activeTab === "Privileges"}
                        >
                          {activeTab === "Users" ? (
                            <>
                              <img
                              className={styles.myimgcolor}
                                style={{ width: "12px", height: "12px" }}
                                src={privilegena}
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <img 
                                style={{ width: "12px", height: "12px" }}
                                src={privilege}
                                alt=""
                              />
                            </>
                          )}
                          {/* <img src={privilege_active} alt="" /> */}
                          Privileges ({data?.privileges?.length})
                        </Button>{" "}
                        <Button
                         id={styles.mybutton_groups}
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="outline-primary"
                          onClick={() => handleTabChange("Users")}
                          active={activeTab === "Users"}
                        >
                          {activeTab === "Users" ? (
                            <>
                              <img style={{ width: "12px", height: "12px" }} className={styles.myimgcolorb}  src={userb} alt="" />
                            </>
                          ) : (
                            <>
                              <img  style={{ width: "12px", height: "12px" }} className={styles.myimgcolor} src={userb} alt="" />
                            </>
                          )}
                          Users ({data?.users?.length})
                        </Button>{" "}
                    
                      </div>
                      {activeTab === "Privileges" && (
                        <div className={styles.privilage_box}>
                          <div className={styles.tabContent}>
                            {(data?.privileges || []).map((item, index) => (
                              <div
                                key={index}
                                style={{ padding: "16px 16px 0px 16px" }}
                              >
                                <p className={styles.image_div}>
                                  <img src={policyEyeSymbol} alt=""></img>
                                  <span className={styles.groupname}>
                                    {item.name}
                                  </span>
                                </p>
                                <p className={styles.groupname_details}>
                                  {item.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {activeTab === "Users" && (
                        <div className={styles.tabContent}>
                          {(data?.users || []).map((item, index) => (
                            <div
                              key={index}
                              className={styles.myMainUserTab_div}
                            >
                              <p className={styles.image_div}>
                                <img
                                  src={users}
                                  alt=""
                                  className={styles.myUSerImage}
                                ></img>
                                <span
                                  className={clsx(
                                    styles.groupname,
                                    styles.groupNAme_editor
                                  )}
                                >
                                  {item.name}
                                </span>
                              </p>
                              <p className={styles.groupname_details}>
                                {item.email}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Offcanvas.Body>
            <div className={styles.modal_footer_css}>
              <button
                disabled={adminContext.deleteLoading}
                onClick={() => {
                  adminContext.setGroupDeleteItemName(data?.group.name || "");
                  adminContext.setGroupDeleteItem(selectedItem);
                }}
                className={styles.delete_button}
              >
                {adminContext.deleteLoading ? "Loading..." : "Delete"}
              </button>
              <div className={styles.buttons}>
                <button
                  disabled={adminContext.deleteLoading}
                  onClick={handleClose}
                  className={styles.cancel_button}
                >
                  Close{" "}
                </button>
                <button
                  disabled={adminContext.deleteLoading}
                  className={styles.edit_button}
                  onClick={handleCreateGroupModalShow}
                >
                  Edit{" "}
                </button>
              </div>
            </div>
          </>
        )}
      </Offcanvas>
    </>
  );
};

export default GroupsInfoModalData;
