import React, { useContext, useMemo } from "react";
// import data from '@/page-components/RI-Capacity/components/Tab-Components/JSON Components/specs-data';
import styles from "@/page-components/RI-Capacity/ri.module.css";
import YearComponent from "./year-component";
import clsx from "clsx";
import { RiContext } from "@/page-components/RI-Capacity/ri.context";


const SpecsModal = ({service,data,isLoading}) => {
  // const { specs1, models, resourceDetails } = data;

  const context = useContext(RiContext);



  const maxSavingsPerMonth = Math.max(
    ...(data?.recommendations || []).map((i) => parseInt(i?.monthly_saving))
  );

  return (
    <div>
      <div className={styles.modalHeadingText}>Reserved Instance models</div>
      <div className={styles.container}>
        <div style={{ display: "flex", flexDirection: "row", width: "38%" }}>
          <div
            className={styles.specs}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              textAlign: "left",
              paddingTop: "12px",
              borderRadius: "8px",
            }}
          >
            <span className={styles.specs_name}>SPECS</span>
            <span className={styles.on_demand_cost}>On-Demand Cost</span>
            <span className={styles.on_demand_cost}>AVG Monthly Cost</span>
            <span className={styles.on_demand_cost}>Break-Even Period</span>
            <span className={styles.on_demand_cost}>Savings vs. On-Demand</span>
          </div>
          <div
            className={styles.specs}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              textAlign: "center",
              paddingTop: "12px",
              borderRadius: "8px",
            }}
          >
            <span className={styles.specs_name}>
              {service.service.service === "azure"
                ? "CURRENT COST"
                : service.service.service === "aws"
                ? "CURRENT RESOURCES"
                : "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              {context?.onDemandC
                ? "$" + Number(context?.onDemandC || 0).toFixed(2)
                : "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              {data?.resource?.monthly_cost
                ? "$" + Number(data?.resource?.monthly_cost || 0).toFixed(2)
                : "--"}
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              -
            </span>
            <span
              className={clsx(styles.on_demand_cost, styles.for_border_line)}
            >
              -
            </span>
          </div>
        </div>
        <div style={{ width: "62%" }}>
          <YearComponent
            maxSavingsPerMonth={maxSavingsPerMonth}
            modalData={data?.recommendations}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className={styles.resourceDetails} style={{ gap: "3px" }}>
        <span className={styles.resource_name}>Resource Name:</span>
        <span className={styles.resource_value}>
          {typeof data?.resource?.resource_id === "string"
            ? data.resource.resource_id.split("/").pop()
            : "--"}
        </span>

        <span className={styles.resource_name}>Resource ID:</span>
        <span
          className={styles.resource_value}
          title={data?.resource?.resource_id || "Nan"}
          style={{
            width: "400px",
            textWrap: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data?.resource?.resource_id || "Nan"},
        </span>
        <span className={styles.resource_name}>Region:</span>
        <span className={styles.resource_value}> {context.region},</span>
        <span className={styles.resource_name}>Operating System:</span>
        <span className={styles.resource_value}> {context?.platform},</span>
        <span className={styles.resource_name}>Tenancy:</span>
        <span className={styles.resource_value}>
          {data?.resource?.tenancy || "Nan"},
        </span>
      </div>
    </div>
  );
};

export default SpecsModal;
