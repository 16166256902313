import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAWSStatistics} aws
 * @property {GetAzureCountStatistics} azure
 */

/**
 * @typedef GetAzureCountStatistics
 * @property {GetIdleStatistics} idle_resources
 * @property {GetRightStatistics} right_sizing
 * @property {GetSnapshotStatistics} snapshots
 * @property {GetSavingStatistics} total_saving
 */

/**
 * @typedef GetAWSStatistics
 * @property {GetIdleStatistics} idle_resources
 * @property {GetRightStatistics} right_sizing
 * @property {GetSnapshotStatistics} snapshots
 * @property {GetSavingStatistics} total_saving
 */

/**
 * @typedef GetIdleStatistics
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetRightStatistics
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetSnapshotStatistics
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetSavingStatistics
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'all-cloud' | 'aws' | 'gcp' | 'azure'  } service
 * @property {string} fromDate
 * @property {string} toDate
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<GetAllStatistics>}
 */

export const getAllTableStatistics = (token) => {
  return async ({
    service,
    page,
    recordsPerPage,
    fromDate,
    toDate
  }) => {
    const response = await fetch(
      `${config.apiHost}/v1/${service}/savings/saving-details?from=${fromDate}&to=${toDate}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        }
      }
    );

    if (!response.ok) {
      if (ERROR_STATUS_CODES.includes(response.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (response.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await response.json()).message);
    }

    const data = await response.json();

    const isAllZeroOrNull = (cloudData) => {
      return (
        cloudData.idle_resources.count === 0 && 
        (cloudData.idle_resources.cost === null || cloudData.idle_resources.cost === 0) &&
        cloudData.right_sizing.count === 0 && 
        (cloudData.right_sizing.cost === null || cloudData.right_sizing.cost === 0) &&
        cloudData.snapshots.count === 0 && 
        (cloudData.snapshots.cost === null || cloudData.snapshots.cost === 0) &&
        cloudData.total_saving.count === 0 && 
        cloudData.total_saving.cost === 0
      );
    };

    const isDataAbsent = isAllZeroOrNull(data.aws) && isAllZeroOrNull(data.azure);

    if (isDataAbsent) {
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      }
      throw new Error("No data available.");
    }

    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllTablesStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllTableStatistics", appContext.idToken, req, ...deps],
    queryFn: () => getAllTableStatistics(appContext.idToken)(req),
  });
  return query;
};
