/** @type {import('./global')} */
import React, { createContext, useState } from "react";

/**
 * @typedef {import('@/services/IdleResources/getAllStatics.repo').GetAllStatisticsFilters} TagItem
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').GetRecommendationsListRes} GetRecommendationsListRes
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').AddAcceptDismissIdleReq} AddAcceptDismissIdleReq
 */

/**
 * @typedef SavingContextType
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} selectRg
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRg
 * @property {TagItem[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedTags
 */

/**
 * @type {React.Context<SavingContextType>}
 */
export const SavingContext = createContext();

/**
 * @returns {SavingContextType}
 */
export const useSavingContext = () => {
  /** @type {UseState<string[]>} */
  const [selectAccounts, setSelectAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRg, setSelectRg] = useState([]);

  /** @type {UseState<TagItem[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  return {
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    selectedTags,
    selectRg,
    setSelectRg,
    setSelectedTags,
  };
};

export const SavingProvider = ({ children }) => {
  const context = useSavingContext();
  return (
    <>
      <SavingContext.Provider value={context}>
        {children}
      </SavingContext.Provider>
    </>
  );
};
