import React, { useState, useMemo, useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownButton, Button } from "react-bootstrap";
import styles from "../idle.module.css";
import uploadicon from "@/assets/img/uploadicon.svg";
import copyicon from "../../../assets/img/copyicon.svg";
import { useArchieveStatistics } from "@/services/IdleResources/getArchiveStatistics.repo";
import { useSearchParams } from "react-router-dom";
import Pagination from "@/components/pagination/pagination.component";
import { IdleContext } from "./idle-context-file.part";
import clsx from "clsx";
import TableErrorState from "@/components/States/TableErrorState";
import Tooltip2 from "@/components/tooltip/tooltip";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import unarchieve from "@/assets/img/unarchieve.svg";
import ToastComponent from "@/page-components/budgets/components/toast";
import { useUpdateAcceptRecommendation } from "@/services/IdleResources/addAcceptFields.repo";
import UnarchiveModal from "@/page-components/snapshot/components/state_change/unarchiveModal";
import { useGetHistory } from "@/services/IdleResources/getAcceptDismissHistory.repo";
import { useSavingTableStatistics } from "@/services/IdleResources/getArchiveStatistics.repo";
import sorting from "@/assets/img/sorting.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
} from "@/lib/errors/error-constants";
import download from "@/assets/img/download.svg";
import { UseSavingDetailsDownload } from "@/services/IdleResources/savingDetailsDownload.repo";
import menuicon from "@/assets/img/menu.svg";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";

const ArchieveTablePart = ({
  service,
  name,
  refreshHeaders,
  archiveHeaderRefeth,
  ipName,
}) => {
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedResourceType, setSelectedResourceType] = useState("All");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [message, setMessage] = useState();
  const [toast, setToast] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [savingsId, setSavingsId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const [enableUnarchiveModal, setEnableUnarchiveModal] = useState(false);
  const [orderBy, setOrderBy] = useState("saving");
  const desc = searchParams.get("desc");

  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isErrorArchive,
    error: errorArchive,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: resourceId,
    };
  }, [service, resourceId]);

  const { data: historyData } = useGetHistory(queryParam);

  const handleResourceTypeSelect = (selectedType) => {
    let resourceType;

    switch (selectedType) {
      case "All":
        resourceType = "all";
        break;
      case "Virtual Machine":
        resourceType = "vm";
        break;
      case "Elastic IP":
      case "Public IP":
        resourceType = "ip";
        break;
      case "Disk":
        resourceType = "disk";
        break;
      case "Load Balancer":
        resourceType = "lb";
        break;
      default:
        resourceType = selectedType.toLowerCase();
    }

    setSelectedResourceType(selectedType);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("resourceType", resourceType);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const handleCopyClick = (resource_id) => {
    const textarea = document.createElement("textarea");
    textarea.value = resource_id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(resource_id);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleUnarchiveModal = (resourceid, snapshotId, history) => {
    setEnableUnarchiveModal(Boolean(resourceid));
    setSavingsId(snapshotId);
    setResourceId(resourceid);
    setCheckedHistory(history);
  };

  const context = useContext(IdleContext);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("page", "1");
    updatedSearchParams.set("resourceType", "all");
    setSearchParams(updatedSearchParams);
  }, [context.selectAccounts, context.selectRegions, context.selectedTags]);

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      resourceType: searchParams.get("resourceType") || "all",
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "false",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedTags,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refetchUnarchiveTable,
  } = useArchieveStatistics(query, [updateData]);

  useEffect(() => {
    if (updateData) {
      refetchUnarchiveTable();
      refreshHeaders();
      archiveHeaderRefeth();
      setEnableUnarchiveModal(false);
    }
  }, [updateData, refreshHeaders, archiveHeaderRefeth, refetchUnarchiveTable]);

  const handleUnarchiveModalClose = () => {
    setEnableUnarchiveModal(false);
  };

  useEffect(() => {
    if (isErrorArchive) {
      setEnableUnarchiveModal(false);
      setMessage({
        message: errorArchive?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [isErrorArchive, errorArchive, setMessage]);

  const tooltip = (
    <Tooltip id="tooltip">
      <span style={{ padding: "4px 8px" }}>Unarchive</span>
    </Tooltip>
  );

  // ---------------------------------------------orderBY--------------------------------------------

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      tableName: "archived",
      resourceType: searchParams.get("resourceType") || "all",
      idleDays: searchParams.get("idleDays") || "all",
      page: parsedPage || 1,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedTags,
    context.showUntaggedOnly,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
  ]);
  const { mutate: downloadMutate } = UseSavingDetailsDownload(downloadQuery);
  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  useEffect(() => {
    if (
      updateData &&
      data?.data?.length === 1 &&
      error?.message === "API_NO_DATA_FOUND_ERROR"
    ) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });

      refetchUnarchiveTable();
    }
  }, [updateData, data?.data?.length, refetchUnarchiveTable, error?.message]);

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}
      <div
        className={clsx(styles.service_table__container, "main_cont_padding")}
      >
        <div className={styles.saving_container}>
          <div className={styles.saving_name_text}>Archived Resources</div>
        </div>
        <div className={styles.saving_down_space}>
          <div className={styles.main_resource_container}>
            <div className={styles.resource_container}>
              <div className={styles.res_type}>
                <div className={styles.res_type_text}>Resource Type</div>

                <div className="dropdown">
                  <div>
                    <DropdownButton
                      id={styles.dropdown_item_button}
                      title={selectedResourceType}
                    >
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect("All")}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect(ipName)}
                      >
                        {ipName}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect("Disk")}
                      >
                        Disk
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() =>
                          handleResourceTypeSelect("Virtual Machine")
                        }
                      >
                        Virtual Machine
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() =>
                          handleResourceTypeSelect("Load Balancer")
                        }
                      >
                        Load Balancer
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.menu_icon_css1}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
            >
              <span
                className={styles.downloadButton}
                onClick={handleDownloadClick}
              >
                <img src={downlaodVM} alt="" />
              </span>
            </OverlayTrigger>
          </div>
        </div>

        {isLoading && <TableErrorState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Idle Resource Data Available",
                additionalMessage:
                  "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS]: {
                message: "No Data Available for Selected Resource Type",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
            }}
          />
        )}
        {!isLoading && !error && data?.count?.total_data > 0 && (
          <>
            <div
              className="container-fluid  p-0"
              style={{ minWidth: "30%", overflowX: "auto" }}
            >
              <table
                className="table table-hover "
                id={styles.customers}
                style={{}}
              >
                <thead>
                  <tr>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      {name}{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account_name")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Name{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("name")}
                      ></img>{" "}
                    </th>
                    {service === "azure" && (
                      <th
                        className={clsx(styles.th_class, styles.table_td_width)}
                      >
                        Resource Group
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            handleOrderByClick("resource_group_name")
                          }
                        ></img>{" "}
                      </th>
                    )}
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource ID{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_id")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Type{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_type")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Region{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>

                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "center" }}
                    >
                      Idle Days &nbsp; &nbsp;
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("idle_days")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ paddingRight: "12px" }}>
                        Available Savings{" "}
                      </span>
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("saving")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "left", minWidth: "90px" }}
                    >
                      Status
                    </th>

                    <th className={styles.menu_head}></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data.map((item, index) => (
                    <tr key={index} className={clsx(styles.table_row, "p-0")}>
                      <td className={styles.table_data__Class}>
                        {item?.account_name || "NA"}
                      </td>
                      <td className={styles.table_data__Class}>
                        {item?.name || "NA"}
                      </td>
                      {service === "azure" && (
                        <td className={styles.table_data__Class}>
                          {item?.resource_group_name || "NA"}
                        </td>
                      )}

                      <td className={styles.table_data__Class}>
                        <span>{item?.resource_id || "NA"}</span>
                        <button
                          className={`${styles.copy_icon} ${
                            copiedResourceId === item?.resource_id
                              ? "visible"
                              : ""
                          }`}
                          onClick={() => handleCopyClick(item?.resource_id)}
                        >
                          <img
                            src={copyicon}
                            alt=""
                            style={{ marginRight: "-15px" }}
                          />
                        </button>
                      </td>

                      <td className={styles.table_data__Class}>
                        {item?.resource_type || "NA"}
                      </td>
                      <td className={styles.table_data__Class}>
                        {item?.region || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ textAlign: "center" }}
                      >
                        {item?.idle_days || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{
                          textAlign: "right",
                          paddingRight: "20px",
                          color: "var(--Success-Text, #127E24)",
                          fontWeight: 600,
                          fontSize: 13,
                        }}
                      >
                        <Tooltip2
                          tooltipContent={
                            item?.saving != null
                              ? "$" + (item?.saving || 0).toFixed(8)
                              : "$0"
                          }
                          overlayProps={{ placement: "top" }}
                        >
                          {(show, setShow) => (
                            <Button
                              onMouseLeave={() => setShow(false)}
                              onMouseEnter={() => setShow(true)}
                              variant="link"
                              style={{
                                textAlign: "right",
                                color: "var(--Success-Text, #127E24)",
                                fontWeight: 600,
                                fontSize: 13,
                                minWidth: 100,
                              }}
                            >
                              {item?.saving === null && "$0"}
                              {item?.saving != null &&
                                item?.saving < 0.1 &&
                                "< $0.1"}
                              {item?.saving > 0.1 &&
                                "$" + (item?.saving || 0).toFixed(2)}
                            </Button>
                          )}
                        </Tooltip2>
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ textAlign: "left" }}
                      >
                        <span className={styles.badgeName}> Open</span>
                      </td>
                      <td className={styles.table_data__Class}>
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                          <button
                            className="fgvhjikj"
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => {
                              handleUnarchiveModal(
                                item?.resource_id,
                                item?.resource_id,
                                item?.history
                              );
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <img
                              src={
                                hoveredIndex === index ? unarchieve : uploadicon
                              }
                              alt=""
                            />
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination totalItems={data?.count?.total_data || 0} />
          </>
        )}
      </div>

      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}

      <UnarchiveModal
        enable={enableUnarchiveModal}
        disable={handleUnarchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        isErrorArchive={isErrorArchive}
        refetchTable={refetchUnarchiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkedHistory={checkedHistory}
        name="Idle Resource"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "unarchive",
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

export default ArchieveTablePart;
