import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllIndividualStatistics
 * @property {GetAllAwsStatisticss} awsCostStatics
 * @property {GetAllAzureStatistics} azureCostStatics
 */

/**
 * @typedef GetAllAwsStatisticss
 * @property {number} Compute
 * @property {number} Container
 * @property {number} Network
 * @property {number} Other
 * @property {number} Serverless
 * @property {number} Storage
 * @property {number} Databases
 * @property {number} totalCost
 */

/**
 * @typedef GetAllAzureStatistics
 * @property {number} Network
 * @property {number} Databases
 * @property {number} Storage
 * @property {number} Databases
 * @property {number} Container
 * @property {number} Other
 * @property {number} Compute
 * @property {number} totalCost
 */

/**
 * @typedef GetAllIndividualStatisticsRes
 * @property  {GetAllIndividualStatistics} awsCostStatics
 */

/**
 * @typedef GetAllIndividualStatisticsReq
 * @property {string} fromDate
 * @property {string} toDate
 */

export const getAllIndividualStatistics = (token) => {
  /**
   * @param {GetAllIndividualStatisticsReq} req
   * @returns {Promise<GetAllIndividualStatistics>}
   */
  return async ({ fromDate, toDate }, signal) => {
    const totalStats = await fetch(
      `${config.apiHost}/v1/allclouds/cost/individual-stats?fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!totalStats.ok) {
      if (ERROR_STATUS_CODES.includes(totalStats.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (totalStats.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await totalStats.json()).message);
    }

    const data = await totalStats.json();

    if (
      (data?.awsCostStatics?.totalCost === undefined || data?.awsCostStatics?.totalCost === 0) &&
      (data?.azureCostStatics?.totalCost === undefined || data?.azureCostStatics?.totalCost === 0)
    ) {
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return data;
  };
};

/**
 * @param {GetAllIndividualStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllIndividualStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllIndividualStatistics", req, appContext.idToken, ...deps],
    queryFn: ({ signal }) =>
      getAllIndividualStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
