import React from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const SnapshotType = ({ mydata }) => {
  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (count >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (count >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }
  // const totalCount = mydata?.unTaggedCount || 0;
  // const formattedCount = formatCount(totalCount);

  return (
    <div className={clsx(styles.untagged_snapshot_main)}>
      <span className={clsx(styles.snapshot_text)}>UNTAGGED SNAPSHOTS</span>
      <span className={clsx(styles.snapshot_value)}>{mydata?.unTaggedCount || 0}</span>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={clsx(styles.snap_cost_text)}>Cost:</span>
        <span className={styles.snap_cost_value}>
          <ToolTipComponent data={mydata?.unTaggedCost || 0}>
            <span style={{ cursor: "context-menu" }}>
              ${Number((mydata?.unTaggedCost).toFixed(2)) || 0}
            </span>
          </ToolTipComponent>
        </span>
      </div>
    </div>
  );
};

export default SnapshotType;
