
import { useContext } from "react";
import config from "../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

const setStatus = (token) => {
  return async (data) => {
    const setStatus = await fetch(
      `${config.apiHost}/v1/${data?.service}/budget/status`,
      {
        method: "POST",
        body: JSON.stringify(data?.body), 
        headers: {
          "Content-Type": "application/json",
           Authorization: token,
        },
      }
    );
    if (!setStatus.ok) {
      throw new Error((await setStatus.json()).message);
    }
    const statusData = await setStatus.json();
    return statusData;
  };
};

export const useStatusBudget = (deps = []) => {
  const appContext = useContext(AppContext);

  const mutate = useMutation({
    mutationKey: ["setStatus", ...deps],
    mutationFn: (body) => setStatus(appContext.idToken)(body),
  });
  return mutate;
};
